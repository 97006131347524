import API from "@/helpers/api";

import {
    THeatingService,
    TManualSetting,
    TManualSettings,
    TQuickSettings,
    TQuickSettingsEnableResponses,
} from "./interfaces";

const HeatingService: THeatingService = {

  getManualSettings: siteId => (
    API.get<TManualSettings>(`/api/site/${siteId}/manualsetting`)
    .then(({ data }) => data)
  ),

  updateManualSetting: (siteId, manualSettingId, body) => (
    API.put<TManualSetting>(`/api/site/${siteId}/manualsetting/${manualSettingId}`, body)
    .then(({ data }) => data)
  ),

  getQuickSettings: siteId => (
    API.get<TQuickSettings[]>(`/api/site/${siteId}/quicksettings`)
    .then(({ data }) => data)
  ),

  updateQuickSettings: (siteId, quickSettingsId, body) => (
    API.put<TQuickSettings>(`/api/site/${siteId}/quicksettings/${quickSettingsId}`, body)
    .then(({ data }) => data)
  ),

  enableQuickSettings: (siteId, quickSettingsId, enabled) => (
    API.put<TQuickSettingsEnableResponses>(`/api/site/${siteId}/quicksettings/${quickSettingsId}/enable`, { enabled })
    .then(({ data }) => data)
  ),

  initQuickSettings: (siteId) => (
      API.post<TQuickSettings[]>(`/api/site/${siteId}/quicksettings/init`)
          .then(({data}) => data)
  )
  
};

export default HeatingService;
