import {isWebViewMobileApp} from "@/helpers/domains/device";
import {
    EGeolocationResponseType, TGeolocationDeviceStateResponse,
    TGeolocationDeviceInfoResponse,
    TGeolocationStateResponse, TGeolocationVersionResponse
} from "@/services/Geolocation/interfaces";
import {
    EFlutterOpenUrlInWebviewType,
    EGeolocationRequests,
    EFlutterConnectorRequestType, EVoltalisConnectorRequests,
    TVoltalisRequestVersionType,
    EVoltalisConnectorRating
} from "@/helpers/domains/mobileApps/interfaces";

const setToken = (token: string | null, siteId: number | undefined) => {
    if (isWebViewMobileApp(window) && token && siteId) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {
            type: 'setToken',
            token,
            siteId: siteId
        })
    }
}
const refreshGeofences = () => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.refreshGeofences})
    }
}

const getDeviceInfos = async (): Promise<TGeolocationDeviceInfoResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.getDeviceInfo})
    }
}
const openUrlInNewWebview = async (type: EFlutterOpenUrlInWebviewType, checkLoading: boolean, url: string): Promise<void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {
            type,
            checkLoading,
            url
        });
    }
}

const closeCurrentWebview = async (): Promise<void> => {
    if (isWebViewMobileApp(window)) {
       return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {type: 'close'});
    }
}

const getGeolocationVersion = async (): Promise<TGeolocationVersionResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.getVersion})
    }
}

const getVoltalisVersion = async (): Promise<TVoltalisRequestVersionType | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {type: EVoltalisConnectorRequests.getVersion})
    }
}

const getPermissionsState = async (): Promise<TGeolocationStateResponse[] | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.getPermissionsState});
    }
}

const getDeviceLocationState = async (): Promise<TGeolocationDeviceStateResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.getLocalisationState});
    }
}

const openAppSettings = async (): Promise<TGeolocationStateResponse[] | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {type: EGeolocationRequests.openAppSettings})
    }
}

const requirePermission = async (locationType: EGeolocationResponseType): Promise<TGeolocationStateResponse | void> => {
    if (isWebViewMobileApp(window)) {
        return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.geolocation, {
            type: EGeolocationRequests.requirePermission,
            permission: locationType
        })
    }
}

const openAppRatingModal = () => {
  if (isWebViewMobileApp(window)) {
    return window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {type: EVoltalisConnectorRating.openAppRatingModal})
  }
}

export {
    setToken,
    refreshGeofences,
    getDeviceInfos,
    getPermissionsState,
    openAppSettings,
    requirePermission,
    getGeolocationVersion,
    getDeviceLocationState,
    getVoltalisVersion,
    openUrlInNewWebview,
    closeCurrentWebview,
    openAppRatingModal
}
