import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "fieldset__inner__input_error"
}
const _hoisted_5 = { class: "MultiStep__footer inner" }

import {computed, onBeforeMount, reactive, ref, watch} from "vue";

import {Form} from "ant-design-vue";
import {checkPasswordSecurity, containsSpecialChars, hasUpper} from '@/helpers/string/password';

import {TPasswordStep} from '../interfaces';
import {AuthModule} from "@/store";
import ERoutes from "@/router/routes";
import {useRoute, useRouter} from "vue-router";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPasswordStep',
  props: {
    next: { type: Function },
    prev: { type: Function }
  },
  setup(__props: any) {

const props = __props

const form = reactive({
  password: '',
  confirmPassword: '',
})

const passwordIsValid = ref(false);
const passwordHasUpper = ref(false);
const passwordHasHeightCaracters = ref(false);
const passwordHasSpecialCaracter = ref(false);
const confirmPasswordIsValid = ref(true);

const {t} = useI18n()
const router = useRouter()
const route = useRoute()

const helpText = computed(() => {
  return `<span class="${passwordHasHeightCaracters.value ? 'is-valid' : 'is-invalid'}">${t('firstConnection.password_security.characters_length', {length: 8})}</span>,
                  <span class="${passwordHasUpper.value ? 'is-valid' : 'is-invalid'}">${t('firstConnection.password_security.upper', {length: 1})}</span>,
                  <span class="${passwordHasSpecialCaracter.value ? 'is-valid' : 'is-invalid'}">${t('firstConnection.password_security.special_character', {length: 1})}</span>`
})

const token = computed(() => {
  return route.query?.token as string;
})

const subscriberId = computed(() => {
  return route.query?.subscriberId as any;
})

const updateConfirmPasswordIsValid = (password: string, confirmPassword: string) => {
  confirmPasswordIsValid.value = confirmPassword === password;
}

const onFinish = () => {
  if (passwordIsValid.value && confirmPasswordIsValid.value) {
    AuthModule.createOnboardingPassword({
      newPassword: form.password,
      token: token.value,
      subscriberId: subscriberId.value
    })
        .then((data) => AuthModule.tryConnectUserFromToken(data.token))
        .then((data) => {
          if (!data) {
            return router.push(ERoutes.RESET_EMAIL_TOKEN);
          }
          props.next();
        })
        .catch(() => {
          router.push(ERoutes.RESET_EMAIL_TOKEN);
        });
  }
}

onBeforeMount(() => {
  updateConfirmPasswordIsValid(form.password, form.confirmPassword);
})

watch(() => form.password, (newValue) => {
  passwordHasHeightCaracters.value = newValue.length >= 8
  passwordHasUpper.value = hasUpper(newValue)
  passwordHasSpecialCaracter.value = containsSpecialChars(newValue)
  passwordIsValid.value = checkPasswordSecurity(newValue);
}, {deep: true})

watch(() => form.confirmPassword, (newValue) => {
  updateConfirmPasswordIsValid(form.password, newValue);

}, {deep: true})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    model: form,
    onFinish: onFinish,
    class: "MultiStep__form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('firstConnection.password')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MyVAntdinput, {
                label: _ctx.$t('form.password'),
                inputType: _unref(EInputType).Password,
                value: form.password,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.password) = $event)),
                id: "password",
                inputRequired: true
              }, {
                help: _withCtx(() => [
                  _createElementVNode("div", {
                    style: {"font-size":"10px"},
                    innerHTML: helpText.value
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["label", "inputType", "value"]),
              _createVNode(MyVAntdinput, {
                label: _ctx.$t('form.confirm_password'),
                inputType: _unref(EInputType).Password,
                value: form.confirmPassword,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.confirmPassword) = $event)),
                id: "confirm_password",
                inputRequired: true,
                error: confirmPasswordIsValid.value ? undefined : _ctx.$t('firstConnection.confirm_password_error')
              }, {
                help: _withCtx(() => [
                  (!confirmPasswordIsValid.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('profile.security.password_not_valid')), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label", "inputType", "value", "error"])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(MyVButton, {
          primary: false,
          type: _unref(EButtonType).Submit,
          disabled: !(form.confirmPassword.length && passwordIsValid.value && confirmPasswordIsValid.value)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('button.first_connection_password_form')), 1)
          ]),
          _: 1
        }, 8, ["type", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})