import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "PageWithReturn__headerPage" }
const _hoisted_2 = {
  key: 0,
  class: "PageWithReturn__subHeader"
}
const _hoisted_3 = { class: "PageWithReturn__main" }
const _hoisted_4 = {
  key: 1,
  class: "PageWithReturn__footer"
}

import {TPageWithReturn} from "./interfaces";
import MyVHeaderPage from "@/components/ui/molecules/MyVHeaderPage/MyVHeaderPage.vue";

interface Props {
  title: TPageWithReturn["title"];
  subtitle?: TPageWithReturn["subtitle"];
  type: TPageWithReturn["type"];
  returnAction: TPageWithReturn["returnAction"];
  headerRight?: any;
  hasHeader?: boolean;
  subHeader?: any;
  footer?: any;
  className?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPageWithReturn',
  props: {
    title: { default: '' },
    subtitle: { default: '' },
    type: { default: undefined },
    returnAction: { default: undefined },
    headerRight: { default: '' },
    hasHeader: { type: Boolean, default: true },
    subHeader: { default: '' },
    footer: { default: '' },
    className: { default: '' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['PageWithReturn__container', {'PageWithReturn__popin':_ctx.type=='popin'}])
  }, [
    (_ctx.type=='popin' && typeof(_ctx.returnAction)=='function')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
          class: "PageWithReturn__backdrop"
        }, "  "))
      : (_ctx.type=='popin')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: _ctx.returnAction,
            class: "PageWithReturn__backdrop"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" ")
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['PageWithReturn', _ctx.className])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.hasHeader)
          ? (_openBlock(), _createBlock(MyVHeaderPage, {
              key: 0,
              title: _ctx.title,
              subtitle: _ctx.subtitle,
              type: _ctx.type,
              returnAction: _ctx.returnAction
            }, {
              header__right: _withCtx(() => [
                _renderSlot(_ctx.$slots, "headerRight", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.headerRight), 1)
                ])
              ]),
              _: 3
            }, 8, ["title", "subtitle", "type", "returnAction"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.$slots.subHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "subHeader", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.subHeader), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
            _renderSlot(_ctx.$slots, "footer", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.footer), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})