import API from "@/helpers/api";

import {TObjective, TObjectiveService} from "./interfaces";

const ObjectiveService: TObjectiveService = {
    getObjective: siteId => (
        API.get<TObjective>(`/api/site/${siteId}/objective`)
            .then(({data}) => data)
    ),

    updateObjectiveWh: (siteId, objective) => (
        API.put<TObjective>(`/api/site/${siteId}/objective/wh?objectiveInWh=${objective}`)
            .then(({data}) => data)
    ),

    updateObjectiveCurrency: (siteId, objective) => (
        API.put<TObjective>(`/api/site/${siteId}/objective/currency?objectiveInCurrency=${objective}`)
            .then(({data}) => data)
    ),

    getCurrencyEstimation: (siteId, wh) => (
        API.get(`/api/site/${siteId}/objective/currency?objectiveInWh=${wh}`, {skipLoading: true})
            .then(({data}) => data)
    ),

    getWhEstimation: (siteId, currency) => (
        API.get(`/api/site/${siteId}/objective/wh?objectiveInCurrency=${currency}`, {skipLoading: true})
            .then(({data}) => data)
    ),

    deleteObjective: siteId => (
        API.delete(`/api/site/${siteId}/objective`)
    )
};

export default ObjectiveService;
