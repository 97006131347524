import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "contractTypePopin"
}
const _hoisted_2 = { class: "contractTypeRadiocontainer" }
const _hoisted_3 = { class: "BottomPopin__text" }
const _hoisted_4 = { class: "BottomPopin__title" }
const _hoisted_5 = { class: "BottomPopin__buttons" }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import ERoutes from "@/router/routes";
import ProfileStep from "@/views/domains/Profile/Contract/ProfileStepView/ProfileStepView.vue";
import ProviderStep from "@/views/domains/Profile/Contract/ProviderStepView/ProviderStepView.vue";
import ChooseContractStep from "@/views/domains/Profile/Contract/ChooseContractStepView/ChooseContractStepView.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {ContractModule, SiteModule} from "@/store";
import {TCompanyContract, TCompanyContracts, TContractEditable, TProvider} from "@/services/Contract/interfaces";
import SubscribedPowerStep from "@/views/domains/Profile/Contract/SubscribedPowerStepView.vue";
import MyVAntdradio from "@/components/ui/atoms/MyVAntdradio/MyVAntdradio.vue";
import EditOrCreateContract from "@/views/domains/Profile/Contract/EditOrCreateContractView.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {useDate} from "@/helpers/dates/date-utils";

interface Props {
  type?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContractView',
  props: {
    type: { default: 'page' }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const showProviderPopin = ref(false);
const showContractPopin = ref(false);
const showContractTypePopin = ref(false);
const showPowerPopin = ref(false);
const isOffPeakHours = ref(false);
const showDeleteContractPopin = ref(false);
const error = ref<string | null>(null)
const powerList = ref<TCompanyContracts>([]);
const contractCreation = ref<TContractEditable | null>(null);

const route = useRoute()
const router = useRouter()
const {t} = useI18n()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const currentRight = computed(() => {
  return EDisplayGroupRightName.MY_CONTRACT;
})

const forbiddenRedirection = computed(() => {
  return ERoutes.HOME;
})

const contractsList = computed(() => {
  return ContractModule.contractsList;
})

const contractKey = computed(() => {
  return ContractModule.contractKey;
})

const contractTypeOptions = computed(() => {
  return [
    {
      label: t('profile.contract.base_type'),
      value: false
    },
    {
      label: t('profile.contract.hours_type'),
      value: true
    }
  ]
})

const fakeContract = computed(() => {
  return ContractModule.fakeContract;
})

const contractHasMode = computed(() => {
  return route.query.mode;
})

const openProviderPopin = () => {
  ContractModule._initFakeContract(route.query.contractId ? parseInt(route.query.contractId as string) : null);
  showProviderPopin.value = true;
}

const backLink = () => {
  if (props.type === 'popin') {
    emit('close')
  } else {
    return router.push(`${ERoutes.HOME}`)
  }
}

const skipContract = (provider: TProvider) => {
  ContractModule._updateFakeContractProperty({
    companyName: provider.name ?? provider.businessName,
    name: t('choose_contract.other_contract'),
  })
  showContractPopin.value = false;
  showProviderPopin.value = true;
}

const goToContractChoice = (provider: TProvider) => {
  ContractModule._updateFakeContractProperty({
    companyName: provider.name,
    name: t('choose_contract.other_contract'),
    companyId: provider.id
  });
  showProviderPopin.value = false;
  showContractPopin.value = true;
}

const goToAfterContractStep = (contractName: string, providerContractList: TCompanyContracts) => {
  ContractModule._updateFakeContractProperty({
    name: contractName,
  });
  powerList.value = providerContractList;
  showContractPopin.value = false;

  if (isSiteCountryFrance.value) {
    showPowerPopin.value = true;
  } else {
    showContractTypePopin.value = true
  }
}

const openContractTypePopin = () => {
  showContractTypePopin.value = true;
}

const closePopin = () => {
  showContractPopin.value = showProviderPopin.value = showPowerPopin.value = showContractTypePopin.value = false;
}

const closePopinAndReset = () => {
  if (route.query.mode === 'create') {
    return router.push(ERoutes.CONTRACT);
  }
  showContractPopin.value = showProviderPopin.value = showPowerPopin.value = showContractTypePopin.value = false;
  contractCreation.value = null;
  ContractModule.getContractsList();
  ContractModule._incrementContractKey();
}

const confirmDeleteContract = () => {
  if (contractsList.value.length === 1) {
    ContractModule.deleteAllContracts().finally(() => {
      router.push(ERoutes.CONTRACT);
    });
  } else {
    const contractId = parseInt(route.query.contractId as string);
    const filteredContracts = contractsList.value.filter(contract => contract.id !== contractId);

    for (let i = 0; i < filteredContracts.length; ++i) {
      if (i === 0 && i === filteredContracts.length - 1) {
        filteredContracts[i].endDate = null;
        filteredContracts[i].startDate = null;
      } else if (i === 0 && i < filteredContracts.length - 1) {
        filteredContracts[i].endDate = null;
      } else if (filteredContracts[i - 1] && filteredContracts[i - 1].startDate && i !== filteredContracts.length - 1) {
        filteredContracts[i].endDate = useDate(filteredContracts[i - 1].startDate).substract(1, 'day').format('YYYY-MM-DD')
      } else if (i === filteredContracts.length - 1) {
        filteredContracts[i].endDate = useDate(filteredContracts[i - 1].startDate).substract(1, 'day').format('YYYY-MM-DD')
        filteredContracts[i].startDate = null;
      }
    }

    ContractModule.updateContract(filteredContracts).then(() => {
      router.push(ERoutes.CONTRACT);
    });
  }
}

const updateContractPowerDatas = async (contract: TCompanyContract | null) => {

  const basePayload: Partial<TContractEditable> = {
    id: fakeContract.value!.id!,
    name: fakeContract.value!.name!,
    subscribedPower: fakeContract.value!.subscribedPower,
    companyName: fakeContract.value!.companyName!,
    companyId: fakeContract.value!.companyId,
    apiContractId: fakeContract.value!.apiContractId,
    siteId: fakeContract.value!.siteId!,
    subscriptionBasePrice: fakeContract.value!.subscriptionBasePrice ?? 0,
    kwhBasePrice: fakeContract.value!.kwhBasePrice ?? 0,
    subscriptionPeakAndOffPeakHourBasePrice: null,
    offpeakHours: [],
    kwhPeakHourPrice: null,
    kwhOffpeakHourPrice: null
  }

  const hpHcPayload: Partial<TContractEditable> = {
    id: fakeContract.value!.id!,
    name: fakeContract.value!.name!,
    subscribedPower: fakeContract.value!.subscribedPower,
    companyName: fakeContract.value!.companyName!,
    siteId: fakeContract.value!.siteId!,
    companyId: fakeContract.value!.companyId,
    apiContractId: fakeContract.value!.apiContractId,
    subscriptionPeakAndOffPeakHourBasePrice: fakeContract.value!.subscriptionPeakAndOffPeakHourBasePrice ?? 0,
    kwhPeakHourPrice: fakeContract.value!.kwhPeakHourPrice ?? 0,
    kwhOffpeakHourPrice: fakeContract.value!.kwhOffpeakHourPrice ?? 0,
    offpeakHours: fakeContract.value!.offpeakHours.length ? fakeContract.value!.offpeakHours : [{
      from: "23:00",
      to: "07:00"
    }],
  }

  let payload = basePayload;

  if (isOffPeakHours.value && contract?.name === "choose_contract.other_contract") {
    payload = hpHcPayload
  } else if (isOffPeakHours.value) {
    payload = hpHcPayload;
  }

  contractCreation.value = payload as TContractEditable;

  closePopin();
}

const selectedPowerContract = (contract: TCompanyContract | null) => {
  ContractModule._updateFakeContractProperty({
    companyName: contract?.company?.name ?? fakeContract.value?.companyName,
    name: (contract?.name ?? contract?.businessName) ?? fakeContract.value?.name,
    kwhOffpeakHourPrice: contract?.kwhOffpeakHourPrice ?? fakeContract.value?.kwhOffpeakHourPrice,
    subscribedPower: contract?.subscribedPower ?? fakeContract.value?.subscribedPower,
    subscriptionPeakAndOffPeakHourBasePrice: contract?.subscriptionPeakAndOffPeakHourBasePrice ?? fakeContract.value?.subscriptionPeakAndOffPeakHourBasePrice,
    subscriptionBasePrice: contract?.subscriptionBasePrice ?? fakeContract.value?.subscriptionBasePrice,
    kwhBasePrice: contract?.kwhBasePrice ?? fakeContract.value?.kwhBasePrice,
    kwhPeakHourPrice: contract?.kwhPeakHourPrice ?? fakeContract.value?.kwhPeakHourPrice,
    apiContractId: contract?.id ?? fakeContract.value?.apiContractId,
    companyId: contract?.company?.id ?? fakeContract.value?.companyId,
  });
  if (!contract || (contract.subscriptionPeakAndOffPeakHourBasePrice !== null && contract.subscriptionBasePrice !== null)) {
    closePopin();
    openContractTypePopin();
  } else {
    updateContractPowerDatas(contract);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVDisplayGroupStore, {
    currentRight: currentRight.value,
    forbiddenRedirection: forbiddenRedirection.value
  }, {
    default: _withCtx(() => [
      _createVNode(MyVPageWithReturn, {
        title: contractHasMode.value ? _ctx.$t('page.contract.title') : _ctx.$t('page.contracts.title'),
        type: _ctx.type,
        "return-action": backLink,
        class: "ContractPage"
      }, _createSlots({
        default: _withCtx(() => [
          (error.value)
            ? (_openBlock(), _createBlock(ErrorBanner, {
                key: 0,
                error: error.value,
                class: "error-banner",
                onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null))
              }, null, 8, ["error"]))
            : _createCommentVNode("", true),
          (!contractHasMode.value)
            ? (_openBlock(), _createBlock(ProfileStep, {
                key: 1,
                onNoProvider: openProviderPopin,
                class: "ProfileStepPage"
              }))
            : (_openBlock(), _createBlock(EditOrCreateContract, {
                contractCreation: contractCreation.value,
                key: contractKey.value,
                onNoProvider: openProviderPopin,
                class: "ProfileStepPage",
                onResetCreation: _cache[1] || (_cache[1] = ($event: any) => (contractCreation.value=null))
              }, null, 8, ["contractCreation"]))
        ]),
        _: 2
      }, [
        (contractHasMode.value === 'edit')
          ? {
              name: "headerRight",
              fn: _withCtx(() => [
                (_openBlock(), _createElementBlock("svg", {
                  class: "icon icon-delete-forever",
                  "aria-hidden": "true",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (showDeleteContractPopin.value = true))
                }, _cache[7] || (_cache[7] = [
                  _createElementVNode("use", { "xlink:href": "#icon-delete-forever" }, null, -1)
                ])))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title", "type"]),
      (showContractPopin.value || showProviderPopin.value || showPowerPopin.value || showContractTypePopin.value)
        ? (_openBlock(), _createBlock(MyVPageWithReturn, {
            key: 0,
            title: _ctx.$t('page.contract.title'),
            type: "popin",
            "return-action": closePopinAndReset
          }, {
            default: _withCtx(() => [
              (showProviderPopin.value)
                ? (_openBlock(), _createBlock(ProviderStep, {
                    key: 0,
                    onNext: goToContractChoice,
                    onSkipContract: skipContract
                  }))
                : _createCommentVNode("", true),
              (showContractPopin.value)
                ? (_openBlock(), _createBlock(ChooseContractStep, {
                    key: 1,
                    onNext: goToAfterContractStep
                  }))
                : _createCommentVNode("", true),
              (showPowerPopin.value)
                ? (_openBlock(), _createBlock(SubscribedPowerStep, {
                    key: 2,
                    onFinish: selectedPowerContract,
                    powerList: powerList.value
                  }, null, 8, ["powerList"]))
                : _createCommentVNode("", true),
              (showContractTypePopin.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('profile.contract.contract_type_label')), 1),
                      _createVNode(MyVAntdradio, {
                        options: contractTypeOptions.value,
                        value: isOffPeakHours.value,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((isOffPeakHours).value = $event))
                      }, null, 8, ["options", "value"])
                    ]),
                    _createVNode(MyVButton, {
                      type: _unref(EButtonType).Submit,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (updateContractPowerDatas(fakeContract.value)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.submit')), 1)
                      ]),
                      _: 1
                    }, 8, ["type"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (showDeleteContractPopin.value)
        ? (_openBlock(), _createBlock(MyVBottomPopin, {
            key: 1,
            onCancel: _cache[6] || (_cache[6] = ($event: any) => (showDeleteContractPopin.value = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[8] || (_cache[8] = _createElementVNode("svg", {
                  class: "icon icon-big-colored-delete-forever",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                ], -1)),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('profile.contract.popin.delete.single_contract.title')), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(MyVButton, {
                    class: "BottomPopin__confirm",
                    onClick: confirmDeleteContract
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, {
                    class: "BottomPopin__cancel",
                    primary: false,
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (showDeleteContractPopin.value = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["currentRight", "forbiddenRedirection"]))
}
}

})