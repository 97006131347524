import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import ERoutes from "@/router/routes";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {useRouter} from "vue-router";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {useResponsive} from "@/composables/useResponsive";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDefineContractPopin',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const router = useRouter()
const {isMobile} = useResponsive()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
    title: _ctx.$t('page.contracts.title'),
    type: "popin",
    onCancel: _cache[1] || (_cache[1] = () =>emit('close')),
    "return-action": () =>emit('close')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.$t('contract.intro_step.text')
      }, null, 8, _hoisted_1),
      _createVNode(MyVButton, {
        primary: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ path:`${_unref(ERoutes).CONTRACT}`,query:{ mode: 'create'}}))),
        type: _unref(EButtonType).Button
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.create_contract')), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    _: 1
  }, 40, ["title", "return-action"]))
}
}

})