import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__timepicker-inlinelabel"
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['form__timepicker'])
}
const _hoisted_3 = {
  key: 0,
  class: "form__group__label"
}
const _hoisted_4 = { class: "form__group__label-text" }
const _hoisted_5 = { key: 0 }

import {TTimepicker} from "./interfaces";

import {Form, TimePicker} from 'ant-design-vue';

import moment from "moment";
import {g_timeFormat} from "@/helpers/domains/dailyprogs";
import {computed, ref, onBeforeMount, watch} from "vue";

export interface Props {
  inputRequired?: TTimepicker["inputRequired"];
  placeholder?: TTimepicker["placeholder"];
  id?: TTimepicker["id"];
  label?: TTimepicker["label"];
  inlinelabel?: TTimepicker["inlinelabel"];
  minuteStep?: TTimepicker["minuteStep"];
  value?: TTimepicker["value"];
  error?: TTimepicker["error"];
  change?: TTimepicker["change"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntdtimepicker',
  props: {
    inputRequired: { type: Boolean, default: false },
    placeholder: { default: undefined },
    id: { default: undefined },
    label: { default: undefined },
    inlinelabel: { default: undefined },
    minuteStep: { default: 15 },
    value: { default: undefined },
    error: { default: undefined },
    change: { type: Function, default: undefined }
  },
  emits: ['update:value', 'openChange'],
  setup(__props: any, { emit: __emit }) {

const {Item} = Form;

const props = __props

const emit = __emit

const m_value = ref<moment.Moment | null>();
const open = ref(false);

const hasValue = computed(() => {
  return m_value.value !== null;
})

const onChange = (time: moment.Moment, formattedTime: string) => {
  m_value.value = time;
  emit('update:value', formattedTime);
  if (props.change) {
    props.change(formattedTime);
  }
}

const onOpenChange = (openValue: boolean) => {
  open.value = openValue;
  emit('openChange', openValue);
}

onBeforeMount(() => {
  m_value.value = props.value ? moment(props.value, g_timeFormat) : null;
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    if (newValue!.split(':')[1] !== oldValue!.split(':')[1]) {
      open.value = false;
    }
    m_value.value = newValue ? moment(newValue, g_timeFormat) : null;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Item), {
    class: _normalizeClass(['form__group form__timepicker-container', {'form__timepicker--withInlinelabel':_ctx.inlinelabel}]),
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      (_ctx.inlinelabel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.inlinelabel), 1))
        : _createCommentVNode("", true),
      _createElementVNode("label", _hoisted_2, [
        _createVNode(_unref(TimePicker), {
          class: _normalizeClass({'has-value': hasValue.value}),
          allowClear: !_ctx.inputRequired,
          inputReadOnly: "",
          placeholder: _ctx.placeholder,
          value: m_value.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((m_value).value = $event)),
          "show-time": true,
          format: "HH:mm",
          onChange: onChange,
          onOpenChange: onOpenChange,
          "minute-step": _ctx.minuteStep,
          open: open.value
        }, null, 8, ["class", "allowClear", "placeholder", "value", "minute-step", "open"]),
        (_ctx.label!==null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.label), 1),
                (_ctx.inputRequired)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "form__group__bar" }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["class", "validate-status", "help"]))
}
}

})