export type TCookieOptions = {
    /**
     * Number of millisecond or date before expiration
     */
    expires?: number | string | Date;

    /**
     * cookie's path, defaults '/'.
     */
    path?: string;

    /**
     * cookie is secure ?
     */
    secure?: boolean;

    /**
     * cookie is use only on HTTP request ?
     */
    httpOnly?: boolean;

    /**
     * cookie's domain, this defaults to the host portion of the current document location.
     */
    domain?: string;

    /**
     * cookie's domain
     */
    "max-age"?: number;

    /**
     * Prevents the browser from sending this Cookie along with cross-site requests.
     */
    samesite?: "lax" | "strict" | "none";
};

export enum ECookieNames {
    CONSUMPTION_CACHE_TTL = '_consumptionCache',
    OBSOLETE_APP_BANNER_DISPLAY_COOKIE = '_obsoleteAppBannerDisplayCookie',
    MOBILE_BANNER_DISPLAY_COOKIE = 'mobileBannerDisplayCookie',
    TOKEN = "__token",
    ENEDIS_DATE = "__enedisDate",
    ENEDIS_COUNTER = "__enedisCounter",
    CONTRACT_NOTIF = "__contractNotif",
    CURRENT_SITE_ID = "__currentSiteId",
    REFRESH_TOKEN = "__refreshToken",
    DONT_ASK_AGAIN_PROG_REMINDER = "__progReminder",
    DONT_ASK_AGAIN_TEMPERATURE_CONTROL_REMINDER = "__temperatureControlReminder",
    DONT_ASK_AGAIN_DEFAULT_CONTRACT = "__defaultContractReminder",
    LOCALE = "__locale",
    EKWATEUR_LOCALE = "isEkwateurLocale",
    PRO_LOCALE = "isProLocale",
}

export type TCookie = { [key: string]: string };
