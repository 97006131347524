import {TDisplayGroupResources} from "@/services/DisplayGroup/interfaces";

export type TThemeInfo = {
    /**
     * all the static resources
     */
    staticResources: TDisplayGroupResources;
    /**
     * Display group name (Ekwateur etc..)
     */
    displayGroupName: string;
};

type TGetTheme = (host?: string) => Promise<TThemeInfo>;

export const DISPLAY_GROUP_EKW = 'Ekwateur';
export const DISPLAY_GROUP_PRO = 'Petit Pro';
export const DISPLAY_GROUP_BETA_TESTER = 'Beta Tester';

export type TThemeService = {
    /**
     * Send a GET request to retrieve the theme.
     */
    getTheme: TGetTheme;
};
