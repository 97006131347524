import API from "@/helpers/api";
import {TNotifications, TNotificationService} from "@/services/Notification/interfaces";

const NotificationService: TNotificationService = {

    getNotifications: (siteId, skipLoading = false) => (
        API.get<TNotifications[]>(`/api/site/${siteId}/notification`, {
            params: {},
            skipLoading
        })
            .then(({data}) => data)
    ),

    readNotifications: (siteId, notificationsId) => (
        API.put(`/api/site/${siteId}/notification`, notificationsId)
            .then(({data}) => data)
    ),

    deleteNotifications: (siteId, notificationsId) => (
        API.delete(`/api/site/${siteId}/notification`, {
            data: notificationsId
        }).then(({data}) => data)
    ),

}

export default NotificationService;
