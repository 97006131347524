import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "behaviourCheckboxes" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "behaviourCheckboxes__group"
}

import {TBehaviourCheckbox} from "./interfaces";

import MyVCheckbox from '@/components/ui/atoms/MyVCheckbox/MyVCheckbox.vue'
import {ref, computed, watch} from "vue";
import {arrayAreDiff} from "@/helpers/array/array";

const NO_OPTION_SELECTED = '__NO_OPTION_SELECTED__';

export interface Props {
  label: TBehaviourCheckbox["label"];
  options: TBehaviourCheckbox["options"];
  values?: TBehaviourCheckbox["values"];
  defaultValues?: TBehaviourCheckbox["defaultValues"];
  noSelectedlabel: TBehaviourCheckbox["noSelectedlabel"];
  showBox?: TBehaviourCheckbox['showBox']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBehaviourCheckbox',
  props: {
    label: { default: undefined },
    options: { default: undefined },
    values: { default: undefined },
    defaultValues: { default: () => [NO_OPTION_SELECTED] },
    noSelectedlabel: { default: undefined },
    showBox: { type: Boolean, default: true }
  },
  emits: ['update:values'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const _values = ref<TBehaviourCheckbox['values']>([]);

const formatedLabel = computed(() => {
  let formatedLabel = props.label!;
  if (formatedLabel && props.label!.length > 50) {
    formatedLabel = formatedLabel.slice(0, 50) + "...";
  }

  return formatedLabel;
})

watch(_values, (values, oldValues) => {
  if (arrayAreDiff<TBehaviourCheckbox['values'][number]>(values, oldValues)) {
    const noSelect = [NO_OPTION_SELECTED];

    if (values.length === 0) {
      _values.value = noSelect;
    }

    if (!oldValues.includes('void') && values.includes('void')) {
      _values.value = _values.value.filter(item => item === 'void')
    }

    if (oldValues.includes('void') && values.length > 1 && values.includes('void')) {
      _values.value = _values.value.filter(item => item !== 'void')
    }

    emit('update:values', (_values.value.length === 1 && noSelect[0] === _values.value[0]) ? [] : [..._values.value]);
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "behaviourCheckboxes__label",
      title: _ctx.label
    }, _toDisplayString(formatedLabel.value), 9, _hoisted_2),
    (_ctx.showBox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(MyVCheckbox, {
            value: _values.value,
            "onUpdate:value": [
              _cache[0] || (_cache[0] = ($event: any) => ((_values).value = $event)),
              _cache[1] || (_cache[1] = args => _values.value = args )
            ],
            options: _ctx.options
          }, null, 8, ["value", "options"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})