import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mobileBanner" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "store" }
const _hoisted_6 = { class: "name" }

import Cookie from "@/helpers/cookie";
import {ThemeModule} from "@/store";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {ECookieNames} from "@/helpers/cookie/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVMobileBanner',
  emits: ['close', 'goToStore'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const {t} = useI18n()

const mobileBannerText = computed(() => {
  const userAgent = window.navigator.userAgent;
  const isIphone = userAgent.match(/iPhone/);
  const isIpad = userAgent.match(/iPad/);

  if (isIphone !== null || isIpad !== null) {
    return t("mobile_banner.apple_store")
  } else {
    return t("mobile_banner.android_store")
  }
})

const isEkwateur = computed(() => {
  return Cookie.get(ECookieNames.EKWATEUR_LOCALE) === 'true';
})

const logo = computed(() => {
  return ThemeModule.theme?.staticResources.favicon;
})

const close = () => {
  emit('close')
}

const goToStore = () => {
  emit('goToStore')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: "icon icon-cross",
      "aria-hidden": "true",
      onClick: close
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
    ]))),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: logo.value,
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(mobileBannerText.value), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(isEkwateur.value ? "Narco by Ekwateur" : "MyVoltalis"), 1)
      ])
    ]),
    _createElementVNode("div", {
      onClick: goToStore,
      class: "linkStore"
    }, _toDisplayString(_ctx.$t('mobile_banner.open')), 1)
  ]))
}
}

})