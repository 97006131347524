abstract class AOctree<TNode> {
    private _world: TNode;
    private _nodes: TNode[];
    private _subWorlds: AOctree<TNode>[] | null;
    private _lastCollision: [TNode, TNode] | null;

    // CTOR
    public constructor(deep: number, ...params: number[]) {
        if (this.constructor.name === 'AOctree') {
            throw new Error('AOctree is an abstract class.');
        }
        this._world = this.createNode(...params);
        this._nodes = [];
        this._lastCollision = null;

        if (deep > 0) {
            const coordinates = params.slice(0, params.length / 2);
            const distances = params.slice(coordinates.length).map(distance => distance / 2);

            if (coordinates.length !== distances.length) {
                throw new Error('You should have as mush coordinates as dimensionnal distances.')
            }

            this._subWorlds = new Array(2**coordinates.length);
            for (let i = 0; i < this._subWorlds.length; ++i) {
                this._subWorlds[i] = new (this as any).constructor(...this.recreateCtorStack(
                    coordinates.map((coordinate, j) => coordinate + distances[j] * Math.min(~~((j ? (i / (2**j)) : (i % 2))), 1)),
                    distances,
                    deep - 1
                ));
            }
        }
        else {
            this._subWorlds = null;
        }
    }

    // Accessors
    public get lastCollision() {
        return this._lastCollision;
    }

    protected get world() {
        return this._world;
    }

    // Methods
    public addNode(...params: number[]) {
        const newNode = this.createNode(...params);
        const octree = this.searchBestWorldForNode(newNode) ?? this;

        if (octree === false || octree.testCollision(newNode)) {
            return false;
        }
        octree._nodes.push(newNode);
        return true;
    }

    public clearCollision() {
        this._lastCollision = null;
    }

    // Virtual
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected createNode(..._: number[]): TNode {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected hasCollision(node1: TNode, node2: TNode): boolean {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected isInsideWorld(node: TNode): boolean {
        throw new Error('Method not implemented.');
    }

    // Override
    // By default, Every child of AOctree is design to follow the parameters structure 
    // (...coordinates, ...distances, deep) on their constructor.
    protected recreateCtorStack(coordinates: number[], distances: number[], deep: number) {
        return [
            ...coordinates,
            ...distances,
            deep
        ];
    }

    private searchBestWorldForNode(node: TNode): AOctree<TNode> | false {
        const isInside = this.isInsideWorld(node);

        if (this._subWorlds !== null) {
            if (isInside) {
                let i = 0;

                while (i < this._subWorlds.length) {
                    const childOctree = this._subWorlds[i].searchBestWorldForNode(node);

                    if (childOctree !== false) {
                        return childOctree;
                    }
                    ++i;
                }
                return this;
            }
        }
        else if (isInside) {
            return this;
        }
        return false;
    }

    private testCollision(node: TNode): boolean {
        for (const _node of this._nodes) {
            if (this.hasCollision(_node, node)) {
                this._lastCollision = [_node, node];
                return true;
            }
        }
        if (Array.isArray(this._subWorlds)) {
            for (const _octree of this._subWorlds) {
                if (_octree.testCollision(node)) {
                    return true;
                }
            }
        }
        return false;
    }
}

export type TOctree2DNode = {
    /**
     * X position of the rectangle top left corner
     */
    x: number;

    /**
     * Y position of the rectangle top left corner
     */
    y: number;

    /**
     * Width of the rectangle
     */
    width: number;

    /**
     * Height of the rectangle
     */
    height: number;
};

class Octree2D extends AOctree<TOctree2DNode> {
    public constructor(worldX: TOctree2DNode['x'], worldY: TOctree2DNode['y'], width: TOctree2DNode['width'], height?: TOctree2DNode['height'], maxDeep = 3) {
        if (typeof worldX !== 'number' || Number.isNaN(worldX) || 
            typeof worldY !== 'number' || Number.isNaN(worldY) ||
            typeof width !== 'number' || Number.isNaN(width) ||
            typeof maxDeep !== 'number' || Number.isNaN(maxDeep)) {
            throw new Error('Bad prototype: new Octree2D(worldX: number, worldY: number, width: number, height: ?number, maxDeep: ?number)');
        }
        if (typeof height !== 'number' || Number.isNaN(height)) {
            height = width;
        }
        if (width <= 0 || height <= 0) {
            throw new Error('Width and Height must be positive number');
        }

        super(maxDeep, worldX, worldY, width, height);
    }

    protected createNode(x: TOctree2DNode['x'], y: TOctree2DNode['y'], width: TOctree2DNode['width'], height: TOctree2DNode['height']): TOctree2DNode {
        if (typeof x !== 'number' || Number.isNaN(x) || 
            typeof y !== 'number' || Number.isNaN(y) ||
            typeof width !== 'number' || Number.isNaN(width)) {
            throw new Error('Bad prototype: createNode(x: number, y: number, width: number, height: ?number)');
        }
        if (typeof height !== 'number' || Number.isNaN(height)) {
            height = width;
        }
        return {
            x,
            y,
            width,
            height
        };
    }

    protected hasCollision(entity1: TOctree2DNode, entity2: TOctree2DNode) {
        return (
            entity1.x < entity2.x + entity2.width &&
            entity1.x + entity1.width > entity2.x &&
            entity1.y < entity2.y + entity2.height &&
            entity1.y + entity1.height > entity2.y
        );
    }

    protected isInsideWorld(node: TOctree2DNode) {
        return (
            node.x >= this.world.x &&
            node.x + node.width <= this.world.x + this.world.width &&
            node.y >= this.world.y &&
            node.y + node.height <= this.world.y + this.world.height
        );
    }
}

export {
    Octree2D
};