import {Dayjs} from "dayjs";
import {ELocale} from "@/store/modules/Intl";

export const calendars: { [key in ELocale]?: any } = {
    fr: {
        calendar: {
            sameDay: '[Aujourd’hui à] LT',
            nextDay: '[Demain à] LT',
            nextWeek: 'dddd [à] LT',
            lastDay: '[Hier à] LT',
            lastWeek: 'dddd [dernier à] LT',
            sameElse: 'L',
        }
    },
    fi: {
        calendar: {
            sameDay: '[Tänään] [klo] LT',
            nextDay: '[Huomenna] [klo] LT',
            nextWeek: 'dddd [klo] LT',
            lastDay: '[Eilen] [klo] LT',
            lastWeek: '[Viime] dddd[na] [klo] LT',
            sameElse: 'L',
        }
    },
    sl: {
        calendar: {
            sameDay: '[Danes ob] LT',
            nextDay: '[Jutri ob] LT',
            nextWeek() {
                switch ((this as unknown as Dayjs).day()) {
                    case 0:
                        return 'V nedeljo ob ' + (this as unknown as Dayjs).format('LT');
                    case 3:
                        return 'V sredo ob ' + (this as unknown as Dayjs).format('LT');
                    case 6:
                        return 'V soboto ob ' + (this as unknown as Dayjs).format('LT');
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                        return 'V dddd ob ' + (this as unknown as Dayjs).format('LT');
                }
            },
            lastDay: '[Včeraj ob] LT',
            lastWeek() {
                switch ((this as unknown as Dayjs).day()) {
                    case 0:
                        return 'Prejšnjo nedeljo ob ' + (this as unknown as Dayjs).format('LT');
                    case 3:
                        return 'Prejšnjo sredo ob ' + (this as unknown as Dayjs).format('LT');
                    case 6:
                        return 'Prejšnjo soboto ob ' + (this as unknown as Dayjs).format('LT');
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                        return 'Prejšnji dddd ob ' + (this as unknown as Dayjs).format('LT');
                }
            },
            sameElse: 'L',
        },
    },
    es: {
        calendar: {
            sameDay() {
                return 'Hoy a la' + (((this as unknown as Dayjs).hour() !== 1) ? 's ' : '') + (this as unknown as Dayjs).format('LT');
            },
            nextDay() {
                return 'Mañana a la' + (((this as unknown as Dayjs).hour() !== 1) ? 's ' : '') + (this as unknown as Dayjs).format('LT');
            },
            nextWeek() {
                return 'dddd a la' + (((this as unknown as Dayjs).hour() !== 1) ? 's ' : '') + (this as unknown as Dayjs).format('LT');
            },
            lastDay() {
                return 'Ayer a la' + (((this as unknown as Dayjs).hour() !== 1) ? 's ' : '') + (this as unknown as Dayjs).format('LT');
            },
            lastWeek() {
                return (
                    'El dddd pasado a la' +
                    ((this as unknown as Dayjs).hour() !== 1 ? 's ' : '') +
                    ']' + (this as unknown as Dayjs).format('LT')
                );
            },
            sameElse: 'L',
        },
    }
}

