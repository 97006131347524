import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "changeDuration__title" }
const _hoisted_2 = {
  key: 0,
  class: "changeDuration__list"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "changeDuration__item" }
const _hoisted_5 = { class: "changeDuration__item" }
const _hoisted_6 = {
  key: 1,
  class: "changeDuration__list"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "changeDuration__item" }
const _hoisted_9 = { class: "changeDuration__item" }

import {TChangeDurationHeaterDatas} from "@/components/domains/Heating/MyVChangeDurationHeater/interfaces";
import {AppliancesModule, HeatingModule, IntlModule} from "@/store";
import ERoutes from "@/router/routes";
import {ELocale} from "@/store/modules/Intl";
import frFr from "ant-design-vue/lib/locale/fr_FR";
import enUs from "ant-design-vue/lib/locale/en_US";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {ConfigProvider} from "ant-design-vue";
import MyVAntddatepicker from "@/components/ui/molecules/MyVAntddatepicker/MyVAntddatepicker.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {TCustomDuration} from "@/components/domains/Heating/quicksettings/MyVCustomDuration/interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {useResponsive} from "@/composables/useResponsive";
import {useCookies} from "@vueuse/integrations/useCookies";
import MyVTemperatureControlModal
  from "@/components/domains/Heating/programs/MyVTemperatureControlModal/MyVTemperatureControlModal.vue";
import {useDate} from "@/helpers/dates/date-utils";
import {useCookieNames} from "@/composables/useCookieNames";
import MixpanelService from "@/services/Mixpanel";
import {ETrackQuicksettingEvent} from "@/services/Mixpanel/interfaces";

export interface Props {
  isEmitMode?: TCustomDuration['isEmitMode'],
  showFullSetting?: TCustomDuration['showFullSetting']
  quickSettingsToEnabled: TCustomDuration['quickSettingsToEnabled']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCustomDuration',
  props: {
    isEmitMode: { type: Boolean, default: false },
    showFullSetting: { type: Boolean, default: false },
    quickSettingsToEnabled: { default: undefined }
  },
  emits: ['close', 'setPeriod', 'hasEndDate', 'openReminder'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const duration = ref('')
const hoursDurations = [1, 2, 3, 6, 12, 24]
const daysDurations = [3, 5, 7, 14, 21]
const {temperatureControlReminderCookie} = useCookieNames()
const cookies = useCookies([temperatureControlReminderCookie.value])
const isTemperatureControlModalOpen = ref(false)

const router = useRouter();
const {isMobile} = useResponsive()

const datePickerLocale = computed(() => IntlModule.locale === ELocale.fr ? frFr : enUs)

const hasTemperatureControlCookie = computed(() => {
  return cookies.get(temperatureControlReminderCookie.value)
})

const quickSettingToActivate = computed(() => {
  return props.quickSettingsToEnabled ? props.quickSettingsToEnabled : HeatingModule.quickSettings?.find(({enabled}) => enabled)
})

const quickSettingRoute = () => {
  {
    HeatingModule.changeEnableStateQuickSettings({
      id: props.quickSettingsToEnabled.id,
      enabled: true
    });
    router.push({
      path: `${ERoutes.PROGRAM}`,
      query: {
        quickSettingsId: props.quickSettingsToEnabled!.id
      }
    });
  }
}
const disableFutureDate = (date: Date) => {
  const yesterday = useDate().substract(1, 'day');
  return useDate(date).isBefore(yesterday);
}

const closePopinDuration = () => {
  emit('close');
}

const changeDuration = (duration: number | string) => {
  let untilFurtherNotice = false;
  let endDate = null;
  if (duration == "untilFurtherNotice") {
    untilFurtherNotice = true;
  } else {
    const durationInDays = props.quickSettingsToEnabled.isDaysDuration ? duration as number : 0;
    const durationInHours = props.quickSettingsToEnabled.isDaysDuration ? 0 : duration as number;

    endDate = useDate().add(durationInDays, 'day').add(durationInHours, 'hour').format("YYYY-MM-DDTHH:mm:ss");
  }

  submitDuration({
    "untilFurtherNotice": untilFurtherNotice,
    "endDate": endDate
  });
}

const onCustomDurationChange = (date: any) => {
  submitDuration({
    "untilFurtherNotice": false,
    "endDate": date.format('YYYY-MM-DD[T]HH:mm:ss')
  });
}

const submitDuration = async (formDatas: TChangeDurationHeaterDatas) => {
  if (props.isEmitMode) {
    emit('setPeriod', {
      id: props.quickSettingsToEnabled.id,
      endDate: formDatas.endDate,
      untilFurtherNotice: formDatas.untilFurtherNotice
    })

    closePopinDuration();
    return;
  }

  emit('hasEndDate');

  await HeatingModule.updateQuickSetting({
    id: props.quickSettingsToEnabled.id,
    body: {
      untilFurtherNotice: formDatas.untilFurtherNotice,
      modeEndDate: formDatas.endDate,
    }
  }).then(quickSetting => quickSetting!.enabled ? AppliancesModule.getAppliances(true) : Promise.resolve())
      .then(() => {
        HeatingModule.startQuickSettings(props.quickSettingsToEnabled.id);
        MixpanelService.trackQuicksetting(ETrackQuicksettingEvent.STARTED, {
          name: props.quickSettingsToEnabled?.name,
          enabled: props.quickSettingsToEnabled.enabled,
          id: props.quickSettingsToEnabled.id,
          content: props.quickSettingsToEnabled,
          mode_end_date: props.quickSettingsToEnabled.modeEndDate,
          until_further_notice: props.quickSettingsToEnabled.untilFurtherNotice,
        })
      }).finally(() => {
        if (HeatingModule.hasActiveQuicksettingTemperatureControl(props.quickSettingsToEnabled.id) && !hasTemperatureControlCookie.value) {
          isTemperatureControlModalOpen.value = true;
        } else {
          emit('openReminder');
          closePopinDuration();
        }
      });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
      class: "custom-duration",
      type: "popin",
      title: _ctx.$t('quicksettings.duration.title', {mode: _ctx.$t(quickSettingToActivate.value.name)}),
      "return-action": closePopinDuration,
      onCancel: closePopinDuration
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('quicksettings.duration.title2', {mode: _ctx.$t(quickSettingToActivate.value.name)})), 1),
        (_ctx.quickSettingsToEnabled.isDaysDuration)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(daysDurations, (duration, index) => {
                return _createElementVNode("li", {
                  class: "changeDuration__item",
                  key: index
                }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: ($event: any) => (changeDuration(duration))
                  }, _toDisplayString(_ctx.$t('heating.duration.days', duration)), 9, _hoisted_3)
                ])
              }), 64)),
              _createElementVNode("li", _hoisted_4, [
                _createElementVNode("form", null, [
                  _createVNode(_unref(ConfigProvider), { locale: datePickerLocale.value }, {
                    default: _withCtx(() => [
                      _createVNode(MyVAntddatepicker, {
                        label: _ctx.$t('heating.duration.custom'),
                        value: duration.value,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((duration).value = $event)),
                        onlyFuture: true,
                        disabledDate: disableFutureDate,
                        showTime: true,
                        class: "changeDuration__btn",
                        onOk: onCustomDurationChange
                      }, null, 8, ["label", "value"])
                    ]),
                    _: 1
                  }, 8, ["locale"])
                ])
              ]),
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("button", {
                  type: "button",
                  class: "changeDuration__btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (changeDuration('untilFurtherNotice')))
                }, _toDisplayString(_ctx.$t('heating.duration.untilFurtherNotice')), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(hoursDurations, (duration, index) => {
                return _createElementVNode("li", {
                  class: "changeDuration__item",
                  key: index
                }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: ($event: any) => (changeDuration(duration))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', duration)), 9, _hoisted_7)
                ])
              }), 64)),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("form", null, [
                  _createVNode(_unref(ConfigProvider), { locale: datePickerLocale.value }, {
                    default: _withCtx(() => [
                      _createVNode(MyVAntddatepicker, {
                        label: _ctx.$t('heating.duration.custom'),
                        value: duration.value,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((duration).value = $event)),
                        onlyFuture: true,
                        disabledDate: disableFutureDate,
                        showTime: true,
                        class: "changeDuration__btn",
                        onOk: onCustomDurationChange
                      }, null, 8, ["label", "value"])
                    ]),
                    _: 1
                  }, 8, ["locale"])
                ])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("button", {
                  type: "button",
                  class: "changeDuration__btn",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (changeDuration('untilFurtherNotice')))
                }, _toDisplayString(_ctx.$t('heating.duration.untilFurtherNotice')), 1)
              ])
            ])),
        (_ctx.showFullSetting)
          ? (_openBlock(), _createBlock(MyVButton, {
              key: 2,
              round: "",
              class: "changeDuration__link",
              onClick: quickSettingRoute
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('quicksettings.full_setting')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 40, ["title"])),
    (isTemperatureControlModalOpen.value)
      ? (_openBlock(), _createBlock(MyVTemperatureControlModal, {
          key: 0,
          onClose: closePopinDuration
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})