import ConsentEnedisService from "@/services/ConsentEnedis";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {TConsentsEnedis, TConsentsEnedisUpdate} from "@/services/ConsentEnedis/interfaces";

import {EModules} from "../modules";
import {AuthModule} from "@/store";
import {TUser} from "@/services/Auth/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";

@Module({ name: EModules.CONSENT })
class Consent extends VuexModule {
  private _enedis: TConsentsEnedis | null = null;

  get enedis() {
    return this._enedis ? [...this._enedis!].map(consent => ({ ...consent, subcategories: [...consent.subcategories] })) : null;
  }

  get enedisIsAllowed() {
    let isAllowed = true
    this.enedis?.forEach(({ subcategories }) => subcategories.forEach(({ enabled }) => {
      if (!enabled) isAllowed = false
    }))

    return isAllowed
  }

  get allConsentAreDisabled() {
    let areDisabled = true;
    this.enedis?.forEach(({ subcategories }) => subcategories.forEach(({ enabled }) => {
      if (enabled) areDisabled = false;
    }))

    return areDisabled;
  }

  /**
   * Mutations
   */
  @Mutation
  public _setEnedis(enedis: TConsentsEnedis): void {
    this._enedis = enedis;
  }

  /**
   * Actions
   */

  @Action({rawError: true})
  public getConsentEnedis(user: TUser): Promise<TConsentsEnedis> {
    if (user) {
      return ConsentEnedisService.getConsentsEnedis(getCurrentSiteIdFromCookie(user))
          .then(consent => {
            this.context.commit('_setEnedis', consent);
            return consent;
          });
    }

    return Promise.reject(new Error('Error 403: You must be connected !'));
  }

    @Action({rawError: true})
  public updateConsentEnedis(consents: TConsentsEnedisUpdate): Promise<TConsentsEnedis> {
    const user = AuthModule.userOrNull;

    if (user) {
      return ConsentEnedisService.updateConsentEnedis(getCurrentSiteIdFromCookie(user), consents)
          .then(consent => {
            const newConsents = consent.filter(({categoryKey}) => this._enedis!.some((enedis) => enedis.categoryKey === categoryKey));
            this.context.commit('_setEnedis', newConsents);
            return newConsents;
          });
    }

    return Promise.reject(new Error('Error 403: You must be connected !'));
  }
}

export default Consent;
