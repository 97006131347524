import {TAppliance, TApplianceRefWithoutId} from "../Appliances/interfaces";
import {TSite} from "../Site/interfaces";

export enum EQuickSettingsName {
    /**
     * Quick mode program set when user is at home.
     */
    AtHome = 'quicksettings.athome',

    /**
     * Quick mode program set when user is absent for a short time.
     */
    ShortAbsence = 'quicksettings.shortleave',

    /**
     * Quick mode program set when user is absent for a long time.
     */
    LongAbsence = 'quicksettings.longleave',
}

export type TManualSettingEditable = {
    /**
     * Appliance's id linked to the manual setting.
     */
    idAppliance: TAppliance['id'];

    /**
     * Is the manual setting running until "endDate".
     */
    untilFurtherNotice: Exclude<TAppliance['programming']['untilFurtherNotice'], undefined>;

    /**
     * Is the manual setting On.
     */
    isOn: Exclude<TAppliance['programming']['isOn'], undefined>;

    /**
     * Manual setting mode's type.
     */
    mode: TAppliance['programming']['mode'];

    /**
     * Manual setting's date programmed to stop the heating.
     */
    endDate: TAppliance['programming']['endDate'];

    /**
     * Manual setting's temperature that need to be reach by the heating.
     */
    temperatureTarget: TAppliance['programming']['temperatureTarget'];

    /**
     * Manual setting's is enabled.
     */
    enabled: boolean;
};

export type TManualSetting = TApplianceRefWithoutId & TManualSettingEditable & {
    /**
     * Manual setting's id.
     */
    id: number;

    /**
     * Manual setting's level from 1 to 3.
     */
    heatingLevel: TAppliance['heatingLevel'];

    /**
     * Manual setting default's temparature run by the manual setting.
     */
    defaultTemperature: TAppliance['programming']['defaultTemperature'];
};

export type TManualSettings = TManualSetting[];

export type TQuickSettingsAppliancesEditable = {
    /**
     * Appliance's id linked to the quick settings.
     */
    idAppliance: TAppliance['id'];

    /**
     * Is the quick setting On.
     */
    isOn: Exclude<TAppliance['programming']['isOn'], undefined>;

    /**
     * Quick setting mode's type.
     */
    mode: TAppliance['programming']['mode'];

    /**
     * Quick setting's temperature that need to be reach by the heating.
     */
    temperatureTarget: TAppliance['programming']['temperatureTarget'];
};

export type TQuickSettingsAppliances = TQuickSettingsAppliancesEditable & TApplianceRefWithoutId;

export type _TQuickSettings = {
    /**
     * Is the quick setting running until "modeEndDate".
     */
    untilFurtherNotice: boolean;

    /**
     * Quick setting's date programmed to stop the heating.
     */
    modeEndDate: string | null;

    /**
     * Is the quick settings enabled.
     */
    enabled: boolean;

    /**
     * Is the quick settings duration in days.
     */
    isDaysDuration: boolean;
};

export type TQuickSettingsEditable = {
    /**
     * List of appliances configurated with the global heating system.
     */
    appliancesSettings: TQuickSettingsAppliancesEditable[];

    /**
     * Is the quick setting running until "modeEndDate".
     */
    untilFurtherNotice: boolean;

    /**
     * Quick setting's date programmed to stop the heating.
     */
    modeEndDate: string | null;
};

export type TQuickSettings = _TQuickSettings & {
    /**
     * Quick settings' id.
     */
    id: number;

    /**
     * Quick settings' name.
     */
    name: EQuickSettingsName;

    /**
     * List of appliances configurated with the global heating system.
     */
    appliancesSettings: TQuickSettingsAppliances[];
};

export type TQuickSettingsEnableResponse = {
    /**
     * Quick settings' id.
     */
    id: TQuickSettings['id'];

    /**
     * Quick settings enabled's state.
     */
    enabled: TQuickSettings['enabled'];
};

export type TProgrammingState = {
    endDate?: string;
    geolocCurrentlyOn: boolean;
    id: number;
    programName: string;
    programType: string;
    untilFurtherNotice: boolean;
}

export type TQuickSettingsEnableResponses = TQuickSettingsEnableResponse[];

type TGetManualSettings = (siteId: TSite['id']) => Promise<TManualSettings | void>;

type TPutManualSetting = (siteId: TSite['id'], manualSettingId: TManualSetting['id'], body: TManualSettingEditable) => Promise<TManualSetting | void>;

type TGetQuickSettings = (siteId: TSite['id']) => Promise<TQuickSettings[] | void>;

type TPutQuickSettings = (siteId: TSite['id'], quickSettingsId: TQuickSettings['id'], body: TQuickSettingsEditable) => Promise<TQuickSettings | void>;

type TPutEnableQuickSettings = (siteId: TSite['id'], quickSettingsId: TQuickSettings['id'], enabled: TQuickSettings['enabled']) => Promise<TQuickSettingsEnableResponses | void>;

type TInitQuickSettings = (siteId: TSite['id']) => Promise<TQuickSettings[] | void>

export type THeatingService = {
    /**
     * Send a GET request to retrieve ALL manual setting
     */
    getManualSettings: TGetManualSettings;

    /**
     * Send a PUT request to update manual setting
     */
    updateManualSetting: TPutManualSetting;

    /**
     * Send a GET request to retrieve quick settings
     */
    getQuickSettings: TGetQuickSettings;

    /**
     * Send a PUT request to update quick settings
     */
    updateQuickSettings: TPutQuickSettings;

    /**
     * Send a PUT request to enable/disabled quick settings
     */
    enableQuickSettings: TPutEnableQuickSettings;

    /**
     * Send a GET request to init the user's quick settings
     */
    initQuickSettings: TInitQuickSettings;
};
