import {TSite} from "../Site/interfaces";

export enum EGeolocationRequests {
    getDeviceInfo = 'getDeviceInfo',
    refreshGeofences = 'refreshGeofences',
    getPermissionsState = 'getPermissionsState',
    getLocalisationState = 'getState',
    openAppSettings = 'openAppSettings',
    requirePermission = 'requirePermission',
    getVersion = 'version'
}

export enum EGeolocationResponseState {
    granted = 'granted',
}

export enum EGeolocationResponseType {
    location = 'location',
    locationBackground = 'locationBackground'
}

export type TGeolocationPhone = {
    deviceId: string | undefined;
    siteId?: number | undefined;
    name: string | undefined;
    brand: string | undefined;
    lastHeartbeatDatetime?: Date | undefined;
    state?: string | undefined;
};

export type TGeolocationStatus = {
    siteId?: number;
    isGeolocReady: boolean;
    radius: number;
    longitude?: number;
    latitude?: number;
    currentlyOn?: boolean;
    activatedByUser: boolean;
};

export type TGeolocationPhoneFromDevice = {
    'deviceId': string;
    'manufacturer': string;
    'model': string;
    'platform': string;
    'version': string;
    'name': string;
}

export type TGeolocationPhoneConfiguration = {
    siteId: number;
    radius: number;
    longitude: number;
    latitude: number;
};

export type TGeolocationStateResponse = {
    type: string;
    state: EGeolocationResponseState
}

export type TGeolocationDeviceStateResponse = {
    started: boolean;
    phoneLocationServiceEnabled: boolean
}

export type TGeolocationVersionResponse = {
    version: string | undefined;
}

export type TGeolocationDeviceInfoResponse = {
    deviceId: string;
    manufacturer: string;
    model: string;
    platform: string;
    version: string;
    name: string;
}

type TGeolocationPhones = (siteId: TSite['id']) => Promise<TGeolocationPhone[] | void>;
type TGetPhoneConfigurations = (siteId: TSite['id']) => Promise<TGeolocationPhoneConfiguration[] | void>;
type TGetGeolocationStatus = (siteId: TSite['id']) => Promise<TGeolocationStatus | void>;
type TPutGeolocationStatus = (siteId: TSite['id'], body: TGeolocationStatus) => Promise<TGeolocationStatus | void>;
type TPostGeolocationPhone = (siteId: TSite['id'], body: TGeolocationPhone) => Promise<TGeolocationPhone | void>;
type TPutGeolocationPhone = (siteId: TSite['id'], body: TGeolocationPhone) => Promise<TGeolocationPhone | void>;
type TDeleteGeolocationPhone = (siteId: TSite['id'], deviceId: string) => void;

export type TGeolocationService = {
    getPhones: TGeolocationPhones;
    getPhoneConfigurations: TGetPhoneConfigurations;
    getGeolocationStatus: TGetGeolocationStatus;
    putGeolocationStatus: TPutGeolocationStatus;
    postGeolocationPhone: TPostGeolocationPhone;
    putGeolocationPhone: TPutGeolocationPhone;
    deleteGeolocationPhone: TDeleteGeolocationPhone;
};