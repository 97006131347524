import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {AuthModule} from "..";
import {EModules} from "../modules";
import  i18n from '@/i18n';

import {
    TAppliance,
    TApplianceEditable,
    TApplianceEditableTemperature,
    TAppliances,
    TUnmanagedAppliance
} from "@/services/Appliances/interfaces";
import AppliancesService from "@/services/Appliances";
import {TSite} from "@/services/Site/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {normalizeWithoutEmoji} from "@/helpers/string/inputFormat";

type TGetAppliance = { id: TAppliance['id'], forceRequest?: boolean };
type TUpdateAppliance = { id: TSite['id'], body: Partial<TApplianceEditable> };
type TUpdateApplianceTemperature = { id: TSite['id'], body: Partial<TApplianceEditableTemperature> };

@Module({name: EModules.APPLIANCES})
class Appliances extends VuexModule {
    private _appliances: TAppliances | undefined = undefined;

    get appliances() {
        return this._appliances?.map(appliance => {
           return {...appliance, name :i18n.global.t(appliance.name)}})
        }

    /**
     * Mutations
     */
    @Mutation
    public _setAppliances(appliances: TAppliances): void {
        this._appliances = appliances;
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public getAppliances(forceRequest = false): Promise<TAppliances | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                (!forceRequest && this.appliances) ? Promise.resolve(this.appliances) :
                    AppliancesService.getAppliances(getCurrentSiteIdFromCookie(user))
                        .then(appliances => {
                            this.context.commit("_setAppliances", appliances as TAppliances);
                            return appliances;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getAppliance({id, forceRequest = false}: TGetAppliance): Promise<TAppliance | void> {
        const user = AuthModule.userOrNull;

        if (forceRequest) {
            return user ?
                AppliancesService.getAppliance(getCurrentSiteIdFromCookie(user), id)
                    .then(appliance => {
                        this.context.commit("_setAppliances", this.appliances!.map(_appliance => _appliance.id === (appliance as TAppliance).id ? appliance as TAppliance : _appliance));
                        return appliance;
                    }) :
                Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return this.getAppliances()
            .then(appliances => {
                const appliance = appliances!.find(_appliance => _appliance.id === id);

                if (appliance === undefined) {
                    throw new Error('Error 404: Appliance not found !');
                }
                return appliance;
            })
            ;
    }

    @Action({rawError: true})
    public updateAppliance({id, body}: TUpdateAppliance): Promise<TAppliance | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return this.getAppliance({id})
                .then(appliance => AppliancesService.updateAppliance(getCurrentSiteIdFromCookie(user), id, {
                        name: normalizeWithoutEmoji(body.name ?? appliance!.name),
                    })
                )
                .then((appliance) => {
                    this.context.commit("_setAppliances", this.appliances!.map(_appliance => _appliance.id === (appliance as TAppliance).id ? appliance as TAppliance : _appliance));
                    this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})

                    return appliance as TAppliance;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                })
                ;
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateApplianceTemperature({id, body}: TUpdateApplianceTemperature): Promise<boolean | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return this.getAppliance({id})
                .then(() => AppliancesService.updateApplianceTemperature(getCurrentSiteIdFromCookie(user), id, {
                        temperatureMeasurement: body.temperatureMeasurement,
                    })
                )
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getUnmanagedAppliances(): Promise<TUnmanagedAppliance[] | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return AppliancesService.getUnmanagedAppliance(getCurrentSiteIdFromCookie(user))
            .then(appliances => appliances)
            .catch(e => {
                console.error(e);
                throw e;
            })
    }

    @Action({rawError: true})
    public putUnmanagedAppliances(body: TUnmanagedAppliance[]): Promise<TUnmanagedAppliance[] | void> {
        const user = AuthModule.userOrNull

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return AppliancesService.putUnmanagedAppliance(getCurrentSiteIdFromCookie(user), body)
            .then(appliances => {
                this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})
                return appliances
            })
            .catch(e => {
                console.error(e);
                throw e;
            })
    }
}

export default Appliances;
