import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form__group__label-text" }
const _hoisted_3 = { key: 0 }

import {TSelect} from "./interfaces";
import {Form, Select} from 'ant-design-vue';
import {computed, onBeforeMount, ref, watch} from "vue";

export interface Props {
  required?: TSelect["required"];
  mode?: TSelect["mode"];
  placeholder?: TSelect["placeholder"];
  id?: TSelect["id"];
  label?: TSelect["label"];
  disabled?: boolean;
  defaultValue?: TSelect["defaultValue"];
  value?: TSelect["value"];
  options?: TSelect["options"];
  error?: TSelect["error"];
  dataTestId?: TSelect['dataTestId'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntdselect',
  props: {
    required: { type: Boolean, default: false },
    mode: { default: undefined },
    placeholder: { default: undefined },
    id: { default: undefined },
    label: { default: undefined },
    disabled: { type: Boolean, default: false },
    defaultValue: { default: undefined },
    value: { default: undefined },
    options: { default: undefined },
    error: { default: undefined },
    dataTestId: { default: undefined }
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const {Item} = Form;

const props = __props

const emit = __emit

const m_value = ref<TSelect['defaultValue']>(undefined);

const hasValue = computed(() => {
  return m_value.value !== undefined;
})

const onChange = (value: TSelect['value']) => {
  m_value.value = value;
  emit('update:value', m_value.value);
}

onBeforeMount(() => {
  m_value.value = props.value === undefined ? props.defaultValue : props.value;
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    m_value.value = newValue;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Item), {
    class: "form__group form__select",
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Select), {
        value: _ctx.value,
        onChange: onChange,
        options: _ctx.options,
        mode: _ctx.mode,
        "show-arrow": !_ctx.disabled,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        class: _normalizeClass({'has-value':hasValue.value}),
        "data-testid": `${_ctx.dataTestId}-select`
      }, null, 8, ["value", "options", "mode", "show-arrow", "disabled", "placeholder", "class", "data-testid"]),
      (_ctx.label!=null)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: "form__group__label"
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "form__group__bar" }, null, -1))
    ]),
    _: 1
  }, 8, ["validate-status", "help"]))
}
}

})