import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import ERoutes from './routes';

import {AuthModule, RouterModule, SiteModule} from '@/store';

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
/**
 * Not auth routes
 */
import NotAuthHome from "@/views/domains/Home/NotAuth/HomeView.vue";
import ForgotPassword from "@/views/domains/Password/NotAuth/ForgotPassword/ForgotPasswordView.vue";
import ResetEmailToken from "@/views/domains/Password/NotAuth/ResetEmailToken/ResetEmailTokenView.vue";
import NewPassword from "@/views/domains/Password/NotAuth/NewPassword/NewPassword.vue";

/**
 * Auth routes
 */
import FirstConnection from "@/views/domains/Home/Auth/FirstConnection/FirstConnectionView.vue";


import Programlist from "@/views/domains/Heating/Program/Programlist/ProgramlistView.vue";
import Programcreate from "@/views/domains/Heating/Program/ProgramCreate/ProgramCreateView.vue";
import ProgramPlanning from "@/views/domains/Heating/Program/Planning/ProgramPlanningView.vue";
import ProgramPlanningEdit from "@/views/domains/Heating/Program/Planning/ProgramPlanningEditView.vue";

import Conso from "@/views/domains/Conso/ConsoView.vue";
import ConsoLive from "@/views/domains/Conso/LiveView.vue";
import ConsoDetail from "@/views/domains/Conso/ConsoDetail/ConsoDetailView.vue";
import RepartitionDetail from "@/views/domains/Conso/RepartitionDetail/RepartitionDetailView.vue";
import RepartitionSubcategories
    from "@/components/domains/Conso/MyVRepartitionSubcategories/MyVRepartitionSubcategories.vue";
import Profile from "@/views/domains/Profile/ProfileView.vue";
import Contact from "@/views/domains/Profile/Contact/Auth/ContactView.vue";
import MyAccount from "@/views/domains/Profile/MyAccount/MyAccountView.vue";
import Contract from "@/views/domains/Profile/Contract/ContractView/ContractView.vue";
import Security from "@/views/domains/Profile/Security/SecurityView.vue";
import Housing from "@/views/domains/Profile/Housing/HousingView.vue";
import LegalInformation from "@/views/domains/Profile/LegalInformation/LegalInformationView.vue";
import ManageChoices from "@/views/domains/Profile/ManageChoices/ManageChoicesView.vue";
import Faq from "@/views/domains/Profile/Faq/FaqView.vue";
import Advices from "@/views/domains/Profile/Advices/AdvicesView.vue";
import AdviceSingle from "@/views/domains/Profile/Advices/AdviceSingleView.vue";
import {TUser} from "@/services/Auth/interfaces";
import DeviceStatus from "@/views/domains/Profile/Diagnostic/DevicesStatus/DeviceStatusView.vue";
import Heating from "@/views/domains/Heating/Heater/HeatingView.vue";
import ContactView from "@/views/domains/Profile/Contact/NotAuth/ContactView.vue";
import Heater from "@/views/domains/Heating/Heater/HeaterView.vue";
import WelcomeView from "@/views/domains/Home/Auth/Welcome/WelcomeView.vue";
import DiagnosticView from "@/views/domains/Profile/Diagnostic/DevicesStatus/DiagnosticView.vue";
import SavingsView from "@/views/domains/Savings/SavingsView.vue";
import {isWebViewMobileApp} from "@/helpers/domains/device";
import {closeCurrentWebview} from "@/helpers/domains/mobileApps/flutter-connectors";

const routes: Array<RouteRecordRaw> = [
    {
        path: ERoutes.HOME,
        name: "home",
        components: {
            default: NotAuthHome,
            logged: WelcomeView,
        },
        meta: {
            title: 'document.home.meta.title'
        },
        children: [
            {
                path: ERoutes.FORGOT_PASSWORD,
                component: ForgotPassword,
                meta: {
                    title: 'document.forgot_password.meta.title'
                }
            },
            {
                path: ERoutes.RESET_EMAIL_TOKEN,
                component: ResetEmailToken,
                meta: {
                    title: 'document.reset_email_token.meta.title'
                }
            },
            {
                path: ERoutes.NEW_PASSWORD,
                component: NewPassword,
                meta: {
                    title: 'document.new_password.meta.title'
                }
            },
            {
                path: ERoutes.CONTACT_NOT_AUTH,
                component: ContactView,
                meta: {
                    title: 'document.contact_not_auth.meta.title'
                }
            }
        ]
    },
    {
        path: `${ERoutes.FIRST_CONNECTION}`,
        name: "FirstConnection",
        components: {
            default: FirstConnection,
            logged: FirstConnection,
        },
        meta: {
            title: 'document.first_connection.meta.title'
        },
    },
    {
        path: ERoutes.HEATING,
        name: ERoutes.HEATING.split('/').pop(),
        components: {
            default: NotAuthHome,
            logged: Heating,
        },
        meta: {
            requiresAuth: true,
            title: 'document.heating.meta.title'
        },
        children: [
            {
                path: ERoutes.PARAM_ID,
                component: Heater,
                meta: {
                    requiresAuth: true,
                    title: 'document.heater.meta.title'
                }
            }
        ]
    },
    {
        path: `${ERoutes.PROGRAM}`,
        name: "ProgramList",
        components: {
            default: NotAuthHome,
            logged: Programlist,
        },
        meta: {
            requiresAuth: true,
            title: 'document.program_list.meta.title'
        }
    },
    {
        path: `${ERoutes.PROGRAM}/${ERoutes.ADDON_CREATE}`,
        name: "ProgramCreate",
        components: {
            default: NotAuthHome,
            logged: Programcreate,
        },
        meta: {
            title: 'document.program_create.meta.title'
        },
    },
    {
        path: `${ERoutes.PROGRAM}/${ERoutes.PARAM_ID}/${ERoutes.ADDON_PLANNING}/${ERoutes.PARAM_APPLIANCE}?`,
        name: "Program",
        components: {
            default: NotAuthHome,
            logged: ProgramPlanning,
        },
        meta: {
            requiresAuth: true,
            title: 'document.program_planning.meta.title'
        },
        children: [
            {
                path: `${ERoutes.ADDON_EDIT}/${ERoutes.PARAM_DAY}`,
                component: ProgramPlanningEdit,
                meta: {
                    title: 'document.program_planning_edit.meta.title'
                },
            }
        ]
    },
    {
        path: ERoutes.CONSO,
        name: ERoutes.CONSO_MONTH,
        components: {
            default: NotAuthHome,
            logged: Conso,
        },
        meta: {
            requiresAuth: true,
            title: 'document.conso.meta.title'
        }
    },
    {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_DETAIL}/:date?`,
        components: {
            default: NotAuthHome,
            logged: ConsoDetail,
        },
        meta: {
            requiresAuth: true,
            title: 'document.conso_detail.meta.title'
        }
    },
    {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/:date`,
        components: {
            default: NotAuthHome,
            logged: RepartitionDetail,
        },
        meta: {
            requiresAuth: true,
            title: 'document.repartition_detail.meta.title'
        },
        children: [
            {
                path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/:date/:category`,
                component: RepartitionSubcategories,
                props: true,
                meta: {
                    requiresAuth: true,
                    title: 'document.repartition_detail_subcategories.meta.title'
                },
            }
        ]
    },
    {
        path: `${ERoutes.CONSO}/live`,
        name: ERoutes.CONSO_LIVE,
        components: {
            default: NotAuthHome,
            logged: ConsoLive,
        },
        meta: {
            requiresAuth: true,
            title: 'document.conso_live.meta.title'
        }
    },
    {
        path: `${ERoutes.SAVINGS}`,
        name: 'savings',
        components: {
            default: NotAuthHome,
            logged: SavingsView,
        },
        meta: {
            requiresAuth: true,
            title: 'document.savings.meta.title'
        }
    },
    {
        path: ERoutes.PROFILE,
        name: ERoutes.PROFILE.split('/').pop(),
        components: {
            default: NotAuthHome,
            logged: Profile,
        },
        meta: {
            requiresAuth: true,
            title: 'document.profile.meta.title'
        },
        children: [
            {
                path: '',
                name: 'MyAccount',
                redirect: ERoutes.MY_ACCOUNT,
                meta: {
                    requiresAuth: true,
                    title: 'document.my_account.meta.title'
                },
            },
            {
                path: ERoutes.MY_ACCOUNT,
                name: 'MyAccount',
                component: MyAccount,
                meta: {
                    requiresAuth: true,
                    title: 'document.my_account.meta.title'
                },
            },
            {
                path: ERoutes.CONTACT,
                name: 'Contact',
                component: Contact,
                meta: {
                    requiresAuth: true,
                    title: 'document.contact.meta.title'
                },
            },
            {
                path: ERoutes.CONTRACT,
                name: 'Contract',
                component: Contract,
                meta: {
                    requiresAuth: true,
                    title: 'document.contracts.meta.title'
                },
            },
            {
                path: ERoutes.SECURITY,
                name: 'Security',
                component: Security,
                meta: {
                    requiresAuth: true,
                    title: 'document.security.meta.title'
                },
            },
            {
                path: ERoutes.HOUSING,
                name: 'Housing',
                component: Housing,
                meta: {
                    requiresAuth: true,
                    title: 'document.housing.meta.title'

                },
            },
            {
                path: ERoutes.LEGAL_INFORMATION,
                name: 'LegalInformation',
                component: LegalInformation,
                meta: {
                    requiresAuth: true,
                    title: 'document.legal_information.meta.title'
                },
            },
            {
                path: ERoutes.MANAGE_CHOICES,
                name: 'ManageChoices',
                component: ManageChoices,
                meta: {
                    requiresAuth: true,
                    title: 'document.manage_choices.meta.title'
                },
            },
            {
                path: ERoutes.FAQ,
                name: 'Faq',
                component: Faq,
                meta: {
                    requiresAuth: true,
                    title: 'document.faq.meta.title'
                },
            },
        ]
    },
    {
        path: ERoutes.ADVICES,
        name: 'Advices',
        components: {
            default: NotAuthHome,
            logged: Advices
        },
        meta: {
            requiresAuth: true,
            title: 'document.advices.meta.title'
        },
    },
    {
        path: `${ERoutes.ADVICE}/${ERoutes.PARAM_ADVICE}`,
        name: 'Advice',
        components: {
            default: NotAuthHome,
            logged: AdviceSingle
        },
        meta: {
            requiresAuth: true,
            title: 'document.advice_single.meta.title'
        },
    },
    {
        path: ERoutes.DIAGNOSTIC,
        name: 'Diagnostic',
        components: {
            default: NotAuthHome,
            logged: DiagnosticView
        },
        meta: {
            requiresAuth: true,
            title: 'document.devices_status.meta.title'
        },
        children: [
            {
                path: ERoutes.PARAM_DEVICE_ID,
                component: DeviceStatus,
                meta: {
                    requiresAuth: true,
                    title: 'document.device_status.meta.title'
                },
            },
        ]
    },
    {
        path: `${ERoutes.LEGAL_INFORMATION}${ERoutes.GCU}`,
        name: 'LegalInformationPublic',
        components: {
            default: LegalInformation,
            logged: LegalInformation
        },
        meta: {
            requiresAuth: false,
            title: 'document.legal_information.meta.title'
        },
    },
    {
        path: ERoutes.CLOSE_WEBVIEW,
        name: 'CloseWebView',
        components: {},
        meta: {
            requiresAuth: false,
        },
        beforeEnter: (to, from, next) => {
            if (isWebViewMobileApp(window)) {
                try {
                    next(false);
                    closeCurrentWebview()
                } catch (error) {
                    console.error('Failed to close webview:', error);
                    next({
                        path: ERoutes.HOME,
                    });
                }
            } else {
                next({
                    path: ERoutes.HOME,
                });
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    let authState: TUser | false | null = null;

    // App is getting user from cookie on first mount, don't need to relog every time.
    if (!AuthModule.userOrNull) {
        try {
            authState = await AuthModule.checkConnectUserState();
        }
            // eslint-disable-next-line no-empty
        catch (e) {

        }
    }

    pageScroll(true); // sortie de popin/menu/autre moment ou on avait bloqué le scroll
    document.dispatchEvent(new Event("closeProfilMenu"));

    if (to.meta.requiresAuth === true && authState !== false && (AuthModule.userOrNull) === null) {
        return next({
            path: ERoutes.LOGIN
        });
    }

    if (to.path !== from.path) {
        RouterModule.incrementCounter(from.path as ERoutes);
    }

    // Basic User is prevented to access these routes.
    if (SiteModule.hasBasicOffer && (to.path.startsWith(ERoutes.CONSO) || to.path === ERoutes.HOUSING || to.path === ERoutes.CONTRACT)) {
        return next(from);
    }

    RouterModule.saveFullLastPath(`${window.location.origin}${from.fullPath}`);

    next();
});

export default router;
