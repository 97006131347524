import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

import {TNotification} from "@/services/Notification/interfaces";

interface Props {
  notification: TNotification;
  hasTabSelected?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVNotificationCard',
  props: {
    notification: { default: undefined },
    hasTabSelected: { type: Boolean, default: false }
  },
  emits: ['readNotif', 'deleteNotif'],
  setup(__props: any, { emit: __emit }) {




const emit = __emit

const readNotif = (id: number) => {
  emit('readNotif', id)
}

const deleteNotif = (notification: TNotification) => {
  emit('deleteNotif', notification)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.NotificationCard, _ctx.notification.seen ? _ctx.$style.wasSeen : ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.NotificationCard__content),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (readNotif(_ctx.notification.id)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.NotificationCard__bar])
      }, null, 2),
      _createElementVNode("div", null, [
        (!_ctx.hasTabSelected)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.notification.categoryKey)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          innerHTML: _ctx.$t(_ctx.notification.contentKey, _ctx.notification.parameters)
        }, null, 8, _hoisted_2)
      ])
    ], 2),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (deleteNotif(_ctx.notification))),
      class: _normalizeClass(_ctx.$style.NotificationCard__crossDelete)
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("svg", {
        class: "icon icon-cross",
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", { "xlink:href": "#icon-cross" })
      ], -1)
    ]), 2)
  ], 2))
}
}

})