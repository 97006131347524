import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "RepartitionSubcategories__comparing"
}
const _hoisted_3 = {
  key: 0,
  class: "header"
}
const _hoisted_4 = { class: "compareData" }
const _hoisted_5 = { class: "currentData" }
const _hoisted_6 = {
  key: 1,
  class: "main"
}
const _hoisted_7 = { class: "bar" }

import ERoutes from "@/router/routes";
import {aggregatedYearFormat, labelMonthsFormat, TRepartitionCategories} from "@/services/Conso/interfaces";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import percent from "@/helpers/number/percent";
import {TRepartitionSubcategoriesElement, TRepartitionSubcategoriesItem} from "./interfaces";
import {getSubcategoriesTotalConsoByType} from "@/helpers/domains/conso";
import {computed, onMounted} from "vue";
import {useRoute} from "vue-router";
import MyVRepartitionSubcategoriesCard
  from "@/components/domains/Conso/MyVRepartitionSubcategoriesCard/MyVRepartitionSubcategoriesCard.vue";
import {useDate} from "@/helpers/dates/date-utils";
import useCurrency from "@/composables/currency";
import { SiteModule } from "@/store";

interface Props {
  subcategories: TRepartitionCategories[];
  consoType?: boolean;
  compareSubcategories?: any[];
  comparingDate?: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVRepartitionSubcategories',
  props: {
    subcategories: { default: () => [] },
    consoType: { type: Boolean, default: true },
    compareSubcategories: { default: undefined },
    comparingDate: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const route = useRoute()
const {formatCurrency, currency} = useCurrency()

const backLink = computed(() => {
  const date = route.params.date;
  return {
    path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${date}`,
    query: {type: route.query.type}
  }
})

const isCurrentMonth = computed(() => {
  const currentDate = useDate();
  const paramMonth = useDate(route.params.date as string).month();
  const paramYear = useDate(route.params.date as string).year();

  return paramMonth === currentDate.month() && paramYear === currentDate.year()
})

const subData = computed(() => {
  if (props.subcategories) {
    const category = route.params.category

    return props.subcategories!.filter(({name}) => name === category)
        .map(el => {
          const subcategories = el.subcategories?.sort((a, b) => a.totalConsumptionInWh! - b.totalConsumptionInWh!) || [];
          const total = getSubcategoriesTotalConsoByType(subcategories, props.consoType);
          const items: TRepartitionSubcategoriesItem[] = [];

          subcategories.forEach((sub, index, array) => {

            let percentage = Math.round(percent((props.consoType ? fromWhToKwh(sub.totalConsumptionInWh || 0) : sub.totalConsumptionInCurrency) || 0, total))
            let totalConsumptionInWh = props.consoType ? fromWhToKwh(sub.totalConsumptionInWh || 0) : sub.totalConsumptionInCurrency

            if (index === array.length - 1 && (sub.totalConsumptionInWh && sub.totalConsumptionInWh >= 0)) {
              percentage = 100 - items.reduce((acc, currentValue) => acc + currentValue.percentage, 0)
              totalConsumptionInWh = total - Math.ceil(items.reduce((acc, currentValue) => acc + currentValue.totalConsumptionInWh!, 0))
            }

            items.push({
              ...sub,
              totalConsumptionInWh,
              totalConsumptionInCurrency: sub.totalConsumptionInCurrency || 0,
              percentage
            });
          });

          return {
            ...el,
            subcategories: subcategories.some((item: TRepartitionCategories) => item.name === 'conso.other.leftover.full') ? [] : items.sort((a, b) => a.name.localeCompare(b.name))
          };
        })[0] as TRepartitionSubcategoriesElement;
  }

  return null;
})

const compareSubData = computed(() => {
  if (props.compareSubcategories) {
    const category = route.params.category

    return props.compareSubcategories!.filter(el => {
      if (el.name === category) {
        let total = 0;

        el.subcategories!.sort((a: TRepartitionCategories, b: TRepartitionCategories) => a.name.localeCompare(b.name)).forEach((sub: any) => {
          total += props.consoType ? sub.totalConsumptionInWh : sub.totalConsumptionInCurrency
        })

        el.subcategories.map((sub: any) => {
          sub.percentage = Math.round(((props.consoType ? sub.totalConsumptionInWh : sub.totalConsumptionInCurrency) / total) * 100)
        })

        return el
      }
    })[0]
  }

  return null;
})

const urlDate = computed(() => {
  const isYear = route.query.type === 'year';
  return useDate(route.params.date as string).locale('fr').format(isYear ? aggregatedYearFormat : labelMonthsFormat);
})

const allComparedData = computed(() => {
  const comparedData: any[] = [];

  const compareSubDataTotal = props.consoType ? fromWhToKwh(compareSubData.value?.total) : compareSubData.value?.total;

  const addData = (name: string, currentKwhVal: number, compareKwhVal: number, isHeader: boolean) =>
      comparedData.push({
        name,
        currentKwhVal,
        compareKwhVal,
        isHeader,
        percentage: compareKwhVal ? ((currentKwhVal - compareKwhVal) / compareKwhVal) * 100 : 0
      });

  addData('Header', subData.value?.total || null, compareSubDataTotal || null, true);

  if (compareSubData.value && subData.value) {
    const subcat = [...compareSubData.value.subcategories, ...subData.value.subcategories.map(sub => ({name: sub.name}))];

    subcat.forEach(subcatItem => {
      const subDataSubcategories = subData.value!.subcategories.find(el => el.name === subcatItem.name);
      const subDataTotalConsumptionInWh = subDataSubcategories?.totalConsumptionInWh || 0;
      const compareSubDataTotalConsumptionInWh = fromWhToKwh(subcatItem.totalConsumptionInWh) || 0;

      addData(
          subcatItem.name || 'unmanagedappliance.category.other',
          props.consoType ? subDataTotalConsumptionInWh || 0 : subDataSubcategories?.totalConsumptionInCurrency || 0,
          props.consoType ? compareSubDataTotalConsumptionInWh : subcatItem.totalConsumptionInCurrency,
          false
      );
    });
  }

  return comparedData;
});

const usePercentageCalculation = (currentKwhVal: number, compareKwhVal: number) => {
  const roundedCurrent = Math.round(currentKwhVal);
  const roundedCompare = Math.round(compareKwhVal);

  const result = roundedCompare === 0 ? 0 : ((roundedCurrent - roundedCompare) / roundedCompare) * 100;
  const sign = result > 0 ? '+' : result < 0 ? '-' : '';

  return `${sign}${Math.abs(Math.round(result))} %`;
};

onMounted(async () => {
  await SiteModule.getSites()
  window.scrollTo(0, 0);
})

//used any because of the actual behavior
function valueText(data: any) {
  if(!data) return '-'
  if (props.consoType) return `${Math.round(data)} ${EEnergyUnit.kWh}`
  return formatCurrency(Math.round(data))
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['RepartitionSubcategories', _ctx.comparingDate ? 'isComparing' : '', (subData.value && !subData.value.subcategories.length && isCurrentMonth.value) ? 'noData' : '' ])
  }, [
    _createVNode(_component_router_link, {
      class: "ReturnLink",
      to: backLink.value,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" ← " + _toDisplayString(_ctx.$t('conso.repartition.return.global_view')), 1)
      ]),
      _: 1
    }, 8, ["to"]),
    (!_ctx.comparingDate)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(MyVRepartitionSubcategoriesCard, {
            unit: _ctx.consoType ? _unref(EEnergyUnit).kWh : _unref(currency),
            "is-active": true,
            category: subData.value ? subData.value.name : '',
            value: Math.round(subData.value?.total) ?? 0
          }, null, 8, ["unit", "category", "value"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subData.value ? subData.value.subcategories : [], (sub) => {
            return (_openBlock(), _createBlock(MyVRepartitionSubcategoriesCard, {
              key: sub,
              unit: _ctx.consoType ? _unref(EEnergyUnit).kWh : _unref(currency),
              category: sub.name,
              value: (_ctx.consoType ? sub.totalConsumptionInWh : sub.totalConsumptionInCurrency) ?? 0,
              percentage: sub.percentage
            }, null, 8, ["unit", "category", "value", "percentage"]))
          }), 128)),
          (subData.value && !subData.value.subcategories.length && isCurrentMonth.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('conso.repartition.currentMonth.noData')), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.comparingDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allComparedData.value, (data, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "",
              key: index
            }, [
              (data.isHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.comparingDate), 1),
                      _createElementVNode("h3", null, _toDisplayString(valueText(data.compareKwhVal)), 1)
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("svg", {
                      class: "icon icon-vs",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-vs" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(urlDate.value), 1),
                      _createElementVNode("h3", null, _toDisplayString(valueText(data.currentKwhVal)), 1)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(['percentage' , data.percentage > 0 ?'positive' : 'negative'])
                    }, [
                      (data.percentage !== Infinity)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(usePercentageCalculation(data.currentKwhVal, data.compareKwhVal)), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('conso.repartition.no_data2')), 1)
                          ], 64))
                    ], 2)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t(data.name)), 1),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", null, _toDisplayString(valueText(data.compareKwhVal)), 1),
                        _cache[2] || (_cache[2] = _createElementVNode("svg", {
                          class: "icon icon-vs-dark",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-vs-dark" })
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(valueText(data.currentKwhVal)), 1),
                        (data.currentKwhVal > 0 && data.compareKwhVal > 0)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(['percentage', data.percentage > 0 ? 'positive' : 'negative'])
                            }, _toDisplayString(data.percentage ? (data.percentage > 0 ? '+' + Math.round(data.percentage) : Math.round(data.percentage)) : undefined) + " % ", 3))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: _normalizeClass(['percentage', data.percentage > 0 ? 'positive' : 'negative'])
                            }, _toDisplayString(_ctx.$t('conso.repartition.no_data2')), 3))
                      ])
                    ])
                  ]))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})