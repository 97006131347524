import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "devices-status-tile-header" }
const _hoisted_2 = { class: "devices-status-tile-content" }
const _hoisted_3 = ["xlink:href"]

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {EDiagnosticStatus, TDiagnostic} from "@/services/Diagnostic/interfaces";
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDevicesStatusTile',
  props: {
    diagnostic: {}
  },
  setup(__props: any) {

const props = __props

const statusIconName = computed(() => {
  switch (props.diagnostic!.status) {
    case EDiagnosticStatus.COMM_ERROR:
    case EDiagnosticStatus.NOK:
    case EDiagnosticStatus.PILOTING_ERROR:
    case EDiagnosticStatus.NOK_UNREACHABLE:
      return 'exclamation-circle'
    case EDiagnosticStatus.TEST_IN_PROGRESS:
      return 'spinner'
    case EDiagnosticStatus.NO_CONSUMPTION:
      return 'question-circle'
    case EDiagnosticStatus.OK:
      return 'check-circle'
    default:
      return 'exclamation-circle'
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.diagnostic.link,
    class: "devices-status-tile",
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.diagnostic.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(['icon', `icon-${statusIconName.value}`]),
          "aria-hidden": "true"
        }, [
          _createElementVNode("use", {
            "xlink:href": `#icon-${statusIconName.value}`
          }, null, 8, _hoisted_3)
        ], 2))
      ]),
      _createVNode(MyVButton, {
        round: "",
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('device_status.more')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})