import API from "@/helpers/api";

import {TContactService, TContactSubjects} from "./interfaces";

const ContactService: TContactService = {

  connectedSend: (siteId, body) => API.post<void>(`/api/site/${siteId}/account/contact`, body),
  
  send: body => API.post<void>('/contact', body),

  getConnectedSubjects: () => (API.get<TContactSubjects[]>('/api/account/contact/subjects').then(({data}) => data)),

  getVisitorSubjects: () => (API.get<TContactSubjects[]>('/contact/subjects').then(({data}) => data))
};

export default ContactService;
