import phoneUtil, {PhoneNumberFormat} from "google-libphonenumber";

export const PHONE_NUMBER_MIN_LENGTH = 10
export const PHONE_NUMBER_MAX_LENGTH = 15

const CODE_FR = "FR"

const phoneNumberUtil = phoneUtil.PhoneNumberUtil.getInstance();

const phoneValidationRegex = /^\+?[\d]+$/;

export const addSpacePhoneNumber = (phoneNumber: string, isSiteCountryFR: boolean):string => {
  if (isSiteCountryFR) {
    try {
        return phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(phoneNumber, CODE_FR), phoneNumber.includes('+') ? PhoneNumberFormat.INTERNATIONAL : PhoneNumberFormat.NATIONAL);
    } catch (e: any) {
      return phoneNumber;
    }
  }
  return phoneNumber
}

export const validatePhoneNumber = (phoneNumber: string, isSiteCountryFR: boolean): boolean => {
    if (phoneNumber && phoneNumber.length === 0) return true;

    if (isSiteCountryFR) {
      try {
          return phoneNumberUtil.isValidNumberForRegion(phoneNumberUtil.parse(phoneNumber, CODE_FR), CODE_FR);
      } catch (e: any) {
          return false;
      }
    }

    return phoneValidationRegex.test(phoneNumber.trim()) && phoneNumber.length >= PHONE_NUMBER_MIN_LENGTH && phoneNumber.length <= PHONE_NUMBER_MAX_LENGTH
}