import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "appliances-list" }
const _hoisted_2 = { class: "AppliancesList__grid" }

import {TAppliancesList} from "./interfaces"
import {createDateString, createUntilText} from "@/helpers/dates/date";
import MyVGeAlert from "@/components/ui/atoms/MyVGeAlert/MyVGeAlert.vue";
import {computed} from "vue";
import {ProgramModule} from "@/store";
import MyVAppliancesTile from "@/components/domains/Heating/appliances/MyVAppliancesTile/MyVAppliancesTile.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesList',
  props: {
    appliances: {}
  },
  setup(__props: any) {



const hasEnergyManager = computed(() => {
  return ProgramModule.hasEnergyManager;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (hasEnergyManager.value)
      ? (_openBlock(), _createBlock(MyVGeAlert, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliances, (appliance) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "AppliancesList__grid-item",
          key: appliance.id
        }, [
          _createVNode(MyVAppliancesTile, {
            id: appliance.id,
            name: appliance.name,
            link: appliance.link,
            mode: appliance.programming.mode,
            "heating-level": appliance.heatingLevel,
            temp: appliance.programming.temperatureTarget,
            type: appliance.applianceType,
            isOn: appliance.programming.isOn,
            prog: _unref(createUntilText)(_ctx.$t, appliance.programming.untilFurtherNotice,appliance.programming.endDate) + ' ' + _unref(createDateString)(_ctx.$t, appliance.programming.untilFurtherNotice, appliance.programming.endDate),
            progType: appliance.programming.progType,
            progName: appliance.programming.progName
          }, null, 8, ["id", "name", "link", "mode", "heating-level", "temp", "type", "isOn", "prog", "progType", "progName"])
        ]))
      }), 128))
    ])
  ]))
}
}

})