import { ECountry, ELocale, countries, isoCodeCountryFromCountry } from '@/composables/countries';
import { TText } from '@/graphs/interfaces';
import { hasFractionalDigits } from '@/helpers/number/formatNumber';
import { SiteModule } from '@/store';
import { computed } from 'vue';

export enum ECurrency {
    EUR = "€",
    GBP = "£",
    HUF = "Ft",
    CZK = "Kč",
    CHF = "CHF",
    SEK = "kr"
}

enum EIsoCodeCurrency {
    EUR = "EUR",
    GBP = "GBP",
    HUF = "HUF",
    CZK = "CZK",
    CHF = "CHF",
    SEK = "SEK"
}

const currencies: ECurrency[] = Object.values(ECurrency)

const currencyFromIsoCode: Record<EIsoCodeCurrency, ECurrency> = {
    [EIsoCodeCurrency.EUR]: ECurrency.EUR,
    [EIsoCodeCurrency.GBP]: ECurrency.GBP,
    [EIsoCodeCurrency.HUF]: ECurrency.HUF,
    [EIsoCodeCurrency.CZK]: ECurrency.CZK,
    [EIsoCodeCurrency.CHF]: ECurrency.CHF,
    [EIsoCodeCurrency.SEK]: ECurrency.SEK,
}

const currencyFromCountry: Record<ECountry, EIsoCodeCurrency> = {
    [ECountry.FRANCE]: EIsoCodeCurrency.EUR,
    [ECountry.SPAIN]: EIsoCodeCurrency.EUR,
    [ECountry.PORTUGAL]: EIsoCodeCurrency.EUR,
    [ECountry.FINLAND]: EIsoCodeCurrency.EUR,
    [ECountry.BELGIUM]: EIsoCodeCurrency.EUR,
    [ECountry.SLOVAKIA]: EIsoCodeCurrency.EUR,
    [ECountry.ITALY]: EIsoCodeCurrency.EUR,
    [ECountry.SLOVENIA]: EIsoCodeCurrency.EUR,
    [ECountry.UK]: EIsoCodeCurrency.GBP,
    [ECountry.JERSEY]: EIsoCodeCurrency.GBP,
    [ECountry.GUERNSEY]: EIsoCodeCurrency.GBP,
    [ECountry.HUNGARY]: EIsoCodeCurrency.HUF,
    [ECountry.CZECH_REPUBLIC]: EIsoCodeCurrency.CZK,
    [ECountry.SWITZERLAND]: EIsoCodeCurrency.CHF,
    [ECountry.SWEDEN]: EIsoCodeCurrency.SEK,
}

const maximumFractionDigits: Record<EIsoCodeCurrency, number> = {
    [EIsoCodeCurrency.EUR]: 2,
    [EIsoCodeCurrency.HUF]: 2,
    [EIsoCodeCurrency.GBP]: 2,
    [EIsoCodeCurrency.CHF]: 2,
    [EIsoCodeCurrency.CZK]: 2,
    [EIsoCodeCurrency.SEK]: 2,
}

export default function useCurrency() {
    const siteCountry = computed<ECountry>(() => {
        //defensive behaviour, if we do not have the country, we assume that it might be certainly French
        return SiteModule.siteCountry && countries.includes(SiteModule.siteCountry) ? SiteModule.siteCountry : ECountry.FRANCE
    })

    const currenciesBeforeText: EIsoCodeCurrency[] = [EIsoCodeCurrency.GBP]

    const currencyIsoCode = computed<EIsoCodeCurrency>(() => currencyFromCountry[siteCountry.value])

    const currency = computed<ECurrency>(() => currencyFromIsoCode[currencyIsoCode.value])

    const isCurrencyBeforeText = computed(() => currenciesBeforeText.includes(currencyIsoCode.value))

    function formatCurrency(value: number, fractionDigits: number = maximumFractionDigits[currencyIsoCode.value]
    ): string {

        const isoCodeCountry: ELocale = isoCodeCountryFromCountry[siteCountry.value]

        return Intl
            .NumberFormat(isoCodeCountry, {
                style: "currency",
                currency: currencyIsoCode.value,
                maximumFractionDigits: hasFractionalDigits(value) ? fractionDigits : 0,
            })
            .format(value)
    }

    function isCurrency(value: string): boolean {
        return currencies.includes(value as ECurrency)
    }

    function formatTextUnitArray(array: TText[]) {
        if (isCurrencyBeforeText.value && array.length === 2) {
            return [array[0], array[1]] = [array[1], array[0]]
        }
        return array
    }

    return {currency, formatCurrency, isCurrency, formatTextUnitArray, isCurrencyBeforeText}
}
