export const toRGBA = (color: string, a: number): string => {
    const rgb = [0, 0, 0];

    if (color.startsWith('#')) {
        for (let i = 0; i < rgb.length; ++i) {
            rgb[i] = parseInt(color.substring(1 + 2 * i, 1 + 2 * (i + 1)), 16);
        }
    }
    else {
        let offset = 4;

        if (color.charAt(3) === 'a') {
            ++offset;
        }

        for (let i = 0; i < color.length; ++i) {
            const start = offset;

            offset = color.indexOf(',', offset);
            if (offset === -1) {
                offset = color.indexOf(')');
            }
            rgb[i] = parseInt(color.substring(start, offset).trim());
            ++offset;
        }
    }

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;
}