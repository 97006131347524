import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "pageWithPopin__container inner-sm-min" }
const _hoisted_3 = { class: "pageWithPopin" }
const _hoisted_4 = {
  key: 0,
  class: "pageWithPopin__left"
}
const _hoisted_5 = {
  key: 1,
  class: "pageWithPopin__left"
}
const _hoisted_6 = { class: "pageWithPopin__right" }

import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import ERoutes from "@/router/routes";
import MyVDevicesStatusList from "@/components/domains/Device/MyVDevicesStatusList/MyVDevicesStatusList.vue";
import {AuthModule, DiagnosticModule, RouterModule} from "@/store";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import logo from "@/assets/images/logo_beta.svg?url";
import {computed, onBeforeMount} from "vue";
import {useRoute, useRouter} from "vue-router";
import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DiagnosticView',
  setup(__props) {

const router = useRouter()
const route = useRoute()

const backLink = computed(() => {
  return () => RouterModule.goBack(ERoutes.HOME);
})

const user = computed(() => {
  return AuthModule.user;
})

const currentSiteId = computed(() => {
  return getCurrentSiteIdFromCookie(user.value)
})

const diagnostics = computed(() => {
  return DiagnosticModule.diagnostics?.slice().sort((a, b) => {
    return a.name.localeCompare(b.name)
  }).map(diagnostic => ({
    ...diagnostic,
    link: `${ERoutes.DIAGNOSTIC}/${diagnostic.csApplianceId}`
  }));
})

const isMobileDevice = computed(() => {
  return window.innerWidth <= 768
})

const handleCompleteDiag = () => {
  DiagnosticModule.startAllDiagnostics(currentSiteId.value).finally(() => {
    DiagnosticModule.getDiagnostics({siteId: currentSiteId.value, forceRequest: true})
  })
}

onBeforeMount(() => {
  if (DiagnosticModule.diagnostics.length && !route.params?.csApplianceId && !isMobileDevice.value) {
    router.push(diagnostics.value[0].link);
  }

  DiagnosticModule.getDiagnostics({
    siteId: currentSiteId.value,
    forceRequest: true
  }).then(() => {
    if (DiagnosticModule.diagnostics.length && !route.params?.csApplianceId && !isMobileDevice.value) {
      router.push(diagnostics.value[0].link);
    }
  });
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(MyVPageMain, {
    logo: _unref(logo),
    homeLink: _unref(ERoutes).HOME,
    type: "page",
    title: _ctx.$t('app.title'),
    class: "diagnostic-view"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVHeaderMainPage, {
          title: "devices_status.title",
          "return-action": backLink.value
        }, null, 8, ["return-action"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (diagnostics.value?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(MyVDevicesStatusList, {
                  diagnostics: diagnostics.value ?? []
                }, null, 8, ["diagnostics"]),
                _createVNode(MyVButton, {
                  class: "diagnostic-view-button__fix",
                  onClick: handleCompleteDiag,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('diagnostic.startCompleteDiag')), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('diagnostic.deviceStatus.noDiagnostic')), 1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createBlock(_component_router_view, {
              key: _ctx.$route.fullPath
            }))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["logo", "homeLink", "title"]))
}
}

})