import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';
import {TDropDown} from "./interfaces";
import Styles from "./MyVDropDown.module.scss";
import {computed, ref, useSlots, onBeforeMount, watch} from "vue";

interface Props {
  showCarret?: TDropDown['showCarret'];
  className?: TDropDown['className'];
  onOpen?: TDropDown['onOpen'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDropDown',
  props: {
    showCarret: { type: Boolean, default: false },
    className: { default: undefined },
    onOpen: { type: Function, default: undefined }
  },
  setup(__props: any) {


const props = __props

const openTimeout = ref(-1);
const item = ref()
const open = ref(false)
const slots = useSlots()

const renderCarret = computed(() => {
  return hasContent.value || props.showCarret;
})

const hasContent = computed(() => {
  return !!slots.content;
})

const toggleOpen = () => {
  const nextState = !open.value;

  document.dispatchEvent(new Event("closeDropDown"));
  open.value = nextState;
}

const close = () => {
  open.value = false;
  window.clearTimeout(openTimeout.value);
}

onBeforeMount(() => {
  document.addEventListener('closeDropDown', close)
})

watch(open, (newValue) => {
  if (newValue) {
    openTimeout.value = window.setTimeout(() => {
      props.onOpen?.(item.value);
    }, parseInt(Styles.openTransition));
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.dropDown)
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(hasContent.value ? "button" : "div"), {
      type: "button",
      class: _normalizeClass(_ctx.$style.dropDown__button + (_ctx.className !== undefined ? ` ${_ctx.className}` : '')),
      onClick: toggleOpen,
      ref_key: "item",
      ref: item
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default"),
        (renderCarret.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`${_ctx.$style.dropDown__carret}${open.value ? ` ${_ctx.$style.open}` : ''}`)
            }, [
              _createVNode(MyVIcon, { name: "triangle" })
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["class"])),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.$style.dropDown__content}${open.value ? ` ${_ctx.$style.open}` : ''}`)
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ], 2))
}
}

})