import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "CirclePercent" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["stroke-width", "r", "cx", "cy"]
const _hoisted_4 = ["width", "height"]
const _hoisted_5 = ["stroke-width", "r", "cx", "cy", "stroke-dasharray"]
const _hoisted_6 = { class: "CirclePercent__percentage" }

import {TCirclePercent} from "./interfaces";
import {computed, ref} from "vue";

export interface Props {
  percent?: TCirclePercent["percent"],
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCirclePercent',
  props: {
    percent: { default: 0 }
  },
  setup(__props: any) {

const props = __props

const radius = ref(42)
const stroke = ref(8)

const size = computed(() => radius.value * 2)
const normalizedRadius = computed(() => radius.value - stroke.value / 2)
const circumference = computed(() => normalizedRadius.value * 2 * Math.PI)
const strokeDashoffset = computed(() => circumference.value - props.percent / 100 * circumference.value)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      width: size.value,
      height: size.value,
      class: "CirclePercent__ring"
    }, [
      _createElementVNode("circle", {
        "stroke-width": stroke.value,
        fill: "transparent",
        r: normalizedRadius.value,
        cx: radius.value,
        cy: radius.value
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_2)),
    (_openBlock(), _createElementBlock("svg", {
      width: size.value,
      height: size.value,
      class: "CirclePercent__ring-progress"
    }, [
      _createElementVNode("circle", {
        "stroke-width": stroke.value,
        fill: "transparent",
        r: normalizedRadius.value,
        cx: radius.value,
        cy: radius.value,
        style: _normalizeStyle({'strokeDashoffset':strokeDashoffset.value}),
        "stroke-dasharray": circumference.value + ' ' + circumference.value
      }, null, 12, _hoisted_5)
    ], 8, _hoisted_4)),
    _createElementVNode("span", _hoisted_6, [
      _createElementVNode("span", null, _toDisplayString(_ctx.percent), 1),
      _cache[0] || (_cache[0] = _createTextVNode("% "))
    ])
  ]))
}
}

})