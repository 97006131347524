import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

import {TSwitch} from "./interfaces";
import {Form, Switch} from 'ant-design-vue';
import {onBeforeMount, ref, watch} from "vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {refDebounced} from "@vueuse/core";

export interface Props {
  defaultValue?: TSwitch["defaultValue"];
  value?: TSwitch["defaultValue"];
  checkedValue?: TSwitch["checkedValue"];
  isPaused?: TSwitch["isPaused"];
  isDebounced? : TSwitch["isDebounced"];
  unCheckedValue?: TSwitch["unCheckedValue"];
  checkedChildren?: TSwitch["checkedChildren"];
  checkedChildrenIcon?: TSwitch["checkedChildrenIcon"];
  unCheckedChildrenIcon?: TSwitch["uncheckedChildrenIcon"];
  unCheckedChildren?: TSwitch["unCheckedChildren"];
  label?: TSwitch["label"];
  size?: TSwitch["size"];
  hasUncheckStyle?: TSwitch["hasUncheckStyle"];
  hasCheckStyle?: TSwitch["hasCheckStyle"];
  onChange: TSwitch["onChange"];
  error?: TSwitch["error"];
  disabled?: TSwitch["disabled"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntdswitch',
  props: {
    defaultValue: { type: Boolean, default: undefined },
    value: { type: Boolean, default: undefined },
    checkedValue: { default: undefined },
    isPaused: { type: Boolean, default: false },
    isDebounced: { type: Boolean, default: false },
    unCheckedValue: { default: undefined },
    checkedChildren: { default: undefined },
    checkedChildrenIcon: { default: undefined },
    unCheckedChildrenIcon: { default: undefined },
    unCheckedChildren: { default: undefined },
    label: { default: undefined },
    size: { default: 'big' },
    hasUncheckStyle: { type: Boolean, default: false },
    hasCheckStyle: { type: Boolean, default: false },
    onChange: { type: Function, default: undefined },
    error: { default: undefined },
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const {Item} = Form;

const props = __props

const checked = ref<boolean|undefined>(false)
const debouncedChecked = refDebounced(checked, 1000)

const changeChecked = (value: boolean) => {
  if (!props.disabled) {
    checked.value = value || false;
    if (props.onChange) {
      props.onChange(checked.value);
      checked.value = props.value;
    }
  }
}

onBeforeMount(() => {
  checked.value = props.value || props.defaultValue || false;
})

watch(() => props.value, (newValue) => {
  checked.value = newValue;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Item), {
    class: _normalizeClass(['switch__group', {'hasUncheckStyle': _ctx.hasUncheckStyle}, {'hasCheckStyle': _ctx.hasCheckStyle}, {'isPaused': _ctx.isPaused} ,{'isChecked': checked.value},{'isUnChecked': !checked.value}, {'outsideLabel':_ctx.label},  _ctx.size]),
    label: _ctx.label,
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (changeChecked(false))),
        type: "button",
        class: _normalizeClass(['switch__label', 'switch__labelunCheckedValue', {'selected':!checked.value}])
      }, _toDisplayString(_ctx.unCheckedValue), 3),
      _createVNode(_unref(Switch), {
        size: "small",
        checked: _ctx.isDebounced ? _unref(debouncedChecked) : checked.value,
        "onUpdate:checked": changeChecked,
        disabled: _ctx.disabled,
        unit: checked.value ? _ctx.unCheckedChildren: _ctx.checkedChildren
      }, _createSlots({ _: 2 }, [
        (_ctx.checkedChildren || _ctx.checkedChildrenIcon)
          ? {
              name: "checkedChildren",
              fn: _withCtx(() => [
                (_ctx.checkedChildrenIcon)
                  ? (_openBlock(), _createBlock(MyVIcon, {
                      key: 0,
                      name: _ctx.checkedChildrenIcon
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.checkedChildren), 1)
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.unCheckedChildren || _ctx.unCheckedChildrenIcon)
          ? {
              name: "unCheckedChildren",
              fn: _withCtx(() => [
                (_ctx.unCheckedChildrenIcon)
                  ? (_openBlock(), _createBlock(MyVIcon, {
                      key: 0,
                      name: _ctx.unCheckedChildrenIcon
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.unCheckedChildren), 1)
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["checked", "disabled", "unit"]),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (changeChecked(true))),
        type: "button",
        class: _normalizeClass(['switch__label', 'switch__labelcheckedValue', {'selected':checked.value}])
      }, _toDisplayString(_ctx.checkedValue), 3)
    ]),
    _: 1
  }, 8, ["class", "label", "validate-status", "help"]))
}
}

})