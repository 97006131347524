
export const arrayAreEqual = <T = any>(array1: T[], array2: T[]): boolean => {
    let areEqual = array1.length === array2.length;

    for (let i = 0; areEqual && i < array2.length; ++i) {
        areEqual = array1.includes(array2[i]);
    }

    return areEqual;
}

export const arrayAreDiff = <T = any>(array1: T[], array2: T[]): boolean => !arrayAreEqual<T>(array1, array2);