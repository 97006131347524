import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "MultiSteps" }
const _hoisted_2 = {
  key: 0,
  class: "MultiSteps__dots"
}
const _hoisted_3 = ["onClick"]

import {TMultiSteps, TMultiStepsControl, TMultiStepValue} from "./interfaces";
import {computed, onBeforeMount, onMounted, ref, useSlots, watch} from "vue";

interface Props {
  defaultStep?: TMultiSteps['defaultStep'];
  showDots?: TMultiSteps['showDots'];
  onStepChange?: TMultiSteps['onStepChange'];
  onFinish?: TMultiSteps['onFinish'];
  canClickDots?: TMultiSteps['canClickDots']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVMultiSteps',
  props: {
    defaultStep: { default: undefined },
    showDots: { type: [Boolean, Array], default: true },
    onStepChange: { default: undefined },
    onFinish: { default: undefined },
    canClickDots: { type: Boolean, default: true }
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const currentStep = ref(0)
const slots = useSlots()

const stepsName = computed(() => {
  return Object.keys(slots);
})

const stepControl = computed((): TMultiStepsControl => {
  const stepsNameValue = stepsName.value;
  const current = currentStep.value + 1;

  return {
    current,
    name: stepsNameValue[currentStep.value],
    isFirstStep: currentStep.value === 0,
    isLastStep: current === stepsNameValue.length,
    prev: currentStep.value !== 0 ? () => {
      --currentStep.value;
    } : undefined,
    next: (currentStep.value + 1) < stepsNameValue.length ? () => {
      ++currentStep.value;
    } : props.onFinish,
    goToStep: goToStep,
  }
})

const mustRenderDots = computed(() => {
  if (Array.isArray(props.showDots)) {
    const stepControlValue = stepControl.value;

    return props.showDots.findIndex(dot => {
      switch (typeof dot) {
        case 'number':
          return dot === stepControlValue.current;
        case 'string':
          return dot === stepControlValue.name;
      }
    }) !== -1;
  }
  return props.showDots;
})


const goToStep = (step: TMultiStepValue) => {
  if (!props.canClickDots) return false;
  const stepsNameValue = stepsName.value;

  if (typeof step === 'string') {
    const stepIndex = stepsNameValue.findIndex(stepName => stepName === step);

    if (stepIndex === -1) {
      console.error('Slot not found ! Please check your step\'s name.');
    } else {
      currentStep.value = stepIndex;
    }
  } else if (step < 1 || step > stepsNameValue.length) {
    console.error('Step index number is out of range !');
  } else {
    currentStep.value = step - 1;
  }
}

onBeforeMount(() => {
  const stepsNameValue = stepsName.value;
  let index;

  if (props.defaultStep) {
    switch (typeof props.defaultStep) {
      case 'number':
        if (props.defaultStep < 0 || props.defaultStep >= stepsNameValue.length) {
          console.error('Slot default value is out of range !');
        } else {
          currentStep.value = props.defaultStep;
        }
        break;

      case 'string':
        if ((index = stepsNameValue.indexOf(props.defaultStep)) !== -1) {
          currentStep.value = index;
        }
        break;
    }
  }
})

onMounted(()=> {
  emit('update:value', stepControl.value);
})

watch(currentStep, (newValue, oldValue) => {
  if (oldValue !== undefined && newValue !== oldValue) {
    props.onStepChange?.(newValue + 1, oldValue + 1);
  }
})

watch(stepControl, (newValue) => {
  emit('update:value', newValue);
}, {deep: true})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepsName.value, (stepName, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stepName,
        class: _normalizeClass(['MultiStep', {'hide':index !== currentStep.value}])
      }, [
        (index === currentStep.value)
          ? _renderSlot(_ctx.$slots, stepName, {
              key: 0,
              step: stepControl.value,
              current: currentStep.value
            })
          : _createCommentVNode("", true)
      ], 2))
    }), 128)),
    (mustRenderDots.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepsName.value, (stepName, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stepName,
              onClick: ($event: any) => (goToStep(index + 1)),
              class: _normalizeClass(['MultiSteps__dot', {'active':index === currentStep.value}])
            }, null, 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})