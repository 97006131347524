import {Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";

const defaultType = sessionStorage.getItem('switchType') ? sessionStorage.getItem('switchType') === 'true' : true

@Module({ name: EModules.SWITCH_CONSO })
class SwitchConso extends VuexModule {
  private _switchType: boolean = defaultType
  
  get switchType() {
    return this._switchType;
  }

  /**
   * Mutations
   */
  @Mutation
  public _setSwitchType(val: boolean): void {
    sessionStorage.setItem('switchType', val.toString())
    this._switchType = val;
  }

}

export default SwitchConso;
