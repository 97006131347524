import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__form" }
const _hoisted_2 = { class: "MultiStep__main" }
const _hoisted_3 = { class: "FirstConnection__container" }
const _hoisted_4 = { class: "text-justify" }
const _hoisted_5 = { class: "FirstConnection__textBlock" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "FirstConnection__textBlock bold" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "LinkyConsentStep__concentItems" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "FirstConnection__textBlock" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "FirstConnection__textBlock bold" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "FirstConnection__submitContainer" }

import {computed, onBeforeMount, ref} from "vue";

import {Form} from "ant-design-vue";
import {ConsentModule} from "@/store";
import {TConsentEnedisUpdate, TConsentsEnedisUpdate} from "@/services/ConsentEnedis/interfaces";


import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {TLinkyConsentStep} from '../interfaces';
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLinkyConsentStep',
  props: {
    next: { type: Function }
  },
  setup(__props: any) {

const props = __props

const form = ref<TConsentsEnedisUpdate>([]);

const consentsOrder = computed(() => {
  return ['enedis.consent.transmission.cdc.name', 'enedis.consent.transmission.idx.name', 'enedis.consent.transmission.powermax.name', 'enedis.consent.contract.name']
})

const canSubmit = computed(() => {
  return form.value.some(consent => {
    return consent.enabled;
  });
})

const toggleSwitch = (consent: TConsentEnedisUpdate, enabled: boolean) => {
  const consentIndex = form.value.findIndex(value => value.nameKey === consent.nameKey);
  if (consentIndex !== -1) {
    form.value[consentIndex].enabled = enabled;
  }
};

const onFinish = () => {
  ConsentModule.updateConsentEnedis(form.value)
      .then(() => {
        props.next();
      });
}

onBeforeMount(() => {
  form.value = ConsentModule.enedis!.reduce((consents, {subcategories}) => {
    subcategories.forEach(({nameKey, enabled}) => {
      if (!enabled) {
        consents.push({nameKey, enabled});
      }
    })

    consents.sort((a, b) => {
      return consentsOrder.value.indexOf(a.nameKey) - consentsOrder.value.indexOf(b.nameKey)
    })

    return consents;
  }, [] as TConsentsEnedisUpdate);

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    model: form.value,
    onFinish: onFinish,
    class: "MultiStep__form linkyForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MyVFieldset, {
            title: _ctx.$t('firstConnection.linky_consent.title')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t('firstConnection.linky_consent.description')
                    }, null, 8, _hoisted_6)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t('firstConnection.linky_consent.description_1')
                    }, null, 8, _hoisted_8)
                  ]),
                  _createElementVNode("ul", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value, (consent, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `${consent.nameKey}-${index}`,
                        class: "LinkyConsentStep__concentItem"
                      }, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(consent.nameKey)), 1),
                        _createElementVNode("div", null, [
                          _createVNode(MyVAntdswitch, {
                            value: consent.enabled,
                            "has-check-style": "",
                            ref_for: true,
                            ref: `${consent.nameKey}-${index}`,
                            onChange: (checked) => toggleSwitch(consent, checked)
                          }, null, 8, ["value", "onChange"])
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t('firstConnection.linky_consent.description_2')
                    }, null, 8, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      innerHTML: _ctx.$t('firstConnection.linky_consent.description_3')
                    }, null, 8, _hoisted_14)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["title"]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(MyVButton, {
              class: "linkySubmit",
              disabled: !canSubmit.value,
              icon: "arrow-right",
              round: true,
              type: _unref(EButtonType).Submit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('firstConnection.linky_consent.submit')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "type"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})