import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modeInfo" }
const _hoisted_2 = { class: "TabHeatingMode__list" }
const _hoisted_3 = ["onClick", "disabled"]
const _hoisted_4 = { class: "TabHeatingMode__item-icon" }
const _hoisted_5 = ["xlink:href"]
const _hoisted_6 = ["xlink:href"]
const _hoisted_7 = { class: "TabHeatingMode__item-title" }

import {TTabHeatingMode} from "./interfaces";
import {computed} from "vue";
import {SiteModule} from "@/store";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";

export interface Props {
  listItem: TTabHeatingMode["listItem"],
  activeTab?: TTabHeatingMode["activeTab"],
  action: TTabHeatingMode["action"],
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTabHeatingMode',
  props: {
    listItem: { default: undefined },
    activeTab: { default: '' },
    action: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const isActive = computed(() => {
  return (slug: string) => props.activeTab === slug;
})

const hasBasicOffer = computed((): boolean => {
  return SiteModule.hasBasicOffer;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(['TabHeatingMode', _ctx.listItem.length > 5 ? 'needScroll' : ''])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$t('header.mode_infos')) + " ", 1),
      (hasBasicOffer.value)
        ? (_openBlock(), _createBlock(MyVIcon, {
            key: 0,
            name: "locked"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItem, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.slug,
          class: _normalizeClass(['TabHeatingMode__listItem', {'TabHeatingMode__listItem_disabled': hasBasicOffer.value}])
        }, [
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.action(item.slug)),
            type: "button",
            disabled: hasBasicOffer.value,
            class: _normalizeClass(['TabHeatingMode__item', {'active': isActive.value(item.slug)}])
          }, [
            _createElementVNode("span", _hoisted_4, [
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(['icon icon-'+item.icon]),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": ['#icon-'+item.icon]
                }, null, 8, _hoisted_5)
              ], 2)),
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(['icon icon--active icon-'+item.iconActive]),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": ['#icon-'+item.iconActive]
                }, null, 8, _hoisted_6)
              ], 2))
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(item.title), 1)
          ], 10, _hoisted_3)
        ], 2))
      }), 128))
    ])
  ], 2))
}
}

})