import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content"
}
const _hoisted_2 = {
  key: 1,
  class: "noContent"
}

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {TNotification, TNotifications} from "@/services/Notification/interfaces";
import {AuthModule, NotificationModule} from "@/store";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {computed, onBeforeMount, ref} from "vue";
import {useI18n} from "vue-i18n";
import MyVNotificationCard from "@/components/ui/atoms/MyVNotificationCard/MyVNotificationCard.vue";
import MyVMenuSlider from "@/components/ui/molecules/MyVMenuSlider/MyVMenuSlider.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVNotification',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const selectedCategory = ref<TNotifications['categoryKey'] | null>(null);
const {t} = useI18n()
const notifications = computed(() => {
  return NotificationModule.notifications;
})

const sliderOptions = computed(() => {
  if (!notifications.value) {
    return null;
  }
  const allNotifCounter = notifications.value.reduce((sum, notifications) => sum + notifications.notifications.length, 0);
  const options: any[] = [
    {
      label: t('notification.all_notifications', {counter: allNotifCounter}),
      value: null
    }
  ];

  notifications.value.forEach(notifications => {
    options.push({
      label: `${t(notifications.categoryKey)} (${notifications.notifications.length})`,
      value: notifications.categoryKey
    })
  })

  return options;
})

const notificationsList = computed((): TNotifications['notifications'] => {
  const notificationsItems: TNotifications['notifications'][] = [];

  if (!selectedCategory.value) {
    notifications.value?.forEach(tempNotif => {
      notificationsItems.push(tempNotif.notifications);
    })

    return notificationsItems.flat(1);
  }

  return notifications.value?.find(notification => notification.categoryKey === selectedCategory.value)?.notifications ?? [];
})

const currentSiteId = computed(() => {
  return getCurrentSiteIdFromCookie(AuthModule.user);
})

const closePopin = () => {
  emit('close')
}

const changeOption = (val: string) => {
  selectedCategory.value = val;
}

const deleteNotif = (notification: TNotification) => {
  NotificationModule.deleteNotifications({
    siteId: currentSiteId.value,
    notificationsId: [notification.id]
  }).then(() => {
    const categoryHasNotifications = notifications.value?.find(notif => notif.categoryKey === notification.categoryKey)?.notifications?.length;
    if (!categoryHasNotifications) {
      selectedCategory.value = null;
    }
  });
}

const deleteAllNotif = () => {
  const ids = notificationsList.value.map(notif => {
    return notif.id;
  })
  if (!ids.length) return;
  NotificationModule.deleteNotifications({
    siteId: currentSiteId.value,
    notificationsId: ids
  });
}

const readNotification = (id: number) => {
  NotificationModule.readNotifications({
    siteId: currentSiteId.value,
    notificationsId: [id]
  });
}

onBeforeMount(() => {
  NotificationModule.getNotifications({
    siteId: getCurrentSiteIdFromCookie(AuthModule.user),
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    type: "popin",
    title: _ctx.$t('page.notification.title'),
    "return-action": closePopin,
    class: "notification"
  }, {
    headerRight: _withCtx(() => [
      (_openBlock(), _createElementBlock("svg", {
        class: "icon icon-delete-forever",
        "aria-hidden": "true",
        onClick: deleteAllNotif
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("use", { "xlink:href": "#icon-delete-forever" }, null, -1)
      ])))
    ]),
    default: _withCtx(() => [
      _createVNode(MyVMenuSlider, {
        class: "menuSlider",
        "is-blue": "",
        onChange: changeOption,
        "item-type": "div",
        "default-current": selectedCategory.value,
        options: sliderOptions.value
      }, null, 8, ["default-current", "options"]),
      (notificationsList.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notificationsList.value, (notif) => {
              return (_openBlock(), _createBlock(MyVNotificationCard, {
                key: notif.id,
                notification: notif,
                hasTabSelected: selectedCategory.value !== null,
                onReadNotif: readNotification,
                onDeleteNotif: deleteNotif
              }, null, 8, ["notification", "hasTabSelected"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("svg", {
              class: "icon icon-bell",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-bell" })
            ], -1)),
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('no_notification')), 1)
          ]))
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})