import {
    ProgramEventProperties,
    ETrackProgramEvent,
    TMixpanelService,
    ETrackQuicksettingEvent,
    QuickSettingEventProperties,
    ManualSettingEventProperties,
    ETrackManualSettingEvent,
    ETrackConsumptionEvent,
    ConsumptionAggregrationTypeEventProperties,
    ConsumptionAggregrationPeriodEventProperties,
    ETrackObjectiveEvent,
    ObjectiveEventProperties,
} from "./interfaces";

import mixpanel, {Dict, Config} from "mixpanel-browser";
import {TUser} from "@/services/Auth/interfaces";
import {useDate} from "@/helpers/dates/date-utils";
import {ESubscriberOffer} from "@/services/Site/interfaces";
import {RouterModule} from "@/store";

const MixpanelService: TMixpanelService = {
    init: function (token: string, options?: Config): void {
        mixpanel.init(token, {
            track_pageview: "url-with-path",
            persistence: "localStorage",
            ...options,
        });
    },
    track(eventName: string, baseProperties?: Dict): void {
        trackEvent(eventName, baseProperties)
    },
    trackProgramEvent(eventName: ETrackProgramEvent, baseProperties?: ProgramEventProperties): void {
        trackEvent(eventName, baseProperties);
    },
    trackQuicksetting(eventName: ETrackQuicksettingEvent, baseProperties?: QuickSettingEventProperties): void {
        trackEvent(eventName, baseProperties);
    },
    trackManualSetting(eventName: ETrackManualSettingEvent, baseProperties: ManualSettingEventProperties): void {
        trackEvent(eventName, baseProperties);
    },
    trackObjective(eventName: ETrackObjectiveEvent, baseProperties: ObjectiveEventProperties): void {
        trackEvent(eventName, baseProperties);
    },
    trackConsumption(
        eventName: ETrackConsumptionEvent,
        baseProperties: ConsumptionAggregrationTypeEventProperties | ConsumptionAggregrationPeriodEventProperties
    ): void {
        trackEvent(eventName, baseProperties);
    },
    identify(user: TUser): void {
        mixpanel.identify(`${user.id}`);
        mixpanel.people.set({
            user_id: user.id,
            installation_date: user.defaultSite.installationDate,
            installation_month_name: useDate(user.defaultSite.installationDate).format("MMMM"),
            default_site_id: user.defaultSite.id,
            default_site_city: user.defaultSite.city,
            default_site_postalcode: user.defaultSite.postalCode,
            default_site_country: user.defaultSite.country,
            default_site_timezone: user.defaultSite.timezone,
            voltalis_version: user.defaultSite.voltalisVersion,
            data_start: user.defaultSite.dataStart,
            has_global_consumption_measure: user.defaultSite.hasGlobalConsumptionMeasure,
            has_ds_measure: user.defaultSite.hasDsoMeasure,
            subscriber_offer: user.defaultSite.hasBasicOffer ? ESubscriberOffer.BASIC : ESubscriberOffer.PREMIUM,
            first_connection: user.firstConnection,
            migrated_user: user.migratedUser,
            display_group: user.displayGroup.name,
        });
    },
    reset(): void {
        mixpanel.reset()
    },
    optOut(): void {
        mixpanel.opt_out_tracking()
    }
};

const trackEvent = <T extends Dict>(eventName: string, baseProperties?: T): void => {
    const enrichedProperties = addCommonProperties(baseProperties || {});
    mixpanel.track(eventName, enrichedProperties);
};

const addCommonProperties = (properties: Dict): Dict => {
    const referrer = document.referrer?.trim();

    let refererDomain = window.location.hostname;
    let refererUrl = RouterModule.fullLastPath

    if (referrer) {
        try {
            const url = new URL(referrer);
            refererDomain = url.hostname;
            refererUrl = url.href;
        } catch (error) {
            console.warn("Invalid referrer URL:");
        }
    }

    return {
        ...properties,
        user_id: mixpanel.get_distinct_id(),
        timestamp: new Date().toISOString(),
        page_domain: window.location.hostname,
        page_path: window.location.pathname,
        page_url: window.location.href,
        page_title: document.title || "Unknown Title",
        referer_domain: refererDomain,
        referer_url: refererUrl,
    };
};

export default MixpanelService;
