import API from "@/helpers/api";

import {TSite, TSiteService} from "./interfaces";

const SiteService: TSiteService = {
  
  getSites: () => (
    API.get<TSite[]>(`/api/site`)
      .then(({ data }) => data)
  ),
  updateSite: (siteId) => (
    API.put<TSite>(`/api/site/${siteId}/default`)
      .then(({ data }) => data)
  ),

  updateSiteName: (siteId, name) => (
      API.put<TSite>(`/api/site/${siteId}/name`, {name})
      .then(({ data }) => data)
  )
};

export default SiteService;
