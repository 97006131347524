import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVHousingEquipment from "@/components/domains/Profile/MyVHousingEquipments/MyVHousingEquipment.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVEquipmentPopin',
  emits: ['close', 'update:value'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const closePopin = () => {
  emit('close')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    type: "popin",
    title: _ctx.$t('popin.equipment_popin.title'),
    "return-action": closePopin,
    class: "equipment-popin"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVHousingEquipment, {
        "onUpdate:value": _cache[0] || (_cache[0] = args => emit('update:value',args))
      })
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})