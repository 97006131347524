import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  ref: "content",
  class: "fieldset__content"
}

import {TFieldset} from "@/components/ui/atoms/MyVFieldset/interfaces";

interface Props {
  title?: TFieldset["title"];
  simple?: TFieldset["simple"];
  noPadding?: TFieldset["noPadding"]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVFieldset',
  props: {
    title: { default: '' },
    simple: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass([{'fieldset':!_ctx.simple}, {'fieldsetSimple':_ctx.simple}, {'fieldset--noPadding':_ctx.noPadding}])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("legend", {
          key: 0,
          class: "fieldset__legend",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ], 512)
  ], 2))
}
}

})