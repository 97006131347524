import API from "@/helpers/api";

import {TConsentEnedisService, TConsentsEnedis} from "./interfaces";

const ConsentEnedisService: TConsentEnedisService = {
  
  getConsentsEnedis: (siteId) => (
    API.get<TConsentsEnedis>(`/api/site/${siteId}/user/consent/enedis`)
      .then(({ data }) => data)
  ),

  updateConsentEnedis: (siteId, consent) => (
    API.put<TConsentsEnedis>(`/api/site/${siteId}/user/consent`, consent)
      .then(({ data }) => data)
  ),
};

export default ConsentEnedisService;
