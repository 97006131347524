import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobilePopin" }
const _hoisted_2 = { class: "device-status-view-container" }

import ERoutes from "@/router/routes";
import {DiagnosticModule, RouterModule} from "@/store";
import {TDiagnostic} from "@/services/Diagnostic/interfaces";
import {computed, ref, watch} from "vue";
import {useRoute} from "vue-router";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVDeviceStatus from "@/components/domains/Device/MyVDeviceStatus/MyVDeviceStatus.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeviceStatusView',
  setup(__props) {

const route = useRoute()
const diagnostic = ref<TDiagnostic>()

const diagnostics = computed(() => {
  return DiagnosticModule.diagnostics
})

const backLink = computed(() => {
  return () => RouterModule.goBack(ERoutes.DIAGNOSTIC);
})

watch(diagnostics, () => {
  diagnostic.value = diagnostics.value.find(({csApplianceId}) => parseInt(route.params?.csApplianceId as string) === csApplianceId);
}, {immediate: true, deep: true})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (diagnostic.value)
      ? (_openBlock(), _createBlock(MyVPageWithReturn, {
          key: 0,
          title: diagnostic.value.name,
          type: "page",
          returnAction: backLink.value,
          class: "device-status-view"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(), _createBlock(MyVDeviceStatus, {
                key: diagnostic.value.status,
                diagnostic: diagnostic.value
              }, null, 8, ["diagnostic"]))
            ])
          ]),
          _: 1
        }, 8, ["title", "returnAction"]))
      : _createCommentVNode("", true)
  ]))
}
}

})