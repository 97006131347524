import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "savings-view-wrapper" }

import ERoutes from "@/router/routes";
import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import logo from "@/assets/images/logo_beta.svg?url";

import {computed, onBeforeMount, ref} from "vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import MyVSavingsCard from "@/components/domains/Savings/MyvSavingsCard/MyVSavingsCard.vue";
import savingsService from "@/services/Savings";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {AuthModule} from "@/store";
import {TSavings} from "@/services/Savings/interfaces";
import {useDate} from "@/helpers/dates/date-utils";
import {labelMonthsFormat} from "@/services/Conso/interfaces";
import {useResponsive} from "@/composables/useResponsive";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'SavingsView',
  setup(__props) {

const savings = ref<TSavings>()

const {isMobile} = useResponsive()
const {t} = useI18n()

const currentSiteId = computed(() => getCurrentSiteIdFromCookie(AuthModule.user))
const startDate = computed(() => t('savingsView.startDate', {startDate: useDate(savings.value?.startDate).format(labelMonthsFormat)}))
const hasStartDate = computed(() => savings.value?.startDate)

onBeforeMount(() => {
  savingsService.getSavings(currentSiteId.value).then((data) => {
    savings.value = data;
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: _unref(logo),
    title: _ctx.$t('app.title'),
    class: "savings-view inner"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVHeaderMainPage, {
        title: _ctx.$t('savingsView.title'),
        subtitle:  (!_unref(isMobile) && hasStartDate.value) ? startDate.value : ''
      }, null, 8, ["title", "subtitle"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVSavingsCard, {
          variant: "currency",
          savings: savings.value
        }, _createSlots({ _: 2 }, [
          (_unref(isMobile) && hasStartDate.value)
            ? {
                name: "subtitle",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(startDate.value), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["savings"]),
        _createVNode(MyVSavingsCard, {
          variant: "carbone",
          savings: savings.value
        }, null, 8, ["savings"])
      ])
    ]),
    _: 1
  }, 8, ["homeLink", "logo", "title"]))
}
}

})