import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["xlink:href"]

import {TDailyConsumption} from "./interfaces";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {computed} from "vue";
import useCurrency from "@/composables/currency";
import {ContractModule} from "@/store";

interface Props {
  value?: TDailyConsumption["value"];
  currencyValue?: TDailyConsumption["currencyValue"];
  oldValue?: TDailyConsumption["oldValue"];
  isDetail?: TDailyConsumption["isDetail"];
  date?: TDailyConsumption["date"];
  unit?: TDailyConsumption["unit"];
  category?: TDailyConsumption["category"];
  showCurrencyEquivalent?: TDailyConsumption["showCurrencyEquivalent"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDailyConsumption',
  props: {
    value: { default: undefined },
    currencyValue: { default: undefined },
    oldValue: { default: undefined },
    isDetail: { type: Boolean, default: false },
    date: { default: undefined },
    unit: { default: EEnergyUnit.kWh },
    category: { default: undefined },
    showCurrencyEquivalent: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const {formatCurrency, isCurrency} = useCurrency()

const contract = computed(() => {
  return ContractModule.currentContract;
})

const icon = computed(() => {
  switch (props.category) {
    case 'conso.category.water':
      return 'water';
    case 'conso.category.heater':
      return 'heater1';
    case 'conso.category.waterheating':
      return 'water';
    case 'cat3':
      return 'home-other';
    default:
      return 'home-other';
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.DailyConsumption])
  }, [
    (_ctx.category)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.DailyConsumption__cat])
        }, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(['icon icon-'+icon.value]),
            "aria-hidden": "true"
          }, [
            _createElementVNode("use", {
              "xlink:href": ['#icon-'+icon.value]
            }, null, 8, _hoisted_1)
          ], 2))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isDetail)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_ctx.$style.DailyConsumption__date])
        }, _toDisplayString(_ctx.date), 3))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([_ctx.$style.DailyConsumption__title])
        }, _toDisplayString(_ctx.$t('daily_consumption.today_conso')), 3)),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.DailyConsumption__values])
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass([_ctx.$style['DailyConsumption__values-icon'], 'icon icon-energy']),
        "aria-hidden": "true"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("use", { "xlink:href": "#icon-energy" }, null, -1)
      ]), 2)),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.$style.DailyConsumption__value])
      }, [
        (_unref(isCurrency)(_ctx.unit))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(formatCurrency)(_ctx.value ?? 0)), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.value ?? 0) + " " + _toDisplayString(_ctx.unit), 1)
            ], 64))
      ], 2)
    ], 2),
    _createVNode(MyVDisplayGroupStore, {
      currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
    }, {
      default: _withCtx(() => [
        (_ctx.currencyValue != 0 && contract.value && _ctx.showCurrencyEquivalent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([_ctx.$style.DailyConsumption__values__equivalent])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([_ctx.$style.DailyConsumption__unit])
              }, _toDisplayString(_ctx.$t('conso.live.equivalent')), 3),
              _createElementVNode("span", {
                class: _normalizeClass([_ctx.$style.DailyConsumption__value])
              }, _toDisplayString(_unref(formatCurrency)(_ctx.currencyValue ?? 0)), 3)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["currentRight"]),
    _renderSlot(_ctx.$slots, "DailyConsumptionUnderText")
  ], 2))
}
}

})