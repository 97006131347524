import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "StartPageBeforeSteps__content" }
const _hoisted_2 = { class: "StartPageBeforeSteps__sectionWelcome" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "StartPageBeforeSteps__sectionWelcomeText__description"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "StartPageBeforeSteps__sectionButton" }
const _hoisted_8 = ["innerHTML"]

import {AuthModule} from "@/store";
import {TStartPageBeforeSteps} from "../interfaces";
import {computed} from "vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'MyVStartPageBeforeSteps',
  props: {
    start: { type: Function }
  },
  setup(__props: any) {



const isMigratedUser = computed(() => {
  return AuthModule.userOrNull?.migratedUser
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", {
        class: "StartPageBeforeSteps__sectionWelcomeText__title",
        innerHTML: _ctx.$t("firstConnection.start_page_before_steps.welcome.title", { greenText: "StartPageBeforeSteps__greenText" })
      }, null, 8, _hoisted_3),
      (!isMigratedUser.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "StartPageBeforeSteps__sectionWelcomeText__description",
            innerHTML: _ctx.$t("firstConnection.start_page_before_steps.welcome")
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (isMigratedUser.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", {
              innerHTML: _ctx.$t("firstConnection.start_page_before_steps.welcome.description")
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", {
        class: "StartPageBeforeSteps__sectionButton__text",
        innerHTML: _ctx.$t("firstConnection.start_page_before_steps.welcome.text")
      }, null, 8, _hoisted_8),
      _createVNode(MyVButton, { onClick: _ctx.start }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("firstConnection.start_page_before_steps.button")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}
}

})