import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {EModules} from "../modules";
import {THousing} from "@/views/domains/Profile/Housing/interfaces";
import {AuthModule} from "@/store";
import HousingService from "@/services/Housing";
import {TPutHousingParams} from "@/services/Housing/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";


@Module({name: EModules.HOUSING})
class Housing extends VuexModule {
    /**
     * State
     */
    private _housing: THousing | undefined = undefined

    /**
     * Getters
     */
    get housing() {
        return this._housing
    }


    /**
     * Mutations
     */
    @Mutation
    public _setHousing(housing: THousing): void {
        this._housing = housing
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public getHousing(): Promise<THousing | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return HousingService.getHousing(getCurrentSiteIdFromCookie(user)).then(housing => {
                this.context.commit('_setHousing', housing)
                return housing
            }).catch(e => {
                console.error(e)
                throw e
            })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateHousing(body: TPutHousingParams): Promise<THousing | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return HousingService.setHousing(getCurrentSiteIdFromCookie(user), body).then(housing => {
                this.context.commit('_setHousing', housing)
                return housing
            }).catch(e => {
                console.error(e)
                throw e
            })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

}

export default Housing;
