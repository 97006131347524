import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "TabNav" }
const _hoisted_2 = { class: "TabNav__list" }
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = { class: "TabNav__item-title" }
const _hoisted_5 = {
  key: 1,
  class: "TabNav__item-wrapper"
}
const _hoisted_6 = {
  class: /*@__PURE__*/_normalizeClass(['TabNav__item', 'TabNav__item--disabled'])
}
const _hoisted_7 = ["xlink:href"]
const _hoisted_8 = { class: "TabNav__item-title" }

import {TTabNav, TTabNavItem} from "./interfaces";
import {useRoute} from "vue-router";
import {computed} from "vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import ERoutes from "@/router/routes";

interface Props {
  listItem: TTabNav["listItem"];
  activeLink?: TTabNav["activeLink"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTabNav',
  props: {
    listItem: { default: undefined },
    activeLink: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const route = useRoute()

const isRouteConso = computed(() => {
  return route.fullPath.includes('conso')
})

const isRouteHeating= computed(() => {
  return route.fullPath.includes(ERoutes.HEATING)
})

const isActiveLink = (item:  TTabNavItem) => {
  if (isRouteHeating.value && typeof item.link === 'string' && item.link.includes(ERoutes.HEATING)) {
    return true;
  }
  return item.link === props.activeLink
}

const activeClassByRoute = (link: string) => {
  if (route!.fullPath.indexOf("/program") !== -1 && link === "/heating") {
    return "active";
  }
  return '';
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItem, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.link
        }, [
          (!item.disabled && !item.locked)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.link,
                "active-class": "active",
                class: _normalizeClass(['TabNav__item', {'active': isActiveLink(item) || (isRouteConso.value && item.title === _ctx.$t('nav.conso'))}, activeClassByRoute(item.link)])
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", {
                    class: _normalizeClass(['TabNav__item-icon icon icon-'+item.icon]),
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", {
                      "xlink:href": ['#icon-'+item.icon]
                    }, null, 8, _hoisted_3)
                  ], 2)),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  (_openBlock(), _createElementBlock("svg", {
                    class: _normalizeClass(['TabNav__item-icon icon icon-'+item.icon]),
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", {
                      "xlink:href": ['#icon-'+item.icon]
                    }, null, 8, _hoisted_7)
                  ], 2)),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(item.title), 1)
                ]),
                (item.locked)
                  ? (_openBlock(), _createBlock(MyVIcon, {
                      key: 0,
                      name: "locked"
                    }))
                  : _createCommentVNode("", true)
              ]))
        ]))
      }), 128))
    ])
  ]))
}
}

})