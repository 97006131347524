import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "RadioHideSection__radio" }

import {TRadioHideSection} from './interfaces';
import MyVAntdradio from "@/components/ui/atoms/MyVAntdradio/MyVAntdradio.vue";
import {onBeforeMount, watch, ref} from "vue";

export interface Props {
  options: TRadioHideSection["options"],
  defaultValue?: TRadioHideSection["defaultValue"],
  value?: TRadioHideSection["value"],
  onChange?: TRadioHideSection["onChange"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVRadioHideSection',
  props: {
    options: { default: () => [] },
    defaultValue: { default: undefined },
    value: { default: undefined },
    onChange: { type: Function, default: undefined }
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const m_value = ref<TRadioHideSection['value']>();

onBeforeMount(() => {
  m_value.value = props.value || props.defaultValue;
})

watch(m_value, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue !== props.value) {
    emit('update:value', newValue);
  }
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue !== m_value.value) {
    m_value.value = newValue;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(MyVAntdradio, {
        value: m_value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((m_value).value = $event)),
        options: _ctx.options,
        defaultValue: _ctx.defaultValue,
        onChange: _ctx.onChange
      }, null, 8, ["value", "options", "defaultValue", "onChange"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['radioHide__content', {'hide':m_value.value !== option.value}])
      }, [
        (option.value === m_value.value)
          ? _renderSlot(_ctx.$slots, `radio${option.value}`, { key: 0 })
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 64))
}
}

})