import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["xlink:href"]

import {TRepartitionSubcategoriesCard} from "./interfaces";
import MyVCirclePercent from "@/components/ui/molecules/MyVCirclePercent/MyVCirclePercent.vue";
import useConsoRepartition from "@/composables/repartition";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {computed} from "vue";

interface Props {
  isActive?: TRepartitionSubcategoriesCard['isActive'];
  value: TRepartitionSubcategoriesCard['value'];
  category?: TRepartitionSubcategoriesCard['category'];
  percentage?: TRepartitionSubcategoriesCard['percentage'];
  unit: TRepartitionSubcategoriesCard['unit']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVRepartitionSubcategoriesCard',
  props: {
    isActive: { type: Boolean, default: false },
    value: { default: undefined },
    category: { default: undefined },
    percentage: { default: undefined },
    unit: { default: EEnergyUnit.kWh }
  },
  setup(__props: any) {

const props = __props

const {consoRepartitionValue} = useConsoRepartition()

const icon = computed(() => {
  switch (props.category) {
    case 'conso.category.waterheating':
      return 'water';
    case 'conso.category.heater':
      return 'heater';
    case 'conso.category.other':
      return 'home-other';
    default:
      return 'home-other';
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard, _ctx.isActive ? _ctx.$style.RepartitionSubcategoriesCardActive : ''])
  }, [
    (_ctx.isActive && _ctx.category)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard__icon])
        }, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(['icon icon-'+icon.value]),
            "aria-hidden": "true"
          }, [
            _createElementVNode("use", {
              "xlink:href": ['#icon-'+icon.value]
            }, null, 8, _hoisted_1)
          ], 2))
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard__main])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard__main__category])
      }, _toDisplayString((_ctx.category && typeof _ctx.category === 'string') ? _ctx.$t(_ctx.category) : null), 3),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard__main__value])
      }, [
        _createElementVNode("span", null, _toDisplayString(_unref(consoRepartitionValue)(props.unit, props.value)), 1)
      ], 2)
    ], 2),
    (!_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_ctx.$style.RepartitionSubcategoriesCard__percentage])
        }, [
          _createVNode(MyVCirclePercent, { percent: _ctx.percentage }, null, 8, ["percent"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})