export enum EBarLineGraphIds {
  /**
   * Graph's id for "temperature"
   */
  temperature,

  /**
   * Graph's id for "peak_hour"
   */
  peak_hour,

  /**
   * Graph's id for "offpeak_hour"
   */
  offpeak_hour,

  /**
   * Graph's id for compared "temperature"
   */
  compare_temperature,

  /**
   * Graph's id for compared "peak_hour"
   */
  compare_peak_hour,

  /**
   * Graph's id for compared "offpeak_hour"
   */
  compare_offpeak_hour,

  contract_base_price
}

export enum EConsoCompare {
  /**
   * Not comparing anything
   */
  NONE = 0,

  /**
   * Compare last period
   */
  LAST_PERIOD = 1,

  /**
   * Compare last year
   */
  LAST_YEAR = 2
}
