import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "geolocation-fix-popin" }
const _hoisted_2 = { class: "BottomPopin__title" }
const _hoisted_3 = { class: "BottomPopin__buttons" }
const _hoisted_4 = ["innerHTML"]

import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {onBeforeMount, onMounted} from "vue";
import {GeolocationModule} from "@/store";
import {
  EGeolocationResponseState,
  EGeolocationResponseType,
  TGeolocationStateResponse
} from "@/services/Geolocation/interfaces";
import {openAppSettings, requirePermission} from "@/helpers/domains/mobileApps/flutter-connectors";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {isAndroid} from "@/helpers/domains/device";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVGeolocationPermissionsPopin',
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const emit = __emit
const handleAutoGeolocationFix = async () => {

  const permissionState = GeolocationModule.permissions
  const locationPermission = permissionState.find(state => state.type === EGeolocationResponseType.location)
  const locationBackgroundPermission = permissionState.find(state => state.type === EGeolocationResponseType.locationBackground)

  if (!locationPermission || !locationBackgroundPermission) {
    return
  }

  if (locationBackgroundPermission.state !== EGeolocationResponseState.granted) {
    openAppSettings().then((permissions: TGeolocationStateResponse[]) => {
      if (!permissions.some(permission => permission.state !== EGeolocationResponseState.granted)) {
        emit('close')
      }
      GeolocationModule.getPermissions()
    })
  }

  await GeolocationModule.refreshGeofences()
}
onBeforeMount(() => {
  GeolocationModule.getPermissions()
  GeolocationModule.refreshGeofences()
})

onMounted(() => {
  requirePermission(EGeolocationResponseType.location).then(() => {
    GeolocationModule.getPermissions()
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVBottomPopin, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVIcon, { name: "pin" }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('geolocation.fixPermissions')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            innerHTML:  _unref(isAndroid) ? _ctx.$t('geolocation.settings.phoneSettings.android.description') : _ctx.$t('geolocation.settings.phoneSettings.ios.description')
          }, null, 8, _hoisted_4),
          _createVNode(MyVButton, {
            class: "BottomPopin__confirm",
            onClick: handleAutoGeolocationFix
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('geolocation.settings.phoneSettings')), 1)
            ]),
            _: 1
          }),
          _createVNode(MyVButton, {
            class: "BottomPopin__confirm",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})