import {TSite} from "../Site/interfaces";

export const namedMonthsFormat = 'MMMM-YYYY'
export const aggregatedDayFormat = 'YYYY-MM-DD';
export const aggregatedDayLiveFormat = 'YYYY-MM-DD';
export const aggregatedWeekFormat = 'YYYY-MM-DD';
export const aggregatedMonthFormat = 'YYYY-MM';
export const aggregatedYearFormat = 'YYYY';
export const weekYearFormat = "DD MMMM YYYY";
export const hourLiveFormat = "HH";
export const labelMonthsFormat = "MMMM YYYY";
export const liveMinuteMode = "TEN_MINUTES";
export const liveSecondMode = "TEN_SECONDS";
export const barLineDayFormat = "ddd DD/MM";

// Max conso in kWh for chart
export const maxKwhConsoByHour = 1;
export const maxKwhConsoByDay = 25;
export const maxKwhConsoByMonth = 800;
export const maxKwhConsoByYear = 10000;

// Max conso in kWh per APPLIANCE for chart
export const maxKwhConsoByHourAppliance = 0.2;
export const maxKwhConsoByDayAppliance = 5;
export const maxKwhConsoByMonthAppliance = 150;
export const maxKwhConsoByYearAppliance = 2000;

// Max conso in currency for chart
export const maxCurrencyConsoByHour = 0.2;
export const maxCurrencyConsoByDay = 5;
export const maxCurrencyConsoByMonth = 160;
export const maxCurrencyConsoByYear = 2000;

// Max conso in currency per APPLIANCE for chart
export const maxCurrencyConsoByHourAppliance = 0.05;
export const maxCurrencyConsoByDayAppliance = 1;
export const maxCurrencyConsoByMonthAppliance = 30;
export const maxCurrencyConsoByYearAppliance = 400;

export enum EAggregationType {
    /**
     * Aggregate datas for every half-hour.
     */
    Hour = 'BY_HOUR',

    /**
     * Aggregate datas for every day.
     */
    Day = 'BY_DAY',

    /**
     * Aggregate datas for every month.
     */
    Month = 'BY_MONTH',

    /**
     * Aggregate datas for every year.
     */
    Year = 'BY_YEAR'
}

export enum EAggregationAlias {
    /**
     * Alias for daily aggregation route.
     */
    Day = 'day',

    /**
     * Alias for weekly aggregation route.
     */
    Week = 'week',

    /**
     * Alias for monthly aggregation route.
     */
    Month = 'month',

    /**
     * Alias for annual aggregation route.
     */
    Year = 'year',

    /**
     * Alias for multiple years
     */
    Years = 'years'
}

export type TConsumption = {

    contractBasePrice: number;

    /**
     * Estimated consumption calculated in country's currency.
     */
    estimatedConsumptionInCurrency: number | null;

    /**
     * Estimated consumption calculated in Wh.
     */
    estimatedConsumptionInWh: number | null;

    objectiveInCurrency?: number;

    objectiveInWh?: number;

    /**
     * Consumption in country's currency during off-peak hours.
     */
    offPeakHourConsumptionInCurrency: number | null;

    /**
     * Consumption in Wh during off-peak hours.
     */
    offPeakHourConsumptionInWh: number | null;

    /**
     * Consumption in country's currency during peak hour.
     */
    peakHourConsumptionInCurrency: number | null;

    /**
     * Consumption in Wh during peak hour.
     */
    peakHourConsumptionInWh: number | null;

    stepTimestampOnSite: string | null;

    temperatureInCelsiusDegree: number | null;
    /**
     * Total consumption in country's currency.
     */
    totalConsumptionInCurrency: number | null;

    /**
     * Total consumption in Wh.
     */
    totalConsumptionInWh: number;

    /**
     * Is the point offpeak.
     */
    isPeakOffPeak: boolean;
};

export type TSummaryConsumption = TConsumption & {

    /**
     * Consumption's objective that we aim for the month in Wh.
     */
    objectiveInWh?: number;

    /**
     * Consumption's objective that we aim for the month in country's currency.
     */
    objectiveInCurrency?: number;

    /**
     * Consumption's objective that we aim for the month in Wh.
     */
    weeklyObjectiveInWh?: number;

    /**
     * Consumption's objective that we aim for the month in country's currency.
     */
    weeklyObjectiveInCurrency?: number;

    /**
     * Consumption's objective that we aim for the month in Wh.
     */
    yearlyObjectiveInWh?: number;

    /**
     * Consumption's objective that we aim for the month in country's currency.
     */
    yearlyObjectiveInCurrency?: number;
}

export type TConsoStep = TConsumption & {
    /**
     * MyVTemperature in celsius degree.
     */
    temperatureInCelsiusDegree: number;

    /**
     * Step time between the last consumption stored as UTC timestamp.
     */
    stepTimestampInUtc: string;
}

export type TConsoAggregated = {
    /**
     * Consumptions' informations.
     */
    consumptions: TConsoStep[];

    totalConsumption: number;
};

export type TConsumptionFullDataParams = {
    period: EAggregationAlias;
    dates: string;
    forceRequest?: boolean;
};

export type TConsumptionFullData = {
    appliances: TConsumptionAppliance[];
    breakdown: TBreakdown | null;
    dataPoints: TConsumption[] | null;
    day?: string;
    month?: string;
    perAppliance: TConsumptionsByApplianceId;
    savings: TConsoSavings | null;
    summary: TSummaryConsumption | null;
    week?: string;
    year?: string;
};

export type TConsumptionAppliance = {
    [key: number]: string
}

export type TConsumptionsByApplianceId = {
    [key: number]: TConsumption[];
}

type TGetConso = (siteId: TSite['id'], date: string, aggregationType: EAggregationType) => Promise<TConsoAggregated | void>;

type TGetRepartition = (siteId: TSite['id'], months?: string[]) => Promise<TBreakdown[] | void>;

type TGetAnnualRepartition = (siteId: TSite['id'], months?: string[]) => Promise<TBreakdown[] | void>;

type TGetRealTimeConso = (siteId: TSite['id'], mode: string, numPoints: number) => Promise<TConsoAggregated | void>;

type TPostStopModulation = (siteId: TSite['id']) => Promise<boolean | void>;

type TGetConsumptionFullData = (siteId: TSite['id'], period: EAggregationAlias, date: string) => Promise<TConsumptionFullData | void>;

type TGetConsumptionMultiYear = (siteId: TSite['id'], years: string) => Promise<TConsumptionFullData | void>;

export type TConsoRoute = (siteId: TSite['id'], date: string, aggregationType: EAggregationType, alias: EAggregationAlias, skipLoading?: boolean) => Promise<TConsoAggregated | void>;

export type TConsoService = {
    /**
     * Send a GET request to retrieve daily consumption's informations aggregated.
     */
    getConsoDailyAggregated: TGetConso;

    /**
     * Send a GET request to retrieve week consumption's informations aggregated.
     */
    getConsoWeeklyAggregated: TGetConso;

    /**
     * Send a GET request to retrieve monthly consumption's informations aggregated.
     */
    getConsoMonthlyAggregated: TGetConso;

    /**
     * Send a GET request to retrieve annual consumption's informations aggregated.
     */
    getConsoAnnualAggregated: TGetConso;

    /**
     * Send a GET request to retrieve monthly repartitions
     */
    getSummaryRepartition: TGetRepartition;

    getWeeklyRepartition: TGetRepartition;

    getMonthlyRepartition: TGetRepartition;

    getAnnualRepartition: TGetAnnualRepartition;

    getRealTimeConso: TGetRealTimeConso;

    stopModulation: TPostStopModulation;

    getConsumptionFullData: TGetConsumptionFullData;

    getMultiYearRepartition: TGetConsumptionMultiYear;
};

export type TRepartitionCategories = {
    name: string;

    totalConsumptionInWh?: number;

    totalConsumptionInCurrency?: number;

    subcategories?: TRepartitionCategories[]
}

export type TBreakdown = {
    categories: TRepartitionCategories[];

    disaggregationCategories: TRepartitionCategories[];
}

export type TConsoSavings = {
    co2InG: number;

    wh: number;

    weather?: number
}