import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "popinForm" }
const _hoisted_2 = { class: "popinForm__main" }
const _hoisted_3 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import {TLegalInformation} from "@/views/domains/Profile/LegalInformation/interfaces";
import API from "@/helpers/api";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {computed, onBeforeMount, ref} from "vue";
import {SiteModule} from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'LegalInformationView',
  setup(__props) {

const articles = ref<TLegalInformation[]>([])
const error = ref<string | null>(null)

const hasBasicOffer = computed(() => SiteModule.hasBasicOffer)

onBeforeMount(() => {
  API.get<TLegalInformation[]>(`/legalnotice?isBasic=${hasBasicOffer.value}`).then(({data}) => {
    articles.value = data
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur'
  })
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('profile.legal_information.title'),
    type: "page",
    "return-action": _unref(ERoutes).HOME,
    class: _normalizeClass([_ctx.$style.LegalInformation])
  }, {
    default: _withCtx(() => [
      (error.value)
        ? (_openBlock(), _createBlock(ErrorBanner, {
            key: 0,
            error: error.value,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = null)),
            class: _normalizeClass(['error-banner', _ctx.$style['error-banner']])
          }, null, 8, ["error", "class"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articles.value, (article, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([_ctx.$style.LegalInformation__content]),
              key: index
            }, [
              _createElementVNode("h2", {
                class: _normalizeClass([_ctx.$style.LegalInformation__title])
              }, _toDisplayString(_ctx.$t(article.categoryKey)), 3),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(article.subcategories, (sub, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: _normalizeClass([_ctx.$style.LegalInformation__subcategories])
                }, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.$t(sub.descriptionKey),
                    class: _normalizeClass([_ctx.$style.LegalInformation__text])
                  }, null, 10, _hoisted_3)
                ], 2))
              }), 128))
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "return-action", "class"]))
}
}

})