import API from "@/helpers/api";
import {convertDayTimeToFrLocaleOrder} from "@/helpers/domains/dailyprogs";

import {
    TDailyProgs,
    TEnergyManager,
    TPlannings,
    TProgram,
    TPrograms,
    TProgramService,
    TProgramWithPlanning
} from "./interfaces";

const ProgramService: TProgramService = {
  getPrograms: siteId => (
    API.get<TProgram[]>(`/api/site/${siteId}/programming/program`)
      .then(({ data }) => data)
  ),

  getProgram: (siteId, programId) => (
    API.get<TProgramWithPlanning>(`/api/site/${siteId}/programming/program/${programId}`)
      .then(({ data }) => {
        data.plannings.forEach(planning => {
          planning.dailyprogs.sort((a, b) => convertDayTimeToFrLocaleOrder(a.dayNumber) - convertDayTimeToFrLocaleOrder(b.dayNumber));
        });
        return data;
      })
  ),

  updateProgram: (siteId, programId, body) => (
    API.put<TPrograms>(`/api/site/${siteId}/programming/program/${programId}`, body)
      .then(({ data }) => data)
  ),

  deleteProgram: (siteId, programId) => (
    API.delete<boolean>(`/api/site/${siteId}/programming/program/${programId}`)
      .then(({ data }) => data)
  ),

  createOrDuplicateProgram: (siteId, name, id) => {
    const body: any = {
      name
    };

    if (id !== undefined) {
      body.id = id;
    }

    return API.post<TProgramWithPlanning>(`/api/site/${siteId}/programming/program`, body)
    .then(({ data }) => data);
  },

  createProgramFromHabit: (siteId, body) => {
    return API.post<TProgramWithPlanning>(`/api/site/${siteId}/programming/program/onboarding`, body)
    .then(({ data }) => data);
  },

  duplicateProgramPlanning: (siteId, body) => (
    API.put<TPlannings>(`/api/site/${siteId}/programming/planning/`, body)
      .then(({ data }) => data)
  ),

  updateProgramPlanningDailyProgs: (siteId, body) => (
    API.put<TDailyProgs>(`/api/site/${siteId}/programming/dailyprog`, body)
      .then(({ data }) => data)
  ),

  getProgrammingState: (siteId) => (
    API.get(`/api/site/${siteId}/programming/state`)
      .then(({data}) => data)
  ),

  resetProgram: (siteId) => (
    API.get(`/api/site/${siteId}/programming/reset`)
      .then(({data}) => data)
  ),

  getEnergyManager: (siteId) => (
      API.get<TEnergyManager>(`/api/site/${siteId}/energymanager`)
          .then(({data}) => data)
  )
};

export default ProgramService;
