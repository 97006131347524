import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IntlModule, ThemeModule} from "@/store";
import Loader from '@/components/ui/atoms/MyVLoader/MyVLoader.vue';

import {TIntlService} from "./interfaces";
import {onBeforeMount, ref} from "vue";

interface Props {
  url?: TIntlService["url"];
  translations?: TIntlService["translations"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVIntlService',
  props: {
    url: { default: undefined },
    translations: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const loading = ref(true)

onBeforeMount(()=> {
  ThemeModule.getTheme(window.location.host)
   .finally(() => {
    if (props.url) {
      IntlModule.initRemote(props.url)
          .catch(() => undefined)
          .finally(() => {
            loading.value = false;
          });
    } else {
      if (props.translations)
      IntlModule.initLocal(props.translations);
      loading.value = false;
    }})
})

return (_ctx: any,_cache: any) => {
  return (!loading.value)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_openBlock(), _createBlock(Loader, { key: 1 }))
}
}

})