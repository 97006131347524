import {TSite} from "@/services/Site/interfaces";

export type TDiagnostic = {
    csApplianceId: number;
    csModulatorId: number;
    diagTestEnabled: boolean;
    link: string;
    name: string;
    status: EDiagnosticStatus;
    startDate?: Date;
    currentDate?: Date;
}


export enum EDiagnosticStatus {
    NO_CONSUMPTION = 'NO_CONSUMPTION',
    PILOTING_ERROR = 'PILOTING_ERROR',
    COMM_ERROR = 'COMM_ERROR',
    NOK_UNREACHABLE = 'NOK_UNREACHABLE',
    NOK_UNREACHABLE_MOMBOX_OFF_SITE = 'NOK_UNREACHABLE_MOMBOX_OFF_SITE',
    NOK = 'NOK',
    OK = 'OK',
    TEST_IN_PROGRESS = 'TEST_IN_PROGRESS',
}

type TGetDiagnostics = (siteId: TSite['id'], skipLoading?: boolean) => Promise<TDiagnostic[]>;
type TPostStartDiagnosticSelfTest = (params: TDiagnosticSelfTestParams) => Promise<void>;
type TPostStartDiagnosticAllTest = (siteId: number) => Promise<void>;
type TGetDiagnosticSelfTest = (params: TDiagnosticSelfTestParams) => Promise<void>;

export type TDiagnosticService = {
    getDiagnostics: TGetDiagnostics;
    startDiagnosticSelfTest: TPostStartDiagnosticSelfTest;
    startDiagnosticAllTest: TPostStartDiagnosticAllTest;
    getDiagnosticSelfTest: TGetDiagnosticSelfTest;
}

export type TDiagnosticSelfTestParams = {
    siteId: TSite['id'];
    csModulatorId: TDiagnostic['csModulatorId']
}

export type TGetDiagnosticsParams = {
    siteId: TSite['id'];
    forceRequest?: boolean;
    skipLoading?: boolean;
}