import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "FirstConnection" }

import MultiSteps from '@/components/ui/molecules/MyVMultiSteps/MyVMultiSteps.vue';
import {TMultiStepsControl} from "@/components/ui/molecules/MyVMultiSteps/interfaces";

import backgroundImg from "@/assets/images/login.jpg";
import backgroundImgMobile from "@/assets/images/login-mobile.jpg";
import {AuthModule, ConsentModule, ThemeModule} from "@/store";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import MyVPageWithImg from "@/components/ui/organisms/layouts/MyVPageWithImg/MyVPageWithImg.vue";
import MyVStartPageBeforeSteps
  from "@/components/domains/Profile/MyVFirstConnectionForms/MyVStartPageBeforeSteps/MyVStartPageBeforeSteps.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVPasswordStep from "@/components/domains/Profile/MyVFirstConnectionForms/MyVPasswordStep/MyVPasswordStep.vue";
import MyVNotifsStep from "@/components/domains/Profile/MyVFirstConnectionForms/MyVNotifsStep/MyVNotifsStep.vue";
import MyVLinkyConsentStep
  from "@/components/domains/Profile/MyVFirstConnectionForms/MyVLinkyConsentStep/MyVLinkyConsentStep.vue";
import MyVCompleteStep from "@/components/domains/Profile/MyVFirstConnectionForms/MyVCompleteStep/MyVCompleteStep.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import {useDate} from "@/helpers/dates/date-utils";
import MyVLangSelect from "@/components/ui/molecules/MyVLangSelect/MyVLangSelect.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FirstConnectionView',
  setup(__props) {

const started = ref(false);
const currentStep = ref(1);
const route = useRoute()
const stepControl = ref<TMultiStepsControl>()

const logo = computed(() => {
  return ThemeModule.theme?.staticResources.logo ?? "";
})

const token = computed(() => {
  return route.query?.token as string;
})

const subscriberId = computed(() => {
  return route.query?.subscriberId as any;
})

const enedisIsNotAllowed = computed(() => {
  if (!AuthModule.userOrNull) return false;
  return !ConsentModule.enedisIsAllowed;
})

const hideSkipButton = computed(() => {
  return stepControl.value?.isLastStep || isPasswordStep.value
})

const isInstallationDateBefore = computed(() => {
  if (!AuthModule.userOrNull) return false;

  const currentSiteId = getCurrentSiteIdFromCookie(AuthModule.user);
  const currentSite = getCurrentSiteObjectFromCookie(currentSiteId);
  const installationDate = currentSite.installationDate;
  if (!installationDate) return true;

  return useDate(currentSite.installationDate).isBefore('2022/06/01')
})

const isNewUser = computed(() => {
  return !AuthModule.userOrNull || (!isMigratedUser.value && AuthModule.userOrNull?.firstConnection);
})

const isMigratedUser = computed(() => {
  return AuthModule.userOrNull?.migratedUser;
})

const hasLinkyStep = computed(() => {
  return isInstallationDateBefore.value && enedisIsNotAllowed.value && isMigratedUser.value;
})

const isPasswordStep = computed(() => {
  return stepControl.value?.name === 'password'
})

const start = () => {
  started.value = true;
}

const stop = () => {
  started.value = false;
}

const prev = () => {
  (stepControl.value?.prev || stop)();
}

const next = () => {
  stepControl.value?.next();
}

const stepChange = (step: number) => {
  currentStep.value = step
}

onBeforeMount(() => {
  if (token.value && subscriberId.value) {
    if (AuthModule.userOrNull) {
      AuthModule.logout()
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyVPageWithImg, {
      backgroundImg: _unref(backgroundImg),
      backgroundImgMobile: _unref(backgroundImgMobile),
      logo: logo.value,
      logoDark: ""
    }, {
      lang: _withCtx(() => [
        _createVNode(MyVLangSelect)
      ]),
      default: _withCtx(() => [
        (!started.value)
          ? (_openBlock(), _createBlock(MyVStartPageBeforeSteps, {
              key: 0,
              start: start,
              class: "startPage"
            }))
          : (_openBlock(), _createBlock(MyVPageWithReturn, {
              key: 1,
              title: _ctx.$t('firstConnection.title'),
              type: "page",
              returnAction: prev
            }, {
              headerRight: _withCtx(() => [
                (!hideSkipButton.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      onClick: next,
                      class: "FirstConnection__skip"
                    }, _toDisplayString(_ctx.$t('firstConnection.skip')), 1))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createVNode(MultiSteps, {
                  "onUpdate:value": _cache[0] || (_cache[0] = (args) =>stepControl.value = args),
                  showDots: ['password', 'complete'],
                  onStepChange: stepChange,
                  canClickDots: false
                }, _createSlots({ _: 2 }, [
                  (isNewUser.value)
                    ? {
                        name: "password",
                        fn: _withCtx(({step}) => [
                          _createVNode(MyVPasswordStep, {
                            prev: stop,
                            next: step.next
                          }, null, 8, ["next"])
                        ]),
                        key: "0"
                      }
                    : undefined,
                  true
                    ? {
                        name: "notifications",
                        fn: _withCtx(({step}) => [
                          _createVNode(MyVNotifsStep, {
                            next: step.next
                          }, null, 8, ["next"])
                        ]),
                        key: "1"
                      }
                    : undefined,
                  (hasLinkyStep.value)
                    ? {
                        name: "linkyConsent",
                        fn: _withCtx(({step}) => [
                          _createVNode(MyVLinkyConsentStep, {
                            next: step.next
                          }, null, 8, ["next"])
                        ]),
                        key: "2"
                      }
                    : undefined,
                  true
                    ? {
                        name: "complete",
                        fn: _withCtx(() => [
                          _createVNode(MyVCompleteStep)
                        ]),
                        key: "3"
                      }
                    : undefined
                ]), 1024)
              ]),
              _: 1
            }, 8, ["title"]))
      ]),
      _: 1
    }, 8, ["backgroundImg", "backgroundImgMobile", "logo"])
  ]))
}
}

})