import {IntlModule} from "@/store";
import {useDate} from "@/helpers/dates/date-utils";

//for simplicity of calculation for UK contracts, we supose that all months have 31 days
export const NUMBER_OF_DAYS_IN_MONTH = 31

const createDateString = (translate: CallableFunction, untilFurtherNotice: boolean | null, date?: string | null): string => {
    if (untilFurtherNotice || !date) {
        return translate("appliance.further_notice");
    }

    const endDate = date ? useDate(date).locale(IntlModule.locale) : useDate();
    const at = translate('global.at');

    // today
    if (endDate.isSame(new Date(), 'days')) {
        return endDate.format("H[h]mm");
    }
    // tomorrow
    if (endDate.isSame(useDate().add(1, 'days').toDate(), 'days')) {
        return translate('global.tomorrow') + " " + endDate.format("H[h]mm")
    }

    // same week
    if (endDate.isSame(new Date(), 'weeks')) {
        return endDate.format(`dddd [${at}] H[h]mm`);
    }

    return endDate.format(`D/MM [${at}] H[h]mm`);
}

const createUntilText = (translate: CallableFunction, untilFurtherNotice: boolean | null, date?: string | null): string => {
    if (untilFurtherNotice || !date) {
        return translate("global.until");
    }

    const endDate = date ? useDate(date).locale(IntlModule.locale) : useDate();

    if (
        endDate.isSame(new Date(), 'days') // today
        || endDate.isSame(useDate().add(1, 'days').toDate(), 'days') // tomorrow
    ) {
        return translate('global.until');
    }

    return translate('global.until2');
}

export {createUntilText, createDateString}