import API from "@/helpers/api";

import {EApplianceType, TAppliance, TAppliances, TAppliancesService, TUnmanagedAppliance,} from "./interfaces";


const AppliancesService: TAppliancesService = {

  getAppliances: siteId => (
    API.get<TAppliances>(`/api/site/${siteId}/managed-appliance`)
      .then(({ data }) => {
        // Les radiateurs doivent être affichés par ordre alphabétique croissant (sur leur nom) et avec le chauffe eau à la fin
        data.sort((a,b)=>{ 
          // envois chauffe eau à la fin
            if(a.applianceType == EApplianceType.WaterHeater){
                return 1;
            }
            if(b.applianceType == EApplianceType.WaterHeater){
                return -1;
            }
          // alphabétique
            if(a.name>b.name){
                return 1;
            }else{
              return -1;
            }
        });
        return data;
      })
  ),

  getAppliance: (siteId, id) => (
    API.get<TAppliance>(`/api/site/${siteId}/managed-appliance/${id}`)
      .then(({ data }) => data)
  ),

  updateAppliance: (siteId, applianceId, body) => (
    API.put<TAppliance>(`/api/site/${siteId}/managed-appliance/${applianceId}`, body)
      .then(({ data }) => data)
  ),

  updateApplianceTemperature: (siteId, applianceId, body) => (
   API.put(`/api/site/${siteId}/managed-appliance/${applianceId}/temperature`, body)
      .then(({ data }) => data)
  ),

  getUnmanagedAppliance: siteId => (
    API.get<TUnmanagedAppliance[]>(`/api/site/${siteId}/unmanaged-appliance`)
      .then(({data}) => data)
  ),

  putUnmanagedAppliance: (siteId, body) => (
    API.put<TUnmanagedAppliance[]>(`/api/site/${siteId}/unmanaged-appliance`, body)
      .then(({data}) => data)
  )
};

export default AppliancesService;
