import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__form" }
const _hoisted_2 = { class: "MultiStep__main" }
const _hoisted_3 = { class: "fieldset__inner" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "MultiStep__footer inner" }
const _hoisted_7 = { class: "NotifsStep__later" }
const _hoisted_8 = ["innerHTML"]

import {TNotifsStep} from '../interfaces';
import {AuthModule} from "@/store";
import {onMounted, ref} from "vue";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import API from "@/helpers/api";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVNotifsStep',
  props: {
    next: { type: Function }
  },
  setup(__props: any) {

const props = __props

const laterUpdate = ref(null)

const onFinish = () => {
  API.put(`/api/site/${getCurrentSiteIdFromCookie(AuthModule.user)}/user/consent`, [
    {
      enabled: true,
      nameKey: "consent.notif-and-alert.notifications.name"
    },
    {
      enabled: true,
      nameKey: "consent.notif-and-alert.exceed-alert.name"
    },
    {
      enabled: true,
      nameKey: "consent.consumption.monthly-report.name"
    },
  ]).then(() => {
    props.next();
  }).catch(e => {
    throw e;
  })
}

onMounted(() => {
  const links = (laterUpdate.value! as HTMLSpanElement).getElementsByTagName('a');

  if (links) {
    for (const link of links) {
      link.addEventListener('click', event => {
        event.preventDefault();
      });
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MyVFieldset, {
        title: _ctx.$t('firstConnection.notifs.title')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "NotifsStep__icon" }, [
              _createElementVNode("span", { class: "NotifsStep__iconCount" }, "9"),
              _createElementVNode("svg", {
                class: "icon icon-bell",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-bell" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                innerHTML: _ctx.$t('firstConnection.notifs.description')
              }, null, 8, _hoisted_5)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(MyVButton, {
        icon: "arrow-right",
        round: true,
        type: _unref(EButtonType).Submit,
        onClick: onFinish
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('firstConnection.notifs.submit')), 1)
        ]),
        _: 1
      }, 8, ["type"]),
      _createElementVNode("p", _hoisted_7, [
        _createElementVNode("span", {
          ref_key: "laterUpdate",
          ref: laterUpdate,
          innerHTML: _ctx.$t('firstConnection.notifs.later_update')
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}
}

})