export const fromWhToKwh = (wh: number) => wh / 1000;

export enum EEnergyUnit {
    Wh = 'Wh',
    W = 'W',
    kVA = "kVA",
    kWh = "kWh",
    MWh = 'MWh',
    TWh = 'TWh',
}

export enum EWeightUnit {
    Kg = 'kg',
}