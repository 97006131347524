import {ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {AppliancesModule} from '@/store';

export default function useApplianceLoader() {
    const route = useRoute();
    const loadedAppliance = ref();

    // Watch for changes in the route params and the store
    watch(
        [() => route.params.id, () => AppliancesModule.appliances],
        ([newId]) => {
            try {
                loadedAppliance.value = AppliancesModule.appliances?.find(
                    ({id}) => parseInt(newId as string) === id
                );
            } catch (error) {
                console.error('Error updating appliance:', error);
            }
        },
        {immediate: true}
    );

    return {loadedAppliance};
}
