import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { EEnergyUnit } from "@/helpers/constants/unit";
import {TConsoListItem} from "./interfaces";
import {ObjectiveModule} from "@/store";
import {computed} from "vue";
import useCurrency from "@/composables/currency";
import { digitalFormat } from "@/helpers/number/formatNumber";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsoListItem',
  props: {
    title: {},
    consoValue: {},
    goal: {},
    unit: {},
    link: {}
  },
  setup(__props: any) {

const props = __props

const {formatCurrency, isCurrency} = useCurrency()

const objective = computed(() => {
  return ObjectiveModule.objective;
})

const formatKWH = (): string => {
  const arraySplitedKWH = digitalFormat(props.consoValue, 2).toString().split(".");
  let formatedKWH = "<strong>" + arraySplitedKWH[0] + "</strong>";

  if (arraySplitedKWH.length > 1) {
    formatedKWH += "," + arraySplitedKWH[1];
  }
  return formatedKWH;
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    class: _normalizeClass([_ctx.$style.ConsoListItem, { [_ctx.$style.isOk]: _ctx.goal > _ctx.consoValue }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.ConsoListItem__title)
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.ConsoListItem__conso)
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('ConsoListItem_conso')) + " : ", 1),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.$style.ConsoListItem__consoNumber, !objective.value ? _ctx.$style['ConsoListItem__consoNumberDefault'] : ''])
        }, [
          (_unref(isCurrency)(_ctx.unit))
            ? (_openBlock(), _createElementBlock("strong", _hoisted_1, _toDisplayString(_unref(formatCurrency)(_ctx.consoValue?? 0)), 1))
            : (_ctx.unit ===  _unref(EEnergyUnit).kWh)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("span", {
                    innerHTML: formatKWH()
                  }, null, 8, _hoisted_2),
                  _createTextVNode(" " + _toDisplayString(_ctx.unit), 1)
                ], 64))
              : _createCommentVNode("", true)
        ], 2)
      ], 2),
      (objective.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.ConsoListItem__goal)
          }, [
            _createElementVNode("i", {
              class: _normalizeClass([_ctx.$style.ConsoListItem__iconFlag])
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("svg", {
                class: "icon icon-flag",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-flag" })
              ], -1)
            ]), 2),
            (_ctx.goal > _ctx.consoValue)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('ConsoListItem_goalOK')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('ConsoListItem_goalNoOK')), 1))
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.$style.ConsoListItem__carretRight)
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", {
          class: "icon icon-carret-right",
          "aria-hidden": "true"
        }, [
          _createElementVNode("use", { "xlink:href": "#icon-carret-right" })
        ], -1)
      ]), 2)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}
}

})