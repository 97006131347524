import API from "@/helpers/api";

import {THousingService,} from "./interfaces";
import {THousing} from "@/views/domains/Profile/Housing/interfaces";


const HousingService: THousingService = {
 getHousing: (siteId) => (
   API.get<THousing>(`/api/site/${siteId}/home`)
     .then(({ data }) => data)
 ),

  setHousing: (id, body) => (
    API.put<THousing>(`/api/site/${id}/home`, body)
      .then(({data}) => data)
  )
};

export default HousingService;
