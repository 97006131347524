import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import headerImg from "@/assets/images/bg-create-program-mobile.jpg"


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVExplicativeConsoPopin',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const closePopin = () => {
  emit('close')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    type: "popin",
    title: _ctx.$t('popin.explicative_conso.title'),
    "return-action": closePopin,
    class: _normalizeClass(_ctx.$style.ExplicativeConso)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.headerImg),
        style: _normalizeStyle(`background-image:url(${_unref(headerImg)})`)
      }, null, 6),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.mainContent)
      }, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('popin.explicative_conso_infos.title')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contentBlock)
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('popin.explicative_conso.period_title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('popin.explicative_conso.period_desc')), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contentBlock)
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('popin.explicative_conso.previous_period_title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('popin.explicative_conso.previous_period_desc')), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contentBlock)
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('popin.explicative_conso.comparision_title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('popin.explicative_conso.comparision_desc')), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contentBlock)
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('popin.explicative_conso.effects_title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('popin.explicative_conso.effects_desc')), 1)
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["title", "class"]))
}
}

})