import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "PageConso__main inner" }
const _hoisted_2 = { class: "PageConso__headerDesktop" }

import {TPageConso} from "./interfaces";

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import logoDark from "@/assets/images/logo_beta.svg?url";
import ERoutes from "@/router/routes";
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPageConso',
  props: {
    title: {}
  },
  setup(__props: any) {



const logo = computed(() => {
  return logoDark;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: logo.value,
    title: _ctx.title,
    class: "PageConso"
  }, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "headerDesktop")
        ]),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["homeLink", "logo", "title"]))
}
}

})