import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mobilePopin PopinListCheck"
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass('PopinListCheck__list')
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "popinForm__footer" }

import {reactive} from "vue";
import {Checkbox, Form} from 'ant-design-vue';

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVDropDown from '@/components/ui/atoms/MyVDropDown/MyVDropDown.vue';
import MyVFieldset from '@/components/ui/atoms/MyVFieldset/MyVFieldset.vue';

import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';

import {TPopinListCheck, TPopinListCheckDatas, TPopinListCheckItem} from './interfaces';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  open: TPopinListCheck['open'];
  title: TPopinListCheck['title'];
  fieldsetTitle?: TPopinListCheck['fieldsetTitle'];
  validateLabel: TPopinListCheck['validateLabel'];
  cancelLabel: TPopinListCheck['cancelLabel'];
  onValidate?: TPopinListCheck['onValidate'];
  onCancel?: TPopinListCheck['onCancel'];
  items: TPopinListCheck['items'];
  noItemsText?: TPopinListCheck['noItemsText'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPopinListCheck',
  props: {
    open: { type: Boolean, default: undefined },
    title: { default: undefined },
    fieldsetTitle: { default: undefined },
    validateLabel: { default: undefined },
    cancelLabel: { default: undefined },
    onValidate: { type: Function, default: undefined },
    onCancel: { type: Function, default: undefined },
    items: { default: undefined },
    noItemsText: { default: undefined }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const form = reactive<TPopinListCheckDatas>({
  items: []
});

const onToggleSelectItem = (id: TPopinListCheckItem['id']) => {
  const itemIndex = form.items.findIndex(item => item === id);

  if (itemIndex !== -1) {
    form.items.splice(itemIndex, 1);
  } else {
    form.items.push(id);
  }
}

const onFinish = () => {
  if (props.onValidate)
    props.onValidate(form);
}

const close = () => {
  emit('close')
}

const cancel = () => {
  emit('close')
}

return (_ctx: any,_cache: any) => {
  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(MyVPageWithReturn, {
          title: _ctx.title,
          type: "popin",
          returnAction: close
        }, {
          subHeader: _withCtx(() => [
            _renderSlot(_ctx.$slots, "subHeader")
          ]),
          footer: _withCtx(() => _cache[0] || (_cache[0] = [])),
          default: _withCtx(() => [
            _createVNode(_unref(Form), {
              class: "popinForm",
              model: form,
              onFinish: onFinish
            }, {
              default: _withCtx(() => [
                _createVNode(MyVFieldset, {
                  title: _ctx.fieldsetTitle,
                  simple: !_ctx.fieldsetTitle,
                  noPadding: !_ctx.fieldsetTitle
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("ul", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item.id
                        }, [
                          _createVNode(MyVDropDown, {
                            onClick: ($event: any) => (item.enable !== false && onToggleSelectItem(item.id)),
                            className: item.enable === false ? 'disabled' : ''
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(item.name), 1),
                              (!item.disabledLabel)
                                ? (_openBlock(), _createBlock(_unref(Checkbox), {
                                    key: 0,
                                    checked: form.items.findIndex(id => id === item.id) != -1,
                                    disabled: !item.enable
                                  }, null, 8, ["checked", "disabled"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.disabledLabel), 1))
                            ]),
                            _: 2
                          }, 1032, ["onClick", "className"])
                        ]))
                      }), 128)),
                      (!_ctx.items.length && _ctx.noItemsText)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.noItemsText), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["title", "simple", "noPadding"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(MyVButton, {
                    type: _unref(EButtonType).Submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.validateLabel), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]),
                  _createElementVNode("button", {
                    onClick: cancel,
                    class: "popinForm__footer-back",
                    type: "button"
                  }, _toDisplayString(_ctx.cancelLabel), 1)
                ])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 3
        }, 8, ["title"])
      ]))
    : _createCommentVNode("", true)
}
}

})