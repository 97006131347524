import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

import {TIcon} from './interfaces';

interface Props {
  name: TIcon['name'];
  className?: TIcon['className'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVIcon',
  props: {
    name: { default: undefined },
    className: { default: '' }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(`icon icon-${_ctx.name} ${_ctx.className}`),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", {
      "xlink:href": ['#icon-' + _ctx.name]
    }, null, 8, _hoisted_1)
  ], 2))
}
}

})