import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "PageConso__headerButtons" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "page2Col" }
const _hoisted_5 = { class: "page2Col__main" }
const _hoisted_6 = { class: "ConsumptionGoal" }
const _hoisted_7 = { class: "BarLineGraphic" }
const _hoisted_8 = { class: "BarLineGraphic__filters" }
const _hoisted_9 = { class: "BarLineGraphic__desktop" }
const _hoisted_10 = { class: "BarLineGraphic__heating-button" }
const _hoisted_11 = { class: "CircleGraphic" }
const _hoisted_12 = { class: "__filters" }
const _hoisted_13 = { class: "__desktop" }
const _hoisted_14 = {
  key: 1,
  class: "__noData"
}
const _hoisted_15 = {
  key: 0,
  class: "HalfCircle"
}
const _hoisted_16 = { class: "__filters" }
const _hoisted_17 = { class: "__content" }
const _hoisted_18 = { class: "page2Col__aside" }
const _hoisted_19 = {
  key: 0,
  class: "ConsoCard__area"
}

import {Dropdown, Menu} from "ant-design-vue";
import MyVMenuSlider from "@/components/ui/molecules/MyVMenuSlider/MyVMenuSlider.vue";
import MyVBarLineGraphic from "@/components/ui/atoms/graphics/MyVBarLineGraphic/MyVBarLineGraphic.vue";
import MyVCircleGraphic from "@/components/ui/atoms/graphics/MyVCircleGraphic/MyVCircleGraphic.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";

import {
  AuthModule,
  ConsoModule,
  ContractModule,
  IntlModule,
  ObjectiveModule,
  SiteModule,
  SwitchConsoModule
} from "@/store";
import ERoutes from "@/router/routes";
import {
  aggregatedMonthFormat,
  aggregatedWeekFormat,
  aggregatedYearFormat,
  EAggregationAlias,
  EAggregationType,
  labelMonthsFormat,
  maxCurrencyConsoByDay,
  maxCurrencyConsoByMonth,
  maxKwhConsoByDay,
  maxKwhConsoByMonth,
  TSummaryConsumption,
  weekYearFormat,
} from "@/services/Conso/interfaces";
import {EAxis, TBarLineGraphic, TLabel,} from "@/components/ui/atoms/graphics/MyVBarLineGraphic/interfaces";
import {TCircleGraphic} from "@/components/ui/atoms/graphics/MyVCircleGraphic/interfaces";
import {THalfCircleGraphic} from "@/components/ui/atoms/graphics/MyVHalfCircleGraphic/interfaces";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {TText, TTooltipOptions} from "@/graphs/interfaces";
import percent from "@/helpers/number/percent";
import {EEnergyUnit, fromWhToKwh} from '@/helpers/constants/unit';
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {EBarLineGraphIds} from "./ConsoDetail/interfaces";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {TSite} from "@/services/Site/interfaces";
import {getContractFromDate} from "@/helpers/domains/conso";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import MyVHalfCircleGraphic from "@/components/ui/atoms/graphics/MyVHalfCircleGraphic/MyVHalfCircleGraphic.vue";
import MyVObjectiveModal from "@/components/ui/organisms/MyVObjectiveModal/MyVObjectiveModal.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import {TObjectiveModal} from "@/components/ui/organisms/MyVObjectiveModal/interfaces";
import MyVPageConso from "@/components/ui/organisms/layouts/MyVPageConso/MyVPageConso.vue";
import MyVPdlValidationModal
  from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationModal/MyVPdlValidationModal.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import MyVConsumptionGoal from "@/components/domains/Conso/MyVConsumptionGoal/MyVConsumptionGoal.vue";
import MyVExplicativeConsoPopin from "@/components/ui/molecules/MyVExplicativeConsoPopin/MyVExplicativeConsoPopin.vue";
import MyVConsoTooltip from "@/components/domains/Conso/MyVConsoTooltip/MyVConsoTooltip.vue";
import {useResponsive} from "@/composables/useResponsive";
import MyVConsoCards from "@/components/domains/Conso/MyVConsoCards/MyVConsoCards.vue";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";
import {DateUtils, useDate} from "@/helpers/dates/date-utils";
import {EButtonSize, EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import useCurrency, {ECurrency} from "@/composables/currency";
import MixpanelService from "@/services/Mixpanel";
import {ETrackConsumptionEvent} from "@/services/Mixpanel/interfaces";
import {TConsoViewOption} from "@/views/domains/Conso/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConsoView',
  setup(__props) {

const g_redirectTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const dropdownVisible_currentLink = ref(false);
const dropdownVisible_currentDateLabel = ref(false);
const showObjectiveModal = ref(false);
const showExplicativeConsoPopin = ref(false);
const showContractPopin = ref(false);
const menuSliderKey = ref(0);
const showConsoInfoPopin = ref(false);
const showDefineContractPopin = ref(false);
const showPdlValidationPopin = ref(false);
const currDate = ref<string | null>(null);
const error = ref<string | null>(null);
const isLoading = ref(true)

const consoTypeKey = ref(0)

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const {isMobile} = useResponsive()
const {currency, formatTextUnitArray, isCurrency, formatCurrency} = useCurrency()

const locale = computed(() => {
  return IntlModule.locale;
})

const rightAxisMin = computed(() => {
  const graph = barLineMonthConso.value.graph;
  const datas = graph?.lines[0]?.datas || [];
  return datas.length ? Math.min(...datas) - 2 : 0;
});

const rightAxisMax = computed(() => {
  const graph = barLineMonthConso.value.graph;
  const datas = graph?.lines[0]?.datas || [];
  return datas.length ? Math.max(...datas) + 2 : undefined;
});

const links = computed((): any => {
  const type = viewType.value.value;

  const queryDate = (route.query.date as string);
  const date = queryDate ? useDate(queryDate, type) : undefined;

  return [
    {
      value: ERoutes.CONSO_LIVE,
      label: t('conso.view.live'),
      url: {path: `${ERoutes.CONSO}/live`, query: {date: undefined}},
    },
    {
      value: ERoutes.CONSO_WEEK,
      label: t('conso.view.weekly'),
      url: {path: ERoutes.CONSO, query: {date: date?.format(aggregatedWeekFormat), type: 'week'}},
    },
    {
      value: ERoutes.CONSO_MONTH,
      label: t('conso.view.monthly'),
      url: {
        path: `${ERoutes.CONSO}`,
        query: {date: date?.format(aggregatedMonthFormat), type: ERoutes.CONSO_MONTH}
      }
    },
    {
      value: ERoutes.CONSO_YEAR,
      label: t('conso.view.annual'),
      url: {path: ERoutes.CONSO, query: {type: 'year', date: date?.format(aggregatedYearFormat)}},
    },
  ];
})

const viewType = computed(() => {
  switch (currentType.value) {
    case ERoutes.CONSO_WEEK:
      return ({
        label: weekYearFormat,
        value: aggregatedWeekFormat,
        routeFormat: aggregatedMonthFormat,
        type: ERoutes.CONSO_MONTH,
        aggregation: EAggregationType.Day,
        format: "DD/MM/YY",
        alias: EAggregationAlias.Week,
        maxConsoValue: consoType.value ? maxKwhConsoByDay : maxCurrencyConsoByDay,
        previousDate: (currentDate: DateUtils) => currentDate.substract(1, 'w'),
        nextDate: (currentDate: DateUtils) => currentDate.add(1, 'w'),
      });
    case ERoutes.CONSO_YEAR:
      return ({
        label: aggregatedYearFormat,
        value: aggregatedYearFormat,
        routeFormat: aggregatedYearFormat,
        type: ERoutes.CONSO_YEAR,
        aggregation: EAggregationType.Year,
        format: "MM-YYYY",
        alias: EAggregationAlias.Year,
        maxConsoValue: consoType.value ? maxKwhConsoByMonth : maxCurrencyConsoByMonth,
        previousDate: (currentDate: DateUtils) => currentDate.substract(1, 'y'),
        nextDate: (currentDate: DateUtils) => currentDate.add(1, 'w'),
      });
    default:
      return ({
        label: labelMonthsFormat,
        value: aggregatedMonthFormat,
        routeFormat: aggregatedYearFormat,
        type: ERoutes.CONSO_YEAR,
        aggregation: EAggregationType.Month,
        format: "MMMM YYYY",
        alias: EAggregationAlias.Month,
        maxConsoValue: consoType.value ? maxKwhConsoByDay : maxCurrencyConsoByDay,
        previousDate: (currentDate: DateUtils) => currentDate.substract(1, 'y'),
        nextDate: (currentDate: DateUtils) => currentDate.add(1, 'w'),
      });
  }
})

const isEditingObjective = computed((): boolean => {
  return objective.value !== undefined;
})

const currentLink = computed(() => {
  const name = route.name;
  const type = route.query.type;

  if (name === ERoutes.CONSO_LIVE) {
    return t('conso.view.live');
  } else if (name === ERoutes.CONSO_WEEK || type === ERoutes.CONSO_WEEK) {
    return t('conso.view.weekly');
  } else if (name === ERoutes.CONSO_YEAR || type === ERoutes.CONSO_YEAR) {
    return t('conso.view.annual');
  }

  return t('conso.view.monthly');
})

const consoSummary = computed(() => {
  const type = viewType.value.value;
  const selectedDate = currentDate.value ? useDate(currentDate.value) : useDate();
  const conso: TSummaryConsumption | undefined = consoFullDataResponses.value[selectedDate.format(type)]?.summary ?? undefined;

  if (!conso)
    return null

  const objectiveDiff = conso.objectiveInWh ? conso.objectiveInWh - conso.totalConsumptionInWh : undefined

  return {
    objectiveDiff,
    total: Math.round(fromWhToKwh(conso.totalConsumptionInWh)),
    showResult: route.query.type === EAggregationAlias.Week && !!ObjectiveModule.objective,
    unit: EEnergyUnit.kWh,
    totalCurrency: Math.round(conso.totalConsumptionInCurrency!)
  }
})

const consumptionByView = computed(() => {
  return t(`conso.chart.consumption.${currentType.value}`);
})

const objectiveDatas = computed(() => {
  const type = viewType.value.value;
  const selectedDate = currentDate.value ? useDate(currentDate.value) : useDate();
  const conso: TSummaryConsumption | null = consoFullDataResponses.value[selectedDate.format(type)]?.summary ?? null;

  if (!conso) {
    return null;
  }

  const res: TObjectiveModal['objectiveData'] = {
    totalObjectiveInKwh: 0,
    totalObjectiveInCurrency: 0
  }

  if (isWeekView.value) {
    res.totalObjectiveInKwh = conso.weeklyObjectiveInWh!;
    res.totalObjectiveInCurrency = conso.weeklyObjectiveInCurrency!;
  } else if (isYearView.value) {
    res.totalObjectiveInKwh = conso.yearlyObjectiveInWh!;
    res.totalObjectiveInCurrency = conso.yearlyObjectiveInCurrency!;
  } else {
    res.totalObjectiveInKwh = conso.objectiveInWh!;
    res.totalObjectiveInCurrency = conso.objectiveInCurrency!;
  }

  return res;
})

const currentYear = computed(() => {
  return currentDate.value ? useDate(currentDate.value).year() : useDate().year();
})

const currentDateLabel = computed(() => {
  return useDate(currentDate.value!).locale(locale.value).format(viewType.value.label);
})

const currentDate = computed(() => {
  return currDate.value;
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const currentType = computed(() => {
  return route.query.type;
})

const setDefaultCurrentDate = computed(() => {
  return menuSliderOptions.value.some(item => item.value === currentDate.value)
      ? currentDate.value
      : {...menuSliderOptions.value.slice(-1)[0]}.value
})

const circleColors = computed(() => {
  return [
    "rgb(62, 62, 124)",
    "#3D78FF",
    "rgb(77, 190, 247)",
    "rgb(14, 3, 140)",
    "#3D78FF",
  ];
})

const isMonthView = computed(() => {
  return currentType.value === ERoutes.CONSO_MONTH;
})

const isYearView = computed(() => {
  return currentType.value === ERoutes.CONSO_YEAR;
})

const isWeekView = computed(() => {
  return currentType.value === ERoutes.CONSO_WEEK;
})

const menuSliderOptions = computed(() => {
  const months: any[] = [];
  const weeks: any[] = [];
  const years: any[] = [];

  const startDate = useDate(currentSite.value.dataStart, 'YYYY-MM-dd').startOf('month');
  const nowNormalized = new Date();

  if (isMonthView.value) {
    const startDateNormalized = startDate.startOf("month");

    while (startDateNormalized.isBefore(nowNormalized)) {
      const label = startDateNormalized.locale(locale.value).format(labelMonthsFormat);

      months.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: startDateNormalized.locale(locale.value).format(aggregatedMonthFormat).toLowerCase()
      });
      startDateNormalized.add(1, "M");
    }

  } else if (isWeekView.value) {
    const startDateNormalized = startDate.startOf("week");

    while (startDateNormalized.isBefore(nowNormalized)) {

      const label = startDateNormalized.locale(locale.value).format(weekYearFormat);

      weeks.push({
        label: label,
        value: startDateNormalized.locale(locale.value).format(aggregatedWeekFormat).toLowerCase()
      })

      startDateNormalized.add(1, 'w');
    }

  } else {
    const startDateNormalized = startDate.startOf("year");
    while (startDateNormalized.isBefore(nowNormalized)) {
      years.push({
        label: startDateNormalized.format('YYYY'),
        value: startDateNormalized.format('YYYY')
      })

      startDateNormalized.add(1, "y");
    }
  }

  if (isWeekView.value) {
    return weeks;
  } else if (isYearView.value) {
    return years;
  } else {
    return months;
  }
})

const consoFullDataResponses = computed(() => {
  return ConsoModule.consumptionFullData.responses;
})

const objective = computed(() => {
  return ObjectiveModule.objective;
})

const shouldRenderTempLine = computed(() => {
  return AuthModule.user.displayGroup.rights.find(({name}) => (
      name === EDisplayGroupRightName.TEMPERATURE_EXT
  ))?.enabled ?? false;
})

const barLineMonthConso = computed(() => {
  const type = viewType.value.value;
  const selectedDate = currentDate.value ? useDate(currentDate.value) : useDate();
  const dataPoints = consoFullDataResponses.value[selectedDate.format(type)]?.dataPoints;
  const unit = EEnergyUnit.kWh;
  const textFormat = isYearView.value ? 'MMMM' : "D";
  let renderLine = shouldRenderTempLine.value;
  let hasOffPeakDatapoint = false;
  const labels: TLabel[] = [];
  const bars: TBarLineGraphic['bars'] = [];
  const lines: TBarLineGraphic['lines'] = [
    {
      id: EBarLineGraphIds.temperature,
      label: t('conso.chart.label.temperature'),
      datas: [],
      color: "rgb(255, 78, 22)",
      baseAxis: EAxis.RIGHT,
      renderTooltip: false
    },
  ];

  if (!dataPoints) {
    return {
      unitEnergy: unit,
      graph: null,
    };
  }

  //Add contract price serie if currency mode
  if (!consoType.value) {
    bars.push({
      id: EBarLineGraphIds.contract_base_price,
      label: t('conso.chart.label.contract_base_price'),
      datas: [],
      color: "#B2B2CB",
    })
  }

  //Add offpeak consumption serie
  bars.push({
    id: EBarLineGraphIds.offpeak_hour,
    label: t('conso.chart.label.offpeak_hour'),
    datas: [],
    color: "rgb(151, 190, 13)",
  })

  //Add peak consumption serie
  bars.push(
      {
        id: EBarLineGraphIds.peak_hour,
        label: isOffPeakContract.value ? t('conso.chart.label.peak_hour') : t('conso.chart.consumption'),
        datas: [],
        color: "rgb(77, 190, 247)",
      }
  )

  const peakHourIndex = bars.findIndex(bar => bar.id === EBarLineGraphIds.peak_hour)
  const offpeakHourIndex = bars.findIndex(bar => bar.id === EBarLineGraphIds.offpeak_hour)
  const contractBasePriceIndex = bars.findIndex(bar => bar.id === EBarLineGraphIds.contract_base_price)


  dataPoints.forEach(el => {

    //Add temperature datapoint
    if (typeof el.temperatureInCelsiusDegree === 'number') {
      lines[0].datas.push(Math.round(el.temperatureInCelsiusDegree));
      renderLine = true;
    } else {
      lines[0].datas.push(el.temperatureInCelsiusDegree!);
    }

    //Add contract base price datapoint
    if (!consoType.value && useDate(el.stepTimestampOnSite!).isSameOrBefore(new Date())) {
      bars[contractBasePriceIndex].datas.push(el.contractBasePrice)
    }

    //Add offpeak datapoint
    if (el.isPeakOffPeak) {
      hasOffPeakDatapoint = true
    }

    const offPeakPoint = consoType.value
        ? fromWhToKwh(el.offPeakHourConsumptionInWh!)
        : el.offPeakHourConsumptionInCurrency!

    bars[offpeakHourIndex].datas.push(offPeakPoint);

    //Add peak datapoint
    bars[peakHourIndex].datas.push(
        consoType.value
            ? fromWhToKwh(el.peakHourConsumptionInWh!)
            : el.peakHourConsumptionInCurrency!
    );

    labels.push({
      text: useDate(el.stepTimestampOnSite!).format(textFormat),
      backgroundColor: (isMonthView.value && useDate(el.stepTimestampOnSite !).locale(locale.value).day() % 6 === 0) ? "rgb(228, 232, 239)" : "",
      date: useDate(el.stepTimestampOnSite !).locale(locale.value).format(viewType.value.value),
    });
  });

  //Remove offpeak hours if no datapoints has offpeak
  if (!hasOffPeakDatapoint) {
    const offPeakBarId = bars.findIndex(bar => bar.id === EBarLineGraphIds.offpeak_hour);
    bars.splice(offPeakBarId, 1)
  }

  return {
    unitEnergy: unit,
    graph: {
      lines: renderLine ? lines : [],
      bars,
      labels,
      leftAxis: {
        title: consoType.value ? unit : currency.value,
      },
      rightAxis: {
        title: ETemperatureUnit.Celsius,
      },
      maxBarThickness: 16,
      onLegendClick: onConsoLegendClick,
    }
  };
})

const contract = computed(() => {
  return getContractFromDate(currentDate.value);
})

const findContractByDate = (date: Date) => {
  return ContractModule.contractsList.find(c => useDate(c.startDate ?? '1970-01-01', 'YYYY-MM-DD').isBefore(date) && useDate(c.endDate ?? undefined).isAfter(date));
}

const isOffPeakContract = computed(() => {
  const date = useDate(currentDate.value!);

  if (isWeekView.value || isMonthView.value) {
    const contract = findContractByDate(date.toDate());
    return contract && contract.offpeakHours.length;
  } else {
    let hasOffPeakHoursContract = false;
    const monthsToCheck = 12;

    for (let i = 1; i <= monthsToCheck; i++) {
      const _date = date.add(i, 'month').toDate();
      const contract = findContractByDate(_date);

      if (contract && contract.offpeakHours.length) {
        hasOffPeakHoursContract = true;
        break;
      }
    }
    return hasOffPeakHoursContract;
  }
})

const user = computed(() => {
  return AuthModule.user;
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType;
})

const circleMonthlyRepartition = computed(() => {
  const unitEnergy = EEnergyUnit.kWh;
  const type = viewType.value.value;
  const selectedDate = currentDate.value ? useDate(currentDate.value) : useDate();

  const repartition = consoFullDataResponses.value[selectedDate.format(type)]?.breakdown;

  if (!repartition) {
    return {
      unitEnergy,
      graph: null
    };
  }

  const items: TCircleGraphic['items'] = []
  const tooltip: TCircleGraphic['tooltip'] = []
  const tooltipValues: number[] = [];

  let textValueSum = 0;
  let textUnit: ECurrency | EEnergyUnit.kWh = consoType.value ? unitEnergy : currency.value;

  if (consoType.value) {
    textUnit = unitEnergy;
  }

  const heaterConso = repartition.categories.find(el => el.name === 'conso.category.heater');
  const waterConso = repartition.categories.find(el => el.name === 'conso.category.waterheating');
  const otherConso = repartition.categories.find(el => el.name === 'conso.category.other');
  if (
      heaterConso && heaterConso!.subcategories!.reduce((_total, sub) => _total + (consoType.value ? sub.totalConsumptionInWh || 0 : sub.totalConsumptionInCurrency || 0), 0) === 0
      &&
      (!waterConso || waterConso!.subcategories!.reduce((_total, sub) => _total + (consoType.value ? sub.totalConsumptionInWh || 0 : sub.totalConsumptionInCurrency || 0), 0) === 0)
      &&
      (!otherConso || otherConso?.subcategories!.reduce((_total, sub) => _total + (consoType.value ? sub.totalConsumptionInWh || 0 : sub.totalConsumptionInCurrency || 0), 0) === 0)
  ) {
    return {
      unitEnergy,
      graph: null
    };
  }

  repartition.categories!.forEach(category => {
    if (!category.subcategories) {
      return;
    }

    const itemCounter = items.length;

    const categoryItem = {
      color: circleColors.value[(itemCounter) % circleColors.value.length],
      label: t(category.name),
      value: 0
    };

    let tooltip_value = 0;
    category!.subcategories!.forEach(subcat => {
      textValueSum += consoType.value ? subcat.totalConsumptionInWh! : subcat.totalConsumptionInCurrency!;
      categoryItem.value += consoType.value ? fromWhToKwh(subcat.totalConsumptionInWh!) : subcat.totalConsumptionInCurrency!;
      tooltip_value += consoType.value ? subcat.totalConsumptionInWh! : subcat.totalConsumptionInCurrency!;
    });
    tooltipValues.push(tooltip_value);
    tooltip_value = consoType.value ? fromWhToKwh(tooltip_value) : tooltip_value;

    let tooltipText: TText[] = [
      {
        value: Math.round(tooltip_value).toString(),
        size: 130,
        color: "rgb(62, 62, 124)",
        bold: true,
      },
      {
        value: textUnit,
        color: "rgb(62, 62, 124)",
        size: 125,
        bold: true,
      },
    ]

    if (isCurrency(textUnit)) {
      tooltipText = formatTextUnitArray(tooltipText)
    }

    tooltip.push({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      arrowColor: 'rgb(187, 187, 187)',
      texts: [tooltipText]
    });
    items.push(categoryItem);
  });

  let texts: TCircleGraphic['texts'] = [
    [
      {
        value: Math.round(consoType.value ? fromWhToKwh(textValueSum) : textValueSum).toString(),
        color: "rgb(62, 62, 124)",
        bold: true,
        removeSpace: true,
      },
      {
        value: ` ${textUnit} `,
        size: 50,
        color: "rgb(62, 62, 124)",
        removeSpace: true,
      },
    ],
  ];

  if (isCurrency(textUnit)) {
    texts = [formatTextUnitArray(texts[0])]
  }

  tooltip.forEach((t, i) => {
    const texts = (t as TTooltipOptions).texts;
    const value = tooltipValues[i];

    texts.push([
      {
        value: `(${percent(value, textValueSum).toFixed(0)}%)`,
        color: "rgb(160, 161, 161)",
        size: 125,
        bold: true,
      }
    ]);
  });

  if (!items.length) {
    return {
      unitEnergy,
      graph: null
    };
  }
  const plugins = {
    tooltip: {
      callbacks: {
        label: (ctx: any) => {
          if (isCurrency(textUnit)) return ` ${formatCurrency(ctx.raw)}`
          return ` ${ctx.raw} ${textUnit}`
        }
      }
    }
  }

  return {
    unitEnergy,
    graph: {
      items: items.filter(item => item.value != 0),
      texts,
      tooltip,
      plugins
    }
  }
})

const halfCircleHourConso = computed(() => {
  let unitEnergy = EEnergyUnit.kWh;
  const type = viewType.value.value;
  const selectedDate = currentDate.value ? useDate(currentDate.value) : useDate();

  const dataPoints = consoFullDataResponses.value[selectedDate.format(type)]?.dataPoints

  if (!contract.value?.kwhOffpeakHourPrice || !dataPoints) {
    return {
      unitEnergy,
      graph: null,
    };
  }

  const consu = dataPoints;

  let peakHourSum = 0;
  let offPeakHourSum = 0;
  consu.forEach((cons) => {
    peakHourSum += consoType.value ? cons.peakHourConsumptionInWh! : cons.peakHourConsumptionInCurrency!;
    offPeakHourSum += consoType.value ? cons.offPeakHourConsumptionInWh! : cons.offPeakHourConsumptionInCurrency!;
  });

  const totalSum = peakHourSum + offPeakHourSum;
  unitEnergy = EEnergyUnit.kWh;

  const items: THalfCircleGraphic['items'] = [{
    label: t('conso.chart.label.peak_hour'),
    value: parseFloat((consoType.value ? fromWhToKwh(peakHourSum) : peakHourSum).toFixed(2)),
    color: "rgb(77, 190, 247)"
  }];

  const totalSumConverted = consoType.value ? {value: fromWhToKwh(totalSum), unit: 'kWh'} : {
    value: totalSum,
    unit: currency.value
  };

  let totalSumConvertedText: TText[] = [
    {
      "value": Math.round(totalSumConverted.value).toString(),
      "size": 80,
      "color": "rgb(62, 62, 124)",
      "bold": true
    },
    {
      "value": totalSumConverted.unit,
      "size": 45,
      "color": "rgb(62, 62, 124)",
    }
  ]

  if (isCurrency(totalSumConverted.unit)) {
    totalSumConvertedText = formatTextUnitArray(totalSumConvertedText)
  }

  const texts: THalfCircleGraphic['texts'] = [
    [],
    totalSumConvertedText,
    [
      {
        "value": consoType.value ? t('conso.chart.value.consumed') : t('conso.chart.value.spent'),
        "size": 45,
        "color": "rgb(62, 62, 124)"
      }
    ],
    [
      {
        "size": 45
      }
    ],
  ];

  let peakHourSumTexts: TText[] = [{
    value: Math.round(consoType.value ? fromWhToKwh(peakHourSum) : peakHourSum).toString(),
    size: 150,
    bold: true,
    color: "#3E3E7C",
  }, {
    value: totalSumConverted.unit,
  }]

  if (isCurrency(totalSumConverted.unit)) {
    peakHourSumTexts = formatTextUnitArray(peakHourSumTexts)
  }

  const tooltip: THalfCircleGraphic['tooltip'] = [{
    backgroundColor: '#00000000',
    texts: [peakHourSumTexts, [{
      value: '(' + percent(peakHourSum, totalSum).toFixed(0) + '%)',
      color: "#00000070",
      bold: true,
      size: 125
    }]]
  }];

  if (offPeakHourSum) {
    items.push({
      label: t('conso.chart.label.offpeak_hour'),
      value: parseFloat((consoType.value ? fromWhToKwh(offPeakHourSum) : offPeakHourSum).toFixed(2)),
      color: "rgb(151, 190, 13)"
    });
  }

  let offPeakHourSumTexts: TText[] = [{
    value: Math.round(consoType.value ? fromWhToKwh(offPeakHourSum) : offPeakHourSum).toString(),
    size: 150,
    color: "#3E3E7C",
    bold: true
  }, {
    value: totalSumConverted.unit,
  }]

  if (isCurrency(totalSumConverted.unit)) {
    offPeakHourSumTexts = formatTextUnitArray(offPeakHourSumTexts)
  }

  tooltip.push({
    backgroundColor: '#00000000',
    texts: [offPeakHourSumTexts, [{
      value: '(' + percent(offPeakHourSum, totalSum).toFixed(0) + '%)',
      color: "#00000070",
      bold: true,
      size: 125
    }]]
  });

  return {
    unitEnergy,
    graph: {
      items, texts, tooltip
    },
  };
})

const redirectToDetail = () => {
  if (isMobile.value) {
    g_redirectTimeout.value = setTimeout(() => {
      g_redirectTimeout.value = null;
      router.push(consoDetailRoute(ERoutes.CONSO_DETAIL));
    }, 200);
  }
}

const onConsoLegendClick = () => {
  if (g_redirectTimeout.value) {
    clearTimeout(g_redirectTimeout.value);
    g_redirectTimeout.value = null;
  }
}

const activeLink = (val: string) => {
  const routeType = route.query.type
  if (routeType) {
    if (routeType === val && route.name === val) {
      return true;
    } else if (routeType === val) {
      return true;
    }

    return false;
  } else {
    return route.name === val;
  }
}

const openConsoInfoPopin = () => {
  showConsoInfoPopin.value = true;
  pageScroll(false);
}

const closePdlValidationPopin = () => {
  showPdlValidationPopin.value = false;
  pageScroll(true);
}

const closeWelcomeConsoPopin = () => {
  showConsoInfoPopin.value = false;
  pageScroll(true);
}

const toggle_dropdownVisible_currentLink = () => {
  dropdownVisible_currentLink.value = !dropdownVisible_currentLink.value;
  dropdownVisible_currentDateLabel.value = false;
}

const toggle_dropdownVisible_currentDateLabel = () => {
  dropdownVisible_currentDateLabel.value = !dropdownVisible_currentDateLabel.value;
  dropdownVisible_currentLink.value = false;
}

const consoDetailRoute = (to: string) => {
  let type = route.query.type as string;
  let date = route.query.date as string;

  if (to !== ERoutes.CONSO_REPARTITION_DETAIL) {
    if (isYearView.value) {
      type = 'month';
      const _date = useDate(date).endOf('year');
      date = _date.format(aggregatedMonthFormat);
      if (_date.isAfter(new Date())) {
        date = useDate().format(aggregatedMonthFormat);
      }
    } else if (isMonthView.value) {
      type = 'week';
      const _date = useDate(date).endOf('month');
      date = _date.format(aggregatedWeekFormat);
      if (_date.isAfter(new Date())) {
        date = useDate().format(aggregatedWeekFormat);
      }
    }
  }

  return {
    path: `${ERoutes.CONSO}/${to}/${date}/${(isMobile.value || to !== ERoutes.CONSO_REPARTITION_DETAIL) ? '' : 'conso.category.heater'}`,
    query: {
      type: type,
      date: undefined
    }
  }
}

const editCurrentDate = (date: string | null = null) => {
  dropdownVisible_currentDateLabel.value = false;
  currDate.value = useDate(date ?? undefined).locale(locale.value).format(viewType.value.value).toLowerCase();
}

const editCurrentType = (url: any): void => {
  router.replace(url);
};

const getObjective = (forceRequest = false) => {
  ObjectiveModule.getObjective(forceRequest);
}

const initData = async (date: string | null = null) => {
  const type = viewType.value.value;
  const alias = viewType.value.alias;
  let forceRequest = false;
  const selectedDate = date ? useDate(date) : useDate();

  const weekData = consoFullDataResponses.value[selectedDate.format(type)]

  //Force request to refresh full data in case the live view already fetch a YYYY-MM-DD format data (with 24 datapoints), the week view also has YYYY-MM-DD format but with 7 datapoints
  if (alias === EAggregationAlias.Week && consoFullDataResponses.value[selectedDate.format(type)] && weekData?.dataPoints && weekData.dataPoints?.length > 7) {
    forceRequest = true
  }

  const fetchConsumptionData = async (date: DateUtils, yearOffset: number, type: string, alias: EAggregationAlias) => {
    return ConsoModule.getConsumptionFullData({
      dates: date.substract(yearOffset, 'year').format(type),
      period: alias,
      forceRequest
    }).catch(handleError);
  }

  const promises = [fetchConsumptionData(selectedDate, 0, type, alias)];

  if (alias === EAggregationAlias.Month || alias === EAggregationAlias.Year) {
    promises.push(fetchConsumptionData(selectedDate, 1, type, alias))
    promises.push(fetchConsumptionData(useDate(), 0, aggregatedYearFormat, EAggregationAlias.Year),
    )
  }

  await Promise.all(promises);
}

const handleError = (e: any) => {
  error.value = e.response ? e.response.data.message : 'Erreur serveur';
};

const openObjectiveModal = () => {
  showObjectiveModal.value = true;
  pageScroll(false);
}

const closeObjectiveModal = () => {
  showObjectiveModal.value = false;
  pageScroll(true);

  const date = route.query.date as string;
  const selectedDateFormat = useDate(date).format(viewType.value.value).toLowerCase();

  initData(selectedDateFormat);
}

const handleCloseAndRestore = () => {
  showConsoInfoPopin.value = true;
  showPdlValidationPopin.value = false;
}

const handleTypeChange = ({value, url}: TConsoViewOption) => {
  MixpanelService.trackConsumption(ETrackConsumptionEvent.AGGREGATION_TYPE_UPDATED, {type: value})
  editCurrentType(url)
}

const trackDateChangeEvent = (date: string | null = null) => {
  const startPeriod = useDate(date).startOf(viewType.value.alias)
  const endPeriod = useDate(date).endOf(viewType.value.alias)
  const daysCount = endPeriod.diff(startPeriod.toDate(), 'day')

  MixpanelService.trackConsumption(ETrackConsumptionEvent.AGGREGATION_PERIOD_UPDATED, {
    period: `${startPeriod.format("DD-MM-YYYY")} -> ${endPeriod.format("DD-MM-YYYY")}`,
    number_of_days: daysCount
  });
}

const handleDateChange = (date: string | null = null) => {
  trackDateChangeEvent(date)
  editCurrentDate(date)
}

const initQueryDate = async () => {
  if (route.name === ERoutes.CONSO_LIVE) {
    return;
  }

  const queryType = route.query.type || 'month'; // Default to 'month' if not provided
  let type: string;
  let start: string;

  switch (queryType) {
    case ERoutes.CONSO_WEEK:
      type = aggregatedWeekFormat;
      start = 'day';
      break;
    case ERoutes.CONSO_YEAR:
      type = aggregatedYearFormat;
      start = 'year';
      break;
    default:
      type = aggregatedMonthFormat;
      start = 'month';
      break;
  }

  const queryDate = route.query.date as string | undefined;
  const startDate = useDate(currentSite.value.dataStart, 'YYYY-MM-dd').startOf(start);
  let date = useDate(queryDate, queryDate ? type : undefined);

  // Ensure the date is within bounds
  if (date.startOf(start) < startDate || !date.isValid()) {
    date = startDate;
  }

  if (date.endOf(start) > useDate().startOf(start)) {
    date = useDate();
  }

  const formattedDate = queryType === 'week'
      ? date.startOf('w').format(aggregatedWeekFormat)
      : date.format(aggregatedMonthFormat);

  editCurrentDate(formattedDate);

  await router.push({
    path: ERoutes.CONSO,
    query: {
      date: currentDate.value,
      type: queryType,
    },
  });

  menuSliderKey.value++;
};

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
          showContractPopin.value = true;
        }
      })
  consoTypeKey.value++;
}

onMounted(async () => {
  await initQueryDate()
  await Promise.all([ContractModule.getContractsList(), ObjectiveModule.getObjective(true), SiteModule.getSites()]).finally(() => {
    isLoading.value = false
  })
})

watch(currentDate, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    router.replace({
      query: {
        date: newValue,
        type: route.query.type
      }
    });
    initData(newValue);
  }
});

watch(viewType, (newValue, oldValue) => {
  if (newValue.aggregation === oldValue?.aggregation) return;
  if (!route.fullPath.includes('conso') || route.fullPath.includes('conso-detail') || route.fullPath.includes(ERoutes.CONSO_REPARTITION_DETAIL)) {
    return;
  }

  let date = route.query.date as string;

  if (oldValue?.aggregation === EAggregationType.Year) {
    if (newValue.aggregation === EAggregationType.Month) {
      date = useDate(date).endOf('year').format(aggregatedMonthFormat);
    } else if (newValue.aggregation === EAggregationType.Day) {
      date = useDate(date).endOf('year').format(aggregatedWeekFormat);
    }
  } else if (oldValue?.aggregation === EAggregationType.Month) {
    if (newValue.aggregation === EAggregationType.Year) {
      date = useDate(date).endOf('year').format(aggregatedYearFormat);
    } else if (newValue.aggregation === EAggregationType.Day) {
      date = useDate(date).endOf('month').format(aggregatedWeekFormat);
    }
  } else if (oldValue?.aggregation === EAggregationType.Day) {
    if (newValue.aggregation === EAggregationType.Year) {
      date = useDate(date).endOf('year').format(aggregatedYearFormat);
    } else if (newValue.aggregation === EAggregationType.Month) {
      date = useDate(date).endOf('month').format(aggregatedMonthFormat);
    }
  }

  const queryType = route.query.type;

  if (queryType === EAggregationAlias.Week) {
    const weekDate = useDate(date, aggregatedWeekFormat);
    const oldInitialDay = weekDate.day();
    const newInitialDay = weekDate.startOf('w').day();

    if (newInitialDay !== oldInitialDay) {
      weekDate.add(newInitialDay - oldInitialDay, 'd');
    }

    date = weekDate.format(viewType.value.value);
  }

  const lastSliderDate = menuSliderOptions.value[menuSliderOptions.value.length - 1]

  if (useDate(date).isAfter(useDate(lastSliderDate).toDate())) {
    date = lastSliderDate;
  }

  dropdownVisible_currentLink.value = false;
  editCurrentDate(date);
})

watch(locale, () => {
  const queryType = route.query.type;

  if (queryType === ERoutes.CONSO_WEEK) {
    const queryDate = route.query.date as string | undefined;
    const date = useDate(queryDate, aggregatedWeekFormat);
    const oldInitialDay = date.day();
    const newInitialDay = date.startOf('w').day();

    if (newInitialDay > oldInitialDay) {
      date.add(newInitialDay - oldInitialDay, 'd');
    }

    router.push({
      path: ERoutes.CONSO,
      query: {
        date: date.startOf('w').format(aggregatedWeekFormat),
        type: EAggregationAlias.Week,
      }
    });
  }
})

watch(showPdlValidationPopin, (newValue) => {
  if (!newValue) {
    ContractModule.updatePdlValidationInvoice({file: null, previewUrl: undefined});
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (!isLoading.value)
    ? (_openBlock(), _createBlock(MyVPageConso, {
        key: 0,
        title: _ctx.$t('conso.page.title')
      }, {
        default: _withCtx(() => [
          _createVNode(MyVHeaderMainPage, {
            title: "conso.page.title",
            class: "HeaderMainPage--md-small",
            isFromConsoOrHeater: "",
            site: currentSite.value
          }, {
            HeaderMainPage__right: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(Dropdown), {
                  class: "__view",
                  visible: dropdownVisible_currentLink.value,
                  "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((dropdownVisible_currentLink).value = $event)),
                  trigger: ['click']
                }, {
                  overlay: _withCtx(() => [
                    _createVNode(_unref(Menu), { class: "menu" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            onClick: ($event: any) => (handleTypeChange(link)),
                            class: _normalizeClass([{ active: activeLink(link.value) }, "item"])
                          }, _toDisplayString(link.label), 11, _hoisted_2))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(MyVButton, {
                      icon: "triangle",
                      class: "PageConso__headerButton",
                      primary: false,
                      onClick: toggle_dropdownVisible_currentLink
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(currentLink.value), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["visible"]),
                _createVNode(_unref(Dropdown), {
                  class: "__dates",
                  visible: dropdownVisible_currentDateLabel.value,
                  "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((dropdownVisible_currentDateLabel).value = $event)),
                  trigger: ['click']
                }, {
                  overlay: _withCtx(() => [
                    _createVNode(_unref(Menu), { class: "menu" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuSliderOptions.value.reverse(), (link, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            onClick: ($event: any) => (handleDateChange(link.value)),
                            class: _normalizeClass([{ active: currentDate.value === link.value }, "item"])
                          }, _toDisplayString(link.label), 11, _hoisted_3))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(MyVButton, {
                      icon: "triangle",
                      class: _normalizeClass(["PageConso__headerButton PageConso__headerButton--hideMobile", {lowercase: isWeekView.value}]),
                      primary: false,
                      onClick: toggle_dropdownVisible_currentDateLabel
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(currentDateLabel.value), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ]),
                  _: 1
                }, 8, ["visible"])
              ])
            ]),
            _: 1
          }, 8, ["site"]),
          (_openBlock(), _createBlock(MyVMenuSlider, {
            onChange: editCurrentDate,
            defaultCurrent: setDefaultCurrentDate.value,
            onClick: trackDateChangeEvent,
            options: menuSliderOptions.value,
            key: menuSliderKey.value,
            itemType: "div"
          }, null, 8, ["defaultCurrent", "options"])),
          (error.value)
            ? (_openBlock(), _createBlock(MyVErrorBanner, {
                key: 0,
                class: "error-banner",
                error: error.value,
                duration: 5000,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (error.value = null))
              }, null, 8, ["error"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(), _createBlock(MyVConsumptionGoal, {
                  onOpenPopinConsumptionGoal: openObjectiveModal,
                  onOpenPopinDefineContract: _cache[3] || (_cache[3] = ($event: any) => (showDefineContractPopin.value = true)),
                  value: consoSummary.value?.total,
                  unit: consoSummary.value?.unit,
                  objectiveDiff: consoSummary.value?.objectiveDiff,
                  "show-result": consoSummary.value?.showResult,
                  estimationCurrency: consoSummary.value?.totalCurrency,
                  key: consoSummary.value
                }, null, 8, ["value", "unit", "objectiveDiff", "show-result", "estimationCurrency"]))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: "BarLineGraphic__title",
                      onClick: openConsoInfoPopin
                    }, [
                      _createElementVNode("h3", null, [
                        _createTextVNode(_toDisplayString(consumptionByView.value) + " ", 1),
                        _cache[7] || (_cache[7] = _createElementVNode("svg", {
                          class: "icon icon-question-circle",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-question-circle" })
                        ], -1))
                      ])
                    ]),
                    _createVNode(_component_router_link, {
                      to: consoDetailRoute(_unref(ERoutes).CONSO_DETAIL),
                      class: "BarLineGraphic__link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('conso.page.button.detail')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createVNode(MyVDisplayGroupStore, {
                    currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
                  }, {
                    default: _withCtx(({disabled}) => [
                      (_openBlock(), _createBlock(MyVAntdswitch, {
                        defaultValue: consoType.value,
                        value: consoType.value,
                        onChange: setConsoType,
                        checkedChildren: _unref(currency),
                        size: "small",
                        unCheckedChildren: barLineMonthConso.value.unitEnergy,
                        key: consoTypeKey.value,
                        disabled: disabled
                      }, null, 8, ["defaultValue", "value", "checkedChildren", "unCheckedChildren", "disabled"]))
                    ]),
                    _: 1
                  }, 8, ["currentRight"])
                ]),
                _createElementVNode("div", {
                  class: "BarLineGraphic__content",
                  onClick: redirectToDetail
                }, [
                  (barLineMonthConso.value?.graph)
                    ? (_openBlock(), _createBlock(MyVBarLineGraphic, _mergeProps({ key: 0 }, barLineMonthConso.value.graph, {
                        "show-tooltip-total": "",
                        "right-axis": {min: rightAxisMin.value, max: rightAxisMax.value,title: _unref(ETemperatureUnit).Celsius}
                      }), null, 16, ["right-axis"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(MyVButton, {
                    primary: false,
                    round: "",
                    size: _unref(EButtonSize).Small,
                    type: _unref(EButtonType).Button,
                    onClick: redirectToDetail
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('repartion.show_details')), 1)
                    ]),
                    _: 1
                  }, 8, ["size", "type"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('conso.chart.repartition')), 1),
                    (circleMonthlyRepartition.value.graph)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: consoDetailRoute(_unref(ERoutes).CONSO_REPARTITION_DETAIL)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('conso.page.button.detail')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true)
                  ]),
                  (circleMonthlyRepartition.value.graph)
                    ? (_openBlock(), _createBlock(MyVDisplayGroupStore, {
                        key: 0,
                        currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
                      }, {
                        default: _withCtx(({disabled}) => [
                          (_openBlock(), _createBlock(MyVAntdswitch, {
                            defaultValue: consoType.value,
                            value: consoType.value,
                            onChange: setConsoType,
                            checkedChildren: _unref(currency),
                            size: "small",
                            unCheckedChildren: circleMonthlyRepartition.value.unitEnergy,
                            key: consoTypeKey.value,
                            disabled: disabled
                          }, null, 8, ["defaultValue", "value", "checkedChildren", "unCheckedChildren", "disabled"]))
                        ]),
                        _: 1
                      }, 8, ["currentRight"]))
                    : _createCommentVNode("", true)
                ]),
                (circleMonthlyRepartition.value.graph)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? 'router-link' : 'div'), {
                      key: 0,
                      to: consoDetailRoute(_unref(ERoutes).CONSO_REPARTITION_DETAIL),
                      class: "__content"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(MyVCircleGraphic, _normalizeProps(_guardReactiveProps(circleMonthlyRepartition.value.graph)), null, 16)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('conso.no_data_available')), 1))
              ]),
              (halfCircleHourConso.value.graph && contract.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('conso.chart.peak_hour')), 1),
                      _createVNode(MyVDisplayGroupStore, {
                        currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
                      }, {
                        default: _withCtx(({disabled}) => [
                          (_openBlock(), _createBlock(MyVAntdswitch, {
                            defaultValue: consoType.value,
                            value: consoType.value,
                            onChange: setConsoType,
                            checkedChildren: _unref(currency),
                            size: "small",
                            unCheckedChildren: halfCircleHourConso.value.unitEnergy,
                            key: consoTypeKey.value,
                            disabled: disabled
                          }, null, 8, ["defaultValue", "value", "checkedChildren", "unCheckedChildren", "disabled"]))
                        ]),
                        _: 1
                      }, 8, ["currentRight"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(MyVHalfCircleGraphic, _mergeProps(halfCircleHourConso.value.graph, { "conso-type": consoType.value }), null, 16, ["conso-type"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_18, [
              (!_unref(SiteModule).isSiteCountryFrance)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(MyVConsoCards, {
                      data: consoFullDataResponses.value,
                      "current-date": currentDate.value,
                      type: currentType.value as string
                    }, null, 8, ["data", "current-date", "type"])
                  ]))
                : _createCommentVNode("", true),
              (showObjectiveModal.value)
                ? (_openBlock(), _createBlock(MyVObjectiveModal, {
                    key: 1,
                    "objective-datas": objectiveDatas.value ?? undefined,
                    isEditing: isEditingObjective.value,
                    onLoaded: getObjective,
                    currentYear: currentYear.value,
                    onClose: closeObjectiveModal,
                    onResetObjective: _cache[4] || (_cache[4] = ($event: any) => (getObjective(true)))
                  }, null, 8, ["objective-datas", "isEditing", "currentYear"]))
                : _createCommentVNode("", true),
              (showExplicativeConsoPopin.value)
                ? (_openBlock(), _createBlock(MyVExplicativeConsoPopin, {
                    key: 2,
                    onClose: _cache[5] || (_cache[5] = ($event: any) => (showExplicativeConsoPopin.value=false))
                  }))
                : _createCommentVNode("", true),
              (showConsoInfoPopin.value)
                ? (_openBlock(), _createBlock(MyVConsoTooltip, {
                    key: 3,
                    onClose: closeWelcomeConsoPopin,
                    "onConsoTooltip::validatePdl": _cache[6] || (_cache[6] = ($event: any) => (showPdlValidationPopin.value = true))
                  }))
                : _createCommentVNode("", true),
              (showPdlValidationPopin.value)
                ? (_openBlock(), _createBlock(MyVPdlValidationModal, {
                    key: 4,
                    onClose: closePdlValidationPopin,
                    onCloseAndRestore: handleCloseAndRestore
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})