import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";
import {AuthModule} from "@/store";
import {
    TDeleteNotificationsParams,
    TGetNotificationsParams,
    TNotifications,
    TReadNotificationsParams
} from "@/services/Notification/interfaces";
import NotificationService from "@/services/Notification";

@Module({name: EModules.NOTIFICATION})
class Notification extends VuexModule {
    private _notifications: TNotifications[] | null = null;

    /**
     * Getters
     */
    get notifications() {
        return this._notifications;
    }

    /**
     * Mutations
     */
    @Mutation
    public _setNotifications(notifications: TNotifications[]): void {
        this._notifications = notifications;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getNotifications({
                                siteId,
                                forceRequest = false,
                                skipLoading = false
                            }: TGetNotificationsParams): Promise<TNotifications[] | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return (forceRequest === false && this.notifications) ? Promise.resolve(this.notifications) : NotificationService.getNotifications(siteId, skipLoading)
                .then(notifications => {
                    this.context.commit('_setNotifications', notifications);
                    return notifications;
                }).catch(e => {
                    this.context.commit('_setNotifications', []);
                    throw e;
                });
        }

        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public readNotifications({siteId, notificationsId}: TReadNotificationsParams): Promise<TNotifications[] | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return NotificationService.readNotifications(siteId, notificationsId)
                .then(notifications => {
                    this.context.commit('_setNotifications', notifications);
                    return notifications;
                }).catch(e => {
                    throw e;
                });
        }

        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public deleteNotifications({
                                   siteId,
                                   notificationsId
                               }: TDeleteNotificationsParams): Promise<TNotifications[] | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return NotificationService.deleteNotifications(siteId, notificationsId)
                .then(notifications => {
                    this.context.commit('_setNotifications', notifications);
                    return notifications;
                }).catch(e => {
                    throw e;
                });
        }

        return Promise.reject(new Error('Error 403: You must be connected !'));
    }
}

export default Notification;
