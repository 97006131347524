import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pdl-validation-explanation-step" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]

import pdlValidationImg from '@/assets/images/pdl-edf.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPdlValidationExplanationStep',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      innerHTML: _ctx.$t('conso.pdl.validation.explanation'),
      class: "pdl-validation-explanation-step-text"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      alt: "",
      src: _unref(pdlValidationImg)
    }, null, 8, _hoisted_3)
  ]))
}
}

})