<template>
  <div class="programCreate__infoBlock">
    <div class="programCreate__infoBlock-title">
      {{ $t('programCreate.infos.title') }}
    </div>
    <p class="programCreate__infoBlock-p1">{{ $t('programCreate.infos.description1') }}</p>
    <div class="programCreate__infoBlock-grey">
      <p class="programCreate__infoBlock-grey-p" v-html="$t('programCreate.infos.description2')"></p>

      <div class="programCreate__infoBlock-icons">
        <div class="programCreate__infoBlock-icon">
          <svg class="icon icon-avertissement-auto" aria-hidden="true">
            <use xlink:href="#icon-avertissement-auto"/>
          </svg>
        </div>
        <div class="programCreate__infoBlock-icon">
          <svg class="icon icon-avertissement-horloge" aria-hidden="true">
            <use xlink:href="#icon-avertissement-horloge"/>
          </svg>
        </div>
        <div class="programCreate__infoBlock-icon">
          <svg class="icon icon-avertissement-maison-horloge" aria-hidden="true">
            <use xlink:href="#icon-avertissement-maison-horloge"/>
          </svg>
        </div>
        <div class="programCreate__infoBlock-icon">
          <svg class="icon icon-avertissement-prog" aria-hidden="true">
            <use xlink:href="#icon-avertissement-prog"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="programCreate__infoBlock-p2" v-html="$t('programCreate.infos.description3')"/>
  </div>
</template>

<script setup>
</script>