import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import ErrorService from "@/services/Error";
import ErrorBanner from './MyVErrorBanner.vue'
import {TErrorBannerService} from "./interfaces";
import {computed, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

interface Props {
  errors?: TErrorBannerService['errors'];
  field?: TErrorBannerService['field'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVErrorBannerService',
  props: {
    errors: { default: undefined },
    field: { default: '' }
  },
  setup(__props: any) {

const props = __props

const show = ref(false)

const {t} = useI18n()

const error = computed(() => {
  const err = ErrorService.getError(props.errors ?? null, props.field);

  return err ? t(err.description, err.parameters) : err
})

const hide = () => {
  show.value = false
}

watch(() => props.errors, (newValue) => {
  if (newValue && newValue.length > 0) {
    show.value = true;
  }
})


return (_ctx: any,_cache: any) => {
  return (show.value)
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        error: error.value,
        hide: hide
      }, () => [
        _createVNode(ErrorBanner, {
          class: _normalizeClass(['error-banner']),
          error: error.value,
          onClose: hide
        }, null, 8, ["error"])
      ])
    : _createCommentVNode("", true)
}
}

})