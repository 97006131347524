import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "AppliancesSlider__container" }
const _hoisted_2 = { class: "AppliancesSlider" }

import {TAppliancesSlider} from "./interfaces"
import {EApplianceMode} from "@/services/Appliances/interfaces";
import MyVAppliancesSlide from "@/components/domains/Heating/appliances/MyVAppliancesSlide/MyVAppliancesSlide.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesSlider',
  props: {
    appliances: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliances, (appliance) => {
        return (_openBlock(), _createBlock(MyVAppliancesSlide, {
          key: appliance.id,
          name: appliance.name,
          mode: appliance.programming.mode,
          heatingLevel: appliance.heatingLevel,
          link: appliance.link,
          isOn: appliance.programming.isOn ?? false,
          isFrostProtection: appliance.programming.mode === _unref(EApplianceMode).FrostProtection,
          type: appliance.applianceType,
          temp: appliance.programming.temperatureTarget ?? undefined,
          progType: appliance.programming.progType,
          progName: appliance.programming.progName ?? undefined
        }, null, 8, ["name", "mode", "heatingLevel", "link", "isOn", "isFrostProtection", "type", "temp", "progType", "progName"]))
      }), 128))
    ])
  ]))
}
}

})