import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "quick_settings_modes" }

import {computed, onBeforeMount, reactive, ref, watch} from "vue";

import {Form} from 'ant-design-vue';

import {EApplianceMode, EApplianceType} from "@/services/Appliances/interfaces";

import {TEditQuickSetting, TEditQuickSettingDatas} from "./interfaces";
import {heatingType as heatingTypeHelper} from "@/helpers/domains/heatingType";
import {TabHeatingModeFakeDatas} from "@/dummies/TabHeatingModeFakeDatas";
import {TQuickSettingsAppliances} from "@/services/Heating/interfaces";
import MyVCustomDuration from "@/components/domains/Heating/quicksettings/MyVCustomDuration/MyVCustomDuration.vue";
import {createDateString, createUntilText} from "@/helpers/dates/date";
import {g_modeOff} from "@/components/domains/Heating/MyVEditRange/interfaces";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import MyVAntdradio from "@/components/ui/atoms/MyVAntdradio/MyVAntdradio.vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVDropDown from "@/components/ui/atoms/MyVDropDown/MyVDropDown.vue";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import MyVTemperatureControl from "@/components/domains/Heating/MyVTemperatureControl/MyVTemperatureControl.vue";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";

interface Props {
  onSubmit: TEditQuickSetting["onSubmit"];
  returnAction?: TEditQuickSetting["returnAction"];
  untilFurtherNotice: TEditQuickSettingDatas["untilFurtherNotice"];
  appliancesSettings: TEditQuickSetting["appliancesSettings"];
  endDate?: TEditQuickSetting["endDate"];
  appliances?: TEditQuickSetting["appliances"];
  currentQuickSetting?: TEditQuickSetting;
}

interface TEditQuickSettingForm {
  untilFurtherNotice: boolean,
  endDate: string | null,
  appliances: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVEditQuickSetting',
  props: {
    onSubmit: { type: Function, default: undefined },
    returnAction: { default: undefined },
    untilFurtherNotice: { default: undefined },
    appliancesSettings: { default: undefined },
    endDate: { default: null },
    appliances: { default: () => [] },
    currentQuickSetting: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const isOpenPopinDuration = ref(false);
const dropDownList = ref(null)
const {t} = useI18n()
const route = useRoute()
const idTimeout = ref<any | null>(null);

const form: TEditQuickSettingForm = reactive({
  untilFurtherNotice: false,
  endDate: null,
  appliances: []
});

const prog = computed(() => {
  return createDateString(t, form.untilFurtherNotice!, form.endDate);
})

const untilText = computed(() => {
  return createUntilText(t, form.untilFurtherNotice, form.endDate)
})

const isEditingQuickSettings = computed(() => {
  return route.query.quickSettings;
})

const optionsMode = computed(() => {
  return (idAppliance: TQuickSettingsAppliances["idAppliance"]) => {
    const appliance = props.appliances?.find(({id}) => id === idAppliance);

    if (appliance) {
      const availableModes = appliance.availableModes || [];

      return [
        ...TabHeatingModeFakeDatas,
        {
          icon: 'colored-power',
          iconActive: 'power',
          title: 'mode.off',
          slug: g_modeOff as EApplianceMode
        }
      ]
          .filter(({slug}) => (
                  slug === (g_modeOff as EApplianceMode)) ||
              availableModes.includes(slug as EApplianceMode)
          )
          .map(({
                  icon,
                  iconActive,
                  title,
                  slug,
                }) => ({
            icon,
            iconActive,
            label: t(((slug === (g_modeOff as EApplianceMode)) || (appliance.applianceType !== EApplianceType.WaterHeater)) ? title : `water_heater.${title}`),
            value: slug,
          }));
    }
    return [];
  }
})

const setPeriod = (body: { endDate: string, untilFurtherNotice: boolean }) => {
  form.endDate = body.endDate;
  form.untilFurtherNotice = body.untilFurtherNotice;
}

const heatingType = (mode: EApplianceMode, isOn: boolean) => {
  return t(heatingTypeHelper(mode, isOn));
}

const iconMode = (mode: EApplianceMode, isOn: boolean) => {
  if (!isOn) {
    return 'gray-power';
  }

  switch (mode) {
    case EApplianceMode.Comfort:
    case EApplianceMode.Comfort1:
    case EApplianceMode.Comfort2:
      return 'colored-sun';
    case EApplianceMode.Eco:
    case EApplianceMode.EcoV:
      return 'colored-moon';
    case EApplianceMode.FrostProtection:
      return 'colored-snow';
    case EApplianceMode.Temp:
      return 'colored-settings';
  }
  return 'colored-power';
}

const onFormApplianceModeChange = (formItem: TEditQuickSettingDatas['appliances'][number], value: string) => {
  if (value !== EApplianceMode.Temp) {
    document.dispatchEvent(new Event("closeDropDown"));
  }

  formItem.isOn = value !== g_modeOff;
  const _id = form.appliances.findIndex((el: any) => el.idAppliance === formItem.idAppliance);
  form.appliances[_id].mode = value as EApplianceMode;

  onFinish(false);
}

const onFinish = (closePopin = false) => {
  const formPayload = {
    ...form,
    untilFurtherNotice: !form.endDate,
    appliances: form.appliances.map((appliance: any, index: number) => {
      const offMode = props.appliances[index].availableModes.includes(EApplianceMode.Normal) ? EApplianceMode.Normal : EApplianceMode.Comfort
      const isModeOff = (appliance.mode as string) === g_modeOff || !appliance.isOn;

      return ({
        ...appliance,
        mode: isModeOff ? offMode : appliance.mode,
        isOn: !isModeOff
      })
    })
  };
  props.onSubmit(formPayload, closePopin);
}

const changeTemperature = () => {
  if (idTimeout.value) clearTimeout(idTimeout.value);
  idTimeout.value = setTimeout(() => {
    onFinish(false)
  }, 200);
}

onBeforeMount(() => {
  form.endDate = props.endDate;
  form.appliances = props.appliancesSettings.slice().sort((a, b) => {
    if (b.applianceType === EApplianceType.WaterHeater) {
      return b.applianceType !== EApplianceType.WaterHeater ? 1 : -1;
    }
    return a.applianceName.localeCompare(b.applianceName);
  })
})

watch(() => form.endDate, (newValue, oldValue) => {
  if (!props.appliances.length || oldValue === null) return;
  onFinish(false)
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    model: form,
    onFinish: onFinish,
    class: "popinForm quickSettingForm"
  }, {
    default: _withCtx(() => [
      (isEditingQuickSettings.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.selectPeriodContainer)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.selectPeriod)
            }, _toDisplayString(_ctx.$t('quicksettings.select_period')), 3),
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpenPopinDuration.value = true)),
              class: _normalizeClass(_ctx.$style.value)
            }, [
              _createElementVNode("div", null, _toDisplayString(untilText.value) + " " + _toDisplayString(prog.value), 1),
              _cache[2] || (_cache[2] = _createElementVNode("svg", { class: "icon icon-pen" }, [
                _createElementVNode("use", { "xlink:href": "#icon-pen" })
              ], -1))
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      _createVNode(MyVFieldset, {
        ref_key: "dropDownList",
        ref: dropDownList,
        title: _ctx.$t('EditQuickSetting.appliance_modes'),
        noPadding: true,
        class: _normalizeClass(_ctx.$style.applianceModes__container)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.applianceModes__list)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.appliances, (applianceSettings, index) => {
              return (_openBlock(), _createBlock(MyVDropDown, {
                key: applianceSettings.idAppliance
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(MyVAntdradio, {
                      hasIcon: true,
                      options: optionsMode.value(form.appliances[index].idAppliance),
                      value: form.appliances[index].isOn ? form.appliances[index].mode : '__STOP__',
                      onChange: (value) => onFormApplianceModeChange(form.appliances[index], value)
                    }, null, 8, ["options", "value", "onChange"]),
                    (form.appliances[index].mode === _unref(EApplianceMode).Temp)
                      ? (_openBlock(), _createBlock(MyVTemperatureControl, {
                          key: 0,
                          modelValue: form.appliances[index].temperatureTarget,
                          "onUpdate:modelValue": [($event: any) => ((form.appliances[index].temperatureTarget) = $event), changeTemperature],
                          max: 30,
                          min: 7
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style.applianceModes__btn_name)
                  }, _toDisplayString(_ctx.$t(applianceSettings.applianceName)), 3),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style.applianceModes__select)
                  }, [
                    _createVNode(MyVIcon, {
                      name: iconMode(form.appliances[index].mode, form.appliances[index].isOn || false),
                      className: _ctx.$style['applianceModes__btn-icon']
                    }, null, 8, ["name", "className"]),
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.$style['applianceModes__btn-mode'])
                    }, [
                      (form.appliances[index].mode === _unref(EApplianceMode).Temp)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(form.appliances[index].temperatureTarget) + _toDisplayString(_unref(ETemperatureUnit).Celsius), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(heatingType(form.appliances[index].mode, form.appliances[index].isOn || false)), 1)
                          ], 64))
                    ], 2)
                  ], 2)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 2)
        ]),
        _: 1
      }, 8, ["title", "class"]),
      (isOpenPopinDuration.value)
        ? (_openBlock(), _createBlock(MyVCustomDuration, {
            key: 1,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (isOpenPopinDuration.value = false)),
            isEmitMode: "",
            onSetPeriod: setPeriod,
            showFullSetting: false,
            quickSettingsToEnabled: _ctx.currentQuickSetting
          }, null, 8, ["quickSettingsToEnabled"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})