import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import $ from 'jquery';
import 'round-slider';
import {TSliderRound} from "./interfaces";
import {onMounted, ref} from "vue";

interface Props {
  min?: TSliderRound["min"];
  max: TSliderRound["max"];
  modelValue: TSliderRound["modelValue"];
  tooltipGenerateTemplate?: TSliderRound['tooltipGenerateTemplate']
  color?: TSliderRound["color"];
  isFromProgram?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVSliderRound',
  props: {
    min: { default: 7 },
    max: { default: undefined },
    modelValue: { default: undefined },
    tooltipGenerateTemplate: { type: Function, default: undefined },
    color: { default: 'rgb(255, 163, 22)' },
    isFromProgram: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const slider = ref()

const updateCurrentValue = (value: number) => {
  if (value < props.min || value > 30) return;
  emit('update:modelValue', value);
  $(slider.value).data('roundSlider').option('value', value);
}

const add = () => {
  updateCurrentValue(props.modelValue + 0.5);
}

const sub = () => {
  updateCurrentValue(props.modelValue - 0.5);
}

onMounted(() => {

  // Slider is a JQuery<HTMLElement> but roundSlider plugin don't use typescript.
  // I keep using any here to avoid make complex code that override JQuery interface just to add one property.
  $(slider.value).roundSlider({
    min: props.min,
    max: props.max,
    value: props.modelValue,
    startAngle: 315,
    handleSize: "+16",
    handleShape: "dot",
    lineCap: "round",
    width: 8,
    circleShape: 'pie',
    sliderType: "min-range",
    disabled: props.isFromProgram,
    svgMode: true,
    editableTooltip: false,
    borderWidth: 0,
    pathColor: 'rgb(205, 210, 222)',
    rangeColor: props.color,
    showTooltip: props.tooltipGenerateTemplate !== null,
    step: 0.5,
    tooltipFormat: (args: any): string => props.tooltipGenerateTemplate?.(args.value) || '',
    beforeCreate: function () {
      const self = this as any;
      const updateOptionsResponsive = (applyToSelf = true) => {
        const _width = self.control.parent().width();
        const _options: any = {
          radius: _width / 2,
          width: Math.max(_width / 30, 8),
          handleSize: `+${_width / 20}`
        };

        if (applyToSelf) {
          for (const option in _options) {
            self.option(option, _options[option]);
          }
        }

        return _options;
      };

      self.options = {
        ...self.options,
        ...updateOptionsResponsive(false)
      };

      self["_bind"]($(window), "resize", updateOptionsResponsive);
    },
    create: function () {
      const self = this as any;
      const handle = self.control.find('.rs-handle');

      handle.css('background-color', props.color);
    },
  })
      .on("update", ({value}: any) => {
        emit('update:modelValue', value);
      });
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.sliderRound__container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sliderRound),
      ref_key: "slider",
      ref: slider
    }, null, 2),
    (!_ctx.isFromProgram)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.sliderRound__buttons)
        }, [
          _createElementVNode("button", {
            type: "button",
            onClick: sub,
            class: _normalizeClass(_ctx.$style.sliderRound__button)
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("svg", {
              class: "icon icon-minus",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-minus" })
            ], -1)
          ]), 2),
          _createElementVNode("button", {
            type: "button",
            onClick: add,
            class: _normalizeClass(_ctx.$style.sliderRound__button)
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("svg", {
              class: "icon icon-add",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-add" })
            ], -1)
          ]), 2)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})