import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

import {ChartDatasets, EAxis, TBarLineGraphic, TBarLineGraphicTotal, TGraphic} from "./interfaces";
import {onBeforeMount, onUpdated, ref, watch} from "vue";
import {roundNumber2} from "@/helpers/number/formatNumber";
import {toRGBA} from "@/helpers/domains/ui/color";
import ERoutes from "@/router/routes";
import {useRoute} from "vue-router";
import {write} from "@/graphs/tools";
import useCurrency from "@/composables/currency";
import {TooltipItem} from "chart.js";
import {useI18n} from "vue-i18n";
import {useResponsive} from "@/composables/useResponsive";

interface Props {
  isDetailed?: boolean;
  bars?: TBarLineGraphic["bars"];
  lines?: TBarLineGraphic["lines"];
  labels?: TBarLineGraphic["labels"];
  leftAxis?: TBarLineGraphic["leftAxis"];
  rightAxis?: TBarLineGraphic["rightAxis"];
  renderTotal?: TBarLineGraphic["renderTotal"];
  axisTargetTotal?: TBarLineGraphic["axisTargetTotal"];
  compare?: TBarLineGraphic['compare'];
  renderLine?: TBarLineGraphic['renderLine'];
  renderFlag?: TBarLineGraphic['renderFlag'];
  flag?: TBarLineGraphic['flag'];
  onAdjustChart?: TBarLineGraphic['onAdjustChart'];
  barThickness?: TBarLineGraphic['barThickness'];
  maxBarThickness?: TBarLineGraphic['maxBarThickness'];
  generateTopLabel?: TBarLineGraphic['generateTopLabel'];
  onLegendClick?: TBarLineGraphic['onLegendClick'];
  hideOnLegendClick?: TBarLineGraphic['hideOnLegendClick'];
  displayLegend?: TBarLineGraphic['displayLegend'];
  needPadding?: boolean;
  isDayType?: boolean;
  showTooltipTotal?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'MyVBarLineGraphic',
  props: {
    isDetailed: { type: Boolean, default: false },
    bars: { default: () => [] },
    lines: { default: () => [] },
    labels: { default: undefined },
    leftAxis: { default: undefined },
    rightAxis: { default: undefined },
    renderTotal: { type: Boolean, default: false },
    axisTargetTotal: { default: EAxis.LEFT },
    compare: { type: Function, default: undefined },
    renderLine: { type: Boolean, default: true },
    renderFlag: { type: Function, default: undefined },
    flag: { default: undefined },
    onAdjustChart: { type: Function, default: undefined },
    barThickness: { default: undefined },
    maxBarThickness: { default: undefined },
    generateTopLabel: { type: Function, default: undefined },
    onLegendClick: { type: Function, default: undefined },
    hideOnLegendClick: { type: Boolean, default: true },
    displayLegend: { type: Boolean, default: true },
    needPadding: { type: Boolean, default: false },
    isDayType: { type: Boolean, default: false },
    showTooltipTotal: { type: Boolean, default: false }
  },
  setup(__props: any) {



const props = __props

const chartDatas = ref<any>();
const update = ref(false);
const barHidden = ref<TBarLineGraphic['bars'][number]['id'][]>();
const chartRef = ref(null)
const route = useRoute()
const {isMobile} = useResponsive();
const {isCurrency, formatCurrency} = useCurrency()
const {t} = useI18n()
let tooltipTimeout: number | undefined;

const createChart = () => {
  const axis: any = {};
  const legendHidden: NonNullable<TGraphic['id']>[] = [];
  const datas: TBarLineGraphicTotal = {};

  const baseYAxis = {
    grid: {
      display: true,
      borderWidth: true,
      borderDash: [8, 4],
    },
  };

  props.bars.forEach((bar, index) => {
    if (bar.renderTotal) {
      const id = bar.stack || props.axisTargetTotal!;

      if (datas[id]) {
        datas[id].lastIndex = index;
        datas[id].datas.forEach((_, index, _datas) => {
          _datas[index] += bar.datas[index];
        });
      } else {
        datas[id] = {
          lastIndex: index,
          datas: [...bar.datas]
        };
      }
    }
  });

  const datasets: ChartDatasets = [];

  props.lines.forEach((line, i) => {
    if (line.hideLegend === true && line.id) {
      legendHidden.push(line.id);
    }

    datasets.push({
      type: "line" as "bar",
      label: line.label,
      data: line.datas,
      borderColor: line.color as string | undefined,
      backgroundColor: line.color as string | undefined,
      order: i + 1,
      tension: 0.5,
      spanGaps: true,
      datalabels: {
        display: false,
      },
      pointBackgroundColor: line.color,
      pointRadius: line.datas.map((_, i) =>
          i === line.datas.length - 1 ? 5 : 0
      ),
      yAxisID: line.baseAxis || EAxis.LEFT,
      renderTooltip: false,
    });
  });

  props.bars.forEach((bar, i) => {
    const stack = bar.stack || props.axisTargetTotal!;
    const isTotalStack = stack === props.axisTargetTotal;

    if (bar.hideLegend === true && bar.id) {
      legendHidden.push(bar.id);
    }

    const barDatas = props.bars.map(({datas}) => datas);
    const color = bar.color ? (typeof bar.color === 'function' ? bar.datas.map((data, index) => (bar.color as CallableFunction)(data, index, barDatas)) : bar.color) : undefined;
    const borderColor = bar.borderColor ? (typeof bar.borderColor === 'function' ? bar.datas.map((data, index) => (bar.borderColor as CallableFunction)(data, index, barDatas)) : bar.borderColor) : undefined;

    datasets.push({
      type: "bar",
      label: bar.label,
      barThickness: props.barThickness,
      maxBarThickness: props.maxBarThickness,
      data: bar.datas.map(roundNumber2),
      borderColor: borderColor || color,
      borderWidth: bar.borderWidth,
      hoverBackgroundColor: color && (isTotalStack ? color : Array.isArray(color) ? color.map(c => toRGBA(c, 0.4)) : toRGBA(color, 0.4)),
      backgroundColor: color && (isTotalStack ? color : Array.isArray(color) ? color.map(c => toRGBA(c, 0.4)) : toRGBA(color, 0.4)),
      borderRadius: 24,
      order: props.lines.length + i + 1,
      yAxisID: bar.baseAxis || EAxis.LEFT,
      stack,
      renderTooltip: bar.renderTooltip ?? true,
      datalabels: {
        display: false
      }
    });
  });

  if (props.leftAxis) {
    axis[EAxis.LEFT] = {
      ...baseYAxis,
      stacked: props.leftAxis!.stacked ?? true,
      position: "left",
      min: props.leftAxis!.min,
      suggestedMax: props.leftAxis!.max,
      display: props.leftAxis!.display ?? true,
    }
  }

  if (props.rightAxis) {
    axis[EAxis.RIGHT] = {
      ...baseYAxis,
      stacked: props.rightAxis.stacked ?? false,
      position: "right",
      min: props.rightAxis.min,
      max: props.rightAxis.max,
      display: props.rightAxis.display ?? true,
      grid: {
        drawOnChartArea: false,
      },
    };
  }

  axis.xTop = {
    display: false
  }
  if (props.generateTopLabel) {
    axis.xTop = {
      position: "top",
      display: true,
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        display: false
      },
      ticks: {
        maxRotation: 0,
        autoskip: true,
        align: 'center',
        padding: 32,
        callback: (value: number) => props.generateTopLabel!(value)
      }
    };
  }

  const leftAxis = props.leftAxis;
  const rightAxis = props.rightAxis;

  const needPadding = props.needPadding
  chartDatas.value = {
    type: "bar",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: props.generateTopLabel ? undefined : {
        padding: {
          top: needPadding ? 56 : 18,
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          reverse: true,
          display: props.displayLegend,
          onClick: (_: any, elem: any, legend: any) => {
            const graphic = elem.datasetIndex < props.lines.length ? props.lines[elem.datasetIndex] : props.bars[elem.datasetIndex - props.lines.length];

            if (props.hideOnLegendClick) {
              const hideItems = [elem.datasetIndex];
              const ci = legend.chart;
              const barInfo: { [key: number]: NonNullable<TGraphic['id']> } = {
                [elem.datasetIndex]: graphic.id!
              };

              if (graphic.associatedLegends) {
                let index = 0;

                for (const line of props.lines) {
                  if (line.id !== undefined && graphic.associatedLegends.includes(line.id)) {
                    hideItems.push(index);
                  }
                  ++index;
                }

                for (const bar of props.bars) {
                  if (bar.id !== undefined && graphic.associatedLegends.includes(bar.id)) {
                    hideItems.push(index);
                    barInfo[index] = bar.id!;
                  }
                  ++index;
                }
              }

              for (const hideItem of hideItems) {
                if (ci.isDatasetVisible(hideItem)) {
                  ci.hide(hideItem);
                  barHidden.value?.push(barInfo[hideItem]);
                } else {
                  ci.show(hideItem);
                  barHidden.value?.splice(barHidden.value.indexOf(barInfo[hideItem]), 1);
                }
              }
            }
            props.onLegendClick?.(graphic);
          },
          labels: {
            boxWidth: 8,
            usePointStyle: true,
            pointStyle: "rectRounded",
            filter: (item: any): boolean => {
              const graphic = item.datasetIndex < props.lines.length ? props.lines[item.datasetIndex] : props.bars[item.datasetIndex - props.lines.length];

              return graphic?.id === undefined || legendHidden.indexOf(graphic?.id) === -1;
            },
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (context: any) {
              if (context.raw != 0 || context.dataset.type === 'line') {
                const value = context.raw > 1 ? Math.round(context.raw) : context.raw
                const unit = context.dataset.yAxisID === 'yRight' ? rightAxis!.title : leftAxis!.title
                const label = `${context.dataset.label}: `

                if (isCurrency(unit)) return label + formatCurrency(value)
                return `${label}${value} ${unit}`
              }
            },
            footer: (tooltipItems: TooltipItem<"bar" | "line">[]) => {
              const chart = tooltipItems[0].chart;
              const dataIndex = tooltipItems[0].dataIndex;

              const barDatasets = chart.data.datasets.filter(item => item.type === "bar" && item.data.some(value => value! > 0));
              if (!props.showTooltipTotal || barDatasets.length === 1) {
                return;
              }

              const total = barDatasets.reduce((sum, dataset) => {
                const value = dataset.data[dataIndex];
                return (typeof value === 'number' && !isNaN(value)) ? sum + (value > 1 ? Math.round(value) : value) : sum;
              }, 0);

              return total > 0
                  ? `${t('conso.chart.tooltip.total', { total: total.toLocaleString() })} ${props.leftAxis?.title ?? ''}`
                  : undefined;
            },
            title: (context: any) => {
              if (props.isDetailed) {
                return context[0]?.label
              }

              switch (route.query.type) {
                case  ERoutes.CONSO_MONTH:
                  return buildDateFromChartContextMonth(context)
                case  ERoutes.CONSO_WEEK:
                  return buildDateFromChartContextWeek(context)
                case  ERoutes.CONSO_YEAR:
                  return `${context[0]?.label} ${route.query.date}`
                default:
                  return ''
              }
            }
          },
          external: (context: any) => {
            const chartInstance = context.chart;

            if (context.opacity === 0 || !isMobile.value) {
              return;
            }

            if (tooltipTimeout) {
              clearTimeout(tooltipTimeout);
            }

            tooltipTimeout = window.setTimeout(() => {
              chartInstance.tooltip.setActiveElements([]);
              chartInstance.update();
            }, 2000);
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: false
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
        ...axis,
      },
    },
    plugins: [
      {
        id: "customBackground",
        beforeDraw: (chart: any) => {
          const ctx = chart.ctx;
          const axis = chart.scales.x;

          const nbItemRendered = (axis.max - axis.min) + 1;
          const width = chart.chartArea.width / nbItemRendered;
          const height = chart.chartArea.height;
          const y = chart.chartArea.top;

          for (let i = 0; i < nbItemRendered; ++i) {
            const pos = axis.ticks[0].value + i;
            const x = axis.getPixelForValue(pos);

            if (props.labels && props.labels[pos].backgroundColor) {
              ctx.save();
              ctx.fillStyle = props.labels[pos].backgroundColor;
              ctx.fillRect(x - Math.ceil(width * 0.5), y, width, height);
              ctx.restore();
            }
          }
        },
      }, props.renderFlag ? {
        id: "customFlag",
        beforeDraw: (chart: any) => {
          const ctx = chart.ctx;
          const axis = chart.scales.x;

          const width = Math.max(chart.chartArea.width / axis.ticks.length, 100);
          const y = chart.chartArea.top - 30;

          axis.ticks.forEach((tick: any) => {
            const flag = props.renderFlag!(tick.label, tick.value as number);

            if (flag !== null) {
              const x = axis.getPixelForValue(tick.value) + width / 2;
              const center = Math.ceil(width / 2);

              ctx.save();

              ctx.fillStyle = props.flag?.color || "rgb(181, 181, 214)";
              ctx.fillRect(x - center, y, width, 25);
              write(ctx, [[{
                value: flag,
                color: props.flag?.textColor || "rgb(255, 255, 255)"
              }]], [width], [], 16, x + center - Math.min(flag.length * 4, 32), y + 18);

              ctx.restore();
            }
          });
        },
      } : {}
    ],
    data: {
      datasets,
      labels: props.labels ? props.labels.map((label) => label.text) : null,
    },
  };

  props.onAdjustChart?.(chartDatas.value);
}

const buildDateFromChartContextMonth = (context: any) => {
  const label = context[0]?.label;
  const queryDate = (route.query?.date as string)?.split('-');

  if (parseInt(label)) {
    const formattedLabel = label.length > 1 ? label : "0" + label;
    return `${formattedLabel}-${queryDate?.reverse()?.join('-')}`;
  }

  return `${label} ${queryDate?.splice(0, 1)}`;
};

const buildDateFromChartContextWeek = (context: any) => {
  return `${context[0]?.label.length > 1 ? context[0]?.label : "0" + context[0]?.label}-${(route.query?.date as string)?.split('-')?.slice(0, 2)?.reverse()?.join('-')}`;
}

onBeforeMount(() => {
  createChart()
})

onUpdated(() => {
  if (update.value) {
    createChart();
    (chartRef.value as any).chartJSState.props.data =
        chartDatas.value.data;
    (chartRef.value as any).chartJSState.props.options =
        chartDatas.value.options;
    (chartRef.value as any).update();
    update.value = false;
  }
})

watch([() => props.bars, () => props.lines, () => props.labels, () => props.leftAxis,
  () => props.rightAxis, () => props.renderTotal, () => props.axisTargetTotal], () => {
  update.value = true
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", {
      class: _normalizeClass(_ctx.$style.axisTitles)
    }, [
      (_ctx.leftAxis && _ctx.leftAxis.display !== false)
        ? (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString(_ctx.leftAxis.title), 1))
        : _createCommentVNode("", true),
      (_ctx.rightAxis && _ctx.rightAxis.display !== false)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, _toDisplayString(_ctx.rightAxis.title), 1))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_unref(Vue3ChartJs), _mergeProps({
      ref_key: "chartRef",
      ref: chartRef
    }, chartDatas.value), null, 16)
  ], 64))
}
}

})