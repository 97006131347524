import API from "@/helpers/api";
import {TDiagnosticService} from "@/services/Diagnostic/interfaces";

const DiagnosticService: TDiagnosticService = {

    getDiagnostics: (siteId, skipLoading) => (
        API.get(`api/site/${siteId}/autodiag`, {
            skipLoading
        })
            .then(({data}) => data)
    ),

    startDiagnosticSelfTest: params => (
        API.post(`api/site/${params.siteId}/autodiag/modulator/${params.csModulatorId}/selftest`, null, {
            skipLoading: true,
            timeout: 60000
        })
    ),

    startDiagnosticAllTest: siteId => (
        API.post(`api/site/${siteId}/autodiag`, null, {
            skipLoading: true,
            timeout: 60000
        })
    ),

    getDiagnosticSelfTest: params => (
        API.get(`api/site/${params.siteId}/autodiag/modulator/${params.csModulatorId}/selftest`)
    )
};

export default DiagnosticService;
