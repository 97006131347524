import {TAppliance} from "@/services/Appliances/interfaces";

export enum EBehaviourPeriod {
  /**
   * Appliance should work all day.
   */
  Day = 'day',

  /**
   * Appliance should work all night.
   */
  Night = 'night',

  Void = 'void'
}

export type TProgramCreateZoneAppliancesDatas = {
  /**
   * Object where key is appliance's id and values are behaviour.
   * Empty mean no period selected.
   */
  [key: TAppliance['id']]: EBehaviourPeriod[];
};

export type TProgramCreateZoneAppliances = TProgramCreateZoneAppliancesDatas & {
  /**
   * Event call when form is submitted.
   */
  onSubmit: (formDatas: TProgramCreateZoneAppliancesDatas, actionOnFinish:void) => void;

  returnAction: string|void;

  appliances: any[];

  behaviours: TProgramCreateZoneAppliancesDatas;
};