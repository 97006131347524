import useCurrency from '@/composables/currency';


export default function useConsoRepartition() {

  const {formatCurrency, isCurrency} = useCurrency()

  function consoRepartitionValue(unit: string, value: number): string {

    const isUnitCurrency: boolean = isCurrency(unit)

    if (value === 0) {
      if(isUnitCurrency) return formatCurrency(0)
      return `0 ${unit}`
    }
    if (value <= 1) {
      if(isUnitCurrency) return `< ${formatCurrency(1)}`
      return `< 1 ${unit}`
    }
    if (value < 2) {
      if(isUnitCurrency) return `< ${formatCurrency(2)}`
      return `< 2 ${unit}`
    }

    const valueRounded: number = value ? Math.round(value) : 0

    if (isUnitCurrency) return formatCurrency(valueRounded)
    return `${valueRounded} ${unit}`
  }

  return {consoRepartitionValue}
}
