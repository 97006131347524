import {TManualSetting} from "../Heating/interfaces";
import {TSite} from "../Site/interfaces";

export enum EApplianceMode {
    /**
     * Normal mode.
     */
    Normal = 'NORMAL',

    /**
     * Comfort mode
     */
    Comfort = 'CONFORT',

    /**
     * Comfort-1 mode
     */
    Comfort1 = 'CONFORTMINUS1',

    /**
     * Comfort-2 mode
     */
    Comfort2 = 'CONFORTMINUS2',

    /**
     * Eco mode
     */
    Eco = 'ECO',

    /**
     * Eco V mode
     */
    EcoV = 'ECOV',

    /**
     * Frost protection mode (hors gel)
     */
    FrostProtection = 'HORS_GEL',

    /**
     * Custom temp mode
     */
    Temp = 'TEMPERATURE',

    /**
     * Stop mode
     */
    Stop = 'STOP',
}

export enum EApplianceType {
    /**
     * Appliance is a heater.
     */
    Heater = 'HEATER',

    /**
     * Appliance is a water heater.
     */
    WaterHeater = 'WATER_HEATER',

    /**
     * Appliance is a heating floor.
     */
    HeatingFloor = 'HEATING_FLOOR',

    /**
     * Appliance is air conditionning
     */
    AirConditioning = "AIR_CONDITIONING", /**

     * Appliance is reversible air conditionning
     */
    ReversibleAirConditioning = "REVERSIBLE_AIR_CONDITIONING",

    /**
     * Appliance is a heat pump
     */
    HeatPump = "HEAT_PUMP",

    /**
     * Appliance is something else.
     */
    Other = "OTHER"

}

export enum EApplianceModulatorType {
    /**
     * Voltalis modulator is version 2 or version 5
     */
    Version_2_OR_5 = "V2_V5",

    /**
     * Voltalis modulator is a relay.
     */
    Relay = "VX_RELAY",

    /**
     * Voltalis modulator is a wire.
     */
    Wire = "VX_WIRE"
}

export enum EVoltalisVersion {
    /**
     * Volalis box version 2
     */
    V2 = "V2",

    /**
     * Volalis box version 5
     */
    V5 = "V5",

    /**
     * Volalis box version X
     */
    Vx = "Vx",

    /**
     * Volalis box version is something else.
     */
    Other = "OTHER"
}

export enum EApplianceProgType {
    /**
     * Appliance is configurate following program's instructions.
     */
    Program = 'USER',

    /**
     * Appliance is configurate following quick settings' instructions.
     */
    Quick = 'QUICK',

    /**
     * Appliance is configurate following manual settings' instructions.
     */
    Manual = 'MANUAL',

    /**
     * Appliance is configurate following default settings' instructions.
     */
    Default = 'DEFAULT',
}

export type TApplianceEditable = {
    /**
     * Appliance's name
     */
    name: string;
};

export type TApplianceEditableTemperature = {
    /**
     * Appliance's temperatureMeasurement
     */
    temperatureMeasurement: number | undefined;
};

export type TApplianceProgramming = {
    /**
     * Appliance current's programmation used among program, quick settings or manual setting.
     */
    progType: EApplianceProgType;

    /**
     * Appliance current programmation's name (Program or Quick only).
     */
    progName: string | null;

    /**
     * Appliance's manual setting associate.
     */
    idManualSetting: TManualSetting['id'];

    /**
     * Appliance is running until further notice (Quick and Manual only) or following 'endDate'.
     */
    untilFurtherNotice: boolean;

    /**
     * Appliance's mode
     */
    mode: EApplianceMode;

    /**
     * Planning's id associate to the appliance. (Program only)
     */
    idPlanning: number | null;

    /**
     * Appliance is On (Quick and Manual only).
     */
    isOn: boolean | null;

    /**
     * Appliance's end date.
     */
    endDate: string | undefined | null;

    /**
     * Appliance's temperature targeted.
     */
    temperatureTarget: number | null;

    /**
     * Appliance's default temperature.
     */
    defaultTemperature: number;
};

export type TAppliance = TApplianceEditable & {
    /**
     * Appliance's id
     */
    id: number;

    /**
     * Appliance's type
     */
    applianceType: EApplianceType;

    /**
     * Appliance heating's level
     */
    heatingLevel: number;

    /**
     * Appliance availables modes.
     */
    availableModes: EApplianceMode[];

    /**
     * Appliance modulator type.
     */
    modulatorType: EApplianceModulatorType;

    /**
     * Version of the Voltalis box that manage the appliance.
     */
    voltalisVersion: EVoltalisVersion;

    /**
     * Current appliance's programmation.
     */
    programming: TApplianceProgramming;
};

export type TAppliances = TAppliance[];

export type TApplianceRefWithoutId = {
    /**
     * Appliance's name.
     */
    applianceName: TAppliance['name'];

    /**
     * Appliance's type.
     */
    applianceType: TAppliance['applianceType'];
};

export type TApplianceRef = TApplianceRefWithoutId & {
    /**
     * Appliance's id.
     */
    idAppliance: TAppliance['id'];
};

type TGetAppliances = (siteId: TSite['id']) => Promise<TAppliances | void>;
type TGetAppliance = (siteId: TSite['id'], id: TAppliance['id']) => Promise<TAppliance | void>;
type TPutAppliance = (siteId: TSite['id'], applianceId: TAppliance['id'], body: TApplianceEditable) => Promise<TAppliance | void>;
type TPutApplianceTemperature = (siteId: TSite['id'], applianceId: TAppliance['id'], body: TApplianceEditableTemperature) => Promise<boolean | void>;
type TGetUnmanagedAppliance = (siteId: TSite['id']) => Promise<TUnmanagedAppliance[] | void>;
type TPutUnmanagedAppliance = (siteId: TSite['id'], body: TUnmanagedAppliance[]) => Promise<TUnmanagedAppliance[] | void>;


export type TAppliancesService = {
    /**
     * Send a GET request to retrieve ALL appliances.
     */
    getAppliances: TGetAppliances;

    /**
     * Send a GET request to retrieve one appliance by id.
     */
    getAppliance: TGetAppliance;

    /**
     * Send a PUT request to update manual setting
     */
    updateAppliance: TPutAppliance;

    /**
     * Send a PUT request to update manual setting
     */
    updateApplianceTemperature: TPutApplianceTemperature;

    /**
     * Send a GET request to get all the unmanaged appliance
     */
    getUnmanagedAppliance: TGetUnmanagedAppliance

    /**
     * Send a PUT request to update all the unmanaged appliance
     */
    putUnmanagedAppliance: TPutUnmanagedAppliance
};


export type TUnmanagedAppliance = {
    category: string;
    quantity: number;
}
