/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";

import {AuthModule, RequestLoaderModule} from "@/store";
import router from "@/router";
import ERoutes from "@/router/routes";
import Cookie from "../cookie";
import IAPI, {TAuthBasicCallback, TAuthTokenCallback} from "./interfaces";
import {ECookieNames} from "@/helpers/cookie/interfaces";

export const maxConnectionBeforeShowPopin = 3;

const API: IAPI = axios.create({
  baseURL: process.env.VUE_APP_REMOTE_SERVER_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

let g_interceptorsRequest = API.interceptors.request.use((config) => {
    if (config.skipLoading !== true) {
        RequestLoaderModule.addRequest();
    }
    return config;
});

API.interceptors.response.use(response => {
    if (response.config.skipLoading !== true) {
        RequestLoaderModule.removeRequest();
    }
    return response;
}, error => {
    if (error.config.skipLoading !== true) {
        RequestLoaderModule.removeRequest();
    }
    return Promise.reject(error);
});

API.setLocale = (
    locale,
    {localeVariableName, onTransformLocaleName = (locale) => locale}
) => {
    if (g_interceptorsRequest !== -1) {
        API.interceptors.request.eject(g_interceptorsRequest);
    }
    g_interceptorsRequest = API.interceptors.request.use((config) => {
        RequestLoaderModule.addRequest();
        config.params = {
            ...config.params,
            [localeVariableName]: onTransformLocaleName(locale),
        };
        return config;
    });
};

API.initRefreshToken = ({
                            refreshTokenUri,
                            onRefreshToken,
                            refreshTokenField = "refresh_token",
                            tokenExpirationField,
                            tokenHTTPCodeError = 401,
                        }) => {
    API.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config;
            const refreshToken = Cookie.get(ECookieNames.REFRESH_TOKEN);

            if (error.response.status === tokenHTTPCodeError) {
                if (
                    !refreshTokenUri ||
                    originalRequest.url.indexOf(refreshTokenUri) !== -1 ||
                    !refreshToken
                ) {
                    AuthModule.logout();
                    router.push(ERoutes.LOGIN);
                } else if (!originalRequest._retry) {
                    originalRequest._retry = true;
                    return API.post(refreshTokenUri, {
                        [refreshTokenField]: refreshToken,
                    })
                        .then(({data}) => onRefreshToken(data))
                        .then(({token, refreshToken}) => {
                            API.setAuthToken!(token, tokenExpirationField);
                            API.setRefreshToken!(refreshToken, tokenExpirationField);
                        });
                }
            }
            return Promise.reject(error);
        }
    );
};

API.getTokenFromCookie = () => Cookie.get(ECookieNames.TOKEN);

API.setRefreshToken = token =>
    Cookie.set(ECookieNames.REFRESH_TOKEN, token);

API.setAuthBasic = ((username, password) => {
    API.defaults.auth = {username, password};
}) as TAuthBasicCallback;

API.forgetAuthBasic = () => {
    delete API.defaults.auth;
};

API.setAuthToken = (token => {
    Cookie.set(ECookieNames.TOKEN, token, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    });
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}) as TAuthTokenCallback;

API.forgetAuthToken = () => {
    Cookie.delete(ECookieNames.TOKEN);
    Cookie.delete(ECookieNames.REFRESH_TOKEN);
    delete API.defaults.headers.common["Authorization"];
};

API.forgetAuth = () => {
    API.forgetAuthBasic!();
    API.forgetAuthToken!();
};

export default API;
