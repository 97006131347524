import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {TErrorBanner} from "@/components/ui/atoms/MyVErrorBanner/interfaces";
import {onMounted} from "vue";

interface Props {
  error: TErrorBanner['error'];
  duration?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVErrorBanner',
  props: {
    error: { default: "Erreur serveur" },
    duration: { default: undefined }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

onMounted(() => {
  if (props.duration)
    setTimeout(() => {
      emit('close')
    }, props.duration)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", null, _toDisplayString(_ctx.error), 1),
    (_openBlock(), _createElementBlock("svg", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      class: "icon icon-cross",
      "aria-hidden": "true"
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
    ])))
  ]))
}
}

})