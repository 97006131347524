import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "planningBar__hours"
}
const _hoisted_2 = { class: "planningBar__hours-content" }
const _hoisted_3 = { class: "planningBar__hour" }
const _hoisted_4 = { class: "planningBar__hour" }
const _hoisted_5 = { class: "planningBar__hour" }
const _hoisted_6 = { class: "planningBar__hour" }
const _hoisted_7 = { class: "planningBar__hour" }
const _hoisted_8 = { class: "planningBar" }

import {TPlanningBar} from './interfaces';
import PlanningBarItem from './MyVPlanningBarItem.vue';

interface Props {
  plannings: TPlanningBar["planning"];
  hideLegend?: TPlanningBar["hideLegend"];
  className?: TPlanningBar["className"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPlanningBar',
  props: {
    plannings: { default: undefined },
    hideLegend: { type: Boolean, default: false },
    className: { default: undefined }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['planningBar__container', _ctx.className])
  }, [
    (!_ctx.hideLegend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('global.time.midnight_zero')), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('global.time.middle_morning')), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('global.time.noon')), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('global.time.middle_afternoon')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('global.time.midnight')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plannings, (planning, index) => {
        return (_openBlock(), _createBlock(PlanningBarItem, {
          key: planning.mode + '-' + index,
          planning: planning
        }, null, 8, ["planning"]))
      }), 128))
    ])
  ], 2))
}
}

})