import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {TObjectiveModalItem} from "@/components/ui/organisms/MyVObjectiveModal/interfaces";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import {computed} from "vue";
import useCurrency from "@/composables/currency";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVObjectiveModalItem',
  props: {
    month: {},
    objective: {},
    consumption: {},
    unitIsEnergy: { type: Boolean }
  },
  setup(__props: any) {


const props = __props

const {currency ,formatCurrency} = useCurrency()

function dataText(text:number) {
  if (datas.value.unit === EEnergyUnit.kWh) return `${text} ${datas.value.unit}`
  return formatCurrency(text)
}

const dataConsumptionText = computed<string>(() => {
  if (parseFloat(datas.value.consumption.toString()) <= 0) return '-'
  return dataText(datas.value.consumption)
})

const dataObjectiveText = computed<string>(() => dataText(datas.value.objective))

const datas = computed(() => {
  const unit = EEnergyUnit.kWh;
  const objective = Math.round(props.unitIsEnergy ? fromWhToKwh(props.objective) : props.objective);
  const consumption = Math.round(props.unitIsEnergy ? fromWhToKwh(props.consumption) : props.consumption)

  return {
    objective,
    consumption,
    unit: props.unitIsEnergy ? unit : currency.value,
    status: props.consumption > props.objective
  };
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(_ctx.$style.objectiveModalListItem)
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.month), 1),
    _createElementVNode("p", null, [
      _createVNode(MyVIcon, {
        name: datas.value.status ? 'red-flag' : 'green-flag'
      }, null, 8, ["name"]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.objectiveModalListItemConsumption)
      }, _toDisplayString(dataConsumptionText.value), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.objectiveModalListItemObjective)
      }, " / " + _toDisplayString(dataObjectiveText.value), 3)
    ])
  ], 2))
}
}

})