import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

import {Dropdown, Menu} from "ant-design-vue";

import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";

import {TLangSelect} from './interfaces';
import {computed, ref} from "vue";

interface Props {
  buttonClassName?: TLangSelect['buttonClassName'];
  itemClassName?: TLangSelect['itemClassName'];
  overlayClassName?: TLangSelect['overlayClassName'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLangSelect',
  props: {
    buttonClassName: { default: undefined },
    itemClassName: { default: undefined },
    overlayClassName: { default: undefined }
  },
  setup(__props: any) {



const MenuItem = Menu.Item;
const isOpen = ref(false);

const intlModule = computed(() => {
  return IntlModule;
})

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
}

const changeLocale = ({key}: { key: string }) => {
  const locale = key.toLowerCase();
  IntlModule.setLocale(locale as ELocale);
  isOpen.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    overlayClassName: _ctx.$style.langDropDown + (_ctx.overlayClassName ? ` ${_ctx.overlayClassName}` : ''),
    visible: isOpen.value
  }, {
    overlay: _withCtx(() => [
      _createVNode(_unref(Menu), {
        class: "menu",
        onClick: changeLocale
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(IntlModule).availableLanguages, (availableLanguage) => {
            return (_openBlock(), _createBlock(_unref(MenuItem), {
              key: availableLanguage,
              class: _normalizeClass({[_ctx.$style.langItem]: true, [_ctx.$style.langItemSelected]: availableLanguage === _unref(IntlModule).locale, [_ctx.itemClassName]: _ctx.itemClassName !== undefined })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(availableLanguage), 1)
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(MyVButton, {
        icon: "triangle",
        "data-testid": "lang-selector-button",
        class: _normalizeClass([_ctx.$style.lang + (_ctx.buttonClassName ? ` ${_ctx.buttonClassName}` : ''), (isOpen.value ? _ctx.$style.toggle : undefined)]),
        primary: false,
        onClick: toggleOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(intlModule.value.filteredLocale), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["overlayClassName", "visible"]))
}
}

})