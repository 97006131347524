import API from "@/helpers/api";

import {TPdlService} from "./interfaces";

const PdlService: TPdlService = {

    postInvoice: (siteId, file: FormData) => (
        API.post(`/api/site/${siteId}/pdl/invoice`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => data)
    ),
};

export default PdlService;
