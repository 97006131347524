import {TSite} from "@/services/Site/interfaces";

export type TContractTimePeriod = {
    /**
     * Begin of the time's period (HH:mm)
     */
    from: string;

    /**
     * End of the time's period (HH:mm)
     */
    to: string;
};

export enum EPdlStates {
    notValidated = 'NOT_VALIDATED',
    validated = 'VALIDATE',
    notEnedis = 'NOT_ENEDIS',
    withoutLinky = 'WITHOUT_LINKY',
}

export const MaxOffPeakHoursRuleInMinutes = 480;
export const MaxOffPeakHoursRuleInMinutesUK = 600;

export type TContractTimePeriods = TContractTimePeriod[];

export type TBaseContract = {
    /*
     * Contract subscribed's power
     */
    subscribedPower: number;

    /*
     * Contract kwh base subscription's price
     */
    kwhBasePrice: number | null;

    /*
     * Contract peak hour's price
     */
    kwhPeakHourPrice: number | null;

    /*
     * Contract offPeak hour's price
     */
    kwhOffpeakHourPrice: number | null;

    /*
     * Contract creation's date
     */
    creationDateTime: string | null;

    startDate: string | null;

    endDate: string | null;

    isPeakOffPeakContract: boolean | null;

    isDefault: boolean;
};

export type TContractEditable = TBaseContract & {
    /**
     * Contract's id
     */
    id: number | null;

    /*
     * Contract's name
     */
    name: string;

    /*
     * Contract base subscription's price
     */
    subscriptionBasePrice: number | null;

    /*
     * Contract peak offpeak subscription's price.
     */
    subscriptionPeakAndOffPeakHourBasePrice: number | null;

    /*
     * Contract company's name
     */
    companyName: string;

    /*
     * Contract site's Id
     */
    siteId: number;

    /**
     * id of the corresponding contract from selectra api, unused for now
     */
    apiContractId: number | null;

    /**
     * id of the corresponding company from selectra api, unused for now
     */
    companyId: number | null;

    /**
     * Array storing offpeak hours on the contract
     */
    offpeakHours: TContractTimePeriods;
};

export type TContract = TContractEditable & {
    /**
     * Array storing peak hours on the contract computed by api.
     */
    peakHours: TContractTimePeriods;
};

export type TProvider = {
    /**
     * Contract company's id
     */
    id: number | null;

    /**
     * Contract company's name
     */
    name: string;

    /**
     * Contract company (trade / business)'s name
     */
    businessName: string;
};

export type TProviders = TProvider[];

export type TPdlValidity = {
    pdlStatus: EPdlStates;
    hasUploadedElectricityBill: boolean;
}

export type TCompanyContract = TBaseContract & {
    /**
     * Company contract's id
     */
    id: number | null;

    /**
     * Company contract's name
     */
    name: string;

    /**
     * Company contract (business / trade)'s name
     */
    businessName: string;

    /**
     * Company contract base's price.
     */
    subscriptionBasePrice: number | null;

    /**
     * Company contract base's price for peak / offpeak hour contract.
     */
    kwhPeakHourPrice: number | null;

    kwhOffpeakHourPrice: number | null;

    /**
     * Company associate to the contract.
     */
    company: TProvider | null;

    /**
     * Is the contract active ?
     */
    active: boolean;

    subscribedPower: number;

    subscriptionPeakAndOffPeakHourBasePrice: number | null;

    /**
     * Check if the price list has bean automatically updated
     */
    priceListAutomaticallyUpdated: boolean;

    creationDateTime: string;

    kwhBasePrice: number;

    apiContractId: number;
};

export type TCompanyContracts = TCompanyContract[];

type TGetContractsList = (siteId: number) => Promise<TContract[]>;

type TPostContract = (siteId: TSite['id'], body: Omit<TContractEditable, 'id'>[]) => Promise<TContract>;

type TPutContract = (siteId: TSite['id'], body: Partial<TContractEditable>[]) => Promise<TContract>;

type TDeleteContract = (siteId: TSite['id']) => Promise<boolean>;

type TGetProviders = () => Promise<TProviders>

type TGetCompanyContracts = (companyId?: TProvider['id']) => Promise<TCompanyContracts>;

type TGetCompanyContract = (id: TCompanyContract['id']) => Promise<TCompanyContract>;

type TGetPdlValidity = (siteId: TSite['id']) => Promise<TPdlValidity>;

export type TContractService = {
    /**
     * Send a GET request to fetch the list of all the user site's contracts
     */
    getContractsList: TGetContractsList;

    /**
     * Send a POST request to created a new user site's contract.
     */
    postContract: TPostContract;

    /**
     * Send a PUT request to update the user site's contract.
     */
    putContract: TPutContract;

    /**
     * Send a DELETE request to remove the user contracts list.
     */
    deleteAllContracts: TDeleteContract;

    /**
     * Send a GET request to fetch all providers
     */
    getProviders: TGetProviders;

    /**
     * Send a GET request to retrieves all contracts of all companies or by company's id.
     */
    getCompanyContracts: TGetCompanyContracts;

    /**
     * Send a GET request to retrieves the company's contract by id.
     */
    getCompanyContract: TGetCompanyContract;

    /**
     * Send a GET request to check the pdl's validity
     */
    getPdlValidity: TGetPdlValidity;
};