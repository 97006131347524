import {TCookie, TCookieOptions} from "./interfaces";

class Cookie {
    public set(name: string, value: string, options: TCookieOptions = {}) {
        let cookie = `${name}=${value}`;

        if (options.expires) {
            let expires: Date | null = null;

            if (options.expires instanceof Date) {
                expires = options.expires;
            } else {
                switch (typeof options.expires) {
                    case "string":
                        expires = new Date(options.expires);
                        break;
                    case "number":
                        expires = new Date();
                        expires.setTime(expires.getTime() + options.expires);
                        break;
                }
            }

            if (expires) {
                cookie += `; expires=${expires.toUTCString()}`;
            }
        }

        if (!options.path) {
            options.path = "/";
        }

        for (const param in options) {
            if (param !== "expires") {
                const option = options[param as keyof TCookieOptions];

                if (typeof option === "string" || typeof option === "number") {
                    cookie += `; ${param}=${option}`;
                } else {
                    cookie += `; ${param}`;
                }
            }
        }

        document.cookie = cookie;
    }

    public getAll(): TCookie {
        const cookies: TCookie = [] as unknown as TCookie;

        if (document.cookie) {
            const decodedCookies = decodeURIComponent(document.cookie).split(";");

            for (const decodedCookie of decodedCookies) {
                const cookieSeparatorIndex = decodedCookie.indexOf("=");
                const cookieName = decodedCookie
                    .substr(0, cookieSeparatorIndex)
                    .trimStart();
                const cookieValue = decodedCookie.substr(cookieSeparatorIndex + 1);

                cookies[cookieName] = cookieValue;
            }
        }

        return cookies;
    }

    public get(name: string): string | null {
        return this.getAll()[name] || null;
    }

    public delete(name: string) {
        this.set(name, "", {expires: "01-01-1970"});
    }

    public isExpired(name: string) {
        const cookieValue = document.cookie
            .split('; ')
            .find((cookie) => cookie.startsWith(`${name}=`));

        if (!cookieValue) {
            return true;
        }

        const match = cookieValue.match(/expires=([^;]+)/);
        if (!match) {
            return false;
        }

        const expirationDate = new Date(match[1]);

        return expirationDate.getTime() <= Date.now();
    }

    public resetLocaleCookies() {
        if (document.cookie) {
            const decodedCookies = decodeURIComponent(document.cookie).split(";");

            for (const decodedCookie of decodedCookies) {
                const cookieSeparatorIndex = decodedCookie.indexOf("=");
                const cookieName = decodedCookie
                    .substr(0, cookieSeparatorIndex)
                    .trimStart();

                if (cookieName.includes('Locale') && cookieName !== '__locale') {
                    this.set(cookieName, 'false')
                }
            }
        }
    }

    public hasParticularLocale() {
        let hasParticularLocale = false;
        if (document.cookie) {
            const decodedCookies = decodeURIComponent(document.cookie).split(";");

            for (const decodedCookie of decodedCookies) {
                const cookieSeparatorIndex = decodedCookie.indexOf("=");
                const cookieName = decodedCookie
                    .substr(0, cookieSeparatorIndex)
                    .trimStart();

                if (cookieName.includes('Locale')) {
                    const cookieValue = decodedCookie.substr(cookieSeparatorIndex + 1);
                    hasParticularLocale = cookieValue === 'true';
                }
            }
        }

        return hasParticularLocale;
    }
}

export default new Cookie();
