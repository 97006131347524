import {useWindowSize} from "@vueuse/core";
import {computed} from "vue";
import {isWebViewMobileApp} from "@/helpers/domains/device";

export function useResponsive() {
    const {width} = useWindowSize()
    const isMobile = computed((): boolean => {
        return width.value <= 768
    })

    const isWebViewMobile = computed((): boolean => {
        return isWebViewMobileApp(window);
    })

    return {isMobile, isWebViewMobile}
}