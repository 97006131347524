import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "WelcomeConsoTooltip" }
const _hoisted_2 = {
  key: 0,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_6 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_7 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_8 = {
  key: 1,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_9 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_10 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_11 = {
  key: 2,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_12 = {
  key: 0,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_13 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_14 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_15 = {
  key: 1,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_16 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_17 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_18 = {
  key: 3,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_19 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 4,
  class: "WelcomeConsoTooltip-block"
}
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "WelcomeConsoTooltip-block__item" }
const _hoisted_34 = {
  key: 0,
  class: "WelcomeConsoTooltip-block__item"
}
const _hoisted_35 = {
  key: 1,
  class: "WelcomeConsoTooltip-block__item"
}
const _hoisted_36 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {AuthModule, ConsentModule, ContractModule, SiteModule} from "@/store";
import {TSite} from "@/services/Site/interfaces";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import ERoutes from "@/router/routes";
import {computed, onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import {EPdlStates} from "@/services/Contract/interfaces";
import {useResponsive} from "@/composables/useResponsive";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsoTooltip',
  emits: ['close', 'conso-tooltip::validate-pdl'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const router = useRouter()
const {isMobile} = useResponsive()
const isLoading = ref(true)

const user = computed(() => {
  return AuthModule.user;
})

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const hasNotConsentToEnedis = computed(() => {
  return ConsentModule.allConsentAreDisabled;
})

const isPdlValid = computed(() => {
  return ContractModule.isPdlValid;
})

const hasUploadedElectricityBill = computed(() => {
  return ContractModule.hasUploadedElectricityBill;
})

const pdlState = computed(() => {
  return ContractModule.pdlState;
})

const close = () => {
  emit("close")
}

const openPdlValidation = () => {
  emit("conso-tooltip::validate-pdl")
  emit("close")
}

const goToChoices = () => {
  return router.push({
    path: ERoutes.MANAGE_CHOICES,
    query: {
      scroll: '1'
    }
  });
}

onBeforeMount(async() => {
  if (isSiteCountryFrance.value) {
    await Promise.all([
    ConsentModule.getConsentEnedis(user.value),
    ContractModule.getPdlValidity(),
  ]).finally(() => isLoading.value = false)
  }
  isLoading.value = false
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isLoading.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
          key: 0,
          title: _ctx.$t('conso.tooltip.title'),
          type: "popin",
          onCancel: close,
          "return-action": close
        }, {
          default: _withCtx(() => [
            (currentSite.value.hasDsoMeasure)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", {
                    class: "WelcomeConsoTooltip-block__item",
                    innerHTML: _ctx.$t('conso.tooltip.general_measure')
                  }, null, 8, _hoisted_3)
                ]))
              : (pdlState.value === _unref(EPdlStates).withoutLinky)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (!currentSite.value.hasGlobalConsumptionMeasure)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('conso.tooltip.devices_measure')), 1),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('conso.tooltip.withoutLinky')), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('conso.tooltip.general_consumption_by_voltalis')), 1),
                          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('conso.tooltip.withoutLinky')), 1)
                        ]))
                  ]))
                : (pdlState.value === _unref(EPdlStates).notEnedis)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (!currentSite.value.hasGlobalConsumptionMeasure)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('conso.tooltip.devices_measure')), 1),
                            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('conso.tooltip.notEnedis')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('conso.tooltip.general_consumption_by_voltalis')), 1),
                            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('conso.tooltip.notEnedis')), 1)
                          ]))
                    ]))
                  : (!currentSite.value.hasDsoMeasure && currentSite.value.hasGlobalConsumptionMeasure)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", {
                            innerHTML: _ctx.$t('conso.tooltip.general_consumption_by_voltalis')
                          }, null, 8, _hoisted_20)
                        ]),
                        (isSiteCountryFrance.value)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (!hasUploadedElectricityBill.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("div", _hoisted_21, [
                                      _createElementVNode("h2", {
                                        innerHTML: _ctx.$t('conso.tooltip.display_history.title')
                                      }, null, 8, _hoisted_22),
                                      _createElementVNode("p", {
                                        innerHTML: _ctx.$t('conso.tooltip.display_history.description')
                                      }, null, 8, _hoisted_23)
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                      (hasNotConsentToEnedis.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('conso.tooltip.no_consent')), 1),
                                            _createVNode(MyVButton, { onClick: goToChoices }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.click_here')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true),
                                      (!isPdlValid.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                            _createElementVNode("div", {
                                              innerHTML: _ctx.$t('conso.tooltip.pdl_invalid')
                                            }, null, 8, _hoisted_27),
                                            _createVNode(MyVButton, { onClick: openPdlValidation }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.validate_pdl')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.validation.inProgress')), 1)
                                  ], 64))
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : (!currentSite.value.hasDsoMeasure && !currentSite.value.hasGlobalConsumptionMeasure)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          _createElementVNode("div", {
                            class: "WelcomeConsoTooltip-block__item",
                            innerHTML: _ctx.$t('conso.tooltip.devices_measure')
                          }, null, 8, _hoisted_29),
                          (isSiteCountryFrance.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (!hasUploadedElectricityBill.value)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createElementVNode("div", _hoisted_30, [
                                        _createElementVNode("h2", {
                                          innerHTML: _ctx.$t('conso.tooltip.general_consumption_follow.title')
                                        }, null, 8, _hoisted_31),
                                        _createElementVNode("p", {
                                          innerHTML: _ctx.$t('conso.tooltip.general_consumption_follow.description')
                                        }, null, 8, _hoisted_32)
                                      ]),
                                      _createElementVNode("div", _hoisted_33, [
                                        (hasNotConsentToEnedis.value)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                              _createElementVNode("div", null, _toDisplayString(_ctx.$t('conso.tooltip.no_consent')), 1),
                                              _createVNode(MyVButton, { onClick: goToChoices }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.click_here')), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]))
                                          : _createCommentVNode("", true),
                                        (!isPdlValid.value)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                              _createElementVNode("div", {
                                                innerHTML: _ctx.$t('conso.tooltip.pdl_invalid')
                                              }, null, 8, _hoisted_36),
                                              _createVNode(MyVButton, { onClick: openPdlValidation }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.validate_pdl')), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('conso.tooltip.validation.inProgress')), 1)
                                    ], 64))
                              ], 64))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
          ]),
          _: 1
        }, 40, ["title"]))
      : _createCommentVNode("", true)
  ]))
}
}

})