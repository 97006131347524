import {TTabHeatingModeItem} from "@/components/domains/Heating/MyVTabHeatingMode/interfaces";
import {EApplianceMode} from "@/services/Appliances/interfaces";

export const TabHeatingModeFakeDatas:  TTabHeatingModeItem[]  = [
  {
    icon: 'colored-sun',
    iconActive: 'sun',
    title: 'mode.confort',
    slug: EApplianceMode.Comfort
  },
  {
    icon: 'colored-confort',
    iconActive: 'confort',
    title: 'mode.confort1',
    slug: EApplianceMode.Comfort1
  },
  {
    icon: 'colored-confort',
    iconActive: 'confort',
    title: 'mode.confort2',
    slug: EApplianceMode.Comfort2
  },
  {
    icon: 'colored-sun',
    iconActive: 'sun',
    title: 'mode.normal',
    slug: EApplianceMode.Normal,
  },
  {
    icon: 'colored-moon',
    iconActive: 'moon',
    title: 'mode.eco',
    slug: EApplianceMode.Eco,
  },
  {
    icon: 'colored-moon',
    iconActive: 'moon',
    title: 'mode.ecov',
    slug: EApplianceMode.EcoV,
  },
  {
    icon: 'colored-snow',
    iconActive: 'snow1',
    title: 'mode.frostProtection',
    slug: EApplianceMode.FrostProtection,
  },
  {
    icon: 'colored-settings',
    iconActive: 'settings',
    title: 'mode.temperature',
    slug: EApplianceMode.Temp,
  },
];