import {TDisplayGroup} from "../DisplayGroup/interfaces";
import {TSite} from "../Site/interfaces";

export type TPhones = {
  /**
   * Phone number
   */
  phoneNumber: string | null;

  /**
   * Phone type.
   */
  phoneType: string | null;
};

export type TUser = {
  /**
   * User's id
   */
  id: number;

  /**
   * User's email.
   */
  email: string;

  /**
   * User's firstname
   */
  firstname: string;

  /**
   * User's lastname
   */
  lastname: string;

  /**
   * Is the first time user connect to MyVoltalis ?
   */
  firstConnection: boolean;

  /**
   * User phone's numbers
   */
  phones: TPhones[];

  /**
   * User's default site.
   */
  defaultSite: TSite;

  /**
   * User's rights
   */
  displayGroup: TDisplayGroup;

  migratedUser: boolean;

  otherSites: TSite[];
};

export enum EAuthRoute {
  /**
   *APIremote server login route.
   * This route must be a POST methods getting in parameters ELoginFields
   */
  LOGIN = "/auth/login",

  /**
   *APIremote server token route.
   * This route must be a GET methods
   */
  LOGIN_FROM_COOKIE = "/api/account/me",

  /**
   *APIremote server logout route.
   * This route must be a DELETE methods
   */
  LOGOUT = "/auth/logout",

  /**
   *APIremote server edit user route.
   * This route must be a PUT methods
   */
  EDIT_USER = "/api/account/me",

  /**
   *APIremote server change password route.
   * This route must be a PUT methods
   */
  CHANGE_PASSWORD = '/auth/password/change',

  /**
   *APIremote server change password route.
   * This route must be a PUT methods
   */
  REQUEST_NEW_PASSWORD = '/auth/password/forgot',

  /**
   *APIremote server check token from lost password
   * This route must be a POST method
   */
  NEW_PASSWORD_CHECK_TOKEN = '/auth/password/token',

  /**
   *APIremote server send new password
   * This route must be a POST method
   */
  NEW_PASSWORD_UPDATE = '/auth/password/create',

  /**
   *APIremote server check user token validity
   * This route must be a POST method
   */
  ONBOARDING_CHECK_TOKEN_VALIDITY = '/onboarding/token',

  /**
   *APIremote server send password created during the onboarding
   * This route must be a POST method
   */
  ONBOARDING_CREATE_PASSWORD = '/onboarding/password',
}

export enum ELoginFields {
  /**
   * Username's field of login route.
   */
  USERNAME = "login",

  /**
   * Password's field of login route.
   */
  PASSWORD = "password",
}

export type TLoginResponse = {
  /**
   * User's token.
   */
  token: string;

  /**
   * Old My Voltalis URL
   */
  oldMyVoltalisUrl: string
};

export type TLoginCallback = (
  username: string,
  password: string
) => Promise<TUser>;

export type TEditUserParams = {
  email: string,
  phones: TPhones[]
}

export type TRequestNewPasswordParams = {
  /**
   * User's email where to send the change password token. 
   */
  email: TUser['email'];
};

export type TCheckTokenValidityParams = {
  /**
   * Given token from the mail
   */
  token: string;

  /**
   * Subscriber id
   */
  subscriberId: number
}

export type TNewPasswordUpdateParams = {
  /**
   * New password
   */
  newPassword: string

  /**
   * Given token from the mail
   */
  token: string;

  /**
   * Subscriber id
   */
  subscriberId: number
}

export type TCreateOnboardingPasswordParams = {
  /**
   * Created password during the onboarding
   */
  newPassword: string

  /**
   * Given token from the mail
   */
  token: string;

  /**
   * Subscriber id
   */
  subscriberId: number
}

export type TChangePasswordParams = {
  /**
   * Define if all token must be revoked.
   */
  invalidateTokens?: boolean;

  /**
   * New user's password.
   */
  newPassword: string;

  /**
   * Current user's password.
   */
  password: string;
}

export type TEditUserCallback = (
  body: TEditUserParams
) => Promise<TUser>;

export type TChangePasswordCallback = (
  body: TChangePasswordParams
) => Promise<void>;

export type TRequestNewPasswordCallback = (
  body: TRequestNewPasswordParams
) => Promise<void>;

export type TCheckTokenValidity = (body: TCheckTokenValidityParams) => Promise<TNewPasswordResponse>

export type TNewPasswordUpdate = (body: TNewPasswordUpdateParams) => Promise<TNewPasswordResponse>

export type TNewPasswordResponse = {
  oldMyVoltalisUrl: string | null;
  token: string
}

export type TCreateOnboardingPassword = (body: TCreateOnboardingPasswordParams) => Promise<TNewPasswordResponse>;

export type TLoginFromCookieCallback = (token: string) => Promise<TUser>;

type TLogoutCallback = () => Promise<void>;

export type TAuthService = {
  /**
   * Send a POST request to EAuthRoute.LOGIN route to log in a user into the app from user's credentials
   */
  login: TLoginCallback;

  /**
   * Send a POST request to EAuthRoute.LOGIN_FROM_COOKIE route to log in a user into the app from token stored on cookie.
   */
  loginFromCookie: TLoginFromCookieCallback;

  /**
   * Remove cookie that store token and forget credential on axios headers.
   */
  logout: TLogoutCallback;

  /**
   * Send a PUT request in order to edit the data of the user (phones & email for now)
   */
  editUser: TEditUserCallback;

  /**
   * Send a POST request in order to send an email to change password
   */
  requestNewPassword: TRequestNewPasswordCallback;

  /**
   * Send a POST request in order to check the user token validity for account creation
   */
  checkOnboardingTokenValidity: TCheckTokenValidity

  /**
   * Send a POST request in order to send the fresh password from onboarding
   */
  createOnboardingPassword: TCreateOnboardingPassword

  /**
   * Send a POST request in order to check token validity from lost password form
   */
  checkNewPasswordTokenValidity: TCheckTokenValidity

  newPasswordUpdate: TNewPasswordUpdate
  /**
   * Send a PUT request in order to update password
   */
  changePassword: TChangePasswordCallback;
};
