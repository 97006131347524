import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "HeaderMainPage__breadcrumb-link-text" }
const _hoisted_2 = { class: "ConsoDetail__header" }
const _hoisted_3 = { class: "ConsoDetail__header__filters" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "isComparing"
}
const _hoisted_7 = { class: "isComparing__explanation" }
const _hoisted_8 = { class: "ConsoDetail__specificRow" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "currentPeriodText" }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = {
  key: 1,
  class: "ConsoDetail__specificLabel"
}
const _hoisted_13 = { class: "search" }
const _hoisted_14 = { class: "ConsoDetail__specificLabel-text" }
const _hoisted_15 = { class: "ConsoDetail__BarLine" }

import {
  aggregatedDayFormat,
  aggregatedMonthFormat,
  aggregatedWeekFormat,
  aggregatedYearFormat,
  EAggregationAlias,
  EAggregationType,
  labelMonthsFormat,
  maxCurrencyConsoByDay,
  maxCurrencyConsoByDayAppliance,
  maxCurrencyConsoByHour,
  maxCurrencyConsoByHourAppliance,
  maxCurrencyConsoByMonth,
  maxCurrencyConsoByMonthAppliance,
  maxCurrencyConsoByYear,
  maxCurrencyConsoByYearAppliance,
  maxKwhConsoByDay,
  maxKwhConsoByDayAppliance,
  maxKwhConsoByHour,
  maxKwhConsoByHourAppliance,
  maxKwhConsoByMonth,
  maxKwhConsoByMonthAppliance,
  maxKwhConsoByYear,
  maxKwhConsoByYearAppliance,
  TConsoStep, TConsumption, TConsumptionFullData,
  weekYearFormat
} from "@/services/Conso/interfaces";
import MyVBarLineGraphic from "@/components/ui/atoms/graphics/MyVBarLineGraphic/MyVBarLineGraphic.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';
import {
  AppliancesModule,
  AuthModule,
  ConsoModule,
  ContractModule,
  IntlModule,
  RequestLoaderModule,
  RouterModule,
  SiteModule,
  SwitchConsoModule
} from "@/store";
import {EBarLineGraphIds, EConsoCompare} from "@/views/domains/Conso/ConsoDetail/interfaces";
import {EAxis, TBarLineGraphic} from "@/components/ui/atoms/graphics/MyVBarLineGraphic/interfaces";
import ERoutes from "@/router/routes";

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import MyVAntddatepicker from "@/components/ui/molecules/MyVAntddatepicker/MyVAntddatepicker.vue";
import ConsoTooltip from "@/components/domains/Conso/MyVConsoTooltip/MyVConsoTooltip.vue";
import {ConfigProvider, Dropdown, Menu} from "ant-design-vue";

import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import {ELocale} from "@/store/modules/Intl";
import frFr from "ant-design-vue/lib/locale/fr_FR";
import enUs from "ant-design-vue/lib/locale/en_US";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {TSite} from "@/services/Site/interfaces";
import {useRoute, useRouter} from "vue-router";
import {getContractFromDate} from "@/helpers/domains/conso";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVPageConso from "@/components/ui/organisms/layouts/MyVPageConso/MyVPageConso.vue";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {
  getAppliancesConsumptionsSum,
  getOtherConsumptionsValues
} from "@/helpers/domains/consoDetailAppliancesSelector";
import {useResponsive} from "@/composables/useResponsive";
import {DateUtils, useDate} from "@/helpers/dates/date-utils";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";
import {Moment} from "moment";
import useCurrency from "@/composables/currency";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConsoDetailView',
  setup(__props) {

const comparingMode = ref(EConsoCompare.NONE);
const barThickness = ref(16);
const nbRenderedItems = ref(4);
const showConsoInfoPopin = ref(false);
const dropdownVisible_aggregations = ref(false);
const dropdownVisible_appliances = ref(false);
const selectedApplianceId = ref(0);
const consoDate = ref<string | undefined>(undefined);
const consoTypeKey = ref(0)
const globalPreviousConsumption = ref<TConsumption[]>()

const route = useRoute();
const router = useRouter();
const {t} = useI18n()
const {isMobile} = useResponsive()
const {currency} = useCurrency()

const backLink = computed(() => {
  return () => RouterModule.goBack({
    path: '/conso',
    query: {date: route.params.date, type: route.query.type}
  });
})

const currentType = computed(() => {
  return route.query.type as string;
})

const isYearOrHourView = computed(() => {
  return [ERoutes.CONSO_YEAR, ERoutes.CONSO_DAY].includes(currentType.value as ERoutes);
})

const isYearView = computed(() => {
  return (currentType.value as ERoutes) === ERoutes.CONSO_YEAR;
})

const shouldRenderTempLine = computed(() => {
  return AuthModule.user.displayGroup.rights.find(({name}) => (
      name === EDisplayGroupRightName.TEMPERATURE_EXT
  ))?.enabled ?? false;
})

const locale = computed(() => {
  return IntlModule.locale;
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType;
})

const selectedAppliance = computed(() => {
  return appliancesOptions.value.find(appliance => appliance.id === selectedApplianceId.value)
})

const currentLink = computed(() => {
  const type = route.query.type;

  return t(`conso.detail.view.${type}`);
})

const currentPeriodText = computed(() => {
  const formattedStartDate = useDate(chartData.value?.stepTimestampOnSite[0]).format(viewType.value.infoText);
  const formattedEndDate = useDate(chartData.value?.stepTimestampOnSite[chartData.value?.stepTimestampOnSite.length - 1]).format(viewType.value.infoText);
  const formattedConsoDate = useDate(consoDate.value).locale(locale.value).format(viewType.value.infoText);

  if (viewType.value.aggregation === EAggregationType.Hour) {
    return formattedConsoDate
  }

  if (isYearView.value || viewType.value.aggregation === EAggregationType.Month) {
    return t('conso_detail.period2', {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
  }

  return t('conso_detail.period', {
    startDate: formattedStartDate,
    endDate: formattedEndDate
  });
})

const viewType = computed(() => {
  switch (currentType.value) {
    case ERoutes.CONSO_DAY:
      return ({
        label: aggregatedDayFormat,
        value: aggregatedDayFormat,
        routeFormat: aggregatedWeekFormat,
        type: ERoutes.CONSO_WEEK,
        aggregation: EAggregationType.Hour,
        alias: EAggregationAlias.Day,
        format: 'H[h]',
        diff: (a: DateUtils, b: DateUtils) => a.startOf('hour').diff(b.startOf('hour').toDate(), 'hour'),
        dateMoveLeft: (currentDate: DateUtils) => currentDate.substract(nbRenderedItems.value, 'hour'),
        dateMoveRight: (currentDate: DateUtils) => currentDate.add(nbRenderedItems.value, 'hour'),
        maxConsoValue: selectedApplianceId.value != 0 ? consoType.value ? maxKwhConsoByHourAppliance : maxCurrencyConsoByHourAppliance : consoType.value ? maxKwhConsoByHour : maxCurrencyConsoByHour,
        infoText: 'dddd DD MMM YYYY',
        nbDiff: 12,
        startOfType: 'hour',
      });
    case ERoutes.CONSO_WEEK:
      return ({
        label: weekYearFormat,
        value: aggregatedWeekFormat,
        routeFormat: aggregatedMonthFormat,
        type: ERoutes.CONSO_MONTH,
        aggregation: EAggregationType.Day,
        format: "DD/MM/YY",
        alias: EAggregationAlias.Month,
        dateMoveLeft: (currentDate: DateUtils) => currentDate.substract(nbRenderedItems.value, 'd'),
        dateMoveRight: (currentDate: DateUtils) => currentDate.add(nbRenderedItems.value, 'd'),
        diff: (a: DateUtils, b: DateUtils) => a.startOf('d').diff(b.startOf('d').toDate(), 'd'),
        compare: (selectedDate: DateUtils, consoDate: DateUtils) => selectedDate.startOf('d').diff(consoDate.startOf('d').toDate(), 'd') === 0,
        compareLabel: () => {
          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
              return 'conso.detailled.compare_to.week.last_period';
          }
          return '';
        },
        getCompareDate: (date: DateUtils) => {
          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
              return date.substract(1, 'month');
          }
          return undefined;
        },
        associateCompareValues: (date: DateUtils, allDatas: TConsoStep[]): TConsoStep => {
          let compareUnit = undefined;
          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
              compareUnit = 'week';
              break;
          }
          if (compareUnit) {
            date.substract(1, compareUnit);
          }
          return allDatas.find(({stepTimestampOnSite}) => useDate(stepTimestampOnSite).diff(date.toDate(), 'day') === 0) || {} as TConsoStep;
        },
        maxConsoValue: selectedApplianceId.value != 0 ? consoType.value ? maxKwhConsoByDayAppliance : maxCurrencyConsoByDayAppliance : consoType.value ? maxKwhConsoByDay : maxCurrencyConsoByDay,
        infoText: 'DD/MM/YY',
        nbDiff: 7,
        startOfType: 'd',
      });
    case ERoutes.CONSO_MONTH:
    default:
      return ({
        label: labelMonthsFormat,
        value: aggregatedMonthFormat,
        routeFormat: aggregatedYearFormat,
        type: ERoutes.CONSO_YEAR,
        aggregation: EAggregationType.Month,
        format: "MMMM YYYY",
        alias: EAggregationAlias.Year,
        dateMoveLeft: (currentDate: DateUtils) => currentDate.substract(nbRenderedItems.value, 'M'),
        dateMoveRight: (currentDate: DateUtils) => currentDate.add(nbRenderedItems.value, 'M'),
        diff: (a: DateUtils, b: DateUtils) => a.startOf('M').diff(b.startOf('M').toDate(), 'M'),
        compare: (selectedDate: DateUtils, consoDate: DateUtils) => selectedDate.startOf('M').diff(consoDate.startOf('M').toDate(), 'M') === 0,
        compareLabel: () => {
          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
              return 'conso.detailled.compare_to.year.last_period';
            case EConsoCompare.LAST_YEAR:
              return 'conso.detailled.compare_to.year.last_year';
          }
          return '';
        },
        getCompareDate: (date: DateUtils) => {
          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
            case EConsoCompare.LAST_YEAR:
              return date.substract(1, 'years');
          }
          return undefined;
        },
        associateCompareValues: (date: DateUtils, allDatas: TConsoStep[]): TConsoStep => {
          let compareUnit;

          switch (comparingMode.value) {
            case EConsoCompare.LAST_PERIOD:
              compareUnit = 'month';
              break;
            case EConsoCompare.LAST_YEAR:
              compareUnit = 'year';
              break;
          }
          if (compareUnit) {
            date.substract(1, compareUnit);
          }
          return allDatas.find(({stepTimestampOnSite}) => useDate(stepTimestampOnSite).diff(date.toDate(), 'month') === 0) || {} as TConsoStep;
        },
        maxConsoValue: selectedApplianceId.value != 0 ? consoType.value ? maxKwhConsoByMonthAppliance : maxCurrencyConsoByMonthAppliance : consoType.value ? maxKwhConsoByMonth : maxCurrencyConsoByMonth,
        infoText: 'MMMM YYYY',
        nbDiff: 6,
        startOfType: 'M',
      });
    case ERoutes.CONSO_YEAR:
      return ({
        label: aggregatedYearFormat,
        value: aggregatedYearFormat,
        routeFormat: aggregatedYearFormat,
        type: ERoutes.CONSO_YEAR,
        aggregation: EAggregationType.Year,
        format: "YYYY",
        alias: EAggregationAlias.Years,
        dateMoveLeft: (currentDate: DateUtils) => currentDate.substract(nbRenderedItems.value, 'y'),
        dateMoveRight: (currentDate: DateUtils) => currentDate.add(nbRenderedItems.value, 'y'),
        diff: (a: DateUtils, b: DateUtils) => a.startOf('y').diff(b.startOf('y').toDate(), 'y'),
        maxConsoValue: selectedApplianceId.value != 0 ? consoType.value ? maxKwhConsoByYearAppliance : maxCurrencyConsoByYearAppliance : consoType.value ? maxKwhConsoByYear : maxCurrencyConsoByYear,
        infoText: 'YYYY',
        nbDiff: 1,
        startOfType: 'y',
      });
  }
})


const currentDate = computed(() => {
  const date = route.params.date ? useDate(route.params.date as string) : useDate();
  const type = viewType.value;

  return {
    label: date.locale(locale.value).format(type.label),
    value: date.locale(locale.value).format(type.value)
  }
})

const specificLabel = computed(() => {
  return t('conso.detail.select.specific.' + currentType.value);
})

const comparisonText = computed(() => {
  switch (route.query?.type) {
    case ERoutes.CONSO_DAY:
      return t('conso.detailled.compare_to.previous_day')
    case ERoutes.CONSO_WEEK:
      return isMobile.value ? t('conso.detailled.compare_to.previous_week') : t('conso.detailled.compare_to.previous_month')
    case ERoutes.CONSO_MONTH:
      return t('conso.detailled.compare_to.previous_year')
    default:
      return t('conso.compared.last_period')
  }
})

const links = computed(() => {
  return [
    {
      value: ERoutes.CONSO_DAY,
      label: t('conso.detail.view.day'),
      url: {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_DETAIL}/${route.params.date}`,
        query: {type: 'day'}
      },
    },
    {
      value: ERoutes.CONSO_WEEK,
      label: t('conso.detail.view.week'),
      url: {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_DETAIL}/${route.params.date}`,
        query: {type: 'week'}
      },
    },
    {
      value: ERoutes.CONSO_MONTH,
      label: t('conso.detail.view.month'),
      url: {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_DETAIL}/${route.params.date}`,
        query: {type: 'month'}
      },
    },
    {
      value: ERoutes.CONSO_YEAR,
      label: t('conso.detail.view.year'),
      url: {
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_DETAIL}/${route.params.date}`,
        query: {type: 'year'}
      },
    },
  ];
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const isLoading = computed(() => {
  return RequestLoaderModule.loading;
})

const consumptionPerAppliance = computed(() => {
  return ConsoModule.consumptionFullData.responses[isYearView.value ? EAggregationAlias.Years : consoDateFormatted.value];
})

const nextConsumptionPerAppliance = computed(() => {
  let diffDateAmount = 1;
  let aggregationDiff = 'y';
  if (viewType.value.aggregation === EAggregationType.Hour) {
    diffDateAmount = 12;
    aggregationDiff = 'hours';
  } else if (viewType.value.aggregation === EAggregationType.Day) {
    diffDateAmount = 1;
    aggregationDiff = 'month';
  }

  return ConsoModule.consumptionFullData.responses[useDate(consoDate.value).add(diffDateAmount, aggregationDiff).format(viewType.value.routeFormat)];
})

const previousConsumptionPerAppliance = computed(() => {
  let diffDateAmount = 1;
  let aggregationDiff = 'y';

  if (viewType.value.aggregation === EAggregationType.Hour) {
    diffDateAmount = 12;
    aggregationDiff = 'hours';
  } else if (viewType.value.aggregation === EAggregationType.Day) {
    diffDateAmount = 1;
    aggregationDiff = 'month';
  }

  return ConsoModule.consumptionFullData.responses[useDate(consoDate.value).substract(diffDateAmount, aggregationDiff).format(viewType.value.routeFormat)];
})

const appliancesId = computed(() => {
  return consumptionPerAppliance.value!.perAppliance;
})

const buildComsumptions = () => {
  let _consumptions: TConsumption[];
  let _nextConsumptions: TConsumption[];
  let _previousConsumptions: TConsumption[];

  if (selectedApplianceId.value == 0) {
    _consumptions = consumptionPerAppliance.value!.dataPoints ?? [];
    _nextConsumptions = nextConsumptionPerAppliance.value?.dataPoints ?? [];
    _previousConsumptions = previousConsumptionPerAppliance.value?.dataPoints ?? [];
  } else if (selectedApplianceId.value == 1 || selectedApplianceId.value == 2) {
    _consumptions = getAppliancesConsumptionsSum(appliancesId.value, consumptionPerAppliance.value);
    _nextConsumptions = getAppliancesConsumptionsSum(appliancesId.value, nextConsumptionPerAppliance.value);
    _previousConsumptions = getAppliancesConsumptionsSum(appliancesId.value, previousConsumptionPerAppliance.value);

    if (selectedApplianceId.value == 2) {
      _consumptions = getOtherConsumptionsValues(_consumptions, consumptionPerAppliance.value?.dataPoints ?? []);
      _previousConsumptions = getOtherConsumptionsValues(_previousConsumptions, previousConsumptionPerAppliance.value?.dataPoints ?? []);
      _nextConsumptions = getOtherConsumptionsValues(_nextConsumptions, nextConsumptionPerAppliance.value?.dataPoints ?? []);
    }
  } else {
    _consumptions = consumptionPerAppliance.value!.perAppliance[selectedApplianceId.value];
    _nextConsumptions = nextConsumptionPerAppliance.value?.perAppliance[selectedApplianceId.value] ?? [];
    _previousConsumptions = previousConsumptionPerAppliance.value?.perAppliance[selectedApplianceId.value] ?? []
  }

  let consumptions = _consumptions;
  let nextConsumptions = _nextConsumptions;
  let previousConsumptions = globalPreviousConsumption.value = _previousConsumptions;

  if (viewType.value.aggregation === EAggregationType.Month || viewType.value.aggregation === EAggregationType.Day) {
    consumptions = _consumptions?.filter(conso => useDate(conso.stepTimestampOnSite).startOf(viewType.value.startOfType).isSameOrBefore(useDate(consoDate.value).startOf(viewType.value.startOfType).toDate())).reverse() ?? null;
    nextConsumptions = _nextConsumptions?.filter(conso => useDate(conso.stepTimestampOnSite).startOf(viewType.value.startOfType).isSameOrBefore(useDate(consoDate.value).startOf(viewType.value.startOfType).toDate()));
    previousConsumptions = _previousConsumptions?.filter(conso => useDate(conso.stepTimestampOnSite).startOf(viewType.value.startOfType).isSameOrBefore(useDate(consoDate.value).startOf(viewType.value.startOfType).toDate())).reverse()
  }
  return {consumptions, nextConsumptions, previousConsumptions};
}

const rightAxisMin = computed(() => {
  const graph = chartData.value
  const datas = graph?.lines ? graph.lines[0].datas : [];
  return datas.length ? Math.min(...datas) - 2 : 0;
});

const rightAxisMax = computed(() => {
  const graph = chartData.value;
  const datas = graph?.lines ? graph.lines[0].datas : [];
  return datas.length ? Math.max(...datas) + 2 : undefined;
});

const chartData = computed(() => {
  if (!consumptionPerAppliance.value) return null;
  const {consumptions, nextConsumptions, previousConsumptions} = buildComsumptions();

  const labels: string[] = [];
  const dates: number[] = [];
  const offPeakHourConsumptionInWh: number[] | undefined = [];
  const offPeakHourConsumptionInCurrency: number[] | undefined = [];
  const peakHourConsumptionInWh: number[] = [];
  const peakHourConsumptionInCurrency: number[] = [];
  const temperatureInCelsiusDegree: number[] = [];
  const stepTimestampOnSite: string[] = [];

  const compareOffPeakHourConsumptionWh: number[] | undefined = [];
  const comparePeakHourConsumptionWh: number[] | undefined = [];
  const compareOffPeakHourConsumptionCurrency: number[] | undefined = [];
  const comparePeakHourConsumptionCurrency: number[] | undefined = [];
  const compareTemperatureInCelsiusDegree: number[] | undefined = [];
  const compareStepTimestampOnSite: string[] = [];
  if (!isYearOrHourView.value) {
    consumptions?.forEach(consu => {
      if (labels.length >= nbRenderedItems.value) return;
      labels.unshift(useDate(consu.stepTimestampOnSite).format(viewType.value.format));
      stepTimestampOnSite.unshift(consu.stepTimestampOnSite!);
      dates.unshift(useDate(consu.stepTimestampOnSite).unix());
      offPeakHourConsumptionInWh.unshift(consu.offPeakHourConsumptionInWh || 0);
      offPeakHourConsumptionInCurrency.unshift(consu.offPeakHourConsumptionInCurrency || 0);
      peakHourConsumptionInWh.unshift(consu.peakHourConsumptionInWh || 0);
      peakHourConsumptionInCurrency.unshift(consu.peakHourConsumptionInCurrency || 0);
      if (consu.temperatureInCelsiusDegree !== null) temperatureInCelsiusDegree.unshift(consu.temperatureInCelsiusDegree);
    });
  } else {
    consumptions?.forEach(consu => {
      if (labels.length >= nbRenderedItems.value) return;
      labels.push(useDate(consu.stepTimestampOnSite).format(viewType.value.format));
      stepTimestampOnSite.push(consu.stepTimestampOnSite!);
      dates.push(useDate(consu.stepTimestampOnSite).unix());
      offPeakHourConsumptionInWh.push(consu.offPeakHourConsumptionInWh || 0);
      offPeakHourConsumptionInCurrency.push(consu.offPeakHourConsumptionInCurrency || 0);
      peakHourConsumptionInWh.push(consu.peakHourConsumptionInWh || 0);
      peakHourConsumptionInCurrency.push(consu.peakHourConsumptionInCurrency || 0);
      if (consu.temperatureInCelsiusDegree !== null) temperatureInCelsiusDegree.push(consu.temperatureInCelsiusDegree);
    });
  }

  if (labels.length < nbRenderedItems.value && !isYearView.value && nextConsumptions?.length) {
    nextConsumptions.filter(conso => useDate(conso.stepTimestampOnSite).isSameOrAfter(consoDate.value)).forEach(consu => {
      if (labels.length >= nbRenderedItems.value) return;
      if (!stepTimestampOnSite.includes(consu.stepTimestampOnSite!)) {
        labels.push(useDate(consu.stepTimestampOnSite).format(viewType.value.format));
        stepTimestampOnSite.push(consu.stepTimestampOnSite!);
        dates.push(useDate(consu.stepTimestampOnSite).unix());
        offPeakHourConsumptionInWh.push(consu.offPeakHourConsumptionInWh || 0);
        offPeakHourConsumptionInCurrency.push(consu.offPeakHourConsumptionInCurrency || 0);
        peakHourConsumptionInWh.push(consu.peakHourConsumptionInWh || 0);
        peakHourConsumptionInCurrency.push(consu.peakHourConsumptionInCurrency || 0);
        if (consu.temperatureInCelsiusDegree !== null) temperatureInCelsiusDegree.push(consu.temperatureInCelsiusDegree);
      }
    });
  } else if (labels.length < nbRenderedItems.value && previousConsumptions?.length && !isYearView.value) {
    previousConsumptions.forEach(consu => {
      if (labels.length >= nbRenderedItems.value) return;
      if (!stepTimestampOnSite.includes(consu.stepTimestampOnSite!)) {
        labels.unshift(useDate(consu.stepTimestampOnSite).format(viewType.value.format));
        stepTimestampOnSite.unshift(consu.stepTimestampOnSite!);
        dates.unshift(useDate(consu.stepTimestampOnSite).unix());
        offPeakHourConsumptionInWh.unshift(consu.offPeakHourConsumptionInWh || 0);
        offPeakHourConsumptionInCurrency.unshift(consu.offPeakHourConsumptionInCurrency || 0);
        peakHourConsumptionInWh.unshift(consu.peakHourConsumptionInWh || 0);
        peakHourConsumptionInCurrency.unshift(consu.peakHourConsumptionInCurrency || 0);
        if (consu.temperatureInCelsiusDegree !== null) temperatureInCelsiusDegree.unshift(consu.temperatureInCelsiusDegree);
      }
    });
  }

  if (comparingMode.value !== EConsoCompare.NONE) {
    stepTimestampOnSite.forEach(time => {
      const timeRouteFormat = viewType.value.dateMoveLeft(useDate(time)).format(viewType.value.routeFormat);

      const consoPerAppliance: TConsumptionFullData | undefined = ConsoModule.consumptionFullData.responses[timeRouteFormat];
      const compareDatas = selectedApplianceId.value == 1 ? consumptions : selectedApplianceId.value != 0 ? consoPerAppliance?.perAppliance[selectedApplianceId.value] : consoPerAppliance?.dataPoints ?? [];

      const leftData = compareDatas?.find(data => useDate(data.stepTimestampOnSite).startOf(viewType.value.startOfType).isSame(viewType.value.dateMoveLeft(useDate(time)).startOf(viewType.value.startOfType).toDate()));

      if (leftData && viewType.value.aggregation === EAggregationType.Hour) {
        compareStepTimestampOnSite.push(leftData.stepTimestampOnSite!);
        compareOffPeakHourConsumptionWh.push(leftData.offPeakHourConsumptionInWh || 0);
        compareOffPeakHourConsumptionCurrency.push(leftData.offPeakHourConsumptionInCurrency || 0);
        comparePeakHourConsumptionWh.push(leftData.peakHourConsumptionInWh || 0);
        comparePeakHourConsumptionCurrency.push(leftData.peakHourConsumptionInCurrency || 0);
        if (leftData.temperatureInCelsiusDegree !== null) compareTemperatureInCelsiusDegree.push(leftData.temperatureInCelsiusDegree);

      } else if (leftData && !compareStepTimestampOnSite.includes(leftData.stepTimestampOnSite!)) {
        compareStepTimestampOnSite.push(leftData.stepTimestampOnSite!);
        compareOffPeakHourConsumptionWh.push(leftData.offPeakHourConsumptionInWh || 0);
        compareOffPeakHourConsumptionCurrency.push(leftData.offPeakHourConsumptionInCurrency || 0);
        comparePeakHourConsumptionWh.push(leftData.peakHourConsumptionInWh || 0);
        comparePeakHourConsumptionCurrency.push(leftData.peakHourConsumptionInCurrency || 0);
        if (leftData.temperatureInCelsiusDegree !== null) compareTemperatureInCelsiusDegree.push(leftData.temperatureInCelsiusDegree);
      }
    });
  }

  const lines: TBarLineGraphic['lines'] = shouldRenderTempLine.value ? [
    {
      id: EBarLineGraphIds.temperature,
      label: t('conso.chart.label.temperature'),
      datas: temperatureInCelsiusDegree.map((t) => Math.round(t)),
      color: "rgb(255, 78, 22)",
      baseAxis: EAxis.RIGHT,
      associatedLegends: [EBarLineGraphIds.compare_temperature]
    },
  ] : [];
  if (comparingMode.value !== EConsoCompare.NONE) {
    lines.push({
      id: EBarLineGraphIds.compare_temperature,
      label: t('conso.chart.label.temperature'),
      datas: compareTemperatureInCelsiusDegree!,
      color: "rgb(255, 78, 22, .4)",
      baseAxis: EAxis.RIGHT,
      hideLegend: true,
    })
  }
  let bars: TBarLineGraphic['bars'] = [
    {
      id: EBarLineGraphIds.compare_offpeak_hour,
      label: t('conso.chart.label.offpeak_hour_old'),
      datas: comparingMode.value !== EConsoCompare.NONE ? (consoType.value ? compareOffPeakHourConsumptionWh!.map(fromWhToKwh) : compareOffPeakHourConsumptionCurrency!) : [],
      color: "rgb(151, 190, 13, .4)",
      stack: 'old',
      hideLegend: true,
      renderTotal: false
    },
    {
      id: EBarLineGraphIds.compare_peak_hour,
      label: t('conso.chart.label.peak_hour_old'),
      datas: comparingMode.value !== EConsoCompare.NONE ? (consoType.value ? comparePeakHourConsumptionWh!.map(fromWhToKwh) : comparePeakHourConsumptionCurrency!) : [],
      color: "rgb(77, 190, 247, .4)",
      stack: 'old',
      hideLegend: true,
      renderTotal: false
    },
    {
      id: EBarLineGraphIds.offpeak_hour,
      label: t('conso.chart.label.offpeak_hour'),
      datas: consoType.value ? offPeakHourConsumptionInWh!.map(fromWhToKwh) : offPeakHourConsumptionInCurrency!,
      color: "rgb(151, 190, 13)",
      associatedLegends: [EBarLineGraphIds.compare_offpeak_hour],
      renderTotal: true,
      hideLegend: !contract.value?.offpeakHours.length
    },
    {
      id: EBarLineGraphIds.peak_hour,
      label: !contract.value?.offpeakHours.length ? t('conso.chart.consumption') : t('conso.chart.label.peak_hour'),
      datas: consoType.value ? peakHourConsumptionInWh.map(fromWhToKwh) : peakHourConsumptionInCurrency,
      color: "rgb(77, 190, 247)",
      renderTotal: true,
      associatedLegends: [EBarLineGraphIds.compare_peak_hour]
    },
  ];

  let max = 0;

  for (let i = 0; i < bars[2].datas.length; ++i) {
    max = Math.max(max, bars[2].datas[i], bars[3]?.datas[i] || 0);
  }

  if (comparingMode.value === EConsoCompare.NONE) {
    bars = bars.splice(2);
  }

  if (currentType.value === 'day') {
    labels.splice(0, labels.length)
    const startOfDay = useDate().localTimezoneDate().startOf('day');

    for (let i = 0; i < 24; i++) {
      const formattedHour = startOfDay.add(i, 'hour').format('H[h]');
      labels.push(formattedHour);
    }
  }

  return {
    stepTimestampOnSite: stepTimestampOnSite,
    lines: !isYearOrHourView.value ? lines : undefined,
    bars,
    labels: labels.map((label: string) => ({
      text: label,
      backgroundColor: (currentType.value === ERoutes.CONSO_WEEK && [0, 6].includes(useDate(label, viewType.value.format).day())) ? "rgb(228, 232, 239)" : '',
    })),
    leftAxis: {
      min: 0,
      max: Math.max(Math.ceil(max * 1.1), viewType.value.maxConsoValue),
      stacked: currentType.value !== ERoutes.CONSO_DAY,
      title: consoType.value ? EEnergyUnit.kWh : currency.value,
      display: true
    },
    hideOnLegendClick: true,
    rightAxis: {
      min: -10,
      max: 35,
      title: ETemperatureUnit.Celsius,
      display: viewType.value.aggregation !== EAggregationType.Hour
    },
    renderTotal: true,
    renderLine: false,
    barThickness: barThickness.value,
    isDayType: currentType.value === ERoutes.CONSO_DAY
  };
})

const appliancesOptions = computed(() => {
      const actualAppliances = appliances.value.map((appliance) => {
        return {id: appliance.id, label: appliance.name}
      })

      if (isGeneralMeasure.value) {
        return [{label: t('conso_detail.appliances.all'), id: 0},
          {label: t('conso_detail.appliances_only'), id: 1},
          ...actualAppliances,
          {label: t('conso_detail.appliances.other'), id: 2}
        ]
      }

      return [
        {
          label: isGeneralMeasure.value ? t('conso_detail.appliances.all') : t('conso_detail.appliances_only'),
          id: 0
        },
        ...actualAppliances]
    }
)

const appliances = computed(() => {
  return AppliancesModule.appliances ?? []
})

const canScrollLeft = computed(() => {
  if (isYearView.value) return false;

  const start = useDate(currentSite.value.dataStart);
  const currentDate = useDate(consoDate.value);

  return currentDate.startOf(viewType.value.startOfType).isAfter(start.startOf(viewType.value.startOfType).toDate())
})

const canScrollRight = computed(() => {
  if (isYearView.value) return false;
  const currentDate = useDate(consoDate.value);
  const todayDate = useDate().toDate();

  if (currentType.value === 'day' && currentDate.isSame(todayDate, 'day')) {
    return false;
  }
  if (currentType.value === 'month' && currentDate.isSame(todayDate, 'month')) {
    return false;
  }
  if (currentType.value === 'week' && currentDate.isSame(todayDate, 'week')) {
    return false;
  }

  return currentDate.startOf(viewType.value.startOfType).isBefore(todayDate);
});

const hasLowHeight = computed(() => {
  return window.innerHeight <= 844;
})

const isGeneralMeasure = computed(() => {
  return currentSite.value.hasGlobalConsumptionMeasure || currentSite.value.hasDsoMeasure;
})

const user = computed(() => {
  return AuthModule.user;
})


const contract = computed(() => {
  return getContractFromDate(consoDate.value);
})

const consoDateFormatted = computed(() => {
  return useDate(consoDate.value).format(viewType.value.routeFormat);
})


const datePickerLocale = computed(() => {
  return IntlModule.locale === ELocale.fr ? frFr : enUs;
})

const closeWelcomeConsoPopin = () => {
  showConsoInfoPopin.value = false;
  pageScroll(true);
}

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
  consoTypeKey.value++
}

const disabledDate = (current: Moment) => {
  const today = useDate().toDate();
  const dataStart = useDate(currentSite.value.dataStart).toDate();

  return current && (current.toDate() < dataStart || current.toDate() > today);
}

const startCompareMode = () => {
  getConsoPerAppliance(viewType.value.dateMoveLeft(viewType.value.dateMoveLeft(useDate(consoDate.value))).format(viewType.value.routeFormat))
  comparingMode.value = EConsoCompare.LAST_PERIOD;
}

const resetCompare = () => {
  comparingMode.value = EConsoCompare.NONE;
  initDatas()
}

const toggleDropdownAggregations = () => {
  dropdownVisible_aggregations.value = !dropdownVisible_aggregations.value;
  dropdownVisible_appliances.value = false;
}

const toggleDropDownAppliances = () => {
  dropdownVisible_appliances.value = !dropdownVisible_appliances.value;
  dropdownVisible_aggregations.value = false;
}

const selectAppliance = (value: number) => {
  selectedApplianceId.value = value;
  dropdownVisible_appliances.value = false;
}

const editCurrentType = (url: any) => {
  router.replace(url);
}

const getConsoPerAppliance = (date: string | string[]) => {
  const maxYear = useDate().year();
  const minYear = useDate(currentSite.value.dataStart).year();

  ConsoModule.getConsumptionFullData({
    dates: isYearView.value ? new Array((maxYear + 1) - minYear).fill(0).map((_, i) => (i + minYear).toString()).join(',') : useDate((date as unknown as string) ?? consoDate.value).format(viewType.value.routeFormat),
    period: viewType.value.alias
  });

}

const activeLink = (val: string) => {
  const routeType = route.query.type;

  return routeType ? routeType === val : route.name === val;
}

const activeAppliance = (val: number | null) => {
  if (val === null) return true;
  return val === selectedApplianceId.value;
}

const changeDate = (date: string) => {
  consoDate.value = date;
}

const updateBarThickness = () => {
  switch (currentType.value) {
    case ERoutes.CONSO_DAY:
      if (window.innerWidth < 400) {
        nbRenderedItems.value = 24;
        barThickness.value = 10;
      } else if (window.innerWidth < 600) {
        nbRenderedItems.value = 24;
        barThickness.value = 10;
      } else if (window.innerWidth < 1070) {
        nbRenderedItems.value = 24;
        barThickness.value = 16;
      } else {
        nbRenderedItems.value = 24;
        barThickness.value = 16;
      }

      break;
    case ERoutes.CONSO_WEEK:
      if (window.innerWidth < 375) {
        nbRenderedItems.value = 7;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 16 : 24;
      } else if (window.innerWidth < 600) {
        nbRenderedItems.value = 7;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 16 : 24;
      } else if (window.innerWidth < 1070) {
        nbRenderedItems.value = comparingMode.value !== EConsoCompare.NONE ? 15 : 30;
        barThickness.value = 16;
      } else {
        nbRenderedItems.value = comparingMode.value !== EConsoCompare.NONE ? 15 : 30;
        barThickness.value = 20;
      }
      break;
    case ERoutes.CONSO_MONTH:
    default:
      if (window.innerWidth < 375) {
        nbRenderedItems.value = 12;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 10 : 16;
      } else if (window.innerWidth < 600) {
        nbRenderedItems.value = 12;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 10 : 16;
      } else if (window.innerWidth < 1070) {
        nbRenderedItems.value = 12;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 30 : 40;
      } else {
        nbRenderedItems.value = 12;
        barThickness.value = comparingMode.value !== EConsoCompare.NONE ? 30 : 40;
      }
      break;
    case ERoutes.CONSO_YEAR:
      if (window.innerWidth < 375) {
        nbRenderedItems.value = 12;
        barThickness.value = 15;
      } else if (window.innerWidth < 600) {
        nbRenderedItems.value = 12;
        barThickness.value = 20;
      } else if (window.innerWidth < 1070) {
        nbRenderedItems.value = 12;
        barThickness.value = 40;
      } else {
        nbRenderedItems.value = 12;
        barThickness.value = 40;
      }
      break;
  }
}

const getPreviousDate = () => {
  const currentDate = useDate(consoDate.value);
  let date = viewType.value.dateMoveLeft(currentDate);

  if (date.startOf(viewType.value.startOfType).isBefore(useDate(currentSite.value.dataStart).startOf(viewType.value.startOfType).toDate())) {
    date = useDate(currentSite.value.dataStart);
  }

  consoDate.value = date.format(viewType.value.value);
  if (comparingMode.value !== EConsoCompare.NONE) {
    getConsoPerAppliance(viewType.value.dateMoveLeft(date).format(viewType.value.value));
  }
}

const getNextDate = () => {
  const currentDateValue = useDate(currentDate.value.value);
  let date = viewType.value.dateMoveRight(currentDateValue);
  if (date.startOf(viewType.value.startOfType).isAfter(useDate().startOf(viewType.value.startOfType).toDate())) {
    date = useDate();
  }

  consoDate.value = date.format(viewType.value.value);
  if (comparingMode.value !== EConsoCompare.NONE) {
    getConsoPerAppliance(viewType.value.dateMoveRight(date).format(viewType.value.value));
  }
}

const loadAdjacentDatas = () => {
  let diffDateAmount = 1;
  let aggregationDiff = 'y';

  if (viewType.value.aggregation === EAggregationType.Hour) {
    diffDateAmount = 1;
    aggregationDiff = 'day';
  } else if (viewType.value.aggregation === EAggregationType.Day) {
    diffDateAmount = 1;
    aggregationDiff = 'month';
  }

  if (useDate(consoDate.value).substract(diffDateAmount, aggregationDiff).startOf(viewType.value.alias).isAfter(useDate(currentSite.value.dataStart).startOf(viewType.value.alias).toDate())) {
    getConsoPerAppliance(useDate(consoDate.value).substract(diffDateAmount, aggregationDiff).format(viewType.value.routeFormat));
  }

  if (useDate(consoDate.value).add(diffDateAmount, aggregationDiff).startOf(viewType.value.alias).isSameOrBefore(useDate().startOf(viewType.value.alias).toDate())) {
    getConsoPerAppliance(useDate(consoDate.value).add(diffDateAmount, aggregationDiff).format(viewType.value.routeFormat));
  }
}
const initDatas = () => {
  consoDate.value = route.params.date as string;

  ContractModule.getContractsList();
  window.addEventListener('resize', updateBarThickness);
  updateBarThickness();
}

onBeforeMount(() => {
  AppliancesModule.getAppliances()
  SiteModule.getSites()
  initDatas()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateBarThickness);
})

watch([comparingMode, currentDate], () => {
  updateBarThickness();
});

watch([currentType, consoDate], ([newCurrentType, newConsoDate]) => {
  let date = newConsoDate
  if (date) {
    if (newCurrentType === EAggregationAlias.Month)
      date = useDate(date).format('YYYY-MM')

    if (newCurrentType === EAggregationAlias.Week)
      date = useDate(date).format('YYYY-MM-DD')

    if (newCurrentType === EAggregationAlias.Year) {
      date = useDate(date).format('YYYY')
      comparingMode.value = EConsoCompare.NONE;
    }

    loadAdjacentDatas()
    getConsoPerAppliance(date)
  }

  router.replace({
    params: {
      date
    },
    query: {
      type: newCurrentType
    }
  });

  dropdownVisible_appliances.value = dropdownVisible_aggregations.value = false;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageConso, {
    title: _ctx.$t('conso.page.title'),
    class: "ConsoDetail"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVHeaderMainPage, {
        title: _ctx.$t('conso.detail.title')
      }, {
        HeaderMainPage__breadcrumb: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (backLink.value && backLink.value(...args))),
            class: "HeaderMainPage__breadcrumb-link"
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "HeaderMainPage__breadcrumb-link-icon" }, [
              _createElementVNode("svg", {
                class: "icon icon-arrow-left",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_1, " < " + _toDisplayString(_ctx.$t("conso.detail.breadcrumb.backLink")), 1)
          ])
        ]),
        HeaderMainPage__right: _withCtx(() => [
          _createVNode(MyVDisplayGroupStore, {
            currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(MyVAntdswitch, {
                defaultValue: consoType.value,
                value: consoType.value,
                onChange: setConsoType,
                checkedChildren: _unref(currency),
                size: "small",
                unCheckedChildren: _unref(EEnergyUnit).kWh,
                key: consoTypeKey.value,
                ref_key: "consoType",
                ref: consoType
              }, null, 8, ["defaultValue", "value", "checkedChildren", "unCheckedChildren"]))
            ]),
            _: 1
          }, 8, ["currentRight"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('conso_detail.filters')) + " :", 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Dropdown), {
            class: "aggregationDropdown",
            visible: dropdownVisible_aggregations.value,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((dropdownVisible_aggregations).value = $event)),
            trigger: ['click']
          }, {
            overlay: _withCtx(() => [
              _createVNode(_unref(Menu), { class: "menu" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      onClick: ($event: any) => (editCurrentType(link.url)),
                      key: index,
                      class: _normalizeClass(['item',{active : activeLink(link.value)}])
                    }, _toDisplayString(link.label), 11, _hoisted_4))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(MyVButton, {
                icon: "triangle",
                class: "PageConso__headerButton",
                primary: false,
                onClick: toggleDropdownAggregations
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(currentLink.value), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["visible"]),
          _createVNode(_unref(Dropdown), {
            class: "appliancesDropdown",
            visible: dropdownVisible_appliances.value,
            "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((dropdownVisible_appliances).value = $event)),
            trigger: ['click']
          }, {
            overlay: _withCtx(() => [
              _createVNode(_unref(Menu), { class: "menu" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(appliancesOptions.value, (appliance, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      onClick: ($event: any) => (selectAppliance(appliance.id)),
                      key: index,
                      class: _normalizeClass(['item', {active: activeAppliance(appliance.id)}])
                    }, _toDisplayString(appliance?.label), 11, _hoisted_5))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(MyVButton, {
                icon: "triangle",
                class: "PageConso__headerButton",
                primary: false,
                onClick: toggleDropDownAppliances
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(selectedAppliance.value?.label), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["visible"])
        ])
      ]),
      (comparingMode.value !== _unref(EConsoCompare).NONE)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(comparisonText.value), 1),
            (_openBlock(), _createElementBlock("svg", {
              onClick: resetCompare,
              class: "isComparing__close icon icon-cross",
              "aria-hidden": "true"
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
            ])))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        (canScrollLeft.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: {"cursor":"pointer"},
              onClick: getPreviousDate,
              class: "switchDateArrow"
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("svg", {
                class: "icon icon-arrow-left",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_9)),
        _createElementVNode("h3", _hoisted_10, _toDisplayString(currentPeriodText.value), 1),
        (canScrollRight.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              style: {"cursor":"pointer"},
              onClick: getNextDate,
              class: "switchDateArrow"
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("svg", {
                class: "icon icon-arrow-right",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-arrow-right" })
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_11))
      ]),
      (currentType.value !== 'year')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[7] || (_cache[7] = _createElementVNode("svg", {
                class: "icon icon-search",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-search" })
              ], -1)),
              _createElementVNode("span", _hoisted_14, _toDisplayString(specificLabel.value), 1)
            ]),
            _createVNode(_unref(ConfigProvider), { locale: datePickerLocale.value }, {
              default: _withCtx(() => [
                _createVNode(MyVAntddatepicker, {
                  disabledDate: disabledDate,
                  value: consoDate.value ?? undefined,
                  "onUpdate:value": changeDate,
                  picker: currentType.value,
                  format: viewType.value.format,
                  showTime: false
                }, null, 8, ["value", "picker", "format"])
              ]),
              _: 1
            }, 8, ["locale"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", {
          class: _normalizeClass(["ConsoDetail__BarLine__chart", {'hasLowHeight': hasLowHeight.value, 'compareMode': comparingMode.value !== _unref(EConsoCompare).NONE}])
        }, [
          (!isLoading.value)
            ? (_openBlock(), _createBlock(MyVBarLineGraphic, _mergeProps({
                key: 0,
                "need-padding": ""
              }, chartData.value, {
                "is-detailed": true,
                "show-tooltip-total": "",
                "right-axis": {min: rightAxisMin.value, max: rightAxisMax.value, title: _unref(ETemperatureUnit).Celsius}
              }), null, 16, ["right-axis"]))
            : _createCommentVNode("", true)
        ], 2),
        (!isYearView.value && comparingMode.value === _unref(EConsoCompare).NONE)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "ConsoDetail__CompareData",
              onClick: startCompareMode
            }, [
              _createVNode(MyVIcon, {
                className: "ConsoDetail__CompareData__icon",
                name: "recipe-create"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('conso.compare.last_period')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (showConsoInfoPopin.value)
        ? (_openBlock(), _createBlock(ConsoTooltip, {
            key: 2,
            onClose: closeWelcomeConsoPopin
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})