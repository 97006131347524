import ThemeService from "@/services/Theme";
import {TThemeInfo} from "@/services/Theme/interfaces";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";

@Module({ name: EModules.THEME })
class Theme extends VuexModule {
    /**
     * State
     */
    private _theme: TThemeInfo | undefined = undefined

    /**
     * Getters
     */
    get theme() {
        return this._theme;
    }

    /**
     * Mutations
     */
    @Mutation
    public _setTheme(theme: TThemeInfo): void {
        this._theme = theme;
    }

    /**
     * Actions
     */
    @Action({ rawError: true })
    public getTheme(host: string): Promise<TThemeInfo> {
        return this.theme ? Promise.resolve(this.theme) : ThemeService.getTheme(host)
        .then(theme => {
            this.context.commit("_setTheme", theme);
            return theme;
        });
    }
}

export default Theme;