import {Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";

@Module({ name: EModules.ID_APPLICATION })
class IdApplication extends VuexModule {
  public key = 0;

  @Mutation
  public _incrementKey() {
    this.key++;
  }
}

export default IdApplication;
