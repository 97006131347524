import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/tgv.png'


const _hoisted_1 = { class: "savings-card-container" }
const _hoisted_2 = { class: "savings-card-title" }
const _hoisted_3 = {
  key: 0,
  class: "savings-card-subtitle"
}
const _hoisted_4 = { class: "savings-card" }
const _hoisted_5 = { class: "savings-card-main" }
const _hoisted_6 = { class: "savings-card-main-message" }
const _hoisted_7 = { class: "savings-card-main-message-value" }
const _hoisted_8 = { class: "savings-card-main-message-text" }
const _hoisted_9 = { class: "savings-card-main-message-sub-message" }
const _hoisted_10 = {
  key: 0,
  class: "savings-card-main-description"
}
const _hoisted_11 = {
  key: 0,
  class: "savings-card-explanation"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 1,
  src: _imports_0,
  "aria-hidden": "true",
  alt: "train"
}

import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {TSavings} from "@/services/Savings/interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {EVariant} from "@/components/domains/Savings/MyvSavingsCard/interfaces";
import ERoutes from "@/router/routes";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import useCurrency from "@/composables/currency";
import {EEnergyUnit, EWeightUnit} from "@/helpers/constants/unit";

const trainCarboneEquivalent = 22.7;
const carCarboneEquivalent = 460;


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVSavingsCard',
  props: {
    savings: {},
    variant: {}
  },
  setup(__props: any) {

const props = __props;

const {t} = useI18n();
const {formatCurrency, currency} = useCurrency()
const iconName = computed(() => (props.variant === EVariant.Carbone ? 'leaf' : 'energy_saving'));
const isCurrency = computed(() => props.variant === EVariant.Currency);
const isCarboneWithSavings = computed(() => props.variant === EVariant.Carbone && props.savings?.co2SavingInKg);

const formattedValue = computed((): string => {
  const {variant, savings} = props;

  let displayValue: number | string = `— ${currency.value}`;

  if (isCurrency.value && savings?.energyTotalSavingsInCurrency) {
    displayValue = savings.energyTotalSavingsInCurrency;
  } else if (variant === EVariant.Carbone && savings?.co2SavingInKg) {
    displayValue = `${savings.co2SavingInKg} ${EWeightUnit.Kg}`;
  }

  return isCurrency.value && typeof displayValue === "number"
      ? formatCurrency(displayValue)
      : String(displayValue);
});

const messageText = computed(() => {
  return isCurrency.value ? t('savingsCard.carbone.saved') : t('savingsCard.carbone.avoided');
});

const trainDistanceEquivalent = computed((): number | undefined => {
  if (props.savings)
    return Math.round((props.savings.co2SavingInKg / 100) * trainCarboneEquivalent);

  return undefined
});

const carDistanceEquivalent = computed((): number | undefined => {
  if (props.savings)
    return Math.round((props.savings?.co2SavingInKg / 100) * carCarboneEquivalent);

  return undefined
});

const showExplanation = computed(() => {
  return (
      (props.variant === EVariant.Currency && (props.savings?.hasNoPrograming || !props.savings)) ||
      (props.savings && props.variant === EVariant.Carbone)
  );
});

const noSavingsMessage = computed(() => t('Vous n’avez pas encore réalisé d’économie significative. Revenez d’ici quelques jours.'));
const programActivationDescription = computed(() => t('savingsCard.consumption.activateProgram.description'));
const programActivationText = computed(() => t('savingsCard.consumption.activateProgram'));


return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(isCurrency.value ? _ctx.$t('conso.card_title.savings') : _ctx.$t('conso.card_title.co2')), 1),
      (_ctx.$slots.subtitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "subtitle")
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['savings-card-wrapper', `savings-card__${props.variant}`])
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(MyVIcon, { name: iconName.value }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(formattedValue.value), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(` ${messageText.value}`), 1)
            ]),
            (isCurrency.value)
              ? (_openBlock(), _createBlock(_component_i18n_t, {
                  key: 0,
                  keypath: "savingsCard.consumption.subMessage",
                  tag: "div"
                }, {
                  value: _withCtx(() => [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(`${props.savings?.energyTotalSavingsInKWh ?? '—'} ${_unref(EEnergyUnit).kWh}`), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        (isCurrency.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('savingsCard.consumption.lastUpdate',)), 1))
          : _createCommentVNode("", true)
      ]),
      (showExplanation.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (isCurrency.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!props.savings)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(MyVIcon, { name: "info" }),
                        _createTextVNode(" " + _toDisplayString(noSavingsMessage.value), 1)
                      ]))
                    : (props.savings?.hasNoPrograming)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("div", null, [
                            _createVNode(MyVIcon, { name: "info" }),
                            _createTextVNode(" " + _toDisplayString(programActivationDescription.value), 1)
                          ]),
                          (props.savings?.hasNoPrograming)
                            ? (_openBlock(), _createBlock(MyVButton, {
                                key: 0,
                                primary: false,
                                type: _unref(EButtonType).Link,
                                to: _unref(ERoutes).PROGRAM
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(programActivationText.value), 1)
                                ]),
                                _: 1
                              }, 8, ["type", "to"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (props.savings?.co2SavingInKg)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(MyVIcon, { name: "arrow-right2" }),
                        _createElementVNode("div", {
                          innerHTML: _ctx.$t('savingsCard.carbone.subMessage', {carEquivalent: carDistanceEquivalent.value, trainEquivalent: trainDistanceEquivalent.value})
                        }, null, 8, _hoisted_14)
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
          ]))
        : _createCommentVNode("", true),
      (isCarboneWithSavings.value)
        ? (_openBlock(), _createElementBlock("img", _hoisted_15))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})