import {TTabNavItem} from "@/components/ui/molecules/MyVTabNav/interfaces";
import ERoutes from "@/router/routes";
import dayjs from "dayjs";
import {aggregatedMonthFormat} from "@/services/Conso/interfaces";
import {ESavingsType} from "@/services/Savings/interfaces";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";

export const TabNavFakeDatasDefault: TTabNavItem[] = [
    {
        icon: 'menu-home',
        title: 'nav.home',
        link: ERoutes.HOME,
        disabled: false,
        lockable: false,
    },
    {
        icon: 'graph',
        title: 'nav.conso',
        link: {
            path: ERoutes.CONSO,
            query: {
                date: dayjs().format(aggregatedMonthFormat),
                type: 'month'
            }
        },
        disabled: false,
        lockable: false,
    },
    {
        icon: 'temperature',
        title: 'nav.heating',
        link: ERoutes.HEATING,
        disabled: false,
        lockable: false,
    },
    {
        icon: 'menu-advices',
        title: 'nav.diagnostics',
        link: ERoutes.ADVICES,
        disabled: false,
        right: EDisplayGroupRightName.MY_ADVICES,
        lockable: false,
    }
];
export const TabNavFakeDatasFrench: TTabNavItem[] = [
    {
        icon: 'menu-home',
        title: 'nav.home',
        link: ERoutes.HOME,
        disabled: false,
        lockable: false,
    },
    {
        icon: 'graph',
        title: 'nav.conso',
        link: {
            path: ERoutes.CONSO,
            query: {
                date: dayjs().format(aggregatedMonthFormat),
                type: 'month'
            }
        },
        disabled: false,
        lockable: true,
    },
    {
        icon: 'temperature',
        title: 'nav.heating',
        link: ERoutes.HEATING,
        disabled: false,
        lockable: false,
    },
    {
        icon: 'pig',
        title: 'nav.savings',
        link: {
            path: ERoutes.SAVINGS,
            query: {
                date: dayjs().format(aggregatedMonthFormat),
                type: ESavingsType.Global
            }
        },
        disabled: false,
        lockable: true,
    }
];