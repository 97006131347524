
export const passwordSecurityLength = 8;

export const hasUpper = (str: string): boolean => (
    /[A-Z]/.test(str)
);

export const containsSpecialChars = (str: string): boolean => (
    // eslint-disable-next-line no-useless-escape
    `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`.split('')
    .some(specialChar => str.includes(specialChar))
);

export const checkPasswordSecurity = (password: string): boolean => (
    password.length >= passwordSecurityLength &&
    hasUpper(password) &&
    containsSpecialChars(password)
);