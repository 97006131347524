import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {EModules} from "../modules";
import {
    EDiagnosticStatus,
    TDiagnostic,
    TDiagnosticSelfTestParams,
    TGetDiagnosticsParams,
} from "@/services/Diagnostic/interfaces";
import {AuthModule} from "@/store";
import DiagnosticService from "@/services/Diagnostic";

@Module({name: EModules.DIAGNOSTIC})
class Diagnostic extends VuexModule {
    /**
     * State
     */
    private _diagnostics: TDiagnostic[] = [];
    private _diagnosticsInProgress: TDiagnostic[] = [];
    private _pollId: number | undefined = undefined;

    /**
     * Getters
     */
    get diagnostics() {
        return this._diagnostics;
    }

    get diagnosticsInProgress() {
        return this._diagnosticsInProgress;
    }

    get pollId() {
        return this._pollId;
    }

    get isAllButOneDiagnosticsOk() {
        if (this.isTestingInProgress)
            return false;

        const koDiagnostics = this._diagnostics?.filter(diag => diag.status !== EDiagnosticStatus.OK && diag.status !== EDiagnosticStatus.NO_CONSUMPTION)
        return koDiagnostics?.length <= 1
    }

    get isTestingInProgress() {
        return this._diagnostics?.some(diag => diag.status === EDiagnosticStatus.TEST_IN_PROGRESS)
    }

    /**
     * Mutations
     */
    @Mutation
    _setDiagnostics(diagnostics: TDiagnostic[]) {
        this._diagnostics = diagnostics;
    }

    @Mutation
    _setDiagnosticsInProgress(diagnostics: TDiagnostic[]) {
        diagnostics.forEach(diagnostic => {
            const index = this._diagnosticsInProgress.findIndex(existingDiagnostic =>
                existingDiagnostic.csApplianceId === diagnostic.csApplianceId
            );

            if (diagnostic.status === EDiagnosticStatus.TEST_IN_PROGRESS) {
                diagnostic.currentDate = new Date();

                if (index !== -1) {
                    // Update diagnostic if it's present and has TEST_IN_PROGRESS status
                    diagnostic.startDate = this._diagnosticsInProgress[index].startDate || new Date();
                    this._diagnosticsInProgress.splice(index, 1, diagnostic);
                } else {
                    // Add the diagnostic if it's not present
                    diagnostic.startDate = new Date();
                    this._diagnosticsInProgress.push(diagnostic);
                }
            } else if (index !== -1) {
                // Remove the diagnostic if it's present but doesn't have TEST_IN_PROGRESS status
                this._diagnosticsInProgress.splice(index, 1);
            }
        });
    }

    @Mutation
    _setPollId(id: number) {
        this._pollId = id;
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public getDiagnostics({
                              siteId,
                              skipLoading = true
                          }: TGetDiagnosticsParams): Promise<TDiagnostic[] | void> {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return DiagnosticService.getDiagnostics(siteId, skipLoading)
            .then(diagnostics => {
                this.context.commit('_setDiagnostics', diagnostics);
                this.context.commit('_setDiagnosticsInProgress', diagnostics);
                return diagnostics;
            });
    }

    @Action({rawError: true})
    public async startSelfDiagnostic({siteId, csModulatorId}: TDiagnosticSelfTestParams) {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        const res = await DiagnosticService.startDiagnosticSelfTest({
            siteId,
            csModulatorId
        });
        this.context.commit('_setDiagnostics', this._diagnostics!.map(diagnostic => {
            return {
                ...diagnostic,
            }
        }))
        return res;
    }

    @Action({rawError: true})
    public async startAllDiagnostics(siteId: number) {
        const user = AuthModule.userOrNull;

        this.context.commit('_setDiagnostics', this._diagnostics.map(diag => {
            return {...diag, status: EDiagnosticStatus.TEST_IN_PROGRESS}
        }));

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }
        return DiagnosticService.startDiagnosticAllTest(siteId);
    }

}

export default Diagnostic;
