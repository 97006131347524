import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "CompleteStep__content" }
const _hoisted_2 = { class: "CompleteStep__congrat" }
const _hoisted_3 = { class: "CompleteStep__congrat-icon" }
const _hoisted_4 = { class: "CompleteStep__congrat-text" }
const _hoisted_5 = ["innerHTML"]

import ERoutes from "@/router/routes";

import {AuthModule, HeatingModule} from "@/store";
import {useRouter} from "vue-router";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCompleteStep',
  setup(__props) {

const router = useRouter()

const finishOnboarding = () => {
  HeatingModule.initQuickSettings().then(() => {
    AuthModule.tryConnectUserFromCookie().then((user) => {
      if (user && user.firstConnection && user.migratedUser) return;
      router.push(ERoutes.HOME)
    })
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createVNode(MyVIcon, { name: "success" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          innerHTML: _ctx.$t('firstConnection.complete.congrat_text')
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createVNode(MyVButton, {
      onClick: finishOnboarding,
      class: "CompleteStep__linkStart"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('firstConnection.complete.link_start')), 1)
      ]),
      _: 1
    })
  ]))
}
}

})