import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__main" }
const _hoisted_2 = { class: "ProgramCreateWeekPresence__greyCheckboxes" }
const _hoisted_3 = { class: "fieldset__inner" }
const _hoisted_4 = { class: "MultiStep__footer inner" }

import {computed, onBeforeMount, reactive, ref} from "vue";
import {Form} from 'ant-design-vue';

import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import MyVAntdtimepicker from '@/components/ui/molecules/MyVAntdtimepicker/MyVAntdtimepicker.vue';
import MyVFieldset from '@/components/ui/atoms/MyVFieldset/MyVFieldset.vue';
import MyVRadioHideSection from '@/components/ui/atoms/MyVRadioHideSection/MyVRadioHideSection.vue';
import MyVCheckHideSection from '@/components/ui/molecules/MyVCheckHideSection/MyVCheckHideSection.vue';

import {TProgramCreateWeekPresence, TProgramCreateWeekPresenceDatas} from "./interfaces";
import {TOption} from "@/components/ui/atoms/MyVRadioHideSection/interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {useI18n} from "vue-i18n";

interface Props {
  onSubmit: TProgramCreateWeekPresence["onSubmit"];
  actionOnFinish?: TProgramCreateWeekPresence["actionOnFinish"];
  returnAction?: TProgramCreateWeekPresence["returnAction"];
  weekLeavingHomeTime?: TProgramCreateWeekPresence["weekLeavingHomeTime"];
  weekBackHomeTime?: TProgramCreateWeekPresence["weekBackHomeTime"];
  weekMiddayLeavingHomeTime?: TProgramCreateWeekPresence["weekMiddayLeavingHomeTime"];
  weekMiddayBackHomeTime?: TProgramCreateWeekPresence["weekMiddayBackHomeTime"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVProgramCreateWeekPresence',
  props: {
    onSubmit: { type: Function, default: undefined },
    actionOnFinish: { default: undefined },
    returnAction: { default: undefined },
    weekLeavingHomeTime: { default: null },
    weekBackHomeTime: { default: null },
    weekMiddayLeavingHomeTime: { default: null },
    weekMiddayBackHomeTime: { default: null }
  },
  setup(__props: any) {

const props = __props

const form = reactive<TProgramCreateWeekPresenceDatas>({
  weekLeavingHomeTime: null,
  weekBackHomeTime: null,
  weekMiddayLeavingHomeTime: null,
  weekMiddayBackHomeTime: null,
});


const defaultWeekLeavingHomeTime = ref('08:00');
const defaultWeekBackHomeTime = ref('18:00');
const defaultWeekMiddayLeavingHomeTime = ref('13:30');
const defaultWeekMiddayBackHomeTime = ref('12:00');
const save = reactive<TProgramCreateWeekPresenceDatas>({
  weekLeavingHomeTime: defaultWeekLeavingHomeTime.value,
  weekBackHomeTime: defaultWeekBackHomeTime.value,
  weekMiddayLeavingHomeTime: defaultWeekMiddayLeavingHomeTime.value,
  weekMiddayBackHomeTime: defaultWeekMiddayBackHomeTime.value,
});
const {t} = useI18n()

const optionsPresence = computed(() => {
  return [
    {
      "label": t('programCreate.tunnel.week_presence_yes'),
      value: true,
    },
    {
      "label": t('programCreate.tunnel.week_presence_no'),
      value: false
    }
  ]
})

const weekMiddayPresence = computed(() => {
  return form.weekMiddayLeavingHomeTime !== null && form.weekMiddayBackHomeTime !== null;
})

const toggleWeekPresence = (val: TOption['value']) => {
  if (val) {
    form.weekLeavingHomeTime = save.weekLeavingHomeTime;
    form.weekBackHomeTime = save.weekBackHomeTime;
  } else {
    save.weekLeavingHomeTime = form.weekLeavingHomeTime;
    save.weekBackHomeTime = form.weekBackHomeTime;
    form.weekLeavingHomeTime = null;
    form.weekBackHomeTime = null;
  }
}

const toggleWeekMiddayPresence = (val: boolean) => {
  if (val) {
    form.weekMiddayLeavingHomeTime = save.weekMiddayLeavingHomeTime;
    form.weekMiddayBackHomeTime = save.weekMiddayBackHomeTime;
  } else {
    save.weekMiddayLeavingHomeTime = form.weekMiddayLeavingHomeTime;
    save.weekMiddayBackHomeTime = form.weekMiddayBackHomeTime;
    form.weekMiddayLeavingHomeTime = null;
    form.weekMiddayBackHomeTime = null;
  }
}

const onFinish = () => {
  props.onSubmit(form);
}

onBeforeMount(() => {
      form.weekLeavingHomeTime = props.weekLeavingHomeTime || defaultWeekLeavingHomeTime.value;
      form.weekBackHomeTime = props.weekBackHomeTime || defaultWeekBackHomeTime.value;
      form.weekMiddayLeavingHomeTime = props.weekMiddayLeavingHomeTime;
      form.weekMiddayBackHomeTime = props.weekMiddayBackHomeTime;
    }
)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(Form), {
    model: form,
    onFinish: onFinish,
    class: "MultiStep__form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('programCreate.tunnel.week_presence')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MyVRadioHideSection, {
                options: optionsPresence.value,
                onChange: toggleWeekPresence,
                defaultValue: true
              }, {
                radiotrue: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(MyVAntdtimepicker, {
                      inputRequired: true,
                      value: form.weekLeavingHomeTime,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.weekLeavingHomeTime) = $event)),
                      inlinelabel: _ctx.$t('programCreate.tunnel.weekLeavingHomeTime.label'),
                      label: _ctx.$t('programCreate.tunnel.hour')
                    }, null, 8, ["value", "inlinelabel", "label"]),
                    _createVNode(MyVAntdtimepicker, {
                      inputRequired: true,
                      value: form.weekBackHomeTime,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.weekBackHomeTime) = $event)),
                      inlinelabel: _ctx.$t('programCreate.tunnel.weekBackHomeTime.label'),
                      label: _ctx.$t('programCreate.tunnel.hour')
                    }, null, 8, ["value", "inlinelabel", "label"])
                  ]),
                  _createVNode(MyVCheckHideSection, {
                    label: _ctx.$t('programCreate.tunnel.weekMiddayPresence.label'),
                    defaultChecked: weekMiddayPresence.value,
                    onChange: toggleWeekMiddayPresence
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyVAntdtimepicker, {
                        inputRequired: true,
                        value: form.weekMiddayBackHomeTime,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.weekMiddayBackHomeTime) = $event)),
                        inlinelabel: _ctx.$t('programCreate.tunnel.weekMiddayBackHomeTime.label'),
                        label: _ctx.$t('programCreate.tunnel.hour')
                      }, null, 8, ["value", "inlinelabel", "label"]),
                      _createVNode(MyVAntdtimepicker, {
                        inputRequired: true,
                        value: form.weekMiddayLeavingHomeTime,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.weekMiddayLeavingHomeTime) = $event)),
                        inlinelabel: _ctx.$t('programCreate.tunnel.weekMiddayLeavingHomeTime.label'),
                        label: _ctx.$t('programCreate.tunnel.hour')
                      }, null, 8, ["value", "inlinelabel", "label"])
                    ]),
                    _: 1
                  }, 8, ["label", "defaultChecked"])
                ]),
                _: 1
              }, 8, ["options"])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(MyVButton, {
          type: _unref(EButtonType).Submit,
          primary: false,
          icon: "carret-right"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('programCreate.tunnel.next')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        (typeof(_ctx.returnAction)=='function')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
              type: "button",
              class: "popinForm__footer-back"
            }, _toDisplayString(_ctx.$t('global.cancel')), 1))
          : (_ctx.returnAction)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.returnAction,
                class: "popinForm__footer-back"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})