
export enum EHeaderPageType {
  /**
   * is a page
   */
  page = "page",
  /**
   * is a popin
   */
  popin = "popin",
}

export type THeaderPage = {
  /**
   * title
   */
  title: string;
  /**
   * title
   */
  subtitle: string;

  /**
   * type
   */
  type: EHeaderPageType|undefined;

  /**
   * returnAction
   */
  returnAction?: string | (() => void);

};
