import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/lock.png'


const _hoisted_1 = { class: "locked-overlay" }
const _hoisted_2 = { class: "overlay-description" }


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLockedOverlay',
  props: {
    description: {}
  },
  setup(__props: any) {


const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "content"),
    _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(['overlay', {'overlay__card':!_ctx.$slots.content}])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overlay-title" }, [
        _createElementVNode("img", { src: _imports_0 }),
        _createTextVNode(" Accès limité ")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(props.description), 1),
        _createElementVNode("strong", null, _toDisplayString(` ${_ctx.$t("subscriberOffer.locked.messages.contact")}`), 1)
      ])
    ], 2)
  ]))
}
}

})