import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "HeaderPage" }
const _hoisted_2 = {
  key: 0,
  class: "icon icon-cross",
  "aria-hidden": "true"
}
const _hoisted_3 = {
  key: 1,
  class: "icon icon-arrow-left",
  "aria-hidden": "true"
}
const _hoisted_4 = {
  key: 0,
  class: "icon icon-cross",
  "aria-hidden": "true"
}
const _hoisted_5 = {
  key: 1,
  class: "icon icon-arrow-left",
  "aria-hidden": "true"
}
const _hoisted_6 = { class: "HeaderPage__center" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "HeaderPage__right" }

import {EHeaderPageType, THeaderPage} from "./interfaces";
import {onBeforeMount, ref} from "vue";

interface Props {
  title: THeaderPage["title"];
  subtitle?: THeaderPage["subtitle"];
  type?: THeaderPage["type"];
  returnAction?: THeaderPage["returnAction"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHeaderPage',
  props: {
    title: { default: undefined },
    subtitle: { default: undefined },
    type: { default: EHeaderPageType.page },
    returnAction: { type: [String, Function], default: undefined }
  },
  setup(__props: any) {

const props = __props

const iconClass = ref("HeaderPage__back");

onBeforeMount(() => {
  if (props.type === 'popin') {
    iconClass.value = "HeaderPage__close";
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (typeof(_ctx.returnAction)=='function')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
          class: _normalizeClass(iconClass.value),
          type: "button"
        }, [
          (_ctx.type=='popin')
            ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
                _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[2] || (_cache[2] = [
                _createElementVNode("use", { "xlink:href": "#icon-arrow-left" }, null, -1)
              ])))
        ], 2))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.returnAction,
          class: _normalizeClass(iconClass.value)
        }, {
          default: _withCtx(() => [
            (_ctx.type=='popin')
              ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[3] || (_cache[3] = [
                  _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[4] || (_cache[4] = [
                  _createElementVNode("use", { "xlink:href": "#icon-arrow-left" }, null, -1)
                ])))
          ]),
          _: 1
        }, 8, ["to", "class"])),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "HeaderPage__title",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "HeaderPage__subtitle",
            innerHTML: _ctx.subtitle
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "header__right")
    ])
  ]))
}
}

})