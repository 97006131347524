import API from "@/helpers/api";

import {
    TCompanyContract,
    TCompanyContracts,
    TContract,
    TContractService,
    TProviders
} from "@/services/Contract/interfaces";

const ContractService: TContractService = {
    getContractsList: siteId => (
        API.get<TContract[]>(`/api/site/${siteId}/subscriber-contract`)
            .then(({data}) => data)
    ),

    postContract: (siteId, body) => (
        API.post<TContract>(`/api/site/${siteId}/subscriber-contract/`, body)
            .then(({data}) => data)
    ),

    putContract: (siteId, body) => (
        API.put<TContract>(`/api/site/${siteId}/subscriber-contract/`, body)
            .then(({data}) => data)
    ),

    deleteAllContracts: (siteId) => (
        API.delete(`/api/site/${siteId}/subscriber-contract`)
            .then(({data}) => data)
    ),

    getProviders: () => (
        API.get<TProviders>('/api/company')
            .then(({data}) => data)
    ),

    getCompanyContracts: companyId => (
        API.get<TCompanyContracts>(companyId === undefined ? '/api/companycontract' : `/api/company/${companyId}/contract`)
            .then(({data}) => data)
    ),

    getCompanyContract: id => (
        API.get<TCompanyContract>(`/api/companycontract/${id}`)
            .then(({data}) => data)
    ),
    /*TODO this endpoint should be in PDL, not contract*/
    getPdlValidity: siteId => (
        API.get(`/api/site/${siteId}/pdl/valid`)
            .then(({data}) => data)
    )
};

export default ContractService;
