import {EApplianceMode, TAppliance} from "@/services/Appliances/interfaces";

export const g_modeOff = 'STOP';

export type TEditRangeDatas = {
    /**
     * Edition range start day.
     */
    start_day: number;

    /**
     * Edition range start hour.
     */
    start_hour: string;

    /**
     * Edition range end day.
     */
    end_day: number;

    /**
     * Edition range end hour.
     */
    end_hour: string;

    /**
     * Range's mode.
     */
    mode: EApplianceMode;

    /**
     * Edition range temperature (Only on Temperature mode).
     */
    temperature: number;
};

export enum EPlainTextHours {
    OneMinuteBeforeMidnight = "23:59",
    Midnight = "00:00",
    MidnightAlternative = "24:00"
}

export type TEditRangeDatasSubmit = TEditRangeDatas & {
    /**
     * Is the appliance activated ?
     */
    isOn: TAppliance['programming']['isOn'];
}

export type TEditRange = {
    /**
     * Edition range day.
     */
    day: number;

    /**
     * Edition range start hour.
     */
    start_hour: string;

    /**
     * Edition range end hour.
     */
    end_hour: string;

    /**
     * Range's mode.
     */
    mode: EApplianceMode;

    /**
     * Range temperature (Only on Temperature mode).
     */
    temperature: number;

    /**
     * Is the mode off or any other ?
     */
    isOn: NonNullable<TEditRangeDatasSubmit['isOn']>;

    /**
     * Event call when form is submitted.
     */
    onSubmit: (formDatas: TEditRangeDatas) => void;

    returnAction: string | void;

    /**
     * Range's available mode.
     */
    availableModes: EApplianceMode[];
};