import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Temperature from '@/components/domains/Heating/MyVTemperature/MyVTemperature.vue';
import {TTemperatureControl} from "./interfaces";
import {computed} from "vue";

interface Props {
  min?: TTemperatureControl["min"];
  max: TTemperatureControl["max"];
  modelValue: TTemperatureControl["modelValue"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTemperatureControl',
  props: {
    min: { default: 7 },
    max: { default: undefined },
    modelValue: { default: undefined }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const currentValue = computed(() => {
  return Math.min(Math.max(props.modelValue, props.min), props.max);
})

const add = () =>{
  if (props.modelValue >= props.max) return;
  emit('update:modelValue', Math.min(props.modelValue + 0.5, props.max));
}

const sub = () =>{
  if (props.modelValue <= props.min) return false;
  emit('update:modelValue', Math.max(props.modelValue - 0.5, props.min));

}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.temperatureControl)
  }, [
    _createElementVNode("button", {
      type: "button",
      onClick: sub,
      class: _normalizeClass(_ctx.$style.temperatureControl__button)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("svg", {
        class: "icon icon-minus",
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", { "xlink:href": "#icon-minus" })
      ], -1)
    ]), 2),
    _createVNode(Temperature, {
      value: currentValue.value,
      className: _ctx.$style.temperatureControl__temperature
    }, null, 8, ["value", "className"]),
    _createElementVNode("button", {
      type: "button",
      onClick: add,
      class: _normalizeClass(_ctx.$style.temperatureControl__button)
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("svg", {
        class: "icon icon-add",
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", { "xlink:href": "#icon-add" })
      ], -1)
    ]), 2)
  ], 2))
}
}

})