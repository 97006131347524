import {EApplianceMode, TAppliance} from "@/services/Appliances/interfaces";

export const heatingType = (mode: EApplianceMode, isOn: TAppliance['programming']['isOn']): string => {
    if (!isOn) {
        return 'mode.off';
    }

    switch (mode) {
        case EApplianceMode.Comfort:
            return 'mode.confort';
        case EApplianceMode.Comfort1:
            return 'mode.confort1';
        case EApplianceMode.Comfort2:
            return 'mode.confort2';
        case EApplianceMode.Eco:
            return 'mode.eco';
        case EApplianceMode.EcoV:
            return 'mode.ecov';
        case EApplianceMode.FrostProtection:
            return 'mode.frostProtection';
        case EApplianceMode.Temp:
            return 'mode.temperature';
        case EApplianceMode.Normal:
            return 'mode.normal';
        default:
            return '';
    }
}

export const heatingIcon = (mode: EApplianceMode, isOn: TAppliance['programming']['isOn']): string => {
    if (!isOn) {
        return 'power';
    }

    switch(mode) {
        case EApplianceMode.Eco:
        case EApplianceMode.EcoV:
            return 'moon1';
        case EApplianceMode.FrostProtection:
            return 'snow1';
        case EApplianceMode.Temp:
            return 'settings';
        case EApplianceMode.Comfort:
        case EApplianceMode.Comfort2:
        default:
            return 'sun1';
    }
}