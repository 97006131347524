import API from "@/helpers/api";

import {TSignatureService, TSignatureStatus} from "@/services/Signature/interfaces";

const SignatureService: TSignatureService = {
    getSignatureStatus: (siteId) => (
        API.get<TSignatureStatus>(`/api/site/${siteId}/signature/signing-link`)
            .then(({data}) => data)
    ),
};

export default SignatureService;
