import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['heaterSlide__content'])
}
const _hoisted_2 = { class: "heaterSlide__header" }
const _hoisted_3 = { class: "heaterSlide__title" }
const _hoisted_4 = { class: "heaterSlide__main" }
const _hoisted_5 = { class: "heaterSlide__icon" }
const _hoisted_6 = ["xlink:href"]
const _hoisted_7 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(['icon icon-power']),
  "aria-hidden": "true"
}
const _hoisted_8 = {
  key: 2,
  class: /*@__PURE__*/_normalizeClass(['heaterSlide__icon-text'])
}
const _hoisted_9 = {
  key: 0,
  class: "heaterSlide__mode"
}
const _hoisted_10 = {
  key: 1,
  class: "heaterSlide__mode"
}

import {TAppliancesSlide} from "./interfaces"
import {EApplianceMode, EApplianceProgType} from "@/services/Appliances/interfaces";
import {heatingType} from "@/helpers/domains/heatingType";
import {computed} from "vue";
import {digitalFormat} from "@/helpers/number/formatNumber";
import {useI18n} from "vue-i18n";

export interface Props {
  name: TAppliancesSlide['name'];
  heatingLevel: TAppliancesSlide['heatingLevel'];
  isOn: TAppliancesSlide['isOn'];
  mode: TAppliancesSlide['mode'];
  link: TAppliancesSlide['link'];
  type?: TAppliancesSlide['type'];
  temp?: TAppliancesSlide["temp"];
  isFrostProtection?: TAppliancesSlide["isFrostProtection"];
  progType?: TAppliancesSlide["progType"];
  progName?: TAppliancesSlide["progName"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesSlide',
  props: {
    name: { default: undefined },
    heatingLevel: { default: undefined },
    isOn: { type: Boolean, default: false },
    mode: { default: undefined },
    link: { default: undefined },
    type: { default: undefined },
    temp: { default: undefined },
    isFrostProtection: { type: Boolean, default: false },
    progType: { default: undefined },
    progName: { default: '' }
  },
  setup(__props: any) {

const props = __props

const {t} = useI18n()

const formatTemp = computed(() => {
  return props.temp ? digitalFormat(props.temp, 1) : ''
})

const formattedName = computed((): string => {
  const name = props.name || "";
  return name.length > 25 ? name.slice(0, 25) + "..." : name;
});


const hasNoProg = computed(() => {
  return props.progType! === EApplianceProgType.Default;
})

const iconName = computed(() => {
  if (hasNoProg.value) {
    return 'prog-off';
  }
  switch (props.mode) {
    case EApplianceMode.Comfort:
    case EApplianceMode.Comfort1:
    case EApplianceMode.Comfort2:
      return 'sun';
    case EApplianceMode.Eco:
    case EApplianceMode.EcoV:
      return 'moon';
    case EApplianceMode.FrostProtection:
      return 'snow1';
    case EApplianceMode.Temp:
      return 'settings';
    case EApplianceMode.Normal:
      return 'power';
  }
  throw new Error(`Appliance's mode ${props.mode} not supported !`);
})

const getHeatingType = computed(() => {
  if (hasNoProg.value) {
    return t('appliance_progType.default')
  }
  return t(heatingType(props.mode, props.isOn));
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    class: _normalizeClass(['heaterSlide', {'heaterSlide--green': _ctx.isOn && !_ctx.isFrostProtection && !hasNoProg.value}, {'heaterSlide--noProg': hasNoProg.value}]),
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(formattedName.value), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            (_ctx.isOn)
              ? (_openBlock(), _createElementBlock("svg", {
                  key: 0,
                  class: _normalizeClass(['icon icon-' + iconName.value]),
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", {
                    "xlink:href": ['#icon-' + iconName.value]
                  }, null, 8, _hoisted_6)
                ], 2))
              : (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[0] || (_cache[0] = [
                  _createElementVNode("use", { "xlink:href": ['#icon-power'] }, null, -1)
                ]))),
            (_ctx.isOn && _ctx.mode==_unref(EApplianceMode).Temp)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                  _createTextVNode(_toDisplayString(formatTemp.value), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("svg", {
                    class: "heaterSlide__icon-celcius icon icon-celcius",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-celcius" })
                  ], -1))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isOn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(getHeatingType.value), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("mode.off")), 1))
        ])
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}
}

})