import {TText} from "./interfaces";

const regexpFontSize = /(\d+)px/;

const insertFontFamily = (baseFont: string, fontFamily?: string) => {
    if (fontFamily) {
        const indexAfterPx = baseFont.indexOf('px') + 2;

        return baseFont.slice(0, indexAfterPx) + ' ' + fontFamily;
    }
    return baseFont;
}

export const evalutateFontSize = (ctx: any, maxWidth: number, baseFont: string, fontFamily?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let fontSize = parseInt(baseFont.match(regexpFontSize)![1]);

    baseFont = insertFontFamily(baseFont, fontFamily);
    do {
        ctx.font = baseFont.replace(regexpFontSize, `${fontSize}px`);
        const textWidth = ctx.measureText('1').width;

        // check if it fits, is within configured limits and that we are not simply toggling back and forth
        if (textWidth < maxWidth)
            fontSize += 1;
        else {
            // reverse last step
            fontSize /= 2;
            break;
        }
    // eslint-disable-next-line no-constant-condition
    } while (true);

    return fontSize;
};

export const getTextsDimensions = (ctx: any, lines: TText[][], fontSize: number) => {
    const baseFont = ctx.font;
    const linesWidth = [];
    const linesHeight = [];

    for (const line of lines) {
        let lineWidth = 0;
        let height = 0;

        for (const { value = '', size = 100 } of line) {
            ctx.font = baseFont.replace(regexpFontSize, `${Math.ceil(fontSize * size / 100)}px`);
            
            const metric = ctx.measureText(value || 'E');

            lineWidth += metric.width;
            height = Math.max(height, metric.actualBoundingBoxAscent + metric.actualBoundingBoxDescent);
        }
        linesWidth.push(lineWidth);
        linesHeight.push(height);
    }

    return [linesWidth, linesHeight];
}

export const write = (ctx: any, lines: TText[][], linesWidth: number[], linesHeight: number[], fontSize: number, initialCenterX: number, initialY: number, fontFamily?: string) => {
    const baseFont = insertFontFamily(ctx.font, fontFamily);
    let centerY = initialY;

    for (let i = 0; i < lines.length; ++i) {
        const line = lines[i];
        let centerX = initialCenterX - linesWidth[i] / 2;

        for (const { value = '', size = 100, color = 'rgb(0, 0, 0)', bold = false, removeSpace = false } of line) {
            if (value) {
                ctx.save();
                ctx.font = (bold ? 'bold ' : '') + baseFont.replace(regexpFontSize, `${Math.ceil(fontSize * size / 100)}px`);
                
                const metric = ctx.measureText(value);

                ctx.textBaseline = 'alphabetic';
                ctx.fillStyle = color;
                ctx.fillText(value, centerX, centerY);
                ctx.restore();

                centerX += metric.width + (removeSpace ? 0 : (metric.width / value.length) / 4);
            }
        }
        centerY += ((linesHeight.length - 1) > i) ? linesHeight[i + 1] * 1.5 : 0;
    }   
}