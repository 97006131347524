import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";

import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVContact from "@/components/domains/Profile/MyVContact/MyVContact.vue";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactView',
  setup(__props) {

const error = ref<string | null>(null)

const showError = (e: any) => {
  error.value = e.response ? e.response.data.message : 'Erreur serveur'
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVDisplayGroupStore, {
    currentRight: _unref(EDisplayGroupRightName).HELP,
    forbiddenRedirection: _unref(ERoutes).HOME
  }, {
    default: _withCtx(() => [
      _createVNode(MyVPageWithReturn, {
        title: _ctx.$t('profile.contact_us.title'),
        type: "page",
        "return-action": _unref(ERoutes).HOME,
        class: "Contact"
      }, {
        default: _withCtx(() => [
          (error.value)
            ? (_openBlock(), _createBlock(MyVErrorBanner, {
                key: 0,
                error: error.value,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = null)),
                class: _normalizeClass(['error-banner', _ctx.$style['error-banner']])
              }, null, 8, ["error", "class"]))
            : _createCommentVNode("", true),
          _createVNode(MyVContact, {
            "contact-text": _ctx.$t('profile.contact.info'),
            onError: showError
          }, null, 8, ["contact-text"])
        ]),
        _: 1
      }, 8, ["title", "return-action"])
    ]),
    _: 1
  }, 8, ["currentRight", "forbiddenRedirection"]))
}
}

})