import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "PageWithImg__inner" }

import {AuthModule, ConsentModule, IntlModule, SiteModule, ThemeModule} from "@/store";
import MyVPageWithImg from '@/components/ui/organisms/layouts/MyVPageWithImg/MyVPageWithImg.vue';
import MyVLangSelect from '@/components/ui/molecules/MyVLangSelect/MyVLangSelect.vue';
import MyVErrorBannerService from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBannerService.vue";
import {TErrors, TRequestError} from '@/services/Error';
import backgroundImg from "@/assets/images/login.jpg";
import backgroundImgMobile from "@/assets/images/login-mobile.jpg";
import ERoutes from "@/router/routes";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import Cookie from "@/helpers/cookie";
import {ELocale} from "@/store/modules/Intl";
import {computed, onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import MyVLoginForm from "@/components/domains/Profile/MyVLoginForm/MyVLoginForm.vue";
import {TLoginFormDatas} from "@/components/domains/Profile/MyVLoginForm/interfaces";
import {setToken} from "@/helpers/domains/mobileApps/flutter-connectors";
import {DISPLAY_GROUP_EKW, DISPLAY_GROUP_PRO} from "@/services/Theme/interfaces";
import {ECookieNames} from "@/helpers/cookie/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const errors = ref<TErrors | null>(null);
const router = useRouter()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const logoDark = computed(() => {
  return ThemeModule.theme?.staticResources.logo;
})

const hasParticularLocale = computed(() => {
  return Cookie.hasParticularLocale();
})

const onSubmit = ({username, password}: TLoginFormDatas) => {
  AuthModule.login({username, password, onBeforeLog: isSiteCountryFrance.value ? ConsentModule.getConsentEnedis?.bind(ConsentModule) : undefined})
      .then((user) => {
        if (user) {
          setToken(Cookie.get(ECookieNames.TOKEN), user.defaultSite.id)
          Cookie.set(ECookieNames.EKWATEUR_LOCALE, user.displayGroup.name === DISPLAY_GROUP_EKW ? 'true' : 'false');
          Cookie.set(ECookieNames.PRO_LOCALE, user.displayGroup.name === DISPLAY_GROUP_PRO ? 'true' : 'false');

          if (user.displayGroup.name === DISPLAY_GROUP_EKW) {
            IntlModule.setLocale(ELocale.fr_EKW)
          }
          if (user.displayGroup.name === DISPLAY_GROUP_PRO) {
            IntlModule.setLocale(ELocale.fr_PPRO)
          }
          if (user.firstConnection && user.migratedUser) {
            return router.push(ERoutes.FIRST_CONNECTION);
          }
        }
        router.push(ERoutes.HOME);
      })
      .catch(({response: {data}}: TRequestError) => {
        if (data) {
          errors.value = data;
        }
      });
}

onBeforeMount(() => {
  pageScroll(true);
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyVPageWithImg, {
      title: _ctx.$t('login.title'),
      backgroundImg: _unref(backgroundImg),
      backgroundImgMobile: _unref(backgroundImgMobile),
      logo: logoDark.value,
      class: "LoginPage"
    }, _createSlots({
      footer: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.footer)
        }, [
          _createVNode(_component_router_link, {
            "data-testid": "login-forgot-password-link",
            to: _unref(ERoutes).FORGOT_PASSWORD,
            class: _normalizeClass(_ctx.$style.footer__link)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forgotpassword.link')), 1)
            ]),
            _: 1
          }, 8, ["to", "class"]),
          _createVNode(_component_router_link, {
            "data-testid": "login-contact-support-link",
            to: _unref(ERoutes).CONTACT_NOT_AUTH,
            class: _normalizeClass(_ctx.$style.footer__link)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contact.link')), 1)
            ]),
            _: 1
          }, 8, ["to", "class"])
        ], 2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(MyVErrorBannerService, { errors: errors.value }, null, 8, ["errors"]),
          _createVNode(MyVLoginForm, {
            onSubmit: onSubmit,
            errors: errors.value
          }, null, 8, ["errors"])
        ])
      ]),
      _: 2
    }, [
      (!hasParticularLocale.value)
        ? {
            name: "lang",
            fn: _withCtx(() => [
              _createVNode(MyVLangSelect)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title", "backgroundImg", "backgroundImgMobile", "logo"]),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }))
  ], 64))
}
}

})