import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "Profile__container inner-sm-min" }
const _hoisted_3 = { class: "Profile__left" }
const _hoisted_4 = { class: "Profile__right" }
const _hoisted_5 = { class: "mobilePopin" }

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";

import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import ProfileMenu from "@/components/ui/organisms/MyVProfileMenu/MyVProfileMenu.vue";
import logo from "@/assets/images/logo_beta.svg?url";
import ERoutes from "@/router/routes";

import {AuthModule} from "@/store";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

const router = useRouter()

const logout = async () => {
  await AuthModule.logout();
  await router.push(ERoutes.HOME);
}


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: _unref(logo),
    title: _ctx.$t('app.title'),
    class: "profile-view"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVHeaderMainPage, { title: "profile.title" }, {
          HeaderMainPage__right: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "Profile__logout",
              onClick: logout
            }, _toDisplayString(_ctx.$t('global.logout')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ProfileMenu)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_router_view, {
              key: _ctx.$route.fullPath
            }))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["homeLink", "logo", "title"]))
}
}

})