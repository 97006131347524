import {ArcElement, DoughnutController} from "chart.js";
import {TText, TTooltipOptions} from "@/graphs/interfaces";
import {evalutateFontSize, getTextsDimensions, write} from "@/graphs/tools";
import {Octree2D} from "@/helpers/domains/octree";

export default class RoundedDoughnut extends DoughnutController {
    draw() {
        const ctx = this.chart.ctx;
        const baseFont = ctx.font;
        const fontFamily = (this.chart as any)._options.fontFamily as string | undefined;
        const arcs = this.getMeta().data as unknown as ArcElement[];
        const lines: TText[][] | undefined = (this.chart as any)._options.texts;
        const tooltip = (this.chart as any)._options.tooltip as TTooltipOptions;
        const tooltipSectionIsEnabled = arcs.length > 0 && Array.isArray(tooltip?.texts) && tooltip.texts.length > 0;
        const multipleTooltipSectionEnabled = arcs.length > 0 && Array.isArray(tooltip) && tooltip.length > 0 && tooltip.length <= arcs.length;
        const tooltipDatas: any[] = [];
        const circumference: number = (this.chart.data.datasets[0] as any).circumference || (this.chart.options as any).circumference;
        let lastCircle: any;

        // Calculate tooltip dimensions if enabled.
        if (tooltipSectionIsEnabled || multipleTooltipSectionEnabled) {
            for (const _tooltip of (Array.isArray(tooltip) ? tooltip : [tooltip])) {
                const padding = 8;
                const tooltipData: any = {
                    width: 0, // Initial padding
                    height: padding, // Initial padding
                    fontSize: evalutateFontSize(ctx, Math.min(16, (arcs[0] as any).innerRadius / 9), baseFont, fontFamily),
                    backgroundColor: _tooltip.backgroundColor || 'rgb(255, 255, 255)'
                };
                const [linesWidth, linesHeight] = getTextsDimensions(ctx, _tooltip.texts, tooltipData.fontSize);

                for (let i = 0; i < linesHeight.length; ++i) {
                    tooltipData.width = Math.max(tooltipData.width, linesWidth[i]);
                    tooltipData.height += linesHeight[i] + (((linesHeight.length - 1) > i) ? linesHeight[i + 1] * 1.5 : 0);
                }
                tooltipData.width += padding * 2;
                tooltipData.linesWidth = linesWidth;
                tooltipData.linesHeight = linesHeight;
                tooltipDatas.push(tooltipData);
            }
        }

        // Try to change layout right but didn't work.
        //(this.chart as any)._options.layout.right = tooltipDatas.width;
        super.draw();

        // Make path border rounded.
        arcs.forEach((arc: any, i, _arcs) => {
            const prevArc = _arcs[i === 0 ? 0 : i - 1];
            const radius = (arc.outerRadius + arc.innerRadius) / 2;
            const thickness = (arc.outerRadius - arc.innerRadius) / 2;
            const startAngle = Math.PI - arc.startAngle - Math.PI / 2;
            const angle = Math.PI - arc.endAngle - Math.PI / 2;

            ctx.save();
            ctx.translate(arc.x, arc.y);

            if (circumference !== 360) {
                ctx.fillStyle = prevArc.options.backgroundColor as string;
                ctx.beginPath();
                ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);
                ctx.fill();
            }

            ctx.fillStyle = arc.options.backgroundColor as string;
            ctx.beginPath();
            ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
            ctx.fill();

            ctx.restore();

            lastCircle = {
                x: arc.x as number,
                y: arc.y as number,
                radius,
                angle,
                thickness
            };
        });

        // Write centered text.
        if (lines && arcs.length > 0) {
            const fontSize = evalutateFontSize(ctx, (arcs[0] as any).innerRadius / 3 - 4, baseFont, fontFamily);
            const [linesWidth, linesHeight] = getTextsDimensions(ctx, lines, fontSize);

            write(
                ctx,
                lines,
                linesWidth,
                linesHeight,
                fontSize,
                (this.chart.chartArea.left + this.chart.chartArea.right) / 2,
                (this.chart.chartArea.top + this.chart.chartArea.bottom) / 2,
                fontFamily
            );
        }

        // Write tooltips.
        if (tooltipSectionIsEnabled) {
            const {
                innerColor = 'rgb(0, 0, 0)',
                outerColor = 'rgb(0, 0, 0)'
            } = tooltip.circle || {};
            const x = lastCircle.radius * Math.sin(lastCircle.angle);
            const y = lastCircle.radius * Math.cos(lastCircle.angle);
            const colors = [outerColor, innerColor];
            const sizeThickness = lastCircle.thickness / (colors.length + 1);

            ctx.save();
            ctx.translate(lastCircle.x, lastCircle.y);

            // Render Circles.
            for (let i = 1; i <= colors.length; ++i) {
                ctx.fillStyle = colors[i - 1];
                ctx.beginPath();
                ctx.arc(x, y, lastCircle.thickness - sizeThickness * i, 0, 2 * Math.PI);
                ctx.fill();
            }

            // Render line between circles and tooltip rectangle.
            const lineLength = sizeThickness * 1.5;

            ctx.translate(x + sizeThickness * colors.length, y);

            ctx.beginPath();
            ctx.strokeStyle = innerColor;
            ctx.moveTo(0, 0);
            ctx.lineTo(lineLength, 0);
            ctx.stroke();

            ctx.translate(lineLength, -tooltipDatas[0].height / tooltipDatas[0].linesHeight.length);

            // Render tooltip rectangle.
            ctx.beginPath();
            ctx.fillStyle = 'rgb(255, 255, 255)';
            ctx.rect(0, 0, tooltipDatas[0].width, tooltipDatas[0].height);
            ctx.fill();

            ctx.translate(tooltipDatas[0].width / 2, tooltipDatas[0].height / tooltipDatas[0].linesHeight.length);

            write(ctx, tooltip.texts, tooltipDatas[0].linesWidth, tooltipDatas[0].linesHeight, tooltipDatas[0].fontSize, 0, 0, fontFamily);

            ctx.restore();
        }
        else if (multipleTooltipSectionEnabled) {
            const chartCenterPoint = {
                x:
                    (this.chart.chartArea.right - this.chart.chartArea.left) / 2 +
                    this.chart.chartArea.left,
                y:
                    (this.chart.chartArea.bottom - this.chart.chartArea.top) / 2 +
                    this.chart.chartArea.top
            };

            for (let i = 0; i < tooltip.length; ++i) {
                const octree = new Octree2D(0, 0, ctx.canvas.width, ctx.canvas.height, 1);
                const currentTooltip = tooltip[i] as TTooltipOptions;
                const arc = arcs[i];
                const centerPoint = arc.getCenterPoint();
                const color = currentTooltip.arrowColor || '#000';

                const rayX = centerPoint.x - chartCenterPoint.x;
                const rayX2 = rayX * rayX;
                const rayY = centerPoint.y - chartCenterPoint.y;
                const rayY2 = rayY * rayY;
                const rayLength = Math.sqrt(rayX2 + rayY2) + 8;

                // Calculate the radius direction.
                const halfRadiusLength = ((arc as any).outerRadius - (arc as any).innerRadius) / 2;
                const pointX = centerPoint.x + halfRadiusLength * (rayX / rayLength);
                const pointY = centerPoint.y + halfRadiusLength * (rayY / rayLength);

                const xDirection = centerPoint.x < chartCenterPoint.x ? -1 : 1;
                const triangleLength = xDirection > 0 ? 5 : -5;
                const moveX = xDirection > 0 ? 0 : tooltipDatas[i].width;
                const moveY = tooltipDatas[i].height / 2;
                const pointFinalX = pointX + Math.max((rayLength - Math.sqrt(rayX2)) * 0.8, 20) * xDirection;
        
                const node = {
                    x: pointFinalX - moveX,
                    y: pointY,
                    width: tooltipDatas[i].width,
                    height: tooltipDatas[i].height
                };
                let nbTry = 0;
                
                do {
                    if (octree.lastCollision) {
                        node.x = octree.lastCollision[0].x + octree.lastCollision[0].width / 2;
                        node.y = octree.lastCollision[0].y + octree.lastCollision[0].height + moveY;
                    }
                    ++nbTry;
                }
                while (octree.addNode(node.x, node.y, node.width, node.height) && nbTry < tooltip.length);
                octree.clearCollision();

                //DRAW CODE
                ctx.save();
                
                // first line: connect between arc's center on the border and tooltip
                ctx.strokeStyle = color;
                ctx.beginPath();
                ctx.moveTo(pointX, node.y);
                ctx.lineTo(node.x + moveX, node.y);
                ctx.stroke();

                ctx.translate(node.x, node.y - moveY);

                // Render arrow triangle.
                ctx.beginPath();
                ctx.fillStyle = color;
                ctx.moveTo(moveX, tooltipDatas[i].height / 2);
                ctx.lineTo(-triangleLength + moveX, triangleLength + moveY);
                ctx.lineTo(-triangleLength + moveX, -triangleLength + moveY);
                ctx.fill();

                // Render tooltip's background
                ctx.beginPath();
                ctx.fillStyle = tooltipDatas[i].backgroundColor;
                ctx.rect(0, 0, tooltipDatas[i].width, tooltipDatas[i].height);
                ctx.fill();

                ctx.translate(tooltipDatas[i].width / 2, tooltipDatas[i].height / tooltipDatas[i].linesHeight.length - 4);
                write(ctx, currentTooltip.texts, tooltipDatas[i].linesWidth, tooltipDatas[i].linesHeight, tooltipDatas[i].fontSize, 0, 0, fontFamily);

                ctx.restore();
            }
        }
    }
}

RoundedDoughnut.id = 'roundedDoughnut';
RoundedDoughnut.defaults = {
    ...DoughnutController.defaults,
    circumference: 220,
    rotation: -110,
    cutout: '80%',
};