import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment, renderList as _renderList, normalizeStyle as _normalizeStyle, Transition as _Transition, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "popinForm__main" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["xlink:href"]
const _hoisted_6 = ["xlink:href"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["xlink:href"]
const _hoisted_9 = { class: "BottomPopin__text" }
const _hoisted_10 = { class: "BottomPopin__title" }
const _hoisted_11 = { class: "BottomPopin__subtitle" }
const _hoisted_12 = { class: "BottomPopin__buttons" }
const _hoisted_13 = { class: "BottomPopin__text" }
const _hoisted_14 = { class: "BottomPopin__title show-ts" }
const _hoisted_15 = { class: "BottomPopin__subtitle" }
const _hoisted_16 = { class: "BottomPopin__buttons" }

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import ERoutes from "@/router/routes";
import {AuthModule, ContractModule, IntlModule, SiteModule, SwitchConsoModule} from "@/store";
import {EPdlStates, TContract, TContractTimePeriods} from "@/services/Contract/interfaces";
import {
  g_firstDayTime,
  g_lastDayTime,
  mergePeriods,
  splitTimePeriods as splitTimePeriodsHelper
} from "@/helpers/domains/dailyprogs";
import Cookie from "@/helpers/cookie";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {notification} from "ant-design-vue";
import MyVPdlValidationModal
  from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationModal/MyVPdlValidationModal.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {useDate} from "@/helpers/dates/date-utils";
import {EButtonSize} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import useCurrency from "@/composables/currency";
import {useResponsive} from "@/composables/useResponsive";
import {useCookieNames} from "@/composables/useCookieNames";
import {NUMBER_OF_DAYS_IN_MONTH} from "@/helpers/dates/date";

interface Props {
  type?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileStepView',
  props: {
    type: { default: 'page' }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {




const emit = __emit

const showDeletePopin = ref(false);
const showPdlValidationModal = ref(false);
const showBottomContractPopin = ref(false);
const selectedIds = ref<number[]>([]);
const contractToEdit = ref<number | null>(null);
const showPastContracts = ref(false)

const router = useRouter()
const route = useRoute()
const {t} = useI18n()
const {isMobile} = useResponsive()
const {formatCurrency} = useCurrency()
const {contractNotificationCookie} = useCookieNames()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)
const isSiteCountryUK = computed(() => SiteModule.isSiteCountryUK)

const contractsList = computed((): TContract[] => {
  return ContractModule.contractsList.sort((a, b) => {
    if (a.startDate === null) return 1; // a is the ancient contract
    if (b.startDate === null) return -1;  // b is the ancient contract
    // Both a and b have startDate and endDate, so compare startDate
    const aStartDate: Date = new Date(a.startDate);
    const bStartDate: Date = new Date(b.startDate);
    return bStartDate.getTime() - aStartDate.getTime();
  });
})

const currentContract = computed(() => {
  return contractsList.value[0];
})

const locale = computed(() => {
  return IntlModule.locale;
})

const hasUnvalidPdl = computed(() => {
  return !ContractModule.isPdlValid;
})

const hasUploadedElectricityBill = computed(() => {
  return ContractModule.hasUploadedElectricityBill;
})

const hasDsoMeasure = computed<boolean | undefined>(() => AuthModule.hasDsoMeasure)

const handleBottomContractClose = () => {
  emit('close')
  showBottomContractPopin.value = false
}
const handleDeletePopinClose = () => {
  emit('close')
  showDeletePopin.value = false
}
const isInvalidPdl = computed(() => isSiteCountryFrance.value && hasUnvalidPdl.value && !hasDsoMeasure.value)

const goToContractEdition = (contractId: number | null, index: number | null, insert: number | null) => {
  if (contractId) {
    return router.push({
      path: `${ERoutes.CONTRACT}`,
      query: {
        mode: 'edit',
        contractId: contractId
      }
    });
  }

  if (index || insert) {
    return router.push({
      path: `${ERoutes.CONTRACT}`,
      query: {
        mode: 'create',
        index,
        insert
      }
    });
  }

  router.push({
    path: `${ERoutes.CONTRACT}`,
    query: {
      mode: 'create',
    }
  });
}

function calculatePrice(subscriptionBasePrice: number) {
  return isSiteCountryUK.value ? subscriptionBasePrice / NUMBER_OF_DAYS_IN_MONTH : subscriptionBasePrice
}

const splitTimePeriods = (contract: TContract) => {
  return splitTimePeriodsHelper(contract.offpeakHours);
}

const isSelected = (id: number | null) => {
  if (id)
    return selectedIds.value!.includes(id);

  return false
}

const addOrRemoveId = (id: number) => {
  if (selectedIds.value!.includes(id)) {
    const index = selectedIds.value!.indexOf(id);

    selectedIds.value!.splice(index, 1);
    return
  }
  selectedIds.value!.push(id);
}

const formattedOffpeak = (contract: TContract) => {
  const checkedPeriods: TContractTimePeriods = checkPeriods(contract);
  const hours = [
    ...checkedPeriods.map(data => {
      return {
        from: useDate(data.from, 'HH:mm').format("HH[H]mm"),
        to: useDate(data.to, 'HH:mm').format("HH[H]mm"),
      }
    }),
  ];

  return hours.slice().sort((a, b) => {
    const _aDate = useDate(a.from, 'HH[H]mm');
    const _bDate = useDate(b.from, 'HH[H]mm');
    if (_aDate.isBefore(_bDate.toDate())) {
      return -1;
    } else if (_aDate.isAfter(_bDate.toDate())) {
      return 1;
    }

    return 0;
  });
}

const openEditContractPopin = (id: number | null) => {
  if (contractsList.value.findIndex(contract => contract.id === id) !== 0) {
    return goToContractEdition(id, null, null);
  }
  showBottomContractPopin.value = true;
  contractToEdit.value = id;
}

const editContract = () => {
  goToContractEdition(contractToEdit.value, null, null);
}

const offPeakWidth = (from: any, to: any) => {
  const fromSplit = from.split(':');
  const toSplit = to.split(':');
  from = parseFloat(from);
  to = parseFloat(to);

  if (fromSplit[1] == 30) {
    from += .5;
  } else if (fromSplit[1] > 30) {
    from += 1;
  }

  if (toSplit[1] == 30) {
    to += .5;
  } else if (toSplit[1] > 30) {
    to += 1;
  }

  return Math.abs(((100 / 24) * (from - to))).toString() + '%';
}

const offPeakStart = (from: any) => {
  const fromSplit = from.split(':');
  from = parseFloat(from);

  if (fromSplit[1] == 30) {
    from += .5;
  } else if (fromSplit[1] > 30) {
    from += 1;
  }

  return Math.abs((100 / 24) * from).toString() + '%';
}

const checkPeriods = (contract: TContract) => {
  let startMidnight = false;
  let endMidnight = false;
  contract.offpeakHours.forEach((hour) => {
    if (hour.from === g_firstDayTime) {
      startMidnight = true;
    } else if (hour.to === g_lastDayTime) {
      endMidnight = true;
    }
  });

  if (startMidnight && endMidnight) {
    return mergePeriods(contract.offpeakHours);
  }

  return splitTimePeriodsHelper(contract.offpeakHours);
}

const confirmDelete = () => {
  SwitchConsoModule._setSwitchType(true);
  ContractModule.deleteAllContracts().then(() => {
    notification.success({
      message: t('profile.contract.delete_success_notification.title'),
      description: t('profile.contract.delete_success_notification.desc'),
      duration: 3
    })
  }).finally(() => {
    ContractModule.getContractsList()
  });
  showDeletePopin.value = false;
}

const contractDate = (startDate: string, endDate: string) => {
  if (!startDate && !endDate) return;
  if (startDate && !endDate) return t('profile.contract.actual_contract', {date: useDate(startDate).locale(locale.value).format('DD MMM YYYY')});
  if (!startDate && endDate) return t('profile.contract.past_contract', {endDate: useDate(endDate).locale(locale.value).format('DD MMM YYYY')})

  return `${useDate(startDate).locale(locale.value).format('MMM YYYY')} - ${useDate(endDate).locale(locale.value).format('MMM YYYY')}`
}

const setContractNotifCookie = () => {
  const cookieExpirationDate = useDate().add(1, 'month').format("YYYY-MM-DD[T]HH:mm:ss");

  Cookie.set(contractNotificationCookie.value, '1', {
    expires: cookieExpirationDate
  });
}

onBeforeMount(async () => {
  setContractNotifCookie();
  await Promise.all([
    ContractModule.getContractsList(),
    isSiteCountryFrance.value ? ContractModule.getPdlValidity() : undefined,
    SiteModule.getSites()])

  if (!contractsList.value.length) {
    await router.replace({
      path: ERoutes.CONTRACT,
      query: {
        mode: 'create'
      }
    })
  } else {
    addOrRemoveId(contractsList.value[0]!.id!);
  }
  contractToEdit.value = null;
})

watch(route, () => {
  if (contractsList.value.length) {
    addOrRemoveId(contractsList.value[0].id!);
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.ProfileStep)
  }, [
    (isInvalidPdl.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.ProfileStep__pdlContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.ProfileStep__pdlHeader)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('contract.pdl.title')), 1),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.ProfileStep__pdlHeaderIcon)
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("svg", {
                class: "icon icon-warning-sign",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-warning-sign" })
              ], -1)
            ]), 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.ProfileStep__pdlBody),
            innerHTML: _ctx.$t('contract.pdf.description')
          }, null, 10, _hoisted_1),
          (_unref(ContractModule).pdlState === _unref(EPdlStates).notEnedis)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('conso.tooltip.notEnedis')), 1))
            : _createCommentVNode("", true),
          (!hasUploadedElectricityBill.value)
            ? (_openBlock(), _createBlock(MyVButton, {
                key: 1,
                size: _unref(EButtonSize).Small,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showPdlValidationModal.value = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.fix_data_collection')), 1)
                ]),
                _: 1
              }, 8, ["size"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.ProfileStep__form, 'popinForm'])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.noProviderBlock)
        }, [
          (!contractsList.value.length)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.$t('contract.intro_step.text')
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createVNode(MyVButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (goToContractEdition(null, null, null)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.create_contract')), 1)
            ]),
            _: 1
          })
        ], 2),
        (contractsList.value[0])
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.contractsTitle)
              }, _toDisplayString(_ctx.$t('profile.contract.currentContract')), 3),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.providerContractsList)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.providerContractContainer),
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (addOrRemoveId(currentContract.value.id!)), ["prevent","stop"]))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.providerContractBlock)
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.contractHeaderInfos)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.contractCompanyName)
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.$t(currentContract.value.companyName)) + " ", 1),
                        _createVNode(MyVIcon, {
                          class: _normalizeClass(_ctx.$style.editContractPen),
                          name: "pen",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (openEditContractPopin(currentContract.value.id)), ["prevent","stop"]))
                        }, null, 8, ["class"])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.providerContractValue)
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(currentContract.value.name)), 1),
                        (_openBlock(), _createElementBlock("svg", {
                          class: _normalizeClass(['icon', `${(isSelected(currentContract.value.id) ? 'icon-circle-up' : 'icon-circle-down')}`]),
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", {
                            "xlink:href": `${(isSelected(currentContract.value.id) ? '#icon-circle-up' : '#icon-circle-down')}`
                          }, null, 8, _hoisted_5)
                        ], 2))
                      ], 2),
                      _createElementVNode("h2", null, _toDisplayString(contractDate(currentContract.value.startDate!, currentContract.value.endDate!)), 1)
                    ], 2)
                  ], 2)
                ], 2),
                _createVNode(_Transition, { name: "slide" }, {
                  default: _withCtx(() => [
                    (isSelected(currentContract.value.id))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.contractDatasContainer)
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style.contractDatasBlock)
                          }, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('global.options')), 1),
                            (currentContract.value.subscribedPower)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(_ctx.$style.contractData)
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.subscribed_power')), 1),
                                  _createElementVNode("span", null, _toDisplayString(`${currentContract.value.subscribedPower} ${_unref(EEnergyUnit).kVA}`), 1)
                                ], 2))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.contractData)
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.contract_type_label')), 1),
                              _createElementVNode("span", null, _toDisplayString(currentContract.value.subscriptionPeakAndOffPeakHourBasePrice ? _ctx.$t('profile.contract.hours_type') : _ctx.$t('profile.contract.base_type')), 1)
                            ], 2)
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style.contractDatasBlock)
                          }, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('profile.contract.tarif_title')), 1),
                            (currentContract.value.subscriptionPeakAndOffPeakHourBasePrice)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style.contractData)
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(isSiteCountryUK.value ? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif')), 1),
                                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(calculatePrice(currentContract.value.subscriptionPeakAndOffPeakHourBasePrice), isSiteCountryUK.value ? 4 : undefined)), 1)
                                  ], 2),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style.contractData)
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.peak_hour_price')), 1),
                                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(currentContract.value.kwhPeakHourPrice!, isSiteCountryUK.value ? 4 : undefined)), 1)
                                  ], 2),
                                  (currentContract.value.kwhOffpeakHourPrice)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style.contractData)
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.offpeak_hour_price')), 1),
                                        _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(currentContract.value.kwhOffpeakHourPrice, isSiteCountryUK.value ? 4 : undefined)), 1)
                                      ], 2))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style.contractData)
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(isSiteCountryUK.value ? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif')), 1),
                                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(calculatePrice(currentContract.value.subscriptionBasePrice!), isSiteCountryUK.value ? 4 : undefined)), 1)
                                  ], 2),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style.contractData)
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.kwh_base_price')), 1),
                                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(currentContract.value.kwhBasePrice!, isSiteCountryUK.value ? 4 : undefined)), 1)
                                  ], 2)
                                ], 64))
                          ], 2),
                          (currentContract.value.subscriptionPeakAndOffPeakHourBasePrice)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style.paddingContainer)
                              }, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style.contractOffPeakContainer)
                                }, [
                                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('profile.contract.offpeak_period')), 1),
                                  _createElementVNode("div", {
                                    class: _normalizeClass([_ctx.$style.ProfileStep__form__offpeak__progress])
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([_ctx.$style.ProfileStep__form__offpeak__progress__bar])
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitTimePeriods(currentContract.value), (el, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          style: _normalizeStyle({
                            position: 'absolute',
                            background: '#97be0d',
                            height: '100%',
                            width: offPeakWidth(el.from, el.to),
                            left: offPeakStart(el.from)
                          })
                                        }, null, 4))
                                      }), 128))
                                    ], 2)
                                  ], 2),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedOffpeak(currentContract.value), (data, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: _normalizeClass([_ctx.$style.contractOffpeakInfoContainer, _ctx.$style.isOffPeak]),
                                      key: index
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.period', {index})) + " - " + _toDisplayString(_ctx.$t('profile.contract.offpeak_hours')), 1),
                                      _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style.contractOffpeakInfo)
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(data.from), 1),
                                        _cache[10] || (_cache[10] = _createElementVNode("svg", {
                                          class: "icon icon-arrow-right",
                                          "aria-hidden": "true"
                                        }, [
                                          _createElementVNode("use", { "xlink:href": "#icon-arrow-right" })
                                        ], -1)),
                                        _createElementVNode("span", null, _toDisplayString(data.to), 1)
                                      ], 2)
                                    ], 2))
                                  }), 128))
                                ], 2)
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 2))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (currentContract.value.isDefault)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.providerContractsListDefaultContract)
                    }, _toDisplayString(_ctx.$t('profile.contract.defaultContract')), 3))
                  : _createCommentVNode("", true)
              ], 2)
            ], 64))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.noContracts)
            }, _toDisplayString(_ctx.$t('contract.choose_contract_step.no_contract_found')), 3)),
        (contractsList.value.length > 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass([_ctx.$style.contractsTitle, _ctx.$style.contractsTitlePast, showPastContracts.value ? _ctx.$style.contractsTitlePastOpen : '']),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (showPastContracts.value = !showPastContracts.value ))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.pastContracts')) + " ", 1),
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(['icon', `${showPastContracts.value ? 'icon-circle-up' : 'icon-circle-down'}`]),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": `${showPastContracts.value ? '#icon-circle-up' : '#icon-circle-down'}`
                }, null, 8, _hoisted_6)
              ], 2))
            ], 2))
          : _createCommentVNode("", true),
        (showPastContracts.value)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(contractsList.value, (contract, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${contract.id}-${index}`
              }, [
                (contractsList.value.length && index > 0 )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.providerContractsList)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.providerContractContainer),
                        onClick: ($event: any) => (addOrRemoveId(contract.id!))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style.providerContractBlock)
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style.contractHeaderInfos)
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.contractCompanyName)
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.$t(contract.companyName)) + " ", 1),
                              _createVNode(MyVIcon, {
                                class: _normalizeClass(_ctx.$style.editContractPen),
                                name: "pen",
                                onClick: ($event: any) => (openEditContractPopin(contract.id))
                              }, null, 8, ["class", "onClick"])
                            ], 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.providerContractValue)
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t(contract.name)), 1),
                              (_openBlock(), _createElementBlock("svg", {
                                class: _normalizeClass(['icon', `${(isSelected(contract.id) ? 'icon-circle-up' : 'icon-circle-down')}`]),
                                "aria-hidden": "true"
                              }, [
                                _createElementVNode("use", {
                                  "xlink:href": `${(isSelected(contract.id) ? '#icon-circle-up' : '#icon-circle-down')}`
                                }, null, 8, _hoisted_8)
                              ], 2))
                            ], 2),
                            _createElementVNode("h2", null, _toDisplayString(contractDate(contract.startDate!, contract.endDate!)), 1)
                          ], 2)
                        ], 2)
                      ], 10, _hoisted_7),
                      _createVNode(_Transition, { name: "slide" }, {
                        default: _withCtx(() => [
                          (isSelected(contract.id))
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style.contractDatasContainer)
                              }, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style.contractDatasBlock)
                                }, [
                                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('global.options')), 1),
                                  (contract.subscribedPower)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style.contractData)
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.subscribed_power')), 1),
                                        _createElementVNode("span", null, _toDisplayString(`${contract.subscribedPower} ${_unref(EEnergyUnit).kVA}`), 1)
                                      ], 2))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style.contractData)
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.contract_type_label')), 1),
                                    _createElementVNode("span", null, _toDisplayString(contract.subscriptionPeakAndOffPeakHourBasePrice !== null ? _ctx.$t('profile.contract.hours_type') : _ctx.$t('profile.contract.base_type')), 1)
                                  ], 2)
                                ], 2),
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style.contractDatasBlock)
                                }, [
                                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('profile.contract.tarif_title')), 1),
                                  (contract.subscriptionPeakAndOffPeakHourBasePrice !== null)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(_ctx.$style.contractData)
                                        }, [
                                          _createElementVNode("span", null, _toDisplayString(isSiteCountryUK.value ? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif')), 1),
                                          _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(calculatePrice(contract.subscriptionPeakAndOffPeakHourBasePrice),isSiteCountryUK.value ? 4 : undefined)), 1)
                                        ], 2),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(_ctx.$style.contractData)
                                        }, [
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.peak_hour_price')), 1),
                                          _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(contract.kwhPeakHourPrice!, isSiteCountryUK.value ? 4 : undefined)), 1)
                                        ], 2),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(_ctx.$style.contractData)
                                        }, [
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.offpeak_hour_price')), 1),
                                          _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(contract.kwhOffpeakHourPrice!,isSiteCountryUK.value ? 4 : undefined)), 1)
                                        ], 2)
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(_ctx.$style.contractData)
                                        }, [
                                          _createElementVNode("span", null, _toDisplayString(isSiteCountryUK.value ? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif')), 1),
                                          _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(calculatePrice(contract.subscriptionBasePrice!),isSiteCountryUK.value ? 4 : undefined)), 1)
                                        ], 2),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(_ctx.$style.contractData)
                                        }, [
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.kwh_base_price')), 1),
                                          _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(contract.kwhBasePrice!,isSiteCountryUK.value ? 4 : undefined)), 1)
                                        ], 2)
                                      ], 64))
                                ], 2),
                                (contract.subscriptionPeakAndOffPeakHourBasePrice !== null)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: _normalizeClass(_ctx.$style.paddingContainer)
                                    }, [
                                      _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style.contractOffPeakContainer)
                                      }, [
                                        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('profile.contract.offpeak_period')), 1),
                                        _createElementVNode("div", {
                                          class: _normalizeClass([_ctx.$style.ProfileStep__form__offpeak__progress])
                                        }, [
                                          (_openBlock(), _createElementBlock("div", {
                                            key: `${contract.id}-${index}`,
                                            class: _normalizeClass([_ctx.$style.ProfileStep__form__offpeak__progress__bar])
                                          }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitTimePeriods(contract), (el, index) => {
                                              return (_openBlock(), _createElementBlock("div", {
                                                key: index,
                                                style: _normalizeStyle({
                            position: 'absolute',
                            background: '#97be0d',
                            height: '100%',
                            width: offPeakWidth(el.from, el.to),
                            left: offPeakStart(el.from)
                          })
                                              }, null, 4))
                                            }), 128))
                                          ], 2))
                                        ], 2),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedOffpeak(contract), (data, index) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            class: _normalizeClass([_ctx.$style.contractOffpeakInfoContainer, _ctx.$style.isOffPeak]),
                                            key: index
                                          }, [
                                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.period', {index})) + " - " + _toDisplayString(_ctx.$t('profile.contract.offpeak_hours')), 1),
                                            _createElementVNode("div", {
                                              class: _normalizeClass(_ctx.$style.contractOffpeakInfo)
                                            }, [
                                              _createElementVNode("span", null, _toDisplayString(data.from), 1),
                                              _cache[11] || (_cache[11] = _createElementVNode("svg", {
                                                class: "icon icon-arrow-right",
                                                "aria-hidden": "true"
                                              }, [
                                                _createElementVNode("use", { "xlink:href": "#icon-arrow-right" })
                                              ], -1)),
                                              _createElementVNode("span", null, _toDisplayString(data.to), 1)
                                            ], 2)
                                          ], 2))
                                        }), 128))
                                      ], 2)
                                    ], 2))
                                  : _createCommentVNode("", true)
                              ], 2))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      (contract.isDefault)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style.providerContractsListDefaultContract)
                          }, _toDisplayString(_ctx.$t('profile.contract.defaultContract')), 3))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          : _createCommentVNode("", true),
        (contractsList.value.length)
          ? (_openBlock(), _createBlock(MyVButton, {
              key: 4,
              class: _normalizeClass(_ctx.$style.ProfileStep__form__deleteContracts),
              size: _unref(EButtonSize).Small,
              round: "",
              primary: false,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (showDeletePopin.value=true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(isSiteCountryFrance.value ? _ctx.$t('profile.contract.delete_all_contracts') : _ctx.$t('profile.contract.buttons.deleteAll')), 1)
              ]),
              _: 1
            }, 8, ["class", "size"]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (showBottomContractPopin.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
          key: 1,
          type: "popin",
          onCancel: handleBottomContractClose,
          "return-action": handleBottomContractClose
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('profile.contract.popin.edit')), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('profile.contract.popin.edit_subtitle')), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(MyVButton, {
                  class: "BottomPopin__confirm",
                  onClick: editContract
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.popin.edit_my_contract')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, {
                  class: "BottomPopin__changeProvider",
                  primary: false,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (goToContractEdition(null, null, null)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.popin.change_provider')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 32))
      : _createCommentVNode("", true),
    (showDeletePopin.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
          key: 2,
          type: "popin",
          title: isSiteCountryFrance.value ? _ctx.$t('profile.contract.delete_all_contracts') : _ctx.$t('profile.contract.buttons.deleteAll'),
          onCancel: handleDeletePopinClose,
          "return-action": handleDeletePopinClose
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[12] || (_cache[12] = _createElementVNode("svg", {
                class: "icon icon-big-colored-delete-forever",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
              ], -1)),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(isSiteCountryFrance.value ? _ctx.$t('profile.contract.delete_all_contracts') : _ctx.$t('profile.contract.buttons.deleteAll')), 1),
                _createElementVNode("div", _hoisted_15, _toDisplayString(isSiteCountryFrance.value ? _ctx.$t('profile.contract.popin.subtitle') : _ctx.$t('profile.contract.popin.subtitle.deleteAll')), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(MyVButton, {
                  class: "BottomPopin__confirm",
                  onClick: confirmDelete
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, {
                  class: "BottomPopin__cancel",
                  primary: false,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (showDeletePopin.value = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 40, ["title"]))
      : _createCommentVNode("", true),
    (showPdlValidationModal.value)
      ? (_openBlock(), _createBlock(MyVPdlValidationModal, {
          key: 3,
          "is-previous-button-displayed": false,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (showPdlValidationModal.value = false))
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})