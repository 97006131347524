import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

import router from "@/router";
import {computed, onBeforeMount} from "vue";
import {EDisplayGroupRightType} from "@/services/DisplayGroup/interfaces";
import {TDisplayGroupProps} from "./interfaces";

interface Props {
  rights: TDisplayGroupProps['rights'];
  currentRight: TDisplayGroupProps['currentRight'];
  hideIfDisabled?: TDisplayGroupProps['hideIfDisabled'];
  forbiddenRight?: TDisplayGroupProps['forbiddenRight'];
  forbiddenRedirection?: TDisplayGroupProps['forbiddenRedirection'];

}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDisplayGroup',
  props: {
    rights: { default: undefined },
    currentRight: { default: undefined },
    hideIfDisabled: { type: Boolean, default: false },
    forbiddenRight: { default: undefined },
    forbiddenRedirection: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const getRight = computed(() => {
  return props.rights.find(({name}) => name === props.currentRight);
})

const display = computed(() => {
  const right = getRight;

  return right.value?.type === EDisplayGroupRightType.DISPLAY ? right.value.enabled : true;
})

const disabled = computed(() => {
  const right = getRight;

  return right.value?.type === EDisplayGroupRightType.ENABLE ? !right.value.enabled : false;
})

onBeforeMount(() => {
  const right = getRight;

  if (props.forbiddenRedirection && right.value?.enabled === false && right.value?.type === props.forbiddenRight) {
    router.push(props.forbiddenRedirection);
  }
})

return (_ctx: any,_cache: any) => {
  return (display.value && (!_ctx.hideIfDisabled || !disabled.value))
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        disabled: disabled.value
      })
    : _createCommentVNode("", true)
}
}

})