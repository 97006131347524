import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = { class: "popinForm__footer" }

import {onBeforeMount, reactive} from "vue";

import {Form} from 'ant-design-vue';

import {TDuplicateProgram} from "./interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  onSubmit: TDuplicateProgram["onSubmit"];
  returnAction?: TDuplicateProgram["returnAction"];
  programName?: TDuplicateProgram["programName"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDuplicateProgram',
  props: {
    onSubmit: { type: Function, default: undefined },
    returnAction: { default: undefined },
    programName: { default: '' }
  },
  setup(__props: any) {

const props = __props

const form = reactive({
  programName: props.programName
})

onBeforeMount(() => {
  form.programName = props.programName;
})

const onFinish = () => {
  props.onSubmit(form);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(Form), {
    model: form,
    onFinish: onFinish,
    class: "popinForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('program.duplicate.fieldset.name')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MyVAntdinput, {
                label: _ctx.$t('program.duplicate.label.name'),
                inputType: _unref(EInputType).Text,
                value: form.programName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.programName) = $event)),
                id: "HeaterName",
                inputRequired: true
              }, null, 8, ["label", "inputType", "value"])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(MyVButton, {
          type: _unref(EButtonType).Submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('button.submit_program_duplicate')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        (typeof(_ctx.returnAction)=='function')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
              class: "popinForm__footer-back",
              type: "button"
            }, _toDisplayString(_ctx.$t('global.cancel')), 1))
          : (_ctx.returnAction)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.returnAction,
                class: "popinForm__footer-back"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})