import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {AuthModule} from "@/store";

import {EDisplayGroupRightType} from "@/services/DisplayGroup/interfaces";

import MyVDisplayGroup from './MyVDisplayGroup.vue';
import {TDisplayGroupProps} from "./interfaces";
import {computed} from "vue";

interface Props {
  currentRight?: TDisplayGroupProps['currentRight'];
  hideIfDisabled?: TDisplayGroupProps['hideIfDisabled'];
  forbiddenRight?: TDisplayGroupProps['forbiddenRight'];
  forbiddenRedirection?: TDisplayGroupProps['forbiddenRedirection'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDisplayGroupStore',
  props: {
    currentRight: { default: undefined },
    hideIfDisabled: { type: Boolean, default: false },
    forbiddenRight: { default: EDisplayGroupRightType.ENABLE },
    forbiddenRedirection: { default: undefined }
  },
  setup(__props: any) {



const rights = computed(() => {
  return AuthModule.userOrNull?.displayGroup?.rights;
})

return (_ctx: any,_cache: any) => {
  return (_ctx.currentRight && rights.value)
    ? (_openBlock(), _createBlock(MyVDisplayGroup, {
        key: 0,
        rights: rights.value,
        currentRight: _ctx.currentRight,
        hideIfDisabled: _ctx.hideIfDisabled,
        forbiddenRight: _ctx.forbiddenRight,
        forbiddenRedirection: _ctx.forbiddenRedirection
      }, {
        default: _withCtx(({disabled}) => [
          _renderSlot(_ctx.$slots, "default", { disabled: disabled })
        ]),
        _: 3
      }, 8, ["rights", "currentRight", "hideIfDisabled", "forbiddenRight", "forbiddenRedirection"]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}
}

})