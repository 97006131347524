import {TSite} from "@/services/Site/interfaces";

type TGetSavings = (siteId: TSite["id"]) => Promise<TSavings>;

export type TSavingsService = {
    /**
     * Send a GET request to retrieve savings data.
     */
    getSavings: TGetSavings;
};


export type TSavings = {
    siteId: number;
    startDate: Date;
    hasNoPrograming: boolean;
    energyPeakHourSavingsInKWh: number;
    energyPeakHourSavingsInCurrency: number;
    energyOffPeakHourSavingsInKWh: number;
    energyOffPeakHourSavingsInCurrency: number;
    energyTotalSavingsInKWh: number;
    energyTotalSavingsInCurrency : number;
    co2SavingInKg: number;
};

export enum ESavingsType {
    Global= "global",
    Yearly = "yearlyl",
    Monthly = "monthly",
}


