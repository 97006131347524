import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__datepicker-decoratedDate"
}
const _hoisted_2 = {
  key: 1,
  class: "form__group__label-fix"
}
const _hoisted_3 = { class: "form__group__label-text" }
const _hoisted_4 = { key: 0 }

import {TDatepicker} from "./interfaces";

import {DatePicker, Form} from 'ant-design-vue';
import {computed, onBeforeMount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {createDateString} from "@/helpers/dates/date";
import moment from "moment";

export interface Props {
  inputRequired?: TDatepicker["inputRequired"];
  placeholder?: TDatepicker["placeholder"];
  id?: TDatepicker["id"];
  label?: TDatepicker["label"];
  value?: TDatepicker["value"];
  dateDecorator?: TDatepicker["dateDecorator"];
  disabled?: TDatepicker["disabled"];
  onlyFuture?: TDatepicker["onlyFuture"];
  showTime?: TDatepicker["showTime"];
  error?: TDatepicker["error"];
  picker?: TDatepicker["picker"];
  format?: TDatepicker["format"];
  disabledDate?: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntddatepicker',
  props: {
    inputRequired: { type: Boolean, default: false },
    placeholder: { default: undefined },
    id: { default: undefined },
    label: { default: undefined },
    value: { default: undefined },
    dateDecorator: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    onlyFuture: { type: Boolean, default: false },
    showTime: { type: Boolean, default: true },
    error: { default: undefined },
    picker: { default: 'date' },
    format: { default: 'YYYY-MM-DD HH:mm' },
    disabledDate: { default: undefined }
  },
  emits: ['update:value', 'ok'],
  setup(__props: any, { emit: __emit }) {

const {WeekPicker, MonthPicker} = DatePicker;
const {Item} = Form;

const props = __props

const emit = __emit

const {t} = useI18n()
const m_value = ref<string | null>(null);
const inputReadOnly = ref(false);

const showTimeFormat = computed(() => {
  if (props.showTime) {
    return {
      format: 'HH:mm',
      'minute-step': 15
    }
  }
  return false;
})

const componentIs = computed(() => {
  if (props.picker == "month") {
    return MonthPicker;
  } else if (props.picker == "week") {
    return WeekPicker;
  }
  return DatePicker;
})


const hasValue = computed(() => {
  return m_value.value !== '' && m_value.value !== null;
})

const formatedDate = computed(() => {
  return (props.dateDecorator && m_value.value) ? createDateString(t, false, m_value.value) : '';
})

/*
to fix calendar in popin : default behaviour set calendar at the body => z-index problems so we set it in the componnent
*/
const getCalendarContainer = (triggerNode: any) => {
  return triggerNode.parentNode.parentNode.parentNode;
}

const onOk = (value: any) => {
  emit('ok', value);
}

const onChange = (value: any) => {
  m_value.value = value ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss') : null;
  emit('update:value', m_value.value);
}

const onFocus = () => {
  /*
     if touch Device, we want to remove the keyboard from opening
  */
  if (('ontouchstart' in window)) {
    inputReadOnly.value = true;
  }
}

onBeforeMount(() => {
  m_value.value = props.value || null;
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    m_value.value = newValue ?? null;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Item), {
    class: "form__group",
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      _createElementVNode("label", {
        class: _normalizeClass(['form__datepicker', {'hasDateDecorator':_ctx.dateDecorator}])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(componentIs.value), {
          class: _normalizeClass({'has-value': hasValue.value}),
          placeholder: _ctx.placeholder,
          value: m_value.value,
          "show-time": showTimeFormat.value,
          format: _ctx.format,
          onChange: onChange,
          showToday: false,
          disabled: _ctx.disabled,
          "disabled-date": _ctx.disabledDate,
          getCalendarContainer: getCalendarContainer,
          onOk: onOk,
          onFocus: onFocus,
          inputReadOnly: inputReadOnly.value
        }, null, 40, ["class", "placeholder", "value", "show-time", "format", "disabled", "disabled-date", "inputReadOnly"])),
        (formatedDate.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(formatedDate.value), 1))
          : _createCommentVNode("", true),
        (_ctx.label!==null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.label), 1),
                (_ctx.inputRequired)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "form__group__bar" }, null, -1))
      ], 2)
    ]),
    _: 1
  }, 8, ["validate-status", "help"]))
}
}

})