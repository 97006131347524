import {computed} from "vue";
import {ECookieNames} from "@/helpers/cookie/interfaces";
import {AuthModule} from "@/store";

export function useCookieNames() {

    const temperatureControlReminderCookie = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_TEMPERATURE_CONTROL_REMINDER}${AuthModule.user?.id}`
        }
    )

    const defaultContratCookie = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_DEFAULT_CONTRACT}${AuthModule.user?.id}`
        }
    )

    const progReminderCookie = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_PROG_REMINDER}${AuthModule.user?.id}`
        }
    )

    const enedisCounterCookie = computed((): string => {
            return `${ECookieNames.ENEDIS_COUNTER}${AuthModule.user?.id}`
        }
    )

    const enedisDateCookie = computed((): string => {
            return `${ECookieNames.ENEDIS_DATE}${AuthModule.user?.id}`
        }
    )

    const contractNotificationCookie = computed((): string => {
            return `${ECookieNames.CONTRACT_NOTIF}${AuthModule.user?.id}`
        }
    )

    return {
        temperatureControlReminderCookie,
        defaultContratCookie,
        progReminderCookie,
        enedisCounterCookie,
        enedisDateCookie,
        contractNotificationCookie
    }
}
