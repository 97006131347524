import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {ETemperatureUnit, TTemperature} from "./interfaces";
import {computed} from "vue";

interface Props {
  value: TTemperature["value"];
  unit?: TTemperature["unit"];
  className?: TTemperature["className"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTemperature',
  props: {
    value: { default: undefined },
    unit: { default: ETemperatureUnit.Celsius },
    className: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const formatTemp = computed(() =>{
  return props.value.toLocaleString(undefined, {maximumFractionDigits: 2})
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.$style.Temperature} ${_ctx.className}`)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass([_ctx.$style.Temperature__value])
    }, _toDisplayString(formatTemp.value), 3),
    _createElementVNode("p", {
      class: _normalizeClass([_ctx.$style.Temperature__unit])
    }, _toDisplayString(_ctx.unit), 3)
  ], 2))
}
}

})