import { ECountry } from "@/composables/countries";
import { EVoltalisVersion } from "../Appliances/interfaces";

export type TSite = {
    /**
     * Site's id.
     */
    id: number;

    /**
     * Site's address.
     */
    address: string;

    /**
     * Site's city.
     */
    city: string;

    /**
     * Site's country.
     */
    country: ECountry;

    /**
     * Date of first user's data (format: )
     */
    dataStart: string;

    /**
     * Site's default.
     */
    default: boolean;

    hasDsoMeasure: boolean;

    hasGlobalConsumptionMeasure: boolean;

    installationDate?: string;

    hasBasicOffer: boolean;

    name: string | null;

    /**
     * Site's postal code.
     */
    postalCode: string;

    /**
     * Site's state.
     */
    state: string;

    timezone: string;

    /**
     * Site Voltalis box version.
     */
    voltalisVersion: EVoltalisVersion;
};

export type TSites = TSite[];

type TGetSites = () => Promise<TSites>;
type TUpdateSite = (siteId: TSite['id']) => Promise<TSite>;
type TUpdateSiteName = (siteId: TSite['id'], name: string) => Promise<TSite>;

export type TSiteService = {
    /**
     * Send a GET request to retrieve site.
     */
    getSites: TGetSites;

    /**
     * Send a PUT request to update site.
     */
    updateSite: TUpdateSite;

    /**
     * Send a PUT request to update site name
     */
    updateSiteName: TUpdateSiteName
};

export type TUpdateSiteNameParams = {
    id: TSite['id'],
    name: string
}

export enum ESubscriberOffer {
    PREMIUM = "PREMIUM",
    BASIC = "BASIC"
}
