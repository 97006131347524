import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "BottomPopin__container" }
const _hoisted_2 = { class: "BottomPopin" }
const _hoisted_3 = {
  key: 0,
  class: "BottomPopin__title"
}
const _hoisted_4 = { class: "BottomPopin__content" }

import {onDeactivated, onMounted, onUnmounted} from "vue";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBottomPopin',
  emits: ['cancel'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const cancel = () => {
  emit('cancel')
  pageScroll(true)
}

onMounted(() => {
  pageScroll(false)
})

onUnmounted(() => {
  pageScroll(true)
})

onDeactivated(() => {
  pageScroll(true)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "BottomPopin__overlay",
      onClick: cancel
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$slots.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})