import {
    TConsumption,
    TConsumptionsByApplianceId,
} from "@/services/Conso/interfaces";

export const getOtherConsumptionsValues = (applianceConsumptions: TConsumption[], totalConsumptions: TConsumption[] | undefined) => {
    return applianceConsumptions.map((conso, index) => {
        return {
            ...conso,
            offPeakHourConsumptionInCurrency: (totalConsumptions?.[index]?.offPeakHourConsumptionInCurrency ?? 0) - (conso.offPeakHourConsumptionInCurrency ?? 0),
            offPeakHourConsumptionInWh: (totalConsumptions?.[index]?.offPeakHourConsumptionInWh ?? 0) - (conso.offPeakHourConsumptionInWh ?? 0),
            peakHourConsumptionInCurrency: (totalConsumptions?.[index]?.peakHourConsumptionInCurrency ?? 0) - (conso.peakHourConsumptionInCurrency ?? 0),
            peakHourConsumptionInWh: (totalConsumptions?.[index]?.peakHourConsumptionInWh ?? 0) - (conso.peakHourConsumptionInWh ?? 0),
            totalConsumptionInCurrency: (totalConsumptions?.[index]?.totalConsumptionInCurrency ?? 0) - (conso.totalConsumptionInCurrency ?? 0),
            totalConsumptionInWh: (totalConsumptions?.[index]?.totalConsumptionInWh ?? 0) - (conso.totalConsumptionInWh ?? 0)
        }
    })
}

export const getAppliancesConsumptionsSum = (consumptionByApplianceId: TConsumptionsByApplianceId, consumptionsPerAppliance: any) => {
    const _consumptions: TConsumption[] = [];
    for (const id in consumptionByApplianceId) {
        consumptionsPerAppliance?.perAppliance[parseInt(id)].forEach((conso: any) => {
            const existingConso = _consumptions.find(c => c.stepTimestampOnSite === conso.stepTimestampOnSite)
            if (existingConso) {
                existingConso!.totalConsumptionInWh! += conso.totalConsumptionInWh;
                existingConso!.totalConsumptionInCurrency! += conso.totalConsumptionInCurrency ?? 0;
                existingConso!.peakHourConsumptionInWh! += conso.peakHourConsumptionInWh ?? 0;
                existingConso!.peakHourConsumptionInCurrency! += conso.peakHourConsumptionInCurrency ?? 0;
                existingConso!.offPeakHourConsumptionInWh! += conso.offPeakHourConsumptionInWh ?? 0;
                existingConso!.offPeakHourConsumptionInCurrency! += conso.offPeakHourConsumptionInCurrency ?? 0;
            } else {
                _consumptions.push({
                    stepTimestampOnSite: conso.stepTimestampOnSite,
                    contractBasePrice: conso.contractBasePrice,
                    estimatedConsumptionInCurrency: conso.estimatedConsumptionInCurrency,
                    estimatedConsumptionInWh: conso.estimatedConsumptionInWh,
                    offPeakHourConsumptionInCurrency: conso.offPeakHourConsumptionInCurrency,
                    offPeakHourConsumptionInWh: conso.offPeakHourConsumptionInWh,
                    peakHourConsumptionInCurrency: conso.peakHourConsumptionInCurrency,
                    peakHourConsumptionInWh: conso.peakHourConsumptionInWh,
                    temperatureInCelsiusDegree: conso.temperatureInCelsiusDegree,
                    totalConsumptionInCurrency: conso.totalConsumptionInCurrency,
                    totalConsumptionInWh: conso.totalConsumptionInWh,
                    isPeakOffPeak: conso.isPeakOffPeak
                })
            }
        });
    }

    return _consumptions;
}