import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {AuthModule} from "..";

import {EModules} from "../modules";
import RatingService from "@/services/Rating";
import { TNextDisplayDate } from "@/services/Rating/interfaces";

@Module({name: EModules.RATING})
class Rating extends VuexModule {
    private _nextDisplayDate: TNextDisplayDate | undefined = undefined;

    /**
     * Getters
     */
    get nextDisplayDate(): TNextDisplayDate {
        return this._nextDisplayDate!;
    }

    /**
     * Mutations
     */

    @Mutation
    public _setNextDisplayDates(nextDisplayDate: TNextDisplayDate): void {
        this._nextDisplayDate = nextDisplayDate;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getNextDisplayDate() {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                this.nextDisplayDate !== undefined ? Promise.resolve(this.nextDisplayDate) :
                    RatingService.getNextDisplayDate()
                        .then(nextDisplayDate => {
                            this.context.commit("_setNextDisplayDates", nextDisplayDate);
                            return nextDisplayDate;
                        })
                        .catch(e => {
                            if (e.response.status === 404) {
                              this.context.commit("_setNextDisplayDates", undefined);
                              return undefined;
                            }
                            console.error(e);
                            throw e;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateNextDisplayDate() {
        const user = AuthModule.userOrNull;

        if (user) {
            return RatingService.updateNextDisplayDate()
                .then(nextDisplayDate => {
                    this.context.commit("_setNextDisplayDates", nextDisplayDate);
                    return nextDisplayDate
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }
}

export default Rating;
