import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "PageWithImg" }
const _hoisted_2 = { class: "PageWithImg__visuel" }
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "PageWithImg__container" }
const _hoisted_6 = { class: "PageWithImg__back" }
const _hoisted_7 = {
  key: 0,
  class: "PageWithImg__header PageWithImg__inner"
}
const _hoisted_8 = { class: "PageWithImg__lang" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "PageWithImg__main" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 1,
  class: "PageWithImg__subtitle PageWithImg__inner"
}
const _hoisted_14 = ["innerHTML"]

import {TPageWithImg} from "./interfaces";
import {computed} from "vue";

export interface Props {
  title: TPageWithImg["title"];
  subtitle?: TPageWithImg["subtitle"];
  logo: TPageWithImg["logo"];
  logoDark: TPageWithImg["logoDark"];
  backgroundImg: TPageWithImg["backgroundImg"];
  backgroundImgMobile: TPageWithImg["backgroundImgMobile"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPageWithImg',
  props: {
    title: { default: '' },
    subtitle: { default: undefined },
    logo: { default: '' },
    logoDark: { default: '' },
    backgroundImg: { default: '' },
    backgroundImgMobile: { default: '' }
  },
  setup(__props: any) {



const isMobile = computed(() => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("picture", null, [
        _createElementVNode("source", {
          srcset: _ctx.backgroundImg,
          media: "(min-width: 768px)"
        }, null, 8, _hoisted_3),
        _createElementVNode("img", {
          src: (_ctx.backgroundImgMobile) ? _ctx.backgroundImgMobile : _ctx.backgroundImg,
          alt: ""
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["PageWithImg__content", {'isMobile': isMobile.value}])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "back")
        ]),
        (_ctx.$slots.lang||_ctx.logo||_ctx.logoDark)
          ? (_openBlock(), _createElementBlock("header", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "lang")
              ]),
              (_ctx.logo)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['PageWithImg__logo', {'PageWithImg__logo--hasDark': _ctx.logoDark}]),
                    "data-testid": "page-with-image-logo"
                  }, [
                    _createElementVNode("img", {
                      src: _ctx.logo,
                      class: "PageWithImg__logo-img",
                      alt: "myvoltalis logo"
                    }, null, 8, _hoisted_9),
                    (_ctx.logoDark)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.logoDark,
                          class: "PageWithImg__logo-img PageWithImg__logo-img--dark",
                          alt: "myvoltalis logo"
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("main", _hoisted_11, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                "data-testid": "page-with-image-title",
                class: _normalizeClass([{'isMobile': isMobile.value}, "PageWithImg__title PageWithImg__inner"])
              }, [
                _createElementVNode("span", { innerHTML: _ctx.title }, null, 8, _hoisted_12)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("span", { innerHTML: _ctx.subtitle }, null, 8, _hoisted_14)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("footer", {
          class: _normalizeClass(["PageWithImg__footer", {'isMobile': isMobile.value}])
        }, [
          _renderSlot(_ctx.$slots, "footer")
        ], 2)
      ], 2)
    ])
  ]))
}
}

})