export enum EFlutterConnectorRequestType {
    voltalis = 'voltalis',
    geolocation = 'geolocation'
}

export enum EGeolocationRequests {
    getDeviceInfo = 'getDeviceInfo',
    refreshGeofences = 'refreshGeofences',
    getPermissionsState = 'getPermissionsState',
    getLocalisationState = 'getState',
    openAppSettings = 'openAppSettings',
    requirePermission = 'requirePermission',
    getVersion = 'version'
}

export enum EVoltalisConnectorRequests {
    getVersion = 'version'
}

export type TVoltalisRequestVersionType = {
    version: number;
}

export enum EFlutterOpenUrlInWebviewType {
    Open = 'open'
}

export enum EVoltalisConnectorRating {
    openAppRatingModal = "openAppRatingModal",
}