import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import mapAlertImg from '@/assets/images/france_map_alert.png'
import {TEcowattAlert, TEcowattAlerts} from "@/components/ui/organisms/MyVEcowattAlert/interfaces";
import {AuthModule, IntlModule} from "@/store";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import {computed} from "vue";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import API from "@/helpers/api";
import {useDate} from "@/helpers/dates/date-utils";

interface Props {
  ecowattAlerts: TEcowattAlerts;
}

const dateTimeFormat = "YYYY-MM-DD[T]HH:mm:ss";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVEcowattAlert',
  props: {
    ecowattAlerts: { default: undefined }
  },
  emits: ['close', 'updateEcowattConsent'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const hasCurrentAlert = computed(() => {
  const today = new Date();

  return props.ecowattAlerts.rteAlerts.find((alert: TEcowattAlert) => {
    return useDate(today).isAfter(useDate(alert.from).toDate()) && useDate(today).isBefore(useDate(alert.to).toDate());
  });
})

const formatJour = (jour: string) => {
  return useDate(jour, dateTimeFormat).locale(IntlModule.locale).format('ddd DD MMMM');
}
const formatPas = (from: string, to: string) => {
  return useDate(from, dateTimeFormat).format('HH[h]mm') + ' - ' + useDate(to, dateTimeFormat).format('HH[h]mm');
}

const closePopin = () => {
  emit('close');
}

const updateEcowattConsent = () => {
  API.put(`/api/site/${getCurrentSiteIdFromCookie(AuthModule.user)}/user/consent`, [
    {
      enabled: !props.ecowattAlerts.isConsentEnabled,
      nameKey: props.ecowattAlerts.consentName
    },
  ]).then(() => emit('updateEcowattConsent'));
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.ecowattAlerts)
    ? (_openBlock(), _createBlock(MyVPageWithReturn, {
        key: 0,
        type: "popin",
        title: _ctx.$t('ecowatt.alert.title'),
        "return-action": closePopin,
        class: _normalizeClass(_ctx.$style.EcowattAlert)
      }, {
        default: _withCtx(() => [
          (hasCurrentAlert.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.hasCurrentAlert)
              }, _toDisplayString(_ctx.$t('ecowatt.alert.current_alert')), 3))
            : _createCommentVNode("", true),
          (_ctx.ecowattAlerts.isConsentEnabled)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.ecowattConsentHeader)
              }, _toDisplayString(_ctx.$t('ecowatt.alert.consent_header_text')), 3))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.MapSection)
          }, [
            _createElementVNode("img", {
              src: _unref(mapAlertImg),
              alt: ""
            }, null, 8, _hoisted_1),
            _createElementVNode("p", {
              innerHTML: _ctx.$t('ecowatt.alert.map_text')
            }, null, 8, _hoisted_2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.AlertsSection)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('ecowatt.alert.list.title')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ecowattAlerts.rteAlerts, (alert, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style.AlertItem),
                key: index
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.date)
                }, [
                  _cache[0] || (_cache[0] = _createElementVNode("svg", {
                    class: "icon icon-alert-sign",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-alert-sign" })
                  ], -1)),
                  _createElementVNode("span", null, _toDisplayString(formatJour(alert.from)), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.time)
                }, [
                  _createElementVNode("span", null, _toDisplayString(formatPas(alert.from, alert.to)), 1)
                ], 2)
              ], 2))
            }), 128))
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.EcowattConsentContainer)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.EcowattConsentTitle)
            }, _toDisplayString(_ctx.$t('ecowatt.alert.consent_title')), 3),
            _createElementVNode("div", {
              innerHTML: _ctx.$t('ecowatt.alert.consent_text')
            }, null, 8, _hoisted_3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.EcowattConsentChoice)
            }, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.ecowattAlerts.consentName)), 1),
              _createVNode(MyVAntdswitch, {
                value: _ctx.ecowattAlerts.isConsentEnabled,
                "default-value": _ctx.ecowattAlerts.isConsentEnabled,
                onChange: updateEcowattConsent,
                class: "hasUncheckStyle"
              }, null, 8, ["value", "default-value"])
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.AlertsText)
          }, [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('ecowatt.alert.text')
            }, null, 8, _hoisted_4)
          ], 2),
          _createVNode(_component_router_link, {
            to: `${_unref(ERoutes).ADVICE}/advice.ecowatt`,
            class: _normalizeClass(_ctx.$style.AdviceLink)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('ecowatt.alert.advice_link')), 1)
            ]),
            _: 1
          }, 8, ["to", "class"])
        ]),
        _: 1
      }, 8, ["title", "class"]))
    : _createCommentVNode("", true)
}
}

})