import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ChooseContractStep" }
const _hoisted_2 = { class: "mainContent" }
const _hoisted_3 = {
  key: 0,
  class: "contractContainer"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {ContractModule} from "@/store";
import {TCompanyContract, TCompanyContracts} from "@/services/Contract/interfaces";
import {computed, ref} from "vue";
import {EEnergyUnit} from "@/helpers/constants/unit";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscribedPowerStepView',
  props: {
    powerList: {}
  },
  emits: ['finish'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const subscribedPower = ref<number | null>(null);
const fakePowerList = ref<number[]>([
  3, 6, 9, 12, 15, 18, 24, 27, 30, 36
])

const fakeContract = computed(() => {
  return ContractModule.fakeContract;
})

const fakeSave = (power: number) => {
  subscribedPower.value = power;
  save(null)
}
const save = (providerContract: TCompanyContract | null) => {
  if (providerContract) {
    if (!providerContract.company?.id) {
      providerContract.company = {
        id: fakeContract.value!.companyId,
        businessName: fakeContract.value!.companyName,
        name: fakeContract.value!.companyName
      }
    }
  } else {
    ContractModule._updateFakeContractProperty({
      subscribedPower: subscribedPower.value!
    });
  }
  emit('finish', providerContract);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('contract.choose_contract_step.choose_subscribed_power')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.powerList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.powerList, (contract, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "contractRow",
                onClick: ($event: any) => (save(contract))
              }, [
                _createElementVNode("span", null, _toDisplayString(`${_ctx.$t(contract.subscribedPower)} ${_unref(EEnergyUnit).kVA}`), 1),
                _createVNode(MyVButton, {
                  class: "chooseContractButton",
                  primary: false,
                  size: "small",
                  onClick: ($event: any) => (save(contract))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contract.choose_contract_step.')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ], 8, _hoisted_4))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fakePowerList.value, (power, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "contractRow",
                onClick: ($event: any) => (fakeSave(power))
              }, [
                _createElementVNode("span", null, _toDisplayString(`${_ctx.$t(power)} ${_unref(EEnergyUnit).kVA}`), 1),
                _createVNode(MyVButton, {
                  class: "chooseContractButton",
                  primary: false,
                  size: "small",
                  onClick: ($event: any) => (fakeSave(power))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contract.choose_contract_step.')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ], 8, _hoisted_6))
            }), 128))
          ]))
    ])
  ]))
}
}

})