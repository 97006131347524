import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';

import {TConsumptionGoal} from "./interfaces";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {ContractModule, SwitchConsoModule} from "@/store";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import useCurrency from "@/composables/currency";

interface Props {
  value: TConsumptionGoal["value"];
  unit: TConsumptionGoal["unit"];
  objectiveDiff?: TConsumptionGoal["objectiveDiff"];
  showResult?: TConsumptionGoal["showResult"];
  estimationCurrency?: TConsumptionGoal["estimationCurrency"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsumptionGoal',
  props: {
    value: { default: undefined },
    unit: { default: undefined },
    objectiveDiff: { default: undefined },
    showResult: { type: Boolean, default: true },
    estimationCurrency: { default: undefined }
  },
  emits: ['openPopinDefineContract', 'openPopinConsumptionGoal'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const {t} = useI18n()
const {formatCurrency} = useCurrency()

const getValueInteger = computed(() => {
  switch (SwitchConsoModule.switchType) {
    case true:
      return Math.round(props.value!)
    case false:
      return formatCurrency(Math.floor(props.estimationCurrency!))
    default:
      return undefined
  }
})

const formattedValue = computed(() => {
  if (props.value || props.value === 0) {
    if (SwitchConsoModule.switchType) return `${getValueDecimals(props.value) !== 0 ? ',' + getValueDecimals(props.value).toString().substring(2) : ''} ${props.unit}`
    return `${t('conso.objective.estimation.estimate')}`

  }

  return undefined
})

const handleDefineObjective = () => {
  if (!ContractModule.contractsList.length) {
    emit('openPopinDefineContract')
  } else {
    emit('openPopinConsumptionGoal')
  }
}

const getValueDecimals = (data: number): number => {
  return Math.round(data % 1 * 100) / 100
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.ConsumptionGoal])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.ConsumptionGoal__info])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.ConsumptionGoal__infoConsumption])
      }, [
        (getValueInteger.value)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass([_ctx.$style.ConsumptionGoal__infoConsumptionBig])
            }, _toDisplayString(getValueInteger.value), 3))
          : _createCommentVNode("", true),
        (formattedValue.value)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: _normalizeClass([_ctx.$style.ConsumptionGoal__infoConsumptionSmall])
            }, _toDisplayString(formattedValue.value), 3))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(MyVDisplayGroupStore, {
        currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT,
        hideIfDisabled: true
      }, {
        default: _withCtx(() => [
          (_ctx.estimationCurrency)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass([_ctx.$style.ConsumptionGoal__infoConsumptionEstimation]),
                innerHTML: _unref(SwitchConsoModule).switchType ? _ctx.$t('conso.objective.estimation', { estimation: `<span>${_unref(formatCurrency)(_ctx.estimationCurrency)}</span>`  }) : `${_ctx.$t('conso.objective.estimation.that_is')} <span>${Math.round(_ctx.value!)} ${_ctx.unit} </span>`
              }, null, 10, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["currentRight"])
    ], 2),
    _createVNode(MyVDisplayGroupStore, {
      currentRight: _unref(EDisplayGroupRightName).MY_OBJECTIVES,
      hideIfDisabled: true
    }, {
      default: _withCtx(() => [
        (_ctx.objectiveDiff)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openPopinConsumptionGoal'))),
              class: _normalizeClass([_ctx.$style.ConsumptionGoal__Set])
            }, [
              (_ctx.objectiveDiff > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([_ctx.$style.ConsumptionGoal__CurrentGoal, _ctx.$style.ConsumptionGoal__CurrentGoal__reached])
                  }, [
                    _createVNode(MyVIcon, {
                      name: "green-flag",
                      class: _normalizeClass([_ctx.$style.ConsumptionGoal__CurrentGoal__reached__icon])
                    }, null, 8, ["class"]),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('conso.objective.reached')), 1)
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([_ctx.$style.ConsumptionGoal__CurrentGoal, _ctx.$style.ConsumptionGoal__CurrentGoal__not_reached])
                  }, [
                    _createVNode(MyVIcon, {
                      name: "red-flag",
                      class: _normalizeClass([_ctx.$style.ConsumptionGoal__CurrentGoal__reached__icon])
                    }, null, 8, ["class"]),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('conso.objective.not_reached')), 1)
                  ], 2)),
              _createElementVNode("span", {
                class: _normalizeClass([_ctx.$style.ConsumptionGoal__SetLink])
              }, _toDisplayString(_ctx.$t('conso.objective.see')), 3)
            ], 2))
          : (_ctx.showResult)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openPopinConsumptionGoal'))),
                class: _normalizeClass([_ctx.$style.ConsumptionGoal__Set])
              }, [
                _createVNode(MyVIcon, { name: "white-flag" }),
                _createElementVNode("span", {
                  class: _normalizeClass([_ctx.$style.ConsumptionGoal__SetLink])
                }, _toDisplayString(_ctx.$t('conso.objective.see')), 3)
              ], 2))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                onClick: handleDefineObjective,
                class: _normalizeClass([_ctx.$style.ConsumptionGoal__Set])
              }, [
                _createVNode(MyVIcon, { name: "white-flag" }),
                _createElementVNode("span", {
                  class: _normalizeClass([_ctx.$style.ConsumptionGoal__SetLink])
                }, _toDisplayString(_ctx.$t('conso.objective.define')), 3)
              ], 2))
      ]),
      _: 1
    }, 8, ["currentRight"])
  ], 2))
}
}

})