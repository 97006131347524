import {TRepartitionCategories} from "@/services/Conso/interfaces";
import {fromWhToKwh} from "@/helpers/constants/unit";
import {ContractModule} from "@/store";
import {useDate} from "@/helpers/dates/date-utils";

export const getSubcategoriesTotalConsoByType = (subcategories: TRepartitionCategories[], type: boolean): number => {
    return subcategories.reduce((_total, sub) => _total + (type ? fromWhToKwh(sub.totalConsumptionInWh || 0) : sub.totalConsumptionInCurrency || 0), 0);
}

export const getContractFromDate = (date: string | null | undefined) => {
    if (ContractModule.contractsList.length === 1) {
        return ContractModule.contractsList[0];
    } else {
        const formattedDate = useDate(date) ?? useDate().format('MM-DD-YYYY');
        return ContractModule.contractsList.find(c => {
            return useDate(c.startDate ?? '01-01-1970').isBefore(formattedDate!) &&
                (c.endDate ? useDate(c.endDate).isAfter(formattedDate!) : useDate().isAfter(formattedDate!));
        });
    }
}