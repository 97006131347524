import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "geolocation-popin-wrapper" }
const _hoisted_2 = { class: "geolocation-popin-item" }
const _hoisted_3 = { class: "geolocation-popin-item-heading" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "geolocation-popin-item" }
const _hoisted_6 = { class: "geolocation-popin-item-heading" }
const _hoisted_7 = { class: "geolocation-popin-item-text" }
const _hoisted_8 = { class: "geolocation-popin-item-slider" }
const _hoisted_9 = { class: "geolocation-popin-item" }
const _hoisted_10 = {
  key: 0,
  class: "geolocation-popin-item-heading"
}
const _hoisted_11 = { class: "geolocation-popin-item-heading" }
const _hoisted_12 = { class: "geolocation-popin-item-text" }
const _hoisted_13 = { class: "geolocation-popin-item-phones" }
const _hoisted_14 = { class: "geolocation-popin-item-phone-edit" }
const _hoisted_15 = {
  key: 0,
  class: "geolocation-popin-item-phone__current"
}
const _hoisted_16 = {
  key: 1,
  class: "geolocation-popin-item-phone-last-activity"
}
const _hoisted_17 = {
  key: 1,
  class: "geolocation-popin-item-phone-edit-buttons"
}
const _hoisted_18 = { class: "BottomPopin__text" }
const _hoisted_19 = { class: "BottomPopin__title" }
const _hoisted_20 = { class: "BottomPopin__subtitle" }
const _hoisted_21 = { class: "BottomPopin__buttons" }
const _hoisted_22 = { class: "BottomPopin__text" }
const _hoisted_23 = { class: "BottomPopin__subtitle" }
const _hoisted_24 = { class: "BottomPopin__buttons" }

import {Divider, notification, Slider} from 'ant-design-vue';
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {computed, nextTick, onBeforeMount, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {GeolocationModule} from "@/store";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {TGeolocationPhone, TGeolocationStatus} from "@/services/Geolocation/interfaces";
import {isWebViewMobileApp} from "@/helpers/domains/device";
import {useI18n} from "vue-i18n";
import MyVGeolocationPermissionsPopin
  from "@/components/domains/Geolocation/MyVGeolocationPermissionsPopin/MyVGeolocationPermissionsPopin.vue";
import {useResponsive} from "@/composables/useResponsive";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {useDate} from "@/helpers/dates/date-utils";
import {EButtonSize} from "@/components/ui/atoms/MyVButton/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVGeolocationPopin',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const isShowMore = ref(false);
const isShowDeletePhone = ref(false);
const radius = ref(0);
const finalRadius = ref(1);
const isFirstLoading = ref(true)
const showGeolocationFix = ref(false)
const editingPhone = reactive<TGeolocationPhone>({
  state: undefined,
  siteId: undefined,
  name: undefined,
  lastHeartbeatDatetime: undefined,
  deviceId: undefined, brand: undefined
});
const {t} = useI18n()

const marksDesktop = ref<Record<number, any>>({
  0: '1km',
  13: '2km',
  23: '4km',
  34: '8km',
  46: '15km',
  57: '25km',
  69: '40km'
});

const marksMobile = ref<Record<number, any>>({
  0: '1km',
  19: '3km',
  34: '8km',
  57: '25km',
});

const {isMobile} = useResponsive()

const marks = computed(() => {
  if (isMobile.value) {
    return marksMobile.value
  } else {
    return marksDesktop.value
  }
})

const currentPhone = computed(() => {
  return GeolocationModule.currentPhone
})

const isFlutterApp = computed(() => {
  return isWebViewMobileApp(window)
})

const phones = computed(() => {
  return GeolocationModule.phones ?? []
})

const status = computed(() => {
  return GeolocationModule.status
})

const displayAddPhoneText = computed(() => !isFlutterApp.value && !phones.value.length)

const isPhoneNameAlreadyInPhones = computed<boolean>(() => phones.value.some(phone => phone.name === editingPhone.name))

const close = () => {
  emit('close')
}

const editPhone = (phone: any) => {
  Object.assign(editingPhone, phone)

  setTimeout(() => {
    const element = document.getElementById(phone.deviceId)
    element!.focus()
    window.scrollTo(0, document.body.scrollHeight);
  }, 100)
}

const numberOfPhonesWithSameStartingName = (name: string): number => phones.value.filter(phone => phone.name?.startsWith(name)).length

const addMyPhone = () => {
  let name = `${currentPhone.value?.manufacturer} ${currentPhone.value?.model}`

  if (numberOfPhonesWithSameStartingName(name) > 0) name += ` (${numberOfPhonesWithSameStartingName(name)})`

  const myPhone: TGeolocationPhone = {
    brand: currentPhone.value?.manufacturer,
    deviceId: currentPhone.value?.deviceId,
    name
  }

  GeolocationModule.postPhone(myPhone).then(() => {
    if (GeolocationModule.isPermissionNeeded) {
      showGeolocationFix.value = true
    }
    GeolocationModule.refreshGeofences();

  }).finally(() => {
        setTimeout(() => {
          GeolocationModule.getPhones();
        }, 1000)
      }
  )
}

const deletePhone = (phone: TGeolocationPhone) => {
  Object.assign(editingPhone, phone)
  isShowDeletePhone.value = true
}

const validateRenamePhone = () => {
  if (isPhoneNameAlreadyInPhones.value) {
    notification.error({
      message: t('geolocation.settings.phoneNameAlreadyInPhones'),
      duration: 3
    })
    return
  }

  GeolocationModule.putPhone(editingPhone).then(() => {
    emptyEditingPhone()
    GeolocationModule.refreshGeofences()
    GeolocationModule.getPhones()
  })

}
const cancelPhone = () => {
  emptyEditingPhone()
}

const confirmDelete = () => {
  GeolocationModule.deletePhone(editingPhone.deviceId!)
      .then(() => {
            GeolocationModule.refreshGeofences()
            GeolocationModule.getPhones()
            isShowDeletePhone.value = false

            notification.success({
              message: t('geolocation.settings.deletePhone.success.title'),
              description: t('geolocation.settings.deletePhone.success.subtitle'),
              duration: 3
            })
          }
      ).catch(() => {
    notification.error({
      message: t('geolocation.settings.deletePhone.error.title'),
      description: t('geolocation.settings.deletePhone.error.subtitle'),
      duration: 3
    })
  })
  emptyEditingPhone()
}

const emptyEditingPhone = () => {
  editingPhone.name = undefined;
  editingPhone.brand = undefined;
  editingPhone.siteId = undefined;
  editingPhone.lastHeartbeatDatetime = undefined;
  editingPhone.state = undefined;
  editingPhone.deviceId = undefined;
}
const easeIn = (val: number, min: number, max: number, strength: number) => {
  val /= max;
  return (max - 1) * Math.pow(val, strength) + min;
}

const easeOutToInitial = (val: number, min: number, max: number, strength: number) => {
  val = (val - min) / (max - min);
  return Math.pow(val, 1 / strength) * max;
}

const formatter = () => {
  return finalRadius.value
}
const updateRadius = () => {
  const statusPayload: TGeolocationStatus = {
    radius: finalRadius.value * 1000,
    activatedByUser: status.value?.activatedByUser ?? false
  }
  GeolocationModule.putStatus(statusPayload).then(() => GeolocationModule.refreshGeofences())
}

onBeforeMount(async () => {
  await GeolocationModule.refreshGeofences()
  await GeolocationModule.retrievePhoneDatas()
})

onMounted(async () => {
  if (GeolocationModule.status) {
    radius.value = easeOutToInitial(GeolocationModule.status.radius / 1000, 1, 100, 2.5)
    finalRadius.value = GeolocationModule.status!.radius / 1000
  }

  await nextTick(() => {
    isFirstLoading.value = false
  })
})

onUnmounted(() => {
  pageScroll(true)
})

watch(radius, (newValue) => {
  if (!isFirstLoading.value) {
    finalRadius.value = Math.round(easeIn(newValue, 1, 100, 2.5))
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyVPageWithReturn, {
      title: _ctx.$t('geolocation.settings.title'),
      type: "popin",
      class: "geolocation-popin",
      "return-action": close
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('geolocation.settings.how')), 1),
            _createElementVNode("div", {
              class: _normalizeClass(['geolocation-popin-item-text-truncated', {'geolocation-popin-item-text-truncated--more' : isShowMore.value}]),
              innerHTML: _ctx.$t('geolocation.settings.description')
            }, null, 10, _hoisted_4),
            _createElementVNode("div", {
              class: "geolocation-popin-show-more",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowMore.value= !isShowMore.value))
            }, _toDisplayString(isShowMore.value ? _ctx.$t('global.showLess') : _ctx.$t('global.showMore')), 1)
          ]),
          _createVNode(_unref(Divider)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('geolocation.settings.activationDistance')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('geolocation.settings.outDistance')), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(Slider), {
                marks: marks.value,
                value: radius.value,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((radius).value = $event)),
                "tip-formatter": formatter,
                onAfterChange: updateRadius
              }, null, 8, ["marks", "value"]),
              _createElementVNode("div", null, _toDisplayString(finalRadius.value) + " km", 1)
            ])
          ]),
          _createVNode(_unref(Divider)),
          _createElementVNode("div", _hoisted_9, [
            (phones.value?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('programList.geolocation.trackedPhones.title')), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('geolocation.settings.noPhones')), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(displayAddPhoneText.value ? _ctx.$t('geolocation.settings.desktop.howTo') : _ctx.$t('geolocation.settings.mobile.howTo')), 1)
                ], 64)),
            _createElementVNode("div", _hoisted_13, [
              (!phones.value.some(phone => phone.deviceId === currentPhone.value?.deviceId) && isFlutterApp.value)
                ? (_openBlock(), _createBlock(MyVButton, {
                    key: 0,
                    onClick: addMyPhone,
                    primary: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(currentPhone.value ? `${_ctx.$t('geolocation.settings.addPhone')} ${currentPhone.value?.manufacturer} ${currentPhone.value?.model}` : _ctx.$t('geolocation.settings.addPhone')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phones.value, (phone) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "geolocation-popin-item-phone",
                  key: phone.deviceId
                }, [
                  _withDirectives(_createElementVNode("div", _hoisted_14, [
                    _createVNode(MyVAntdinput, {
                      id: phone.deviceId,
                      value: editingPhone.name,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((editingPhone.name) = $event))
                    }, null, 8, ["id", "value"])
                  ], 512), [
                    [_vShow, editingPhone && editingPhone.deviceId === phone.deviceId]
                  ]),
                  _createElementVNode("div", null, [
                    (!(editingPhone && editingPhone.deviceId === phone.deviceId))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (phone.deviceId === _unref(GeolocationModule).currentPhone?.deviceId)
                            ? (_openBlock(), _createBlock(MyVIcon, {
                                key: 0,
                                name: "check-circle"
                              }))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(phone.name), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!(editingPhone && editingPhone.deviceId === phone.deviceId))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(`${_ctx.$t('geolocation.settings.lastActivity')} : ${phone.lastHeartbeatDatetime ? _unref(useDate)(phone.lastHeartbeatDatetime).calendar() : _unref(t)('global.unknown')}`), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", null, [
                    (!editingPhone.deviceId)
                      ? (_openBlock(), _createBlock(MyVIcon, {
                          key: 0,
                          name: "pen",
                          onClick: ($event: any) => (editPhone(phone))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (editingPhone.name?.length && editingPhone.deviceId === phone.deviceId)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createVNode(MyVButton, {
                            onClick: validateRenamePhone,
                            size: _unref(EButtonSize).Small
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Ok ")
                            ])),
                            _: 1
                          }, 8, ["size"]),
                          _createVNode(MyVButton, {
                            onClick: cancelPhone,
                            size: _unref(EButtonSize).Small
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                            ]),
                            _: 1
                          }, 8, ["size"]),
                          _createVNode(MyVIcon, {
                            name: "delete-forever",
                            onClick: deletePhone
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ])
          ])
        ]),
        ( isFlutterApp.value && isShowDeletePhone.value)
          ? (_openBlock(), _createBlock(MyVBottomPopin, {
              key: 0,
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (isShowDeletePhone.value = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("svg", {
                    class: "icon icon-big-colored-delete-forever",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('geolocation.settings.deletePhone.title')), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('geolocation.settings.deletePhone.subtitle')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(MyVButton, {
                      class: "BottomPopin__confirm",
                      onClick: confirmDelete
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(MyVButton, {
                      class: "BottomPopin__cancel",
                      primary: false,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (isShowDeletePhone.value = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }))
          : (isShowDeletePhone.value)
            ? (_openBlock(), _createBlock(MyVPageWithReturn, {
                key: 1,
                title: _ctx.$t('geolocation.settings.deletePhone.title'),
                type: "popin",
                class: "geolocation-popin",
                "return-action": () => isShowDeletePhone.value = false
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('geolocation.settings.deletePhone.subtitle')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(MyVButton, {
                      primary: false,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (isShowDeletePhone.value = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(MyVButton, { onClick: confirmDelete }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["title", "return-action"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"]),
    (showGeolocationFix.value)
      ? (_openBlock(), _createBlock(MyVGeolocationPermissionsPopin, {
          key: 0,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (showGeolocationFix.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})