import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "popinForm" }
const _hoisted_2 = { class: "popinForm__main" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import {TFaq} from "@/views/domains/Profile/Faq/interfaces";
import API from "@/helpers/api";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";

import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {onBeforeMount, ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqView',
  setup(__props) {

const selectedIds = ref<string[]>([])
const faqs = ref<TFaq[]>([])
const error = ref<string | null>(null)

const addOrRemoveId = (id: string) => {
  if (selectedIds.value!.includes(id)) {
    const index = selectedIds.value!.indexOf(id);

    selectedIds.value!.splice(index, 1)
    return
  }
  selectedIds.value!.push(id)
}

const isSelected = (id: string) => {
 return selectedIds.value!.includes(id)
}

onBeforeMount(() => {
  API.get<TFaq[]>('/api/user/faq').then(({data}) => {
    faqs.value = data
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur'
  })
})

return (_ctx: any,_cache: any) => {
  const _directive_nl2br = _resolveDirective("nl2br")!

  return (_openBlock(), _createBlock(MyVDisplayGroupStore, {
    currentRight: _unref(EDisplayGroupRightName).HELP,
    forbiddenRedirection: _unref(ERoutes).HOME
  }, {
    default: _withCtx(() => [
      _createVNode(MyVPageWithReturn, {
        title: _ctx.$t('profile.faq.title'),
        type: "page",
        "return-action": _unref(ERoutes).HOME,
        class: _normalizeClass([_ctx.$style.Faq])
      }, {
        default: _withCtx(() => [
          (error.value)
            ? (_openBlock(), _createBlock(ErrorBanner, {
                key: 0,
                error: error.value,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = null)),
                class: _normalizeClass(['error-banner', _ctx.$style['error-banner']])
              }, null, 8, ["error", "class"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (faqs.value.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(faqs.value, (faq, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass([_ctx.$style.Faq__block]),
                      key: index
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.Faq__block__header),
                        onClick: ($event: any) => (addOrRemoveId(index as string))
                      }, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.$t(faq.questionKey)), 1),
                        (_openBlock(), _createElementBlock("svg", {
                          class: _normalizeClass('icon icon-carret-down ' + (isSelected(index as string) ? _ctx.$style.carretUp : '')),
                          "aria-hidden": "true"
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("use", { "xlink:href": "#icon-carret-down" }, null, -1)
                        ]), 2))
                      ], 10, _hoisted_3),
                      _createVNode(_Transition, { name: "slide" }, {
                        default: _withCtx(() => [
                          (isSelected(index as string))
                            ? _withDirectives((_openBlock(), _createElementBlock("p", {
                                key: 0,
                                innerHTML: _ctx.$t(faq.descriptionKey)
                              }, null, 8, _hoisted_4)), [
                                [_directive_nl2br]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ], 2))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "return-action", "class"])
    ]),
    _: 1
  }, 8, ["currentRight", "forbiddenRedirection"]))
}
}

})