
export enum ECountry {
    FRANCE = "France",
    SPAIN = "Espagne",
    PORTUGAL = "Portugal",
    FINLAND = "Finlande",
    BELGIUM = "Belgique",
    SLOVAKIA = "Slovaquie",
    ITALY = "Italie",
    SLOVENIA = "Slovénie",
    JERSEY = "Jersey",
    GUERNSEY = "Guernesey",
    UK = "Royaume-Uni",
    HUNGARY = "Hongrie",
    CZECH_REPUBLIC = "République Tchèque",
    SWITZERLAND = "Suisse",
    SWEDEN = "Suède",
}

export const countries: ECountry[] = Object.values(ECountry);

export enum ELocale {
    FRANCE = "fr-FR",
    UK = "en-GB",
    SPAIN = "es-ES",
    PORTUGAL = "pt-PT",
    FINLAND = "fi-FI",
    BELGIUM = "fr-BE",
    SLOVAKIA = "sk-SK",
    ITALY = "it-IT",
    SLOVENIA = "sl-SI",
    HUNGARY = "hu-HU",
    CZECH_REPUBLIC = "cs-CZ",
    SWITZERLAND = "fr-CH",
    SWEDEN = "sv-SE"
}

export const isoCodeCountryFromCountry: Record<ECountry, ELocale> = {
    [ECountry.FRANCE]: ELocale.FRANCE,
    [ECountry.UK]: ELocale.UK,
    [ECountry.SPAIN]: ELocale.SPAIN,
    [ECountry.PORTUGAL]: ELocale.PORTUGAL,
    [ECountry.FINLAND]: ELocale.FINLAND,
    [ECountry.BELGIUM]: ELocale.BELGIUM,
    [ECountry.SLOVAKIA]: ELocale.SLOVAKIA,
    [ECountry.ITALY]: ELocale.ITALY,
    [ECountry.SLOVENIA]: ELocale.SLOVENIA,
    [ECountry.JERSEY]: ELocale.UK,
    [ECountry.GUERNSEY]: ELocale.UK,
    [ECountry.HUNGARY]: ELocale.HUNGARY,
    [ECountry.CZECH_REPUBLIC]: ELocale.CZECH_REPUBLIC,
    [ECountry.SWITZERLAND]: ELocale.SWITZERLAND,
    [ECountry.SWEDEN]: ELocale.SWEDEN,
}