import {EDisplayGroupRightName, EDisplayGroupRightType, TDisplayGroup} from "@/services/DisplayGroup/interfaces"

const DisplayGroupObject: TDisplayGroup = {
    "name": "Default",
    "rights": [
        {
            "name": EDisplayGroupRightName.DIAG_ENERGIE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NOTIF_PARTENAIRE,
            "enabled": false,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.TEMPERATURE_EXT,
            "enabled": false,
            "type": EDisplayGroupRightType.DISPLAY
        },
        {
            "name": EDisplayGroupRightName.BUDGET_NOTIFICATION_ALERT,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.MY_CONTRACT,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NEWS,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NOTIF_NEWS,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.LINKY,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.MY_BUDGET,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.HELP,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.MY_HOUSE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.TREND_DETAILS,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NOTIF_BILAN,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.HELP_MAIL,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.DISPLAY_INSTANT_POWER,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NEWSCHEDULER,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.ONOFF,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.ABSENCE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.OPTI_CONSUMPTION,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.ECO_MODE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.MY_OBJECTIVES,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.DISAGGREGATION,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.NOTIFICATION,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.HELP_TELEPHONE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.REAL_TIME_ACTIVATION,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.CONTACT,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.DIAG_TARIF,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.MY_ADVICES,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        },
        {
            "name": EDisplayGroupRightName.DIAG_PUISSANCE,
            "enabled": true,
            "type": EDisplayGroupRightType.ENABLE
        }
    ],
    "resources": {
        "favicon": "default-favicon.png",
        "fontcolor": "#3E3E7C",
        "logo": "default-logo.png",
        "secondarycolor": "#97BE0D",
        "headercolor": "#3E3E7C",
        "primarycolor": "#4DBEF7",
        "font": "default-font.ttf"
    }
}

export default DisplayGroupObject;