import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "HeaderMainPage" }
const _hoisted_2 = { class: "HeaderMainPage__container" }
const _hoisted_3 = { class: "HeaderMainPage__left" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "HeaderMainPage__subtitle"
}
const _hoisted_6 = { class: "HeaderMainPage__right" }

import {THeaderMainPage} from "./interfaces";
import {AuthModule} from "@/store";
import {THeaderPage} from "@/components/ui/molecules/MyVHeaderPage/interfaces";
import {computed} from "vue";

interface Props {
  title?: THeaderMainPage["title"];
  returnAction?: THeaderPage["returnAction"];
  subtitle?: THeaderMainPage["subtitle"];
  isFromConsoOrHeater?: THeaderMainPage["isFromConsoOrHeater"];
  site?: THeaderMainPage["site"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHeaderMainPage',
  props: {
    title: { default: '' },
    returnAction: { type: [String, Function], default: '' },
    subtitle: { default: '' },
    isFromConsoOrHeater: { type: Boolean, default: false },
    site: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const siteAddress = computed(() => {
      if (props.site)
        return `${props.site.address}, ${props.site.postalCode} ${props.site.city}`;

      return ''
    }
)

const isMultiSites = computed(() => {
  return AuthModule.user.otherSites.length;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['HeaderMainPage__breadcrumb',{'empty':!_ctx.$slots.HeaderMainPage__breadcrumb}])
    }, [
      _renderSlot(_ctx.$slots, "HeaderMainPage__breadcrumb")
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['HeaderMainPage__title', {'isConso': _ctx.isFromConsoOrHeater}])
            }, [
              (typeof(_ctx.returnAction)=='function')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
                    class: "HeaderPage__back",
                    type: "button"
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("svg", {
                      class: "icon icon-arrow-left",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t(_ctx.title)), 1),
              (_ctx.isFromConsoOrHeater && isMultiSites.value)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.site?.name ? _ctx.site.name : siteAddress.value), 1))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.subtitle)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "HeaderMainPage__right")
      ])
    ])
  ]))
}
}

})