import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "conso-card-text" }
const _hoisted_2 = { class: "conso-card-text-tags" }
const _hoisted_3 = { class: "conso-card-bignumber" }
const _hoisted_4 = {
  key: 0,
  class: "conso-card-tags"
}

import {TConsoCard} from "./interfaces";

interface Props {
  text: TConsoCard["text"];
  img: TConsoCard['img'];
  value: TConsoCard["value"];
  unit: TConsoCard["unit"];
  title: TConsoCard["title"];
  tags?: TConsoCard["tags"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsoCard',
  props: {
    text: { default: undefined },
    img: { default: undefined },
    value: { default: undefined },
    unit: { default: undefined },
    title: { default: undefined },
    tags: { default: undefined }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "conso-card",
    style: _normalizeStyle(_ctx.img ? ('background-image: url(' + _ctx.img + ')') : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.value ?? null), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.value ? _ctx.unit : null), 1)
          ]),
          (_ctx.tags?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, loop) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "conso-card-tag",
                    key: loop
                  }, _toDisplayString(tag), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.text ?? null), 1)
        ])
      ])
    ])
  ], 4))
}
}

})