import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "AppliancesTile__top" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "AppliancesTile__level" }
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__iconContainer'])
}
const _hoisted_5 = ["xlink:href"]
const _hoisted_6 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(['icon', 'icon-gray-power', 'AppliancesTile__icon']),
  "aria-hidden": "true"
}
const _hoisted_7 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__modeName'])
}
const _hoisted_8 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__modeName'])
}
const _hoisted_9 = {
  key: 2,
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__isOff'])
}
const _hoisted_10 = { class: "AppliancesTile__prog" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

import MyVTemperature from "@/components/domains/Heating/MyVTemperature/MyVTemperature.vue";

import {EApplianceMode, EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";
import {heatingType} from "@/helpers/domains/heatingType";
import {TAppliancesTile} from "@/components/domains/Heating/appliances/MyVAppliancesTile/interfaces";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {DiagnosticModule} from "@/store";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {EDiagnosticStatus} from "@/services/Diagnostic/interfaces";

export interface Props {
  name: TAppliancesTile["name"];
  mode: TAppliancesTile["mode"];
  temp?: TAppliancesTile["temp"];
  prog: TAppliancesTile["prog"];
  heatingLevel?: TAppliancesTile["heatingLevel"];
  link: TAppliancesTile["link"];
  id?: TAppliancesTile["id"];
  isOn?: TAppliancesTile["isOn"];
  type?: EApplianceType;
  progType?: EApplianceProgType;
  progName?: TAppliancesTile["progName"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesTile',
  props: {
    name: { default: undefined },
    mode: { default: undefined },
    temp: { default: undefined },
    prog: { default: undefined },
    heatingLevel: { default: 0 },
    link: { default: undefined },
    id: { default: undefined },
    isOn: { default: false },
    type: { default: undefined },
    progType: { default: EApplianceProgType.Default },
    progName: { default: '' }
  },
  setup(__props: any) {


const props = __props

const {t} = useI18n()

const formatedName = computed(() => {
  let formatedName = props.name;
  if (props.name.length > 25) {
    formatedName = formatedName.slice(0, 25) + "...";
  }
  return formatedName;
})

const isDiagnosticKo = computed(() => {
  const diag = DiagnosticModule.diagnostics.find(diag => diag.csApplianceId === props.id!)

  if (diag) {
    return diag?.status !== EDiagnosticStatus.OK && diag?.status !== EDiagnosticStatus.TEST_IN_PROGRESS
  }

  return false;
})

const iconName = computed(() => {
  if (props.progType === EApplianceProgType.Default) {
    return 'prog-off';
  } else if (props.type === EApplianceType.WaterHeater) {
    return props.isOn ? ('colored-power') : ('gray-power');
  } else {
    switch (props.mode) {
      case EApplianceMode.Comfort:
      case EApplianceMode.Comfort1:
      case EApplianceMode.Comfort2:
        return 'colored-sun';
      case EApplianceMode.Eco:
      case EApplianceMode.EcoV:
        return 'colored-moon';
      case EApplianceMode.FrostProtection:
        return 'colored-snow';
      case EApplianceMode.Temp:
        return 'colored-settings';
      case EApplianceMode.Normal:
        return props.isOn ? (
            'colored-sun'
        ) : (
            'gray-power'
        );
    }
  }
  throw new Error(`Appliance's mode ${props.mode} not supported !`);
})

const getHeatingType = computed(() => {
  return t(heatingType(props.mode, props.isOn));
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link!,
    class: _normalizeClass(['AppliancesTile', {'AppliancesTile__off': !_ctx.isOn }]),
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", { title: _ctx.name }, _toDisplayString(formatedName.value), 9, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (isDiagnosticKo.value)
            ? (_openBlock(), _createBlock(MyVIcon, {
                key: 0,
                name: "exclamation-circle"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['AppliancesTile__iconArea', _ctx.progType === _unref(EApplianceProgType).Default ? 'offProg' : ''])
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isOn)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                class: _normalizeClass(['icon', 'icon-'+iconName.value, 'AppliancesTile__icon']),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": ['#icon-' + iconName.value]
                }, null, 8, _hoisted_5)
              ], 2))
            : (_openBlock(), _createElementBlock("svg", _hoisted_6, _cache[0] || (_cache[0] = [
                _createElementVNode("use", { "xlink:href": ['#icon-gray-power'] }, null, -1)
              ])))
        ]),
        (_ctx.isOn && _ctx.mode === _unref(EApplianceMode).Temp && _ctx.temp)
          ? (_openBlock(), _createBlock(MyVTemperature, {
              key: 0,
              class: _normalizeClass(['AppliancesTile__temp']),
              value: _ctx.temp
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ], 2),
      ((_ctx.type !== _unref(EApplianceType).WaterHeater && _ctx.mode!=='NORMAL') && _ctx.isOn && _ctx.progType !== _unref(EApplianceProgType).Default)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(getHeatingType.value), 1))
        : _createCommentVNode("", true),
      ((_ctx.type === _unref(EApplianceType).WaterHeater || _ctx.mode=='NORMAL') && _ctx.isOn && _ctx.progType !== _unref(EApplianceProgType).Default)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('mode.on')), 1))
        : _createCommentVNode("", true),
      (!_ctx.isOn)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('mode.off')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.progType == _unref(EApplianceProgType).Program)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t(_ctx.progName) ?? ''), 1))
          : (_ctx.progType !== _unref(EApplianceProgType).Default)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t(_ctx.prog)), 1))
            : (_ctx.progType == _unref(EApplianceProgType).Default)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('appliance_progType.default')), 1))
              : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}
}

})