export const pageScroll = (allowScroll:boolean) => {
  document.querySelectorAll("html,body").forEach((item)=>{
    if(allowScroll) {
      item.classList.remove("noScroll");
    }
    else {
      item.classList.add("noScroll");
    }
  });
};
