import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "MyAccount__phones" }
const _hoisted_4 = { style: {"color":"red"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "MyAccount__relocate" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "popinForm__footer" }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import {notification} from "ant-design-vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {TMyAccountForm} from "@/views/domains/Profile/MyAccount/interfaces";
import {AuthModule, IntlModule, SiteModule} from "@/store";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";

import {PHONE_NUMBER_MAX_LENGTH, addSpacePhoneNumber, validatePhoneNumber} from "@/helpers/string/phone-number";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {TSite} from "@/services/Site/interfaces";
import {weekYearFormat} from "@/services/Conso/interfaces";
import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {EButtonSize, EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {useDate} from "@/helpers/dates/date-utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyAccountView',
  setup(__props) {

const form = reactive<TMyAccountForm>({
  email: '',
  phones: [],
  address: '',
  memberSince: null,
  name: '',
  reference: '',
  city: '',
  postalCode: '',
  siteName: ''
})
const error = ref<string | null>(null)
const phonesError = ref<string[]>([]);
let initialForm = ''

const {t} = useI18n()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const user = computed(() => {
  return AuthModule.user;
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const isFormValid = computed(() => {
  return phonesError.value.filter(el => {
    return el !== ''
  }).length <= 0 && !isSameForm.value && form.phones?.some(phone => phone.phoneNumber != "")
})

const locale = computed(() => {
  return IntlModule.locale;
})

const isMultiSites = computed(() => {
  return user.value.otherSites.length;
})

const save = async () => {
  if (!isFormValid.value) return false

  if (isMultiSites.value && form.siteName) {
    await SiteModule.updateSiteName({
      id: currentSiteId.value,
      name: form.siteName
    })
  }

  await AuthModule.editUser({
    email: form.email,
    phones: form.phones
  }).then(() => {
    notification.success({
      message: t('notification.success.title'),
      description: t('notification.success.desc'),
      duration: 3
    })
  })
}

const removePhone = (index: number) => {
  form.phones?.splice(index, 1)
  phonesError.value.splice(index, 1)
}

const isSameForm = computed(() => {
  return initialForm === JSON.stringify(form.phones).replace(/ /g, '');
})

onBeforeMount(() => {
  form.name = user.value.firstname + ' ' + user.value.lastname
  form.email = user.value.email
  form.reference = user.value.id.toString()
  form.memberSince = user.value.defaultSite.installationDate ? useDate(user.value.defaultSite.installationDate).locale(locale.value).format(weekYearFormat) : null
  form.address = `${currentSite.value.address}, ${currentSite.value.postalCode} ${currentSite.value.city}`
  form.phones = user.value.phones!
  initialForm = JSON.stringify(form.phones).replace(/ /g, '')
  form.postalCode = currentSite.value.postalCode
  form.city = currentSite.value.city
  form.siteName = currentSite.value.name

  if (user.value.phones.length === 0) {
    form.phones.push({phoneNumber: null, phoneType: null})
  }
})

watch(() => form.phones, () => {
  for (let i = 0; i < form.phones.length; i++) {
    if (form.phones[i].phoneNumber !== null) {
      phonesError.value[i] = !validatePhoneNumber(form.phones[i].phoneNumber!, isSiteCountryFrance.value) ? t('profile.my_account.phone_error') : ''
      form.phones[i].phoneNumber = addSpacePhoneNumber(form.phones[i].phoneNumber!.toString(), isSiteCountryFrance.value);
    }
  }
}, {immediate: true, deep: true})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('page.my_account.title'),
    type: "page",
    "return-action": _unref(ERoutes).HOME,
    class: "MyAccount"
  }, {
    default: _withCtx(() => [
      (error.value)
        ? (_openBlock(), _createBlock(MyVErrorBanner, {
            key: 0,
            error: error.value,
            class: "error-banner",
            onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null))
          }, null, 8, ["error"]))
        : _createCommentVNode("", true),
      _createElementVNode("form", {
        onSubmit: _withModifiers(save, ["prevent"]),
        class: "popinForm"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(MyVAntdinput, {
              disabled: "",
              value: form.name,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.name) = $event)),
              id: "Name",
              label: _ctx.$t('input.your_name')
            }, null, 8, ["value", "label"]),
            _createVNode(MyVAntdinput, {
              disabled: "",
              value: form.reference,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.reference) = $event)),
              id: "reference",
              label: _ctx.$t('input.your_reference')
            }, null, 8, ["value", "label"]),
            (form.memberSince)
              ? (_openBlock(), _createBlock(MyVAntdinput, {
                  key: 0,
                  disabled: "",
                  value: form.memberSince,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.memberSince) = $event)),
                  id: "reference",
                  label: _ctx.$t('input.member_since')
                }, null, 8, ["value", "label"]))
              : _createCommentVNode("", true),
            _createVNode(MyVAntdinput, {
              disabled: "",
              value: form.email,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((form.email) = $event)),
              id: "email",
              label: _ctx.$t('input.email')
            }, null, 8, ["value", "label"]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.phones, (phone, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "MyAccount__phoneRow",
                  key: index
                }, [
                  _createVNode(MyVAntdinput, {
                    class: "MyAccount__phonePhone",
                    value: phone.phoneNumber,
                    "onUpdate:value": ($event: any) => ((phone.phoneNumber) = $event),
                    id: `phone_${index}`,
                    maxlength: _unref(PHONE_NUMBER_MAX_LENGTH),
                    label: _ctx.$t('input.phone'),
                    error: phonesError.value[index] ?? null
                  }, {
                    help: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(phonesError.value[index] ?? null), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "onUpdate:value", "id", "maxlength", "label", "error"]),
                  (index > 0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: ($event: any) => (removePhone(index)),
                        class: "MyAccount__phones__delete"
                      }, _cache[7] || (_cache[7] = [
                        _createElementVNode("svg", {
                          class: "icon icon-cross",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-cross" })
                        ], -1)
                      ]), 8, _hoisted_5))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createVNode(MyVAntdinput, {
              disabled: "",
              value: form.address,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((form.address) = $event)),
              id: "address",
              label: _ctx.$t('input.your_address'),
              "input-type": _unref(EInputType).Textarea,
              "min-rows": 1
            }, null, 8, ["value", "label", "input-type"]),
            (isMultiSites.value)
              ? (_openBlock(), _createBlock(MyVAntdinput, {
                  key: 1,
                  value: form.siteName,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((form.siteName) = $event)),
                  id: "siteName",
                  label: _ctx.$t('input.site_name')
                }, null, 8, ["value", "label"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('page.my_account.relocate_strong')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('page.my_account.relocate_text')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(MyVButton, {
                type: _unref(EButtonType).Link,
                to: _unref(ERoutes).CONTACT,
                class: "MyAccount__contactService",
                primary: false,
                round: "",
                size: _unref(EButtonSize).Small
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.my_account.contact_member_service')), 1)
                ]),
                _: 1
              }, 8, ["type", "to", "size"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(MyVButton, {
            class: "MyAccount__save",
            type: _unref(EButtonType).Submit,
            disabled: !isFormValid.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('page.my_account.submit')), 1)
            ]),
            _: 1
          }, 8, ["type", "disabled"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "return-action"]))
}
}

})