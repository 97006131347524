import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "housing-equipment" }
const _hoisted_2 = {
  key: 0,
  class: "housing-equipment-categories"
}
const _hoisted_3 = { class: "housing-equipment-categories__category__name" }
const _hoisted_4 = ["xlink:href"]
const _hoisted_5 = { class: "housing-equipment-categories__category__value" }
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = ["onClick"]

import {onBeforeMount, ref} from "vue";
import {TUnmanagedAppliance} from "@/services/Appliances/interfaces";
import {AppliancesModule} from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHousingEquipment',
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const appliances = ref<TUnmanagedAppliance[]>([])
const error = ref<string | null>(null)
const idTimeout = ref<any | null>(null);
const appliancesCategoryToHide = ['unmanagedappliance.category.lighting']

const changeQuantity = (val: number, index: number) => {
  const payload = JSON.parse(JSON.stringify(appliances.value));

  payload[index].quantity += val

  if (idTimeout.value) clearTimeout(idTimeout.value);
  idTimeout.value = setTimeout(() => {
    AppliancesModule.putUnmanagedAppliances(payload).then(data => {
      appliances.value = data!.filter(appliance => !appliancesCategoryToHide.includes(appliance.category))
      emit('update:value', data)
      setAppliancesIcon()
    }).catch(e => {
      error.value = e.response ? e.response.data.message : 'Erreur serveur'
    })
  }, 300)
}

const setAppliancesIcon = () => {
  appliances.value = appliances.value.map((el: TUnmanagedAppliance) => {
    return {
      ...el,
      icon: el.category.split('.')[1]
    }
  })
}

onBeforeMount(() => {
  AppliancesModule.getUnmanagedAppliances().then(data => {
    appliances.value = data!.filter(appliance => !appliancesCategoryToHide.includes(appliance.category))
    setAppliancesIcon()
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur'
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('profile.housing.infos_appliances.description')), 1),
    (appliances.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(appliances.value, (appliance, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "housing-equipment-categories__category",
              key: index
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.$t(appliance.name)) + " ", 1),
                (_openBlock(), _createElementBlock("svg", {
                  class: _normalizeClass(['icon', 'icon-'+appliance.icon, 'appliance_icon']),
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", {
                    "xlink:href": '#icon-'+appliance.icon
                  }, null, 8, _hoisted_4)
                ], 2))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  disabled: appliance.quantity === 0,
                  type: "button",
                  onClick: ($event: any) => (changeQuantity(-1, index))
                }, _cache[0] || (_cache[0] = [
                  _createElementVNode("svg", {
                    class: "icon icon-minus",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-minus" })
                  ], -1)
                ]), 8, _hoisted_6),
                _createElementVNode("span", null, _toDisplayString(appliance.quantity), 1),
                _createElementVNode("button", {
                  type: "button",
                  onClick: ($event: any) => (changeQuantity(1, index))
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("svg", {
                    class: "icon icon-add",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-add" })
                  ], -1)
                ]), 8, _hoisted_7)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})