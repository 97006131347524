import API from "@/helpers/api";

import {TSavingsService} from "./interfaces";

const savingsService: TSavingsService = {
    getSavings: (siteId) => (
        API.get(`/api/site/${siteId}/savings`)
            .then(({data}) => data)
    ),
};

export default savingsService;
