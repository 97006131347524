import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = { class: "fieldset__inner" }
const _hoisted_4 = { class: "MultiStep__footer inner" }

import {onBeforeMount, reactive} from "vue";
import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import MyVAntdtimepicker from '@/components/ui/molecules/MyVAntdtimepicker/MyVAntdtimepicker.vue';
import MyVFieldset from '@/components/ui/atoms/MyVFieldset/MyVFieldset.vue';
import {Form} from 'ant-design-vue';

import {TProgramCreateHabitHours, TProgramCreateHabitHoursDatas} from "./interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  onSubmit: TProgramCreateHabitHours["onSubmit"];
  actionOnFinish?: TProgramCreateHabitHours["actionOnFinish"];
  returnAction?: TProgramCreateHabitHours["returnAction"];
  weekBedtime?: TProgramCreateHabitHours["weekBedtime"];
  weekWakeUpTime?: TProgramCreateHabitHours["weekWakeUpTime"];
  weekendBedtime?: TProgramCreateHabitHours["weekendBedtime"];
  weekendWakeUpTime?: TProgramCreateHabitHours["weekendWakeUpTime"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVProgramCreateHabitHours',
  props: {
    onSubmit: { type: Function, default: undefined },
    actionOnFinish: { default: undefined },
    returnAction: { default: undefined },
    weekBedtime: { default: "22:30" },
    weekWakeUpTime: { default: "07:00" },
    weekendBedtime: { default: "23:00" },
    weekendWakeUpTime: { default: "09:00" }
  },
  setup(__props: any) {

const props = __props

const form = reactive<TProgramCreateHabitHoursDatas>({
  weekBedtime: '',
  weekWakeUpTime: '',
  weekendBedtime: '',
  weekendWakeUpTime: '',
})

const onFinish = () => {
  props.onSubmit(form, props.actionOnFinish);
}

onBeforeMount(() => {
  form.weekBedtime = props.weekBedtime;
  form.weekWakeUpTime = props.weekWakeUpTime;
  form.weekendBedtime = props.weekendBedtime;
  form.weekendWakeUpTime = props.weekendWakeUpTime;
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(Form), {
    model: form,
    onFinish: onFinish,
    class: "MultiStep__form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('programCreate.tunnel.week_habit')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MyVAntdtimepicker, {
                inputRequired: true,
                value: form.weekWakeUpTime,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.weekWakeUpTime) = $event)),
                inlinelabel: _ctx.$t('programCreate.tunnel.wakeup_time'),
                label: _ctx.$t('programCreate.tunnel.hour')
              }, null, 8, ["value", "inlinelabel", "label"]),
              _createVNode(MyVAntdtimepicker, {
                inputRequired: true,
                value: form.weekBedtime,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.weekBedtime) = $event)),
                inlinelabel: _ctx.$t('programCreate.tunnel.sleep_time'),
                label: _ctx.$t('programCreate.tunnel.hour')
              }, null, 8, ["value", "inlinelabel", "label"])
            ])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(MyVFieldset, {
          title: _ctx.$t('programCreate.tunnel.weekend_habit')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(MyVAntdtimepicker, {
                inputRequired: true,
                value: form.weekendWakeUpTime,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.weekendWakeUpTime) = $event)),
                inlinelabel: _ctx.$t('programCreate.tunnel.wakeup_time'),
                label: _ctx.$t('programCreate.tunnel.hour')
              }, null, 8, ["value", "inlinelabel", "label"]),
              _createVNode(MyVAntdtimepicker, {
                inputRequired: true,
                value: form.weekendBedtime,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.weekendBedtime) = $event)),
                inlinelabel: _ctx.$t('programCreate.tunnel.sleep_time'),
                label: _ctx.$t('programCreate.tunnel.hour')
              }, null, 8, ["value", "inlinelabel", "label"])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(MyVButton, {
          type: _unref(EButtonType).Submit,
          primary: false,
          icon: "carret-right"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('programCreate.tunnel.next')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        (typeof(_ctx.returnAction)=='function')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
              type: "button",
              class: "popinForm__footer-back"
            }, _toDisplayString(_ctx.$t('global.cancel')), 1))
          : (_ctx.returnAction)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.returnAction,
                class: "popinForm__footer-back"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})