import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["xlink:href"]

import {EDropDownMenuDirection, TDropDownMenu} from "./interfaces";
import {onBeforeMount, ref} from "vue";

interface Props {
  icon?: TDropDownMenu['icon'];
  direction?: TDropDownMenu['direction'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDropDownMenu',
  props: {
    icon: { default: 'dot-menu' },
    direction: { default: EDropDownMenuDirection.BottomLeft }
  },
  setup(__props: any) {



const open = ref(false)

const toggleOpen = () => {
  open.value = !open.value;
}
const close = () => {
  open.value = false;
}
const stopPropagation = (e: any) => {
  e.stopPropagation();
}

onBeforeMount(() => {
  document.addEventListener('click', close)
  document.addEventListener('closeDropDownMenu', close)
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropDownMenu",
    onClick: stopPropagation
  }, [
    _createElementVNode("button", {
      type: "button",
      class: "dropDownMenu__button",
      onClick: toggleOpen,
      title: _ctx.$t('global.open_option_menu')
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(['dropDownMenu__button-icon', 'icon', 'icon-'+_ctx.icon]),
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", {
          "xlink:href": ['#icon-'+_ctx.icon]
        }, null, 8, _hoisted_2)
      ], 2))
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(`dropDownMenu__content ${_ctx.direction} ${open.value ?  'open' : ''}`),
      onClick: stopPropagation
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})