import API from "@/helpers/api";

import {TWelcomeInfo, TWelcomeService} from "./interfaces";

const WelcomeService: TWelcomeService = {
  getWelcomeInformations: siteId => (
    API.get<TWelcomeInfo>(`/api/site/${siteId}/consumption/welcome`)
    .then(({ data }) => data)
  ),
};

export default WelcomeService;
