import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = {
  key: 0,
  class: "card-container-header"
}

import {ECardContainerColor} from "@/components/ui/atoms/MyVCardContainer/interfaces";
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCardContainer',
  props: {
    hideHeader: { type: Boolean },
    contentColor: {}
  },
  setup(__props: any) {

const props = __props

const buildColor = computed(() => {
  if (props.contentColor)
    return `card-container-content__${props.contentColor}`

  return `card-container-content__${ECardContainerColor.none}`

})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "left"),
          _renderSlot(_ctx.$slots, "right")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['card-container-content', buildColor.value ])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ]))
}
}

})