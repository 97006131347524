import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signature-reminder" }
const _hoisted_2 = { class: "signature-reminder-wrapper" }
const _hoisted_3 = { class: "signature-reminder-icon-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "signature-reminder-title" }
const _hoisted_6 = { class: "signature-reminder-subtitle" }
const _hoisted_7 = ["innerHTML"]

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import earthPlant from '@/assets/images/earth_plant.png'
import {onBeforeMount, onUnmounted} from "vue";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {TSignatureStatus} from "@/services/Signature/interfaces";
import {isWebViewMobileApp} from "@/helpers/domains/device";
import {openUrlInNewWebview} from "@/helpers/domains/mobileApps/flutter-connectors";
import {EFlutterOpenUrlInWebviewType} from "@/helpers/domains/mobileApps/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVSignatureReminderPopin',
  props: {
    signatureUrl: {}
  },
  emits: ['linkClicked'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props

const handleSignature = () => {
  if (props.signatureUrl) {
    if (isWebViewMobileApp(window)) {
      openUrlInNewWebview(EFlutterOpenUrlInWebviewType.Open, false, props.signatureUrl)
    } else {
      window.open(props.signatureUrl)
    }
  }
}

onBeforeMount(() => {
  pageScroll(false);
})

onUnmounted(() => {
  pageScroll(true);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "signature-reminder-icon",
          src: _unref(earthPlant),
          alt: "plant"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('cee.signatureReminder.title')), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('cee.signatureReminder.subtitle')), 1),
      _createElementVNode("div", {
        innerHTML: _ctx.$t('cee.signatureReminder.description'),
        class: "signature-reminder-description"
      }, null, 8, _hoisted_7),
      _createVNode(MyVButton, {
        class: "signature-reminder-button",
        round: "",
        onClick: handleSignature
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('cee.signatureReminder.completeRegistration')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("a", {
        class: "signature-reminder-link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('linkClicked')))
      }, _toDisplayString(_ctx.$t('cee.signatureReminder.alreadyRegistered')), 1)
    ])
  ]))
}
}

})