import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["xlink:href"]
const _hoisted_2 = ["data-testid", "for"]
const _hoisted_3 = { class: "" }
const _hoisted_4 = { key: 0 }

import {EInputType, TInput} from "./interfaces";

import {Form, Input} from 'ant-design-vue';
import {computed, onBeforeMount, ref, watch} from "vue";

export interface Props {
  autoComplete?: TInput["autoComplete"];
  inputRequired?: TInput["inputRequired"];
  inputType?: TInput["inputType"];
  placeholder?: TInput["placeholder"];
  id?: TInput["id"];
  hasAnyStep?: boolean;
  icon?: TInput["icon"];
  label?: TInput["label"];
  value?: TInput["value"];
  defaultValue?: TInput["defaultValue"];
  error?: TInput["error"];
  success?: TInput["success"];
  maxlength?: TInput["maxlength"];
  min?: TInput["min"];
  max?: TInput["max"];
  disabled?: TInput['disabled'];
  allowClear?: TInput['allowClear'];
  hasUnit?: TInput['hasValue'];
  minRows?: TInput['minRows'];
  maxRows?: TInput['maxRows'];
  dataTestId?: TInput['dataTestId'];
  addonAfter?: TInput['addonAfter']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntdinput',
  props: {
    autoComplete: { default: undefined },
    inputRequired: { type: Boolean, default: false },
    inputType: { default: EInputType.Text },
    placeholder: { default: undefined },
    id: { default: undefined },
    hasAnyStep: { type: Boolean, default: false },
    icon: { default: undefined },
    label: { default: undefined },
    value: { default: undefined },
    defaultValue: { default: '' },
    error: { default: undefined },
    success: { default: undefined },
    maxlength: { default: undefined },
    min: { default: undefined },
    max: { default: undefined },
    disabled: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: false },
    hasUnit: { type: Boolean, default: false },
    minRows: { default: 2 },
    maxRows: { default: 6 },
    dataTestId: { default: undefined },
    addonAfter: {}
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const {Item} = Form;
const {TextArea} = Input;

const props = __props

const emit = __emit

const type = ref(EInputType.Text)
const m_value = ref('')
const hasAutofill = ref(false)
const input = ref()
const isFocused = ref(false)

const hasValue = computed(() => {
  return isFocused.value || m_value.value !== '' || hasAutofill.value;
});

const checkAnimation = (e: any) => {
  if (e.animationName == "onAutoFillStart") {
    hasAutofill.value = true;
  } else if (e.animationName == "onAutoFillCancel") {
    hasAutofill.value = false;
  }
}

const onChange = (e: any) => {
  if (props.inputType === EInputType.NumberInput && e.data === 'e') return false
  m_value.value = e.target.value;
  emit('update:value', m_value.value);
}

const togglePassword = () => {
  type.value = (type.value === EInputType.Password) ? EInputType.Text : EInputType.Password;
}

const handleFocus = () => {
  isFocused.value = true;
};

const handleBlur = () => {
  isFocused.value = false;
};

onBeforeMount(() => {
  type.value = props.inputType!;
  m_value.value = props.value || props.defaultValue;
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    m_value.value = newValue;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Item), {
    class: "form__group",
    "validate-status": _ctx.error ? 'error' : (_ctx.success ? 'success' : undefined)
  }, {
    default: _withCtx(() => [
      (_ctx.inputType=='textarea')
        ? (_openBlock(), _createBlock(_unref(TextArea), {
            key: 0,
            "auto-size": { minRows: _ctx.minRows, maxRows: _ctx.maxRows },
            placeholder: _ctx.placeholder,
            type: _ctx.inputType,
            required: _ctx.inputRequired,
            id: _ctx.id,
            name: _ctx.id,
            class: _normalizeClass({'has-value': hasValue.value}),
            onChange: onChange,
            value: m_value.value,
            defaultValue: _ctx.defaultValue,
            maxlength: _ctx.maxlength,
            disabled: _ctx.disabled
          }, null, 8, ["auto-size", "placeholder", "type", "required", "id", "name", "class", "value", "defaultValue", "maxlength", "disabled"]))
        : (_openBlock(), _createBlock(_unref(Input), {
            "data-testid": `${_ctx.dataTestId}-input`,
            allowClear: _ctx.allowClear,
            onAnimationstart: checkAnimation,
            step: _ctx.hasAnyStep ? 'any' : null,
            autocomplete: _ctx.autoComplete,
            ref_key: "input",
            ref: input,
            key: _ctx.id,
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            type: type.value,
            required: _ctx.inputRequired,
            id: _ctx.id,
            name: _ctx.id,
            class: _normalizeClass({'has-value': hasValue.value, 'has-unit': _ctx.hasUnit}),
            onChange: onChange,
            value: m_value.value,
            defaultValue: _ctx.defaultValue,
            maxlength: _ctx.maxlength,
            min: _ctx.min,
            max: _ctx.max,
            autocapitalize: "off",
            addonAfter: _ctx.addonAfter,
            onFocus: handleFocus,
            onBlur: handleBlur
          }, _createSlots({ _: 2 }, [
            (_ctx.icon || _ctx.inputType=='password')
              ? {
                  name: "suffix",
                  fn: _withCtx(() => [
                    (_ctx.inputType==='password')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: _normalizeClass(['form__togglePassword',{'active':type.value!=='password'}]),
                          onClick: togglePassword
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("svg", {
                            class: "icon ion-eye",
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", { "xlink:href": "#icon-eye" })
                          ], -1)
                        ]), 2))
                      : (_openBlock(), _createElementBlock("svg", {
                          key: 1,
                          class: _normalizeClass('icon icon-'+_ctx.icon),
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", {
                            "xlink:href": '#icon-'+_ctx.icon
                          }, null, 8, _hoisted_1)
                        ], 2))
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["data-testid", "allowClear", "step", "autocomplete", "disabled", "placeholder", "type", "required", "id", "name", "class", "value", "defaultValue", "maxlength", "min", "max", "addonAfter"])),
      (_ctx.label!==null)
        ? (_openBlock(), _createElementBlock("label", {
            key: 2,
            "data-testid": `${_ctx.dataTestId}-label`,
            for: _ctx.id,
            class: "form__group__label"
          }, [
            _createElementVNode("span", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.inputRequired)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " *"))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "form__group__bar" }, null, -1)),
      _renderSlot(_ctx.$slots, "help")
    ]),
    _: 3
  }, 8, ["validate-status"]))
}
}

})