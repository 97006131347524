import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment } from "vue"

import {Chart} from 'chart.js';
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {TBarRangeColorGraphic} from "./interfaces";
import {onBeforeMount, onUpdated, ref, watch} from "vue";

type ChartDatasets = Chart<'bar'>['data']['datasets'];

interface Props {
  labels: TBarRangeColorGraphic["labels"];
  peakDatas: TBarRangeColorGraphic["datas"];
  offpeakDatas: TBarRangeColorGraphic["datas"];
  leftAxis: TBarRangeColorGraphic["leftAxis"];
  columnBackgroundColor: TBarRangeColorGraphic["columnBackgroundColor"];
  labelFilter?: TBarRangeColorGraphic["labelFilter"];
  ranges: TBarRangeColorGraphic["ranges"];
  onAdjustChart?: TBarRangeColorGraphic['onAdjustChart'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBarRangeColorGraphic',
  props: {
    labels: { default: undefined },
    peakDatas: { default: undefined },
    offpeakDatas: { default: undefined },
    leftAxis: { default: undefined },
    columnBackgroundColor: { default: undefined },
    labelFilter: { type: Function, default: undefined },
    ranges: { default: undefined },
    onAdjustChart: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props


const chartDatas = ref<any>();
const update = ref(false);
const chartRef = ref(null)

const createChart = () => {
  const legendHidden = ['Total'];

  const labelFilter = props.labelFilter !== undefined ? props.labelFilter : () => true;
  const ranges = props.ranges
  ranges.sort((ra, rb) => ra.start - rb.start);

  const datasets = [{
    type: "bar",
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    data: props.peakDatas,
    backgroundColor: "rgb(77, 190, 247)",
    order: 1,
    datalabels: {
      display: false,
    },
  },{
    type: "bar",
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    data: props.offpeakDatas,
    backgroundColor: "rgb(151, 190, 13)",
    order: 1,
    datalabels: {
      display: false,
    },
  }] as ChartDatasets;

  datasets.push({
    label: legendHidden[0],
    data: props.labels.map(() => props.leftAxis.max - ((props.leftAxis.step || 1) / 20)),
    backgroundColor: props.columnBackgroundColor,
    hoverBackgroundColor: props.columnBackgroundColor,
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    order: 2,
    datalabels: {
      display: false,
    },
  });

  chartDatas.value = {
    type: "bar",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 24,
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          reverse: true,
          labels: {
            boxWidth: 8,
            usePointStyle: true,
            pointStyle: 'rectRounded',
            filter: (item: any): boolean => {
              return legendHidden.indexOf(item.text) === -1;
            },
            generateLabels: () => {
              const labels: any[] = [];

              for (const range of props.ranges) {
                if (labels.findIndex(label => label.text === range.title) === -1) {
                  labels.push({
                    text: range.title,
                    fillStyle: range.color,
                    boxWidth: 8,
                    pointStyle: 'rectRounded',
                    usePointStyle: true,
                  });
                }
              }
              return labels;
            }
          }
        },
        tooltip: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label: function (context: any) {
              if (context.raw)
                return `${props.ranges.slice().reverse()[context.datasetIndex].title} : ${context.raw.toFixed(2) } ${props.leftAxis.title}`
            },
            title: (context: any) => {
                return context[0]?.label
              }
            }
          }
      },
      scales: {
        x: {
          ticks: {
            callback: function (value: number) {
              const label: string = (this as any).getLabelForValue(value);

              return labelFilter(label) ? label : '';
            },
          },
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          min: props.leftAxis.min,
          max: props.leftAxis.max,
          ticks: {
            stepSize: props.leftAxis.step || 1
          },
          grid: {
            display: true,
            borderWidth: false,
            borderDash: [8, 4]
          },
          stacked: true
        },
      },
    },
    data: {
      datasets,
      labels: props.labels,
    },
  };

  props.onAdjustChart?.(chartDatas.value);
}

onUpdated(() => {
  if (update.value && chartRef.value) {
    createChart();
    (chartRef.value as any).chartJSState.props.data = chartDatas.value.data;
    (chartRef.value as any).chartJSState.props.options = chartDatas.value.options;
    (chartRef.value as any).update();
    update.value = false;
  }
})

onBeforeMount(() => {
  createChart()
})

watch([() => props.labels, () => props.peakDatas, () => props.offpeakDatas, () => props.leftAxis, () => props.columnBackgroundColor,
  () => props.labelFilter, () => props.ranges], () => {
  update.value = true
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.leftAxis)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.axisTitles)
        }, _toDisplayString(_ctx.leftAxis.title), 3))
      : _createCommentVNode("", true),
    _createVNode(_unref(Vue3ChartJs), _mergeProps({
      ref_key: "chartRef",
      ref: chartRef
    }, chartDatas.value), null, 16)
  ], 64))
}
}

})