import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "DevicesStatusList" }

import MyVDevicesStatusTile from "@/components/domains/Device/MyVDevicesStatusTile/MyVDevicesStatusTile.vue";
import {TDiagnostic} from "@/services/Diagnostic/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDevicesStatusList',
  props: {
    diagnostics: {}
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.diagnostics, (diagnostic, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "gridItem",
        key: index
      }, [
        _createVNode(MyVDevicesStatusTile, { diagnostic: diagnostic }, null, 8, ["diagnostic"])
      ]))
    }), 128))
  ]))
}
}

})