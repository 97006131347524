import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {AuthModule} from "..";

import {TObjective} from "@/services/Objective/interfaces";
import ObjectiveService from "@/services/Objective";

import {EModules} from "../modules";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import MixpanelService from "@/services/Mixpanel";
import {ETrackObjectiveEvent} from "@/services/Mixpanel/interfaces";
import {EEnergyUnit} from "@/helpers/constants/unit";
import useCurrency from "@/composables/currency";

const defaultObjective: TObjective = {
    yearlyObjectiveInWh: 0,
    yearlyObjectiveInCurrency: 0
};

@Module({name: EModules.OBJECTIVE})
class Objective extends VuexModule {
    private _objective: TObjective | undefined = undefined;

    /**
     * Getters
     */
    get objective(): TObjective | undefined {
        return this._objective;
    }

    /**
     * Mutations
     */

    @Mutation
    public _setObjective(objective: TObjective): void {
        this._objective = objective;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getObjective(forceRequest = false) {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                (this.objective !== undefined && !forceRequest) ? Promise.resolve(this.objective) :
                    ObjectiveService.getObjective(getCurrentSiteIdFromCookie(user))
                        .then(objective => {
                            this.context.commit("_setObjective", objective);
                            return objective;
                        })
                        .catch(e => {
                            if (e.response.status !== 404) {
                                throw e;
                            }
                            this.context.commit("_setObjective", undefined);
                            return defaultObjective;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateObjectiveWh(objective: number) {
        const user = AuthModule.userOrNull;

        if (user) {
            return ObjectiveService.updateObjectiveWh(getCurrentSiteIdFromCookie(user), objective)
                .then(objective => {
                    this.context.commit("_setObjective", objective);
                    this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})
                    if (!this._objective) {
                        MixpanelService.trackObjective(ETrackObjectiveEvent.ADDED, {
                            value: `${objective.yearlyObjectiveInWh! / 1000} ${EEnergyUnit.kWh}`,
                        })
                    }

                    return objective;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateObjectiveCurrency(objective: number) {
        const user = AuthModule.userOrNull;
        if (user) {
            return ObjectiveService.updateObjectiveCurrency(getCurrentSiteIdFromCookie(user), objective)
                .then(objective => {
                    this.context.commit("_setObjective", objective);
                    this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})
                    if (!this._objective) {
                        const {currency} = useCurrency();
                        MixpanelService.trackObjective(ETrackObjectiveEvent.ADDED, {
                            value: `${objective.yearlyObjectiveInCurrency!} ${currency.value}`,
                        })
                    }
                    return objective;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getCurrencyEstimation(wh: number) {
        const user = AuthModule.userOrNull;

        if (user) {
            return ObjectiveService.getCurrencyEstimation(getCurrentSiteIdFromCookie(user), wh)
                .then(res => {
                    return res;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getWhEstimation(objective: number) {
        const user = AuthModule.userOrNull;

        if (user) {
            return ObjectiveService.getWhEstimation(getCurrentSiteIdFromCookie(user), objective)
                .then(res => {
                    return res;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public deleteObjetive() {
        const user = AuthModule.userOrNull;

        if (user) {
            this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})

            return ObjectiveService.deleteObjective(getCurrentSiteIdFromCookie(user));
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }
}

export default Objective;
