import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {AppliancesModule, AuthModule, SiteModule} from "@/store";

import TabNav from "./MyVTabNav.vue";
import {TTabNav} from "./interfaces";
import {EDisplayGroupRightType} from "@/services/DisplayGroup/interfaces";
import {computed} from "vue";
import ERoutes from "@/router/routes";
import {useResponsive} from "@/composables/useResponsive";

interface Props {
  listItem: TTabNav["listItem"];
  activeLink?: TTabNav["activeLink"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTabNavStore',
  props: {
    listItem: { default: undefined },
    activeLink: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const {isMobile} = useResponsive()

const listItemFiltered = computed(() => {
  const listItem = [];

  for (const item of props.listItem) {
    const tmpItem = {...item};

    if (tmpItem.right) {
      const right = AuthModule.user.displayGroup.rights.find(({name}) => name === tmpItem.right);
      const hasBasicOffer = SiteModule.hasBasicOffer;

      if (right?.enabled === false) {
        switch (right.type) {
          case EDisplayGroupRightType.ENABLE:
            tmpItem.disabled = true;
            break;
          case EDisplayGroupRightType.DISPLAY:
            continue;
        }
      }

      if (hasBasicOffer && item.lockable) {
        tmpItem.locked = true;
      }
    }
    const firstAppliance = AppliancesModule.appliances?.[0]

    if (!isMobile.value && tmpItem.link === ERoutes.HEATING && firstAppliance) {
      tmpItem.link += `/${AppliancesModule.appliances?.length ? firstAppliance.id : ""}`
    }

    listItem.push(tmpItem);
  }

  return listItem;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TabNav, {
    listItem: listItemFiltered.value,
    activeLink: _ctx.activeLink
  }, null, 8, ["listItem", "activeLink"]))
}
}

})