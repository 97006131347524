import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pdl-validation-image-upload-step" }
const _hoisted_2 = { class: "pdl-validation-image-upload-step-action" }
const _hoisted_3 = { for: "file" }
const _hoisted_4 = {
  key: 0,
  class: "pdl-validation-image-upload-step-preview"
}
const _hoisted_5 = {
  key: 0,
  class: "icon icon-file-pdf",
  "aria-hidden": "true"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 2,
  class: "pdl-validation-image-upload-step-error"
}
const _hoisted_9 = {
  key: 3,
  class: "pdl-validation-image-upload-step-error"
}

import {PlusOutlined} from '@ant-design/icons-vue';
import {FileInfo} from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationImageUploadStep/interfaces";
import {ContractModule} from "@/store";
import {Spin} from 'ant-design-vue';
import imageCompression from 'browser-image-compression';
import {computed, reactive, ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPdlValidationModalImageUploadStep',
  setup(__props) {

const fileValue = reactive<FileInfo>({file: null, previewUrl: undefined});
const isSizeError = ref(false);
const isLoading = ref(false);
const isFormatError = ref(false);
const authorizedMime = ref<string[]>(["image/jpeg", "image/png", "application/pdf"])

const invoiceUrl = computed((): FileInfo => {
  return ContractModule.pdlValidationInvoice;
})

const onFileChange = async (e: any) => {
  isLoading.value = true
  await ContractModule.updatePdlValidationInvoice({file: null, previewUrl: undefined});
  let file = e.target?.files[0]

  if (!file){
    isLoading.value = false
  }

  if (file.type === 'image/jpeg' || file.type === 'image/png') {
    file = await compressImage(file);
  }
  fileValue.file = file;
  fileValue.previewUrl= URL.createObjectURL(file) ?? undefined

  if (file.size / 1000000 > 5) {
    isSizeError.value = true;
    isLoading.value = false
  } else if (!authorizedMime.value.includes(file.type)) {
    isFormatError.value = true;
    isLoading.value = false
  } else {
    isSizeError.value = false;
    isFormatError.value = false;

    ContractModule.updatePdlValidationInvoice(fileValue).then(() => isLoading.value = false)
  }
}

const compressImage = async (imageFile: File) => {
  const options = {
    maxSizeMB: 5,
    maxWidthOrHeight: 1920,
    useWebWorker: false
  }
  try {
    return await imageCompression(imageFile, options)

  } catch (error) {
    isSizeError.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _createVNode(_unref(PlusOutlined)),
        _createTextVNode(" " + _toDisplayString(invoiceUrl.value.previewUrl ? _ctx.$t('conso.pdl.validation.update') : _ctx.$t('conso.pdl.validation.upload_invoice')), 1)
      ]),
      _createElementVNode("input", {
        type: "file",
        onChange: onFileChange,
        id: "file",
        accept: "image/png, image/jpeg, application/pdf"
      }, null, 32)
    ]),
    (invoiceUrl.value.previewUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (invoiceUrl.value.file?.type === 'application/pdf')
            ? (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[0] || (_cache[0] = [
                _createElementVNode("use", { "xlink:href": "#icon-file-pdf" }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: invoiceUrl.value.previewUrl,
                alt: ""
              }, null, 8, _hoisted_6)),
          (invoiceUrl.value.file)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(invoiceUrl.value.file.name), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(_unref(Spin), {
          key: 1,
          size: "size"
        }))
      : _createCommentVNode("", true),
    (isFormatError.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('conso.pdl.validation.format_error')), 1))
      : _createCommentVNode("", true),
    (isSizeError.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('conso.pdl.validation.size_error')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})