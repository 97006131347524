const checkForAlphanumericAndAccent = (event: any) => {
    if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ]+$/.test(event.key) || event.keyCode === 8) return true; // Match with regex
    else return event.preventDefault(); // If not match, don't add to input text
}

const normalizeWithoutEmoji = (input: string): string => {
    const emojiRegex = /[\u{1F600}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;

    return input.replace(emojiRegex, '').trim();
}

export {checkForAlphanumericAndAccent, normalizeWithoutEmoji}