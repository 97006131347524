import {TErrors, TErrorService, TRequestError} from "./interfaces";

export type {
  TErrors,
  TRequestError
}

const ErrorService: TErrorService = {
  getError: (errors, field = '') => errors?.find(error => error.field === field) || null
};

export default ErrorService;
