import API from "@/helpers/api";

import {EAuthRoute, ELoginFields, TAuthService, TLoginResponse, TNewPasswordResponse, TUser,} from "./interfaces";

export const getUser = (): Promise<TUser> => API.get<TUser>(EAuthRoute.LOGIN_FROM_COOKIE).then(({ data }) => data);

const AuthService: TAuthService = {
  login: (username, password) =>
    API.post<TLoginResponse>(EAuthRoute.LOGIN, {
      [ELoginFields.USERNAME]: username,
      [ELoginFields.PASSWORD]: password,
    })
    .then(({ data: { token } }) => {
      API.setAuthToken!(token);
      return getUser();
    })
    .catch(e => {
      if (e.response && e.response.status == 301) {
        window.location.href = e.response.data.oldMyVoltalisUrl
      }
      throw e;
    }),

  loginFromCookie: (token) => {
    API.setAuthToken!(token);
    return getUser();
  },

  logout: () => {
    if (API.defaults.headers.common["Authorization"]) {
      return new Promise(resolve => {
        API.delete(EAuthRoute.LOGOUT)
        .catch(console.error)
        .finally(() => {
          API.forgetAuth!();
          resolve();
        });
      })
    }
    else {
      API.forgetAuth!();
    }
    return Promise.resolve();
  },

  editUser: body =>
    API.put<TUser>(EAuthRoute.EDIT_USER, {
      email: body.email,
      phones: body.phones
    })
    .then(({data}) => data),


  changePassword: body => (
    API.put<void>(EAuthRoute.CHANGE_PASSWORD, {
      invalidateTokens: body.invalidateTokens,
      newPassword: body.newPassword,
      password: body.password
    })
    .then(({ data }) => data)
  ),

  requestNewPassword: ({ email }) => (
    API.post(EAuthRoute.REQUEST_NEW_PASSWORD, {
      email
    })
    .then(({ data }) => data)
  ),

  checkOnboardingTokenValidity: body => (
    API.post<TNewPasswordResponse>(EAuthRoute.ONBOARDING_CHECK_TOKEN_VALIDITY, body).then(({data}) => data)
  ),

  createOnboardingPassword: body => (
    API.post<TNewPasswordResponse>(EAuthRoute.ONBOARDING_CREATE_PASSWORD, body)
      .then(({data}) => data)
  ),

  checkNewPasswordTokenValidity: body => (
    API.post<TNewPasswordResponse>(EAuthRoute.NEW_PASSWORD_CHECK_TOKEN, body)
      .then(({data}) => data)
  ),

  newPasswordUpdate: body => (
    API.post<TNewPasswordResponse>(EAuthRoute.NEW_PASSWORD_UPDATE, body)
      .then(({data}) => data)
  )
};

export default AuthService;
