import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "CirclePercentCompare" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "total"
}

import {TCirclePercentCompare} from "./interfaces";
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCirclePercentCompare',
  props: {
    categoryTotal: {},
    compareTotal: {}
  },
  setup(__props: any) {


const props = __props

const datas = computed(() => {
  const percent = Math.round(((props.categoryTotal - props.compareTotal) / props.compareTotal) * 100)
  const total = Math.round(props.categoryTotal - props.compareTotal)

  return {percent, total}
})

const infinity = computed(() => Infinity)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["percent", {negative: datas.value.percent < 0}])
    }, [
      (datas.value.percent && datas.value.percent !== infinity.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(datas.value.percent > 0 ? `+${datas.value.percent}` : datas.value.percent), 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "%", -1))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('conso.repartition.no_data')), 1))
    ], 2),
    (datas.value.percent !== infinity.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(datas.value.total > 0 ? `+${datas.value.total}` : datas.value.total) + "kWh ", 1))
      : _createCommentVNode("", true)
  ]))
}
}

})