import {TUser} from "@/services/Auth/interfaces";
import Cookie from "@/helpers/cookie";
import {TSite} from "@/services/Site/interfaces";
import {AuthModule} from "@/store";
import {ECookieNames} from "@/helpers/cookie/interfaces";

// TODO: refactor me inside a dedicated service
export const getCurrentSiteIdFromCookie = (user: TUser) => {
  const cookieName = `${ECookieNames.CURRENT_SITE_ID}${user?.id}`;
  const currentSiteIdCookie = Cookie.get(cookieName);

  return currentSiteIdCookie ? parseInt(currentSiteIdCookie) as TSite['id']  : user?.defaultSite.id;
}

export const getCurrentSiteObjectFromCookie = (siteId: TSite['id']) => {
  const userOtherSites = AuthModule.user.otherSites;
  const currentSite = userOtherSites.find(site => site.id === siteId);

  return currentSite ? currentSite : AuthModule.user.defaultSite;
}