import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {EModules} from "../modules";
import {AuthModule} from "@/store";
import ContractService from "@/services/Contract";
import {EPdlStates, TContract, TContractEditable, TPdlValidity} from "@/services/Contract/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {FileInfo} from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationImageUploadStep/interfaces";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import {getUser} from "@/services/Auth";

@Module({name: EModules.CONTRACT})
class Contract extends VuexModule {
    /**
     * State
     */
    private _contractsList: TContract[] = [];
    private _contractKey = 0;
    private _pdlState: EPdlStates | undefined = undefined;
    private _hasUploadedElectricityBill = false;
    private _fakeContract: TContract | undefined = undefined;
    private _isPdlChecked = false;
    private _pdlValidationInvoice: FileInfo = {file: null, previewUrl: undefined};

    /**
     * Getters
     */
    get contractsList() {
        return this._contractsList;
    }

    get isPdlValid() {
        return this._pdlState === EPdlStates.validated;
    }

    get pdlState() {
        return this._pdlState;
    }

    get hasUploadedElectricityBill() {
        return this._hasUploadedElectricityBill;
    }

    get contractKey() {
        return this._contractKey;
    }

    get currentContract() {
        return this._contractsList.find(contract => !contract.endDate)
    }

    get isCurrentContractDefault() {
        return this._contractsList.some(contract => contract.isDefault)
    }

    get fakeContract() {
        return this._fakeContract;
    }

    get isPdlChecked() {
        return this._isPdlChecked;
    }

    get pdlValidationInvoice() {
        return this._pdlValidationInvoice;
    }

    /**
     * Mutations
     */
    @Mutation
    public _updateFakeContractProperty(datas: Partial<TContract>): void {
        if (!this._fakeContract) {
            this._fakeContract = {
                id: null,
                apiContractId: null,
                name: '',
                subscribedPower: 0,
                companyName: '',
                subscriptionBasePrice: null,
                kwhBasePrice: null,
                creationDateTime: null,
                isPeakOffPeakContract: false,
                offpeakHours: [],
                kwhPeakHourPrice: null,
                siteId: getCurrentSiteIdFromCookie(AuthModule.user),
                companyId: null,
                kwhOffpeakHourPrice: null,
                subscriptionPeakAndOffPeakHourBasePrice: null,
                peakHours: [],
                endDate: null,
                startDate: null,
                isDefault: false,
            };
        }
        for (const key in datas) {
            (this._fakeContract as any)[key] = (datas as any)[key]
        }
    }

    @Mutation
    _incrementContractKey(): void {
        this._contractKey++;
    }

    @Mutation
    _setInvoiceSent(hasUploadedElectricityBill: boolean): void {
        this._hasUploadedElectricityBill = hasUploadedElectricityBill
    }

    @Mutation
    _initFakeContract(contractId: number | null): void {
        this._fakeContract = (this._contractsList.length && contractId !== null) ? JSON.parse(JSON.stringify(this._contractsList.find(contract => contract.id === contractId))) : undefined;
    }

    @Mutation
    _hasCheckPdl(): void {
        this._isPdlChecked = true;
    }

    @Mutation
    _setPdlState(state: EPdlStates): void {
        this._pdlState = state;
    }

    @Mutation
    _setPdlValidationInvoice(invoice: FileInfo): void {
        this._pdlValidationInvoice = invoice;
    }

    @Mutation
    _setContractsList(contractsList: TContract[]): void {
        this._contractsList = contractsList;
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public getContractsList(forceRequest = false): Promise<TContract[] | void> {
        const user = AuthModule.userOrNull;

        if (!user)
            return Promise.reject(new Error('Error 403: You must be connected !'));

        if (this._contractsList.length && !forceRequest)
            return Promise.resolve(this._contractsList);

        return ContractService.getContractsList(getCurrentSiteIdFromCookie(user)).then(contracts => {
            this.context.commit('_setContractsList', contracts.reverse());
            return contracts;
        }).catch(e => {
            this.context.commit('_setContractsList', []);
            throw e;
        }).finally(async () => {
            if (this._contractsList.length === 0) {
                await updateSwitchConso(true)
            }
        })
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public createContract(body: Omit<TContractEditable, 'id'>[]): Promise<TContract> {
        const user = AuthModule.userOrNull;

        if (user) {
            return ContractService.postContract(getCurrentSiteIdFromCookie(user), body)
                .then(contracts => {
                    this.context.commit('_setContractsList', contracts!);
                    this.context.commit('_incrementContractKey');
                    this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})

                    return contracts;
                }).catch(e => {
                    console.error(e);
                    throw e;
                })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));

    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public updateContract(body: Partial<TContractEditable>[]): Promise<TContract> {
        const user = AuthModule.userOrNull;

        if (user) {
            return ContractService.putContract(getCurrentSiteIdFromCookie(user), body)
                .then(contracts => {
                    this.context.commit('_setContractsList', contracts);
                    this.context.commit('_incrementContractKey');
                    this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})

                    return contracts;
                }).catch(e => {
                    console.error(e);
                    throw e;
                }).finally(async () => {
                    await getUser()
                    await this.context.dispatch('getContractsList');
                })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public deleteAllContracts(): Promise<boolean | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return ContractService.deleteAllContracts(getCurrentSiteIdFromCookie(user)).then(res => {
                this.context.commit('_setContractsList', []);
                this.context.dispatch('invalidateConsumptionCacheTtl', null, {root: true})

                return res;
            }).catch(e => {
                throw e;
            }).finally(async () => {
                await getUser()
            })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getPdlValidity(forceRequest = false): Promise<TPdlValidity> {
        const user = AuthModule.userOrNull;
        if ((!this.isPdlChecked || forceRequest)) {
            this.context.commit('_hasCheckPdl')
            return ContractService.getPdlValidity(getCurrentSiteIdFromCookie(user!)).then(res => {
                this.context.commit('_setPdlState', res.pdlStatus);
                this.context.commit('_setInvoiceSent', res.hasUploadedElectricityBill);
                return res;
            }).catch(e => {
                throw e;
            })
        }
        return Promise.resolve({pdlStatus: this.pdlState!, hasUploadedElectricityBill: this.hasUploadedElectricityBill})
    }

    @Action({rawError: true})
    public async updatePdlValidationInvoice(invoice: FileInfo) {
        this.context.commit('_setPdlValidationInvoice', invoice)
    }
}

export default Contract;
