export enum EButtonSize {
  /**
   * MyVButton is large.
   */
  Large = "large",

  /**
   * MyVButton is medium.
   */
  Medium = "medium",

  /**
   * MyVButton is small.
   */
  Small = "small",
  /**
   * MyVButton is small.
   */
  Slim = "slim",
}

export enum EButtonType {
  /**
   * MyVButton is a simple button that will use on click event
   */
  Button = 'button',

  /**
   * MyVButton is a submit form button to trigger on submit event.
   */
  Submit = 'submit',

  /**
   * MyVButton is a link.
   */
  Link = 'link'
}

export type TButton = {
  /**
   * MyVButton must use primary theme or secondary.
   */
  primary?: boolean;

  /**
   * inline
   */
  inline?: boolean;

  /**
   * disabled
   */
  disabled?: boolean;

  /**
   * icon
   */
  icon?: string;

  /**
   * class
   */
  class?: string;

  /**
   * MyVButton's size
   */
  size?: EButtonSize;

  /**
   * MyVButton's type
   */
  type?: EButtonType;

  /**
   * MyVButton's border are round
   */
  round?: boolean;

  /**
   * Event trigger when user click on button.
   */
  onClick: (event: any) => void;

  /**
   * link target
   */
  to:string;
};
