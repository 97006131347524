import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Contact" }
const _hoisted_2 = { class: "popinForm__main" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "Contact__messageSection" }
const _hoisted_5 = { "data-testid": "contact-subtitle-write" }
const _hoisted_6 = { class: "inner" }
const _hoisted_7 = { class: "popinForm__footer" }
const _hoisted_8 = {
  class: "Contact__phoneSection",
  "data-testid": "contact-subtitle-phone-section"
}
const _hoisted_9 = { "data-testid": "contact-subtitle-call" }
const _hoisted_10 = { class: "Contact__phoneSection__content" }
const _hoisted_11 = { class: "Contact__phoneSection__content__title" }
const _hoisted_12 = ["href"]

import {Form, notification} from "ant-design-vue";
import {EValidationState} from '@/helpers/constants/interfaces';
import {PHONE_NUMBER_MAX_LENGTH, addSpacePhoneNumber, validatePhoneNumber} from "@/helpers/string/phone-number";
import {validateEmail} from "@/helpers/string/email";
import {AuthModule, SiteModule} from "@/store";

import ContactService from "@/services/Contact";
import {TContactSendBody, TContactSubjects} from "@/services/Contact/interfaces";
import {load} from 'recaptcha-v3'
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import MyVAntdselect from "@/components/ui/atoms/MyVAntdselect/MyVAntdselect.vue";
import {useRoute, useRouter} from "vue-router";
import ERoutes from "@/router/routes";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVContact',
  props: {
    contactText: {}
  },
  emits: ['success', 'error'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const user = computed(() => {
  return AuthModule.userOrNull
})

const {t} = useI18n()
const route = useRoute()
const router = useRouter()

const form = reactive<TContactSendBody>({
  name: user.value?.lastname ?? null,
  firstName: user.value?.firstname ?? null,
  email: user.value?.email ?? null,
  phoneNumber: user.value?.phones.length ? user.value.phones[0].phoneNumber : null,
  subject: null,
  message: null,
})

const subjects = ref<TContactSubjects[]>([]);
const isPhoneNumberValid = ref(EValidationState.EMPTY);
const isEmailValid = ref(EValidationState.EMPTY);
const isSubmitDisabled = ref(true)
const phoneError = ref<string | null>(null);
const emailError = ref<string | null>(null);

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const subjectsOptions = computed(() => {
  return subjects.value.map(subject => ({
        value: t(subject.subject),
        label: t(subject.subject),
      }
  ));
})

const isFormValid = computed(() => {
  return isSubmitDisabled.value && isEmailValid.value === EValidationState.SUCCESS && isPhoneNumberValid.value === EValidationState.SUCCESS && form.name && form.subject && form.message
})

const sendMessage = async () => {
  const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
    autoHideBadge: true
  })

  // Execute reCAPTCHA with action "login".
  const token = await recaptcha.execute('login')

  if (isFormValid.value && token) {
    let contactServiceCall;
    isSubmitDisabled.value = false

    if (!user.value) {
      contactServiceCall = ContactService.send({
        ...form,
        token
      })
    } else {
      contactServiceCall = ContactService.connectedSend(
          getCurrentSiteIdFromCookie(user.value),
          {
            ...form,
            token
          })
    }

    contactServiceCall
        .then(() => {
          form.subject = null;
          form.message = null;
          notification.success({
            message: t('notification.contact.success.title'),
            description: t('notification.contact.success.desc'),
            duration: 3
          })
          emit('success');
          if (route.query.origin)
            router.push(route.query.origin as ERoutes)
        })
        .catch(e => {
          emit('error', e);
        }).finally(() => isSubmitDisabled.value = true);
  }
}

onBeforeMount(() => {
  ContactService[user.value ? 'getConnectedSubjects' : 'getVisitorSubjects']()
      .then(subjectsItem => {
        subjects.value = subjectsItem;
      });
})

onMounted(() => {
  if (route.query.prefilledMessage) {
    form.message = route.query.prefilledMessage as string
    form.subject = t('contact.subject.loggedin.deviceissues')
  }
})

watch(() => form.phoneNumber, (newValue) => {
  if (newValue) {
    phoneError.value = !validatePhoneNumber(newValue, isSiteCountryFrance.value) ? t('profile.contact.phone_number_security') : null
    form.phoneNumber = addSpacePhoneNumber(newValue, isSiteCountryFrance.value);
  }

  isPhoneNumberValid.value = newValue ? (validatePhoneNumber(newValue, isSiteCountryFrance.value) ? (EValidationState.SUCCESS) : (EValidationState.ERROR)) : EValidationState.EMPTY;
}, {immediate: true})

watch(() => form.email, (newValue) => {
      if (newValue) {
        emailError.value = !validateEmail(newValue) ? t('profile.contact.email_security') : null
      }
      isEmailValid.value = newValue ? (validateEmail(newValue) ? (EValidationState.SUCCESS) : (EValidationState.ERROR)) : EValidationState.EMPTY;
    }, {immediate: true}
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Form), {
      onSubmit: _withModifiers(sendMessage, ["prevent"]),
      class: _normalizeClass(['Contact__messageSection__form', 'popinForm'])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", {
            class: "Contact__info",
            innerHTML: _ctx.contactText,
            "data-testid": "contact-subtitle"
          }, null, 8, _hoisted_3),
          _createVNode(MyVDisplayGroupStore, {
            currentRight: _unref(EDisplayGroupRightName).HELP_MAIL,
            hideIfDisabled: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('profile.contact.by_message')), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(MyVAntdinput, {
                    "input-required": "",
                    id: "name",
                    inputType: _unref(EInputType).Text,
                    "data-test-id": "contact-name",
                    label: _ctx.$t('profile.contact.label.name'),
                    value: form.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.name) = $event))
                  }, null, 8, ["inputType", "label", "value"]),
                  _createVNode(MyVAntdinput, {
                    id: "firstName",
                    "data-test-id": "contact-first-name",
                    inputType: _unref(EInputType).Text,
                    label: _ctx.$t('profile.contact.label.first_name'),
                    value: form.firstName,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.firstName) = $event))
                  }, null, 8, ["inputType", "label", "value"]),
                  _createVNode(MyVAntdinput, {
                    help: emailError.value,
                    "data-test-id": "contact-email",
                    "input-required": "",
                    error: emailError.value,
                    id: "Email",
                    inputType: _unref(EInputType).Text,
                    label: _ctx.$t('profile.contact.label.email'),
                    value: form.email,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.email) = $event))
                  }, null, 8, ["help", "error", "inputType", "label", "value"]),
                  _createVNode(MyVAntdinput, {
                    help: phoneError.value,
                    "data-test-id": "contact-phone",
                    "input-required": "",
                    error: phoneError.value,
                    id: "PhoneNumber",
                    maxlength: _unref(PHONE_NUMBER_MAX_LENGTH),
                    inputType: _unref(EInputType).Text,
                    label: _ctx.$t('profile.contact.label.phone_number'),
                    value: form.phoneNumber,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.phoneNumber) = $event))
                  }, null, 8, ["help", "error", "maxlength", "inputType", "label", "value"]),
                  _createVNode(MyVAntdselect, {
                    required: "",
                    id: "subject",
                    options: subjectsOptions.value,
                    "data-test-id": "contact-subject",
                    label: _ctx.$t('profile.contact.label.subject'),
                    value: form.subject,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((form.subject) = $event))
                  }, null, 8, ["options", "label", "value"]),
                  _createVNode(MyVAntdinput, {
                    "input-required": "",
                    id: "Message",
                    inputType: _unref(EInputType).Textarea,
                    "data-test-id": "contact-message",
                    label: _ctx.$t('profile.contact.label.message'),
                    value: form.message,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((form.message) = $event))
                  }, null, 8, ["inputType", "label", "value"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["currentRight"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(MyVButton, {
            "data-testid": "contact-submit-button",
            disabled: !isFormValid.value,
            type: "submit",
            class: "Contact__messageSection__form__save"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.contact.send')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    }),
    _createVNode(MyVDisplayGroupStore, {
      currentRight: _unref(EDisplayGroupRightName).HELP_TELEPHONE,
      hideIfDisabled: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('profile.contact.by_phone')), 1),
          _createElementVNode("div", _hoisted_10, [
            _cache[6] || (_cache[6] = _createElementVNode("svg", {
              class: "icon icon-big-colored-sun",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-big-colored-sun" })
            ], -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('profile.contact.member_service')), 1),
            _createElementVNode("a", {
              "data-testid": "contact-subtitle-call-number",
              class: "Contact__phoneSection__content__phoneNumber",
              href: `tel:${_ctx.$t('profile.contact.member_service_phone_number')}`
            }, _toDisplayString(_ctx.$t('profile.contact.member_service_phone_number')), 9, _hoisted_12),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('profile.contact.member_service.hours')), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["currentRight"])
  ]))
}
}

})