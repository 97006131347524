import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {EModules} from "../modules";

@Module({name: EModules.REQUEST_LOADER})
class RequestLoader extends VuexModule {

    private _counter = 0;

    get loading() {
        return this._counter > 0;
    }

    /**
     * Mutations
     */
    @Mutation
    public _incrementCounterRequest(val: number): void {
        this._counter = Math.max(this._counter + val, 0);
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public addRequest(): void {
        this.context.commit('_incrementCounterRequest', 1);
    }

    @Action({rawError: true})
    public removeRequest(): void {
        this.context.commit('_incrementCounterRequest', -1);
    }
}

export default RequestLoader;
