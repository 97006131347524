import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "ChooseContractStep" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mainContent" }
const _hoisted_4 = {
  key: 0,
  class: "contractContainer"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "noContract"
}

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {ContractModule, SiteModule} from "@/store";
import ContractService from "@/services/Contract";
import {TCompanyContracts} from "@/services/Contract/interfaces";
import {computed, onBeforeMount, ref} from "vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue";
import { EInputType } from "@/components/ui/atoms/MyVAntdinput/interfaces";

const OTHER_CONTRACT_NAME = "choose_contract.other_contract"


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseContractStepView',
  emits: ["next"],
  setup(__props, { emit: __emit }) {

const contractList = ref<TCompanyContracts>([])
const contractString = ref<string|undefined>(undefined)

const emit = __emit;

const isSiteCountryFrance = computed<boolean>(() => SiteModule.isSiteCountryFrance)

const contractKeys = computed(() => {
  return Object.keys(contractList.value).sort();
})
const save = (contractName: string) => {
  const providerContractList: TCompanyContracts = (contractList.value[contractName as any] as unknown as TCompanyContracts);
  const isOtherContract = contractName === OTHER_CONTRACT_NAME;
  emit('next', contractName, isOtherContract ? [] : providerContractList?.sort((a, b) => a.subscribedPower - b.subscribedPower));
}

const groupBy = (xs: TCompanyContracts, key: string) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, []);
}

onBeforeMount(() => {
  const companyId = ContractModule.fakeContract!.companyId;
  if (isSiteCountryFrance.value) {
    ContractService.getCompanyContracts(companyId!).then(data => {
      contractList.value = groupBy(data, 'name').sort();
    })
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isSiteCountryFrance.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('contract.choose_contract_step.choose_provider')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (isSiteCountryFrance.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (contractKeys.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contractKeys.value, (contract, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "contractRow",
                      onClick: ($event: any) => (save(contract))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(contract)), 1),
                      _createVNode(MyVButton, {
                        class: "chooseContractButton",
                        primary: false,
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('contract.choose_contract_step.')), 1)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_5))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('contract.choose_contract_step.no_contract_found')), 1))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(MyVAntdinput, {
              "input-type": _unref(EInputType).Text,
              value: contractString.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((contractString).value = $event)),
              "allow-clear": "",
              label: _ctx.$t('contract.choose_contract_step.choose_provider')
            }, null, 8, ["input-type", "value", "label"]),
            _createVNode(MyVButton, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next', contractString.value ?? '', []))),
              disabled: !contractString.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.next')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 64))
    ])
  ]))
}
}

})