export enum EDropDownMenuDirection {
    BottomLeft = "bottomLeft",
    BottomCenter = "bottomCenter",
    BottomRight = "bottomRight",
    TopLeft = "topLeft",
    TopCenter = "topCenter",
    TopRight = "topRight",
}
export type TDropDownMenu = {
    icon?: string;
    direction?: EDropDownMenuDirection;
}