import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import meteo from "@/assets/images/meteo.jpg";
import economie from "@/assets/images/economie.jpg";
import co2 from "@/assets/images/co2.jpg";
import {useI18n} from "vue-i18n";
import MyVConsoCard from "@/components/domains/Conso/MyVConsoCard/MyVConsoCard.vue";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {ConsumptionCardEntry, ConsumptionEntry} from "@/components/domains/Conso/MyVConsoCards/interfaces";
import ERoutes from "@/router/routes";
import {
  aggregatedMonthFormat, aggregatedYearFormat,
  EAggregationAlias,
  TConsumptionFullData
} from "@/services/Conso/interfaces";
import {computed} from "vue";
import {useDate} from "@/helpers/dates/date-utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsoCards',
  props: {
    data: {},
    currentDate: {},
    type: {}
  },
  setup(__props: any) {

const {t} = useI18n();

const props = __props;

const consoSavings = computed(() => props.data[props.currentDate]?.savings)

const cards = computed(() => [
  props.type === ERoutes.CONSO_YEAR && createConsumptionEntry(getYearlyConsumption()),
  props.type === ERoutes.CONSO_MONTH && !useDate(props.currentDate).isSame(new Date(), 'month') && createConsumptionEntry(getMonthlyConsumption.value),
  createSavingsEntry(consoSavings.value?.wh, EEnergyUnit.kWh, t('conso.card.erasures'), economie, t('conso.card_title.savings')),
  createSavingsEntry(consoSavings.value?.co2InG, 'kg', t('conso.card.co2'), co2, t('conso.card_title.co2')),
].filter(Boolean));

const getConsumption = (format: string, subtractYears = 0) : ConsumptionCardEntry => {
  const dateKey = useDate(props.currentDate).substract(subtractYears, 'years').format(format);

  const totalEntries = props.data[dateKey]?.dataPoints?.length ?? 0;
  const entriesWithConsumption = props.data[dateKey]?.dataPoints?.filter(entry => entry.totalConsumptionInWh > 0).length ?? 0;

  //At least 10% of the datapoints have consumption value. If not, the card won't be displayed to avoid difference discrepancy
  const hasSufficientEntries = entriesWithConsumption / totalEntries >= 0.9

  return {conso: props.data[dateKey]?.summary?.totalConsumptionInWh ?? 0, hasSufficientEntries};
};

const calculateConsumptionChange = (currentConso?: number, previousConso?: number) =>
    currentConso && previousConso ? ((currentConso - previousConso) / previousConso) * 100 : undefined;

const createConsumptionEntry = (consoCalc?: number): ConsumptionEntry => ({
  unit: "%",
  value: consoCalc ? (consoCalc > 0 ? `+${Math.round(consoCalc)}` : Math.round(consoCalc)) : undefined,
  text: !consoCalc ? t('conso.no_history_consumption') : `${t('compared_to_date')} ${useDate(useDate(props.currentDate).substract(1, 'year')).format(props.type === EAggregationAlias.Month ? 'MMMM YYYY' : 'YYYY')}`,
  img: meteo,
  title: t('conso.card_title.consumption'),
});

const getYearlyConsumption = () => {
  const currentMonth = useDate().month();
  const currentYear = useDate().year();
  let previousRawConso = props.data[useDate(props.currentDate).substract(1, 'year').format(aggregatedYearFormat)]?.dataPoints
  const currentConso = getConsumption(aggregatedYearFormat).conso

  if (useDate(props.currentDate).year() === currentYear) {
    previousRawConso = previousRawConso?.slice(0, currentMonth + 1)
  }

  const previousConso = previousRawConso?.reduce((sum, entry) => sum + entry.totalConsumptionInWh, 0);

  return calculateConsumptionChange(currentConso, previousConso);
};

const createSavingsEntry = (
    savings: number | undefined,
    unit: string,
    textKey: string,
    img: string,
    title: string
): ConsumptionEntry | undefined =>
    savings && Math.round(savings / 1000) > 0
        ? {
          unit,
          value: -Math.round(savings / 1000),
          text: savings ? t(textKey) : t('conso.no_history_consumption'),
          img,
          title,
        }
        : undefined;

const getMonthlyConsumption = computed(() => {
  const currentConso = getConsumption(aggregatedMonthFormat);
  const previousConso = getConsumption(aggregatedMonthFormat, 1);

  if (currentConso.hasSufficientEntries && previousConso.hasSufficientEntries)
  return calculateConsumptionChange(currentConso.conso, previousConso.conso);

  return undefined
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards.value as ConsumptionEntry[], (card, index) => {
    return (_openBlock(), _createBlock(MyVConsoCard, {
      key: index,
      value: card.value,
      unit: card.unit,
      text: card.text,
      title: card.title,
      img: card.img
    }, null, 8, ["value", "unit", "text", "title", "img"]))
  }), 128))
}
}

})