import API from "@/helpers/api";
import {
    TGeolocationPhone,
    TGeolocationPhoneConfiguration,
    TGeolocationService, TGeolocationStatus
} from "@/services/Geolocation/interfaces";

const GeolocationService: TGeolocationService = {
    getGeolocationStatus: (siteId: number) =>
        API.get<TGeolocationStatus>(`/api/site/${siteId}/geoloc`)
            .then(({data}) => data),
    putGeolocationStatus: (siteId, body) =>
        API.put<TGeolocationStatus>(`/api/site/${siteId}/geoloc`, body)
            .then(({data}) => data)
    ,
    getPhoneConfigurations: (deviceId: number) =>
        API.get<TGeolocationPhoneConfiguration[]>(`/api/geofence/configurations/${deviceId}`)
            .then(({data}) => data),
    getPhones: (siteId) =>
        API.get<TGeolocationPhone[]>(`/api/site/${siteId}/geoloc/phone`, {skipLoading: true})
            .then(({data}) => data
            ),
    putGeolocationPhone: (siteId, body) =>
        API.put<TGeolocationPhone>(`/api/site/${siteId}/geoloc/phone/${body.deviceId}`, body)
            .then(({data}) => data
            ),
    postGeolocationPhone: (siteId, body) =>
        API.post<TGeolocationPhone>(`/api/site/${siteId}/geoloc/phone`, body)
            .then(({data}) => data
            ),
    deleteGeolocationPhone: (siteId, deviceId) =>
        API.delete<TGeolocationPhone>(`/api/site/${siteId}/geoloc/phone/${deviceId}`)
};

export default GeolocationService;
