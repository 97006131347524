import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "safe-area"
}

import {useRoute, useRouter} from "vue-router";
import {SafeArea} from "capacitor-plugin-safe-area";
import Loader from '@/components/ui/atoms/MyVLoader/MyVLoader.vue';
import {TUser} from "./services/Auth/interfaces";
import {
  AuthModule,
  ConsentModule,
  ContractModule,
  DiagnosticModule,
  IdApplicationModule,
  RequestLoaderModule,
  SiteModule,
  SwitchConsoModule,
  ThemeModule
} from "@/store";

import ERoutes from "./router/routes";
import Icomoon from '@/assets/icomoon/symbol-defs.svg';

import 'round-slider/dist/roundslider.min.css';
import '@/assets/icomoon/style.css';
import '@/scss/style.scss';
import DisplayGroupObject from "@/dummies/DisplayGroupObject";
import * as momentTz from "moment-timezone";
import {DISPLAY_GROUP_PRO, TThemeInfo} from "@/services/Theme/interfaces";
import {applyTheme} from "@/helpers/domains/ui/theme";
import {ELocale} from "@/store/modules/Intl";
import Cookie from "@/helpers/cookie";
import MyVIntlService from "@/components/ui/atoms/MyVIntlService/MyVIntlService.vue";
import {computed, onMounted, ref, watch} from "vue";
import MyVDefaultContractPopin from "@/components/domains/Contract/MyVDefaultContractPopin/MyVDefaultContractPopin.vue";
import {useCookies} from "@vueuse/integrations/useCookies";
import MyVRotatePhone from "@/components/ui/atoms/MyVRotatePhone/MyVRotatePhone.vue";
import {ECookieNames} from "@/helpers/cookie/interfaces";
import {useCookieNames} from "@/composables/useCookieNames";
import MyVDefineContractPopin from "@/components/domains/Contract/MyVDefineContractPopin/MyVDefineContractPopin.vue";
import dayjs from "dayjs";
import MixpanelService from "@/services/Mixpanel";

const MIXPANEL_VENDOR_NAME = "c:mixpanel"

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter()
const route = useRoute()
const {defaultContratCookie} = useCookieNames()

const loading = ref(true);
const notAuthLoading = ref(true);
const showDefaultContractPopin = ref(false);
const showDefineContractPopin = ref(false);
const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const translationsUrl = computed((): string => {
  return process.env.VUE_APP_INTL_URL!;
})

const user = computed((): TUser | null => {
  return AuthModule.userOrNull;
})

const selectRouter = computed((): string => {
  return user.value === null ? "default" : "logged";
})

const requestLoading = computed(() => {
  return RequestLoaderModule.loading;
})

const themeResources = computed(() => {
  return ThemeModule.theme ?? DisplayGroupObject["resources"];
})

const appKey = computed(() => {
  return IdApplicationModule.key;
})

const isRouteNameContract = computed(() => {
  return route.name === 'Contract'
})

const displayDefineContractPopin = computed(() => {
  return showDefineContractPopin.value && !isRouteNameContract.value && ContractModule.contractsList.length === 0
})

const displayDefaultContractPopin = computed(() => {
  return !hasDefaultContractCookie.value && showDefaultContractPopin.value && ContractModule.isCurrentContractDefault && !isRouteNameContract.value
})

const hasDefaultContractCookie = computed(() => {
  const cookies = useCookies([defaultContratCookie.value])

  return cookies.get(defaultContratCookie.value)
})

const displayGroupsToListen = computed(() => {
  // add future display group to listen if you need new resources for this site
  return [
    {
      displayGroupName: DISPLAY_GROUP_PRO,
      localeCookie: ECookieNames.PRO_LOCALE,
    }
  ];
})

const hasDisplayGroupFromHost = computed(() => {
  // add every future locale that are based on host (ex: EKW)
  const displayGroupFromHostLocales = [
    ECookieNames.EKWATEUR_LOCALE,
  ]
  let isFromHost = false;
  displayGroupFromHostLocales.forEach(locale => {
    if (Cookie.get(locale) && Cookie.get(locale) === 'true') {
      isFromHost = true;
    }
  });

  return isFromHost;
})

const setupDidomiOnReady = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push((Didomi: any) => {
      try {
        const isTrackingAllowed = Didomi.getUserStatus().vendors.consent.enabled.some((consent: any) => consent === MIXPANEL_VENDOR_NAME);
        AuthModule._setIsTrackingAllowed(isTrackingAllowed)
        resolve();
      } catch (error) {
        console.error('Error in Didomi onReady logic');
        reject(error);
      }
    });
  });
};

onMounted(async () => {
  SafeArea.getSafeAreaInsets()
      .then(safeArea => {
        document.documentElement.style.setProperty("--safe-area-top", `${safeArea.insets.top}px`);
        document.documentElement.style.setProperty("--safe-area-bottom", `${safeArea.insets.bottom}px`);
        document.documentElement.style.setProperty("--safe-area-left", `${safeArea.insets.left}px`);
        document.documentElement.style.setProperty("--safe-area-right", `${safeArea.insets.right}px`);
      });
})

watch(themeResources, (newValue) => {
  applyTheme((newValue as TThemeInfo).staticResources);
})

watch(route, (newValue) => {
  showDefineContractPopin.value = false;

  const token = newValue.query.token as string;
  let promise: Promise<TUser | null> | null = null;

  if (token && [ERoutes.HOME, ERoutes.LOGIN].includes(route.path as ERoutes)) {
    promise = AuthModule.tryConnectUserFromToken(token);
  } else if (AuthModule.userOrNull === null) {
    promise = AuthModule.tryConnectUserFromCookie();
  }

  if (promise !== null) {
    promise
        .then<unknown>(user => {
          if (user) {
            if (!DiagnosticModule.diagnostics || !DiagnosticModule.diagnostics.length) {
              DiagnosticModule.getDiagnostics({siteId: user.defaultSite.id, forceRequest: true},)
            }
            if (isSiteCountryFrance.value) {
              return ConsentModule.getConsentEnedis(user).then(() => {
                if (user.firstConnection && user.migratedUser) return router.replace(ERoutes.FIRST_CONNECTION);
              });
            }
          } else if (newValue.meta.requiresAuth) {
            return router.replace(ERoutes.LOGIN);
          }
        })
        .finally(() => {
          loading.value = false;
        });
  } else {
    loading.value = false;
  }
})

watch(user, (newValue) => {
  if (newValue === null) {
    notAuthLoading.value = false;
  } else if (newValue) {
    setupDidomiOnReady()
        .then(() => {
          const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;

          if (mixpanelToken) {
            MixpanelService.init(mixpanelToken)
          }

          if (!AuthModule.isTrackingAllowed || process.env.NODE_ENV !== "production") {
            MixpanelService.optOut()
          }

          if (newValue) {
            MixpanelService.identify(newValue)
          }
        })

    const displayGroupListened = displayGroupsToListen.value.find(group => group.displayGroupName === newValue.displayGroup.name);

    if (!hasDisplayGroupFromHost.value) {
      Cookie.resetLocaleCookies();
      Cookie.set(ECookieNames.LOCALE, (((navigator.language || (navigator as any).userLanguage) as ELocale | '' | undefined) || ELocale.fr).split('-')[0] as ELocale);

      if (displayGroupListened) {
        Cookie.set(displayGroupListened.localeCookie, 'true');
      }
    }

    ThemeModule._setTheme({
      displayGroupName: newValue.displayGroup.name,
      staticResources: newValue.displayGroup.resources
    })

    dayjs.tz.setDefault(newValue.defaultSite.timezone);
    momentTz.tz.setDefault(newValue.defaultSite.timezone);
    if (newValue.firstConnection && newValue.migratedUser) {
      return router.push(ERoutes.FIRST_CONNECTION);
    }
  }
})

watch(loading, (newValue) => {
  if (!newValue && AuthModule.userOrNull === null) {
    notAuthLoading.value = false;
  }
})

watch(() => SwitchConsoModule.switchType, (newValue, oldValue) => {
  if (newValue && !oldValue && SiteModule.isSiteCountryUK) {
    showDefineContractPopin.value = true;
  }

  if (!newValue && oldValue)
    showDefaultContractPopin.value = true;
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Icomoon)),
    _createVNode(MyVIntlService, { url: translationsUrl.value }, {
      default: _withCtx(() => [
        (!loading.value && (user.value !== null || !notAuthLoading.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (requestLoading.value)
                ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                : _createCommentVNode("", true),
              (!loading.value)
                ? (_openBlock(), _createBlock(_component_router_view, {
                    name: selectRouter.value,
                    key: appKey.value
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createBlock(Loader, { key: 1 })),
        _createVNode(MyVRotatePhone),
        (displayDefaultContractPopin.value)
          ? (_openBlock(), _createBlock(MyVDefaultContractPopin, {
              key: 2,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (showDefaultContractPopin.value = false))
            }))
          : _createCommentVNode("", true),
        (displayDefineContractPopin.value)
          ? (_openBlock(), _createBlock(MyVDefineContractPopin, {
              key: 3,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (showDefineContractPopin.value = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["url"])
  ], 64))
}
}

})