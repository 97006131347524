import {ContractModule, SiteModule, SwitchConsoModule} from "@/store";
import {AxiosError} from "axios";

export const updateSwitchConso = async (val: boolean) => {
    if (!val) {
        if (!ContractModule.contractsList.length) {
            return await ContractModule.getContractsList()
                .then(() => {
                    SwitchConsoModule._setSwitchType(val)
                    return true
                })
                .catch((e: AxiosError) => {
                    if (SiteModule.isSiteCountryUK && e.response?.status === 404) {
                        SwitchConsoModule._setSwitchType(false)
                        return false
                    }
                    SwitchConsoModule._setSwitchType(true)
                    return false
                })
                .finally(() => {
                    SwitchConsoModule._setSwitchType(true)
                })
        }
        SwitchConsoModule._setSwitchType(val)
    }
    SwitchConsoModule._setSwitchType(val)
    return true
}