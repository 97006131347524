import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["xlink:href"]

import {TRepartitionDetailCard} from "./interfaces";
import ERoutes from "@/router/routes";
import MyVCirclePercent from "@/components/ui/molecules/MyVCirclePercent/MyVCirclePercent.vue";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import MyVCirclePercentCompare from "@/components/ui/atoms/MyVCirclePercentCompare/MyVCirclePercentCompare.vue";
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useDate} from "@/helpers/dates/date-utils";
import useConsoRepartition from "@/composables/repartition";

interface Props {
  value: TRepartitionDetailCard['value'];
  category?: TRepartitionDetailCard['category'];
  percentage: TRepartitionDetailCard['percentage'];
  subcategories?: TRepartitionDetailCard['subcategories'];
  compareData?: TRepartitionDetailCard['subcategories'];
  isComparing?: boolean;
  unit: TRepartitionDetailCard['unit'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVRepartitionDetailCard',
  props: {
    value: { default: undefined },
    category: { default: undefined },
    percentage: { default: undefined },
    subcategories: { default: () => [] },
    compareData: { default: undefined },
    isComparing: { type: Boolean, default: false },
    unit: { default: EEnergyUnit.kWh }
  },
  setup(__props: any) {

const props = __props

const route = useRoute()
const {consoRepartitionValue} = useConsoRepartition()

const subcategoriesTotal = computed(() => {
  const sum = props.subcategories?.reduce((acc, obj) => {
    return acc + obj.totalConsumptionInWh!
  }, 0)

  return fromWhToKwh(sum)
})

const isActive = computed(() => {
  return route.params.category === props.category;
})

const routeLink = computed(() => {
  return {
    path: `${ERoutes.CONSO}/repartition-detail/${route.params.date}/${props.category}`,
    query: {
      type: route.query.type
    }
  };
})

const hasSubcategories = computed(() => {
  return props.subcategories.length > 1 || (props.subcategories.some(subcategorie => subcategorie.name === 'conso.other.leftover.full') && isCurrentMonth.value)
})

const compareDataCategory = computed(() => {
  if (!props.compareData) return false

  return props.compareData.filter(el => el.name === props.category)[0]
})

const isCurrentMonth = computed(() => {
  const currentDate = useDate();
  const paramMonth = useDate(route.params.date as string).month();
  const paramYear = useDate(route.params.date as string).year();

  return paramMonth === currentDate.month() && paramYear === currentDate.year()
})

const compareDataCategoryTotal = computed(() => {
  if (!compareDataCategory.value) return null

  const sum = compareDataCategory.value.subcategories?.reduce((acc, obj) => {
    return acc + obj.totalConsumptionInWh!
  }, 0)

  return fromWhToKwh(sum || 0)
})

const icon = computed(() => {
  switch (props.category) {
    case 'conso.category.waterheating':
      return 'colored-water';
    case 'conso.category.heater':
      return 'colored-heater';
    case 'cat3':
      return 'colored-home-other';
    default:
      return 'colored-home-other';
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(hasSubcategories.value ? 'router-link' : 'div'), {
    to: routeLink.value,
    class: _normalizeClass([_ctx.$style.RepartitionDetailCard, isActive.value ? _ctx.$style.RepartitionDetailCardActive : ''])
  }, {
    default: _withCtx(() => [
      (_ctx.category)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([_ctx.$style.RepartitionDetailCard__icon])
          }, [
            (_openBlock(), _createElementBlock("svg", {
              class: _normalizeClass(['icon icon-'+icon.value]),
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", {
                "xlink:href": ['#icon-'+icon.value]
              }, null, 8, _hoisted_1)
            ], 2))
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.RepartitionDetailCard__main])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.$style.RepartitionDetailCard__category])
        }, _toDisplayString(_ctx.category ? _ctx.$t(_ctx.category) : null), 3),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.RepartitionDetailCard__value])
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref(consoRepartitionValue)(props.unit, props.value)), 1)
        ], 2),
        (hasSubcategories.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.$style.RepartitionDetailCard__link])
            }, _toDisplayString(_ctx.$t('repartion.show_details')), 3))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.RepartitionDetailCard__percentage])
      }, [
        (!_ctx.isComparing)
          ? (_openBlock(), _createBlock(MyVCirclePercent, {
              key: 0,
              percent: _ctx.percentage
            }, null, 8, ["percent"]))
          : (_openBlock(), _createBlock(MyVCirclePercentCompare, {
              key: 1,
              "category-total": Math.round(subcategoriesTotal.value),
              "compare-total": Math.round(compareDataCategoryTotal.value!)
            }, null, 8, ["category-total", "compare-total"]))
      ], 2)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}
}

})