import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loader-container" }
const _hoisted_2 = { class: "center" }

import {Spin} from 'ant-design-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Spin), { size: "large" })
    ])
  ]))
}
}

})