export enum ETemperatureUnit {
  Celsius = "°C",
  Fahrenheit = "°F"
}

export type TTemperature = {
  /**
   *  MyVTemperature to display
   */
  value: number;

  /**
   * Unit of the given temperature
   */
  unit?: ETemperatureUnit;

  /**
   * Optional additionnal CSS classes.
   */
  className?: string;
};
