import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  class: "popinForm__greyBlock",
  "data-testid": "forgot-password-view-subtitle"
}
const _hoisted_5 = { class: "popinForm__footer" }

import {AuthModule} from "@/store";

import ERoutes from "@/router/routes";

import MyVPageWithReturn from '@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue';
import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';
import {computed, ref} from "vue";
import MyVForgetPasswordForm from "@/components/domains/Profile/MyVForgetPasswordForm/MyVForgetPasswordForm.vue";
import {TForgetPasswordFormDatas} from "@/components/domains/Profile/MyVForgetPasswordForm/interfaces";

const FORM_FORGET_PASSWORD = 'form_forget_password'


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPasswordView',
  setup(__props) {

const emailValue = ref<string | null>(null);
const loading = ref(false)
const emailSent = computed(() => {
  return emailValue.value !== null;
})

const sendEmail = (email ?: TForgetPasswordFormDatas['email']) => {
  const finalEmail = email ?? emailValue.value;

  if (!loading.value) {
    loading.value = true;
    AuthModule.requestNewPassword({
      email: finalEmail!
    })
        .then(() => {
          emailValue.value = finalEmail!;
        })
        .finally(() => {
          loading.value = false;
        });
  }
}

const submit = ({email}: TForgetPasswordFormDatas) => {
  sendEmail(email);
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('forgotpassword.title'),
    type: "popin",
    returnAction: _unref(ERoutes).LOGIN,
    "data-testid": "forgot-password-view"
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (emailSent.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.icon)
              }, [
                _createVNode(MyVIcon, { name: "success" })
              ], 2),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.message_success)
              }, _toDisplayString(_ctx.$t('forgotpassword.email_sent')), 3)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('forgotpassword.subtitle')), 1),
              _createElementVNode("div", {
                class: _normalizeClass('inner ' + _ctx.$style.form)
              }, [
                _createVNode(MyVForgetPasswordForm, {
                  formId: FORM_FORGET_PASSWORD,
                  onSubmit: submit
                })
              ], 2),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(MyVButton, {
                  "data-testid": "forgot-password-view-submit-button",
                  form: FORM_FORGET_PASSWORD,
                  type: _unref(EButtonType).Submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button.forget_password_form')), 1)
                  ]),
                  _: 1
                }, 8, ["type"]),
                _createVNode(_component_router_link, {
                  "data-testid": "forgot-password-view-cancel-button",
                  to: _unref(ERoutes).LOGIN,
                  class: "popinForm__footer-back"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]))
      ])
    ]),
    _: 2
  }, [
    (!emailSent.value)
      ? {
          name: "footer",
          fn: _withCtx(() => []),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "returnAction"]))
}
}

})