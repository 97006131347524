import API from "@/helpers/api";

import {TThemeInfo, TThemeService} from "./interfaces";

const ThemeService: TThemeService = {
  getTheme: host => (
    API.get<TThemeInfo>(`/static-resources${host ? `?host=${host}` : ''}`)
    .then(({ data }) => data)
  ),
};

export default ThemeService;
