import {TDisplayGroupResources} from "@/services/DisplayGroup/interfaces";
import {useFavicon} from "@vueuse/core";

export const applyTheme = (theme: TDisplayGroupResources) => {
    document.documentElement.style.setProperty("--favicon", theme.favicon);
    document.documentElement.style.setProperty("--font", theme.font);
    document.documentElement.style.setProperty("--fontcolor", theme.fontcolor);
    document.documentElement.style.setProperty("--headercolor", theme.headercolor);
    document.documentElement.style.setProperty("--logo", theme.logo);
    document.documentElement.style.setProperty("--primarycolor", theme.primarycolor);
    document.documentElement.style.setProperty("--secondarycolor", theme.secondarycolor);

    const icon = useFavicon();
    icon.value = theme.favicon;
}