import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVContact from "@/components/domains/Profile/MyVContact/MyVContact.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('profile.contact_us.title'),
    type: "popin",
    returnAction: _unref(ERoutes).LOGIN,
    "data-testid": "contact-view"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVContact, {
        "contact-text": _ctx.$t('login.contact.info')
      }, null, 8, ["contact-text"])
    ]),
    _: 1
  }, 8, ["title", "returnAction"]))
}
}

})