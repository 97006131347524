import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {TCircleGraphic, TCircleGraphicLegends} from "./interfaces";
import {roundNumber2} from "@/helpers/number/formatNumber";
import {onBeforeMount, onUpdated, ref, watch} from "vue";

interface Props {
  items: TCircleGraphic["items"];
  texts?: TCircleGraphic["texts"];
  tooltip?: TCircleGraphic["tooltip"];
  plugins?:  TCircleGraphic["plugins"]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCircleGraphic',
  props: {
    items: { default: undefined },
    texts: { default: () => [] },
    tooltip: { default: undefined },
    plugins: {}
  },
  setup(__props: any) {

const props = __props


const chartDatas = ref<any>({});
const update = ref(false);
const legends = ref<TCircleGraphicLegends>([]);
const chartRef = ref(null)

const createChart = () => {
  const datas: number[] = [];
  const backgroundsColor: string[] = [];
  const labels: string[] = [];
  legends.value = []

  props.items.forEach(({label, value, color}) => {
    labels.push(label);
    datas.push(value);
    backgroundsColor.push(color);

    const legend = legends.value.find(({label: _label}) => _label === label);

    if (legend) {
      legend.color = color;
    } else {
      legends.value.push({label, color});
    }
  });

  chartDatas.value = {
    type: "roundedDoughnut",
    options: {
      responsive: true,
      aspectRatio: Array.isArray(props.tooltip) ? 1.6 : props.tooltip ? 2 : 1,
      plugins: {
        ...props.plugins,
        legend: {
          display: false
        }
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20
        },
      },
      tooltip: props.tooltip,
      texts: props.texts
    },
    data: {
      datasets: [
        {
          data: datas.map(roundNumber2),
          backgroundColor: backgroundsColor,
          hoverBackgroundColor: backgroundsColor,
          datalabels: {
            display: false,
          },
          borderWidth: 0,
          circumference: 360,
          rotation: 0,
        },
      ],
      labels: labels,
    },
  };
}


onUpdated(() => {
  if (update.value) {
    createChart();
    (chartRef.value as any).chartJSState.props.data =
        chartDatas.value.data;
    (chartRef.value as any).chartJSState.props.options =
        chartDatas.value.options;
    (chartRef.value as any).update();
    update.value = false;
  }
})

onBeforeMount(() => {
  createChart()
})

watch([() => props.items, () => props.texts, () => props.tooltip], () => {
  update.value = true
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Vue3ChartJs), _mergeProps({
      ref_key: "chartRef",
      ref: chartRef
    }, chartDatas.value), null, 16),
    (legends.value)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(_ctx.$style.legend)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(legends.value, (legend) => {
            return (_openBlock(), _createElementBlock("li", {
              key: legend.label
            }, [
              _createElementVNode("span", {
                style: _normalizeStyle(`background-color: ${legend.color}`)
              }, null, 4),
              _createTextVNode(" " + _toDisplayString(legend.label), 1)
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})