import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {computed, onBeforeMount, reactive, ref} from "vue";
import {AppliancesModule, AuthModule, ProgramModule} from "@/store";

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {TMultiStepsControl} from "@/components/ui/molecules/MyVMultiSteps/interfaces";
import MyVProgramCreateHabitHours
  from "@/components/domains/Heating/programs/MyVProgramCreateHabitHours/MyVProgramCreateHabitHours.vue";

import {TProgramFromHabit, TProgramWithPlanning} from "@/services/Program/interfaces";
import {EBehaviourPeriod} from "@/components/domains/Device/MyVProgramCreateZoneAppliances/interfaces";

import {TProgramCreateFormProps, TProgramCreateFromHabit} from './interfaces';

import ERoutes from "@/router/routes";
import {EVoltalisVersion} from "@/services/Appliances/interfaces";
import ProgramCreateSuccess from "@/views/domains/Heating/Program/ProgramCreate/ProgramCreateSuccessView.vue";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import MyVProgramCreateZoneAppliances
  from "@/components/domains/Device/MyVProgramCreateZoneAppliances/MyVProgramCreateZoneAppliances.vue";
import MyVProgramCreateWeekPresence
  from "@/components/domains/Heating/programs/MyVProgramCreateWeekPresence/MyVProgramCreateWeekPresence.vue";
import MyVMultiSteps from "@/components/ui/molecules/MyVMultiSteps/MyVMultiSteps.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramFromHabitView',
  props: {
    cancelCreate: { type: Function },
    baseForm: {},
    generateEditLink: { type: Function }
  },
  setup(__props: any) {

const props = __props


const form = reactive<TProgramCreateFromHabit>({})
const stepper = ref(null)
const newProgram = ref<TProgramWithPlanning | null>(null);
const linkToprogram = ref("");
const isFinish = ref(false);

const isSubmit = computed(() => {
  return newProgram.value !== null;
})

const stepControl = computed(() => {
  return (stepper.value as any).stepControl
})

const appliances = computed(() => {
  return AppliancesModule.appliances;
})

const isVxVersion = computed(() => {
  return AuthModule.user.defaultSite.voltalisVersion === EVoltalisVersion.Vx
})

const back = () => {
  stepControl.value?.prev ? stepControl.value.prev() : props.cancelCreate()
}

const nextStep = (formDatas: any, stepControl: TMultiStepsControl) => {
  (form as any)[stepControl.name] = formDatas;
  stepControl.next()
}

const onFinish = () => {
  const dayZoneAppliances: TProgramFromHabit['dayZoneAppliances'] = [];
  const nightZoneAppliances: TProgramFromHabit['nightZoneAppliances'] = [];
  const voidZoneAppliances: TProgramFromHabit['voidZoneAppliances'] = [];

  const {
    createHabitHours,
    createWeekPresence,
    createZoneAppliances
  } = form;

  for (const key in createZoneAppliances!) {
    const applianceId = parseInt(key);
    const createZoneAppliance = createZoneAppliances[key];

    if (createZoneAppliance.length === 1 && createZoneAppliance.includes(EBehaviourPeriod.Void)) {
      voidZoneAppliances.push(applianceId);
    } else if (createZoneAppliance.length === 0) {
      dayZoneAppliances.push(applianceId);
      nightZoneAppliances.push(applianceId);
    } else {
      for (const period of createZoneAppliance) {
        if (period === EBehaviourPeriod.Day) {
          dayZoneAppliances.push(applianceId);
        }
        if (period === EBehaviourPeriod.Night) {
          nightZoneAppliances.push(applianceId);
        }
      }
    }
  }

  ProgramModule.createProgramFromHabit({
    name: props.baseForm.name,

    /*Step 1*/
    weekBedtime: createHabitHours!.weekBedtime,
    weekWakeUpTime: createHabitHours!.weekWakeUpTime,
    weekendBedtime: createHabitHours!.weekendBedtime,
    weekendWakeUpTime: createHabitHours!.weekendWakeUpTime,

    /*Step 2*/
    weekLeavingHomeTime: createWeekPresence!.weekLeavingHomeTime,
    weekBackHomeTime: createWeekPresence!.weekBackHomeTime,
    weekMiddayLeavingHomeTime: createWeekPresence!.weekMiddayLeavingHomeTime,
    weekMiddayBackHomeTime: createWeekPresence!.weekMiddayBackHomeTime,

    /*Step 3*/
    dayZoneAppliances,
    nightZoneAppliances,
    voidZoneAppliances,
  })
      .then(program => {
        isFinish.value = true;
        newProgram.value = program;
        linkToprogram.value = `${ERoutes.PROGRAM}/${newProgram.value.id}/${ERoutes.ADDON_PLANNING}`;
        pageScroll(true);
      }).catch(e => {
    console.error(e)
  });
}

onBeforeMount(() => {
  AppliancesModule.getAppliances();
  pageScroll(true);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isSubmit.value)
      ? (_openBlock(), _createBlock(MyVPageWithReturn, {
          key: 0,
          title: _ctx.$t('programCreate.tunnel.title.from_habit'),
          type: "page",
          returnAction: back
        }, {
          default: _withCtx(() => [
            _createVNode(MyVMultiSteps, {
              ref_key: "stepper",
              ref: stepper,
              onFinish: onFinish
            }, {
              createHabitHours: _withCtx(({step}) => [
                _createVNode(MyVProgramCreateHabitHours, {
                  onSubmit: (formDatas)=> nextStep(formDatas,step),
                  weekBedtime: form[step.name]?.weekBedtime,
                  weekWakeUpTime: form[step.name]?.weekWakeUpTime,
                  weekendBedtime: form[step.name]?.weekendBedtime,
                  weekendWakeUpTime: form[step.name]?.weekendWakeUpTime
                }, null, 8, ["onSubmit", "weekBedtime", "weekWakeUpTime", "weekendBedtime", "weekendWakeUpTime"])
              ]),
              createWeekPresence: _withCtx(({step}) => [
                _createVNode(MyVProgramCreateWeekPresence, {
                  onSubmit: (formDatas)=> nextStep(formDatas,step),
                  weekLeavingHomeTime: form[step.name]?.weekLeavingHomeTime,
                  weekBackHomeTime: form[step.name]?.weekBackHomeTime,
                  weekMiddayLeavingHomeTime: form[step.name]?.weekMiddayLeavingHomeTime,
                  weekMiddayBackHomeTime: form[step.name]?.weekMiddayBackHomeTime
                }, null, 8, ["onSubmit", "weekLeavingHomeTime", "weekBackHomeTime", "weekMiddayLeavingHomeTime", "weekMiddayBackHomeTime"])
              ]),
              createZoneAppliances: _withCtx(({step}) => [
                _createVNode(MyVProgramCreateZoneAppliances, {
                  onSubmit: (formDatas)=> nextStep(formDatas,step),
                  appliances: appliances.value,
                  behaviours: form[step.name]
                }, null, 8, ["onSubmit", "appliances", "behaviours"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (isFinish.value)
      ? (_openBlock(), _createBlock(MyVPageWithReturn, {
          key: 1,
          title: "",
          type: "page",
          "return-action": back,
          class: "scrollable programCreateSuccess"
        }, {
          default: _withCtx(() => [
            _createVNode(ProgramCreateSuccess, {
              linkToProgram: linkToprogram.value,
              isVx: isVxVersion.value
            }, null, 8, ["linkToProgram", "isVx"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})