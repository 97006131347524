export enum EDisplayGroupRightName {
    /**
     * Display group name use for "DIAG_ENERGIE"
     */
    DIAG_ENERGIE = "DIAG_ENERGIE",
    
    /**
     * Display group name use for "NOTIF_PARTENAIRE"
     */
    NOTIF_PARTENAIRE = "NOTIF_PARTENAIRE",
    
    /**
     * Display group name use for "BUDGET_NOTIFICATION_ALERT"
     */
    BUDGET_NOTIFICATION_ALERT = "BUDGET_NOTIFICATION_ALERT",
    
    /**
     * Display group name use for "MY_CONTRACT"
     */
    MY_CONTRACT = "MY_CONTRACT",
    
    /**
     * Display group name use for "TEMPERATURE_EXT"
     */
    TEMPERATURE_EXT = "TEMPERATURE_EXT",
    
    /**
     * Display group name use for "NEWS"
     */
    NEWS = "NEWS",
    
    /**
     * Display group name use for "NOTIF_NEWS"
     */
    NOTIF_NEWS = "NOTIF_NEWS",
    
    /**
     * Display group name use for "LINKY"
     */
    LINKY = "LINKY",
    
    /**
     * Display group name use for "MY_BUDGET"
     */
    MY_BUDGET = "MY_BUDGET",
    
    /**
     * Display group name use for "HELP"
     */
    HELP = "HELP",
    
    /**
     * Display group name use for "MY_HOUSE"
     */
    MY_HOUSE = "MY_HOUSE",
    
    /**
     * Display group name use for "TREND_DETAILS"
     */
    TREND_DETAILS = "TREND_DETAILS",
    
    /**
     * Display group name use for "NOTIF_BILAN"
     */
    NOTIF_BILAN = "NOTIF_BILAN",
    
    /**
     * Display group name use for "HELP_MAIL"
     */
    HELP_MAIL = "HELP_MAIL",
    
    /**
     * Display group name use for "DISPLAY_INSTANT_POWER"
     */
    DISPLAY_INSTANT_POWER = "DISPLAY_INSTANT_POWER",
    
    /**
     * Display group name use for "NEWSCHEDULER"
     */
    NEWSCHEDULER = "NEWSCHEDULER",
    
    /**
     * Display group name use for "ONOFF"
     */
    ONOFF = "ONOFF",
    
    /**
     * Display group name use for "ABSENCE"
     */
    ABSENCE = "ABSENCE",
    
    /**
     * Display group name use for "OPTI_CONSUMPTION"
     */
    OPTI_CONSUMPTION = "OPTI_CONSUMPTION",
    
    /**
     * Display group name use for "ECO_MODE"
     */
    ECO_MODE = "ECO_MODE",
    
    /**
     * Display group name use for "MY_OBJECTIVES"
     */
    MY_OBJECTIVES = "MY_OBJECTIVES",
    
    /**
     * Display group name use for "DISAGGREGATION"
     */
    DISAGGREGATION = "DISAGGREGATION",
    
    /**
     * Display group name use for "NOTIFICATION"
     */
    NOTIFICATION = "NOTIFICATION",
    
    /**
     * Display group name use for "HELP_TELEPHONE"
     */
    HELP_TELEPHONE = "HELP_TELEPHONE",
    
    /**
     * Display group name use for "DIAG_TARIF"
     */
    DIAG_TARIF = "DIAG_TARIF",
    
    /**
     * Display group name use for "CONTACT"
     */
    CONTACT = "CONTACT",
    
    /**
     * Display group name use for "REAL_TIME_ACTIVATION"
     */
    REAL_TIME_ACTIVATION = "REAL_TIME_ACTIVATION",
    
    /**
     * Display group name use for "MY_ADVICES"
     */
    MY_ADVICES = "MY_ADVICES",
    
    /**
     * Display group name use for "DIAG_PUISSANCE"
     */
    DIAG_PUISSANCE = "DIAG_PUISSANCE"
}

export enum EDisplayGroupRightType {
    /**
     * Is the user allows to see and use the module.
     */
    ENABLE = 'ENABLE',

    /**
     * Is the user allows to see and but not to use the module.
     */
    DISPLAY = 'DISPLAY'
}

export type TDisplayGroupRight = {
  /**
   * Right's name.
   */
  name: EDisplayGroupRightName;

  /**
   * Is Right enabled ?
   */
  enabled: boolean;

  /**
   * Type of right allowed.
   */
  type: EDisplayGroupRightType;
};

export type TDisplayGroupRights = TDisplayGroupRight[];

export type TDisplayGroupResources = {
  /**
   * Resource informations about "favicon"
   */
  favicon: string;

  /**
   * Resource informations about "logo"
   */
  logo: string;

  /**
   * Resource informations about "fontcolor"
   */
  fontcolor: string;

  /**
   * Resource informations about "secondarycolor"
   */
  secondarycolor: string;

  /**
   * Resource informations about "headercolor"
   */
  headercolor: string;

  /**
   * Resource informations about "primarycolor"
   */
  primarycolor: string;

  /**
   * Resource informations about "font"
   */
  font: string;
};

export type TDisplayGroup = {
  /**
   * Display group's name (use for debug).
   */
  name: string;

  /**
   * Display group's resources informations.
   */
  resources: TDisplayGroupResources;

  /**
   * Display group's rights.
   */
  rights: TDisplayGroupRights;
};