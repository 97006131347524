import API from "@/helpers/api";
import { TNextDisplayDate, TRatingService } from "@/services/Rating/interfaces";

const RatingService: TRatingService = {

  getNextDisplayDate: () => (
    API.get<TNextDisplayDate>(`/api/app/rate/next-display-date`)
      .then(({ data }) => data)
  ),

  updateNextDisplayDate: () => (
    API.put(`/api/app/rate`)
      .then(({ data }) => data)
  ),
};

export default RatingService;
