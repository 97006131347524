import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-mode", "title"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["xlink:href"]

import {heatingIcon, heatingType} from "@/helpers/domains/heatingType";

import {TPlanningBarItem} from './interfaces';
import {EApplianceMode} from "@/services/Appliances/interfaces";
import {computed} from "vue";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPlanningBarItem',
  props: {
    planning: {}
  },
  setup(__props: any) {

const props = __props

const {t}= useI18n()

const width= computed((): number => {
  return Math.ceil(props.planning.duration * 100 / 24);
})

const mode= computed((): string => {
  return props.planning.isOn ? props.planning.mode.toLowerCase().replace(" ", "-") : 'off'
})

const icon= computed((): string => {
  return heatingIcon(props.planning.mode, props.planning.isOn);
} )

const getHeatingType = computed((): string=> {
  return t(heatingType(props.planning.mode, props.planning.isOn));
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(['planningBarItem', mode.value]),
    style: _normalizeStyle({ 'width': width.value + '%'  }),
    "data-mode": mode.value,
    title: getHeatingType.value
  }, [
    (_ctx.planning.mode === _unref(EApplianceMode).Temp)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.planning.temperature) + "° ", 1))
      : (_openBlock(), _createElementBlock("svg", {
          key: 1,
          class: _normalizeClass(['planningBarItem__icon', 'icon icon-'+icon.value])
        }, [
          _createElementVNode("use", {
            "xlink:href": ['#icon-' + icon.value]
          }, null, 8, _hoisted_3)
        ], 2))
  ], 14, _hoisted_1))
}
}

})