import {androidEkwateur, androidMyv, iosEkwateur, iosMyv} from '@/components/domains/MobileApp/interfaces';
import {AuthModule} from "@/store";

export function useAppStoreRedirect() {

    const goToStore = () => {
        const userAgent = window.navigator.userAgent;
        const isIos = /iPhone/.test(userAgent) || /iPad/.test(userAgent);
        const isAndroid = /Android/.test(userAgent);

        const appUrls = {
            ios: AuthModule.isEkwateur ? iosEkwateur : iosMyv,
            android: AuthModule.isEkwateur ? androidEkwateur : androidMyv,
        };

        if (isIos) {
            window.location.href = appUrls.ios;
        } else if (isAndroid) {
            window.location.href = appUrls.android;
        }
    };

    return {goToStore};
}
