export enum EDiagnosticAnswers {
    YES = 'YES',
    NO = 'NO',
}

export type TResolutionButton = {
    label: string;
    action: () => void;
    isRefresh?: boolean;
}

export type TResolutionStep = {
    id: number;
    icon?: string;
    title?: string;
    text?: string | string[];
    animation?: string;
    answerType?: EDiagnosticAnswers;
    children?: TResolutionStep[];
    buttons?: TResolutionButton[];
}