import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {THalfCircleGraphic} from "./interfaces";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {onBeforeMount, onUpdated, ref, watch} from "vue";
import useCurrency from "@/composables/currency";

interface Props {
  items: THalfCircleGraphic["items"];
  targetValue?: THalfCircleGraphic["targetValue"];
  texts: THalfCircleGraphic["texts"];
  tooltip?: THalfCircleGraphic["tooltip"];
  fontFamily?: THalfCircleGraphic["fontFamily"];
  consoType: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHalfCircleGraphic',
  props: {
    items: { default: undefined },
    targetValue: { default: 0 },
    texts: { default: undefined },
    tooltip: { default: undefined },
    fontFamily: { default: undefined },
    consoType: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

const {formatCurrency} = useCurrency()

const chartDatas = ref<any>(null);
const update = ref(false);
const chartRef = ref(null)
const createChart = () => {
  const targetValueLabel = '__target__';
  const datas: number[] = [];
  const backgroundsColor: string[] = [];
  const labels: string[] = [];
  let allValue = 0;

  props.items.forEach(({label, value, color}) => {
    labels.push(label);
    datas.push(value);
    backgroundsColor.push(color);

    allValue += value;
  });

  const gapBetweenTargetedValue = props.targetValue - allValue;

  if (gapBetweenTargetedValue > 0) {
    labels.push(targetValueLabel);
    datas.push(gapBetweenTargetedValue);
    backgroundsColor.push('rgb(228, 232, 239)');
  }

  const consoType = props.consoType
  chartDatas.value = {
    type: 'roundedDoughnut',
    options: {
      responsive: true,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          filter: (tooltipItem: any) => (gapBetweenTargetedValue > 0) ? (tooltipItem.dataIndex !== datas.length - 1) : true,
          callbacks: {
            label: function (context: any) {
              const valueText = consoType ? `${context.formattedValue} ${EEnergyUnit.kWh}` : formatCurrency(context.formattedValue)
              return ` ${context.label}: ${valueText}`
            },
            title: function () {
              return null
            }
          },
        },
      },
      layout: {
        padding: {
          top: 24,
          bottom: 24
        },
      },
      texts: props.texts,
      tooltip: props.tooltip,
      fontFamily: props.fontFamily
    },
    data: {
      datasets: [{
        data: datas,
        backgroundColor: backgroundsColor,
        hoverBackgroundColor: backgroundsColor,
        datalabels: {
          display: false,
        },
        borderWidth: 0,
      }],
      labels: labels,
    },
  };
}

onUpdated(() => {
  if (update.value) {
    createChart();
    (chartRef.value as any).chartJSState.props.data = chartDatas.value.data;
    (chartRef.value as any).chartJSState.props.options = chartDatas.value.options;
    (chartRef.value as any).update();
    update.value = false;
  }
})

onBeforeMount(() => {
  createChart()
})

watch([() => props.items, () => props.targetValue, () => props.texts, () => props.tooltip], () => {
  update.value = true
})


return (_ctx: any,_cache: any) => {
  return (chartDatas.value !== null)
    ? (_openBlock(), _createBlock(_unref(Vue3ChartJs), _mergeProps({
        key: 0,
        ref_key: "chartRef",
        ref: chartRef
      }, chartDatas.value), null, 16))
    : _createCommentVNode("", true)
}
}

})