import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";

export const formatNumberWithAllDecimal = (myNumber: number) => (IntlModule.locale === ELocale.fr) ? myNumber.toString().replace(".", ",") : myNumber.toString();
export const formatNumber = (myNumber: number, decimal = 2) => (IntlModule.locale === ELocale.fr) ? myNumber.toFixed(decimal).replace(".",",") : myNumber.toFixed(decimal);

export const digitalFormat = (num: number | string, decimal = 1): any => {
    if (typeof num !== 'number') {
        num = parseFloat(num);
    }
    const res = num.toLocaleString(undefined, {maximumFractionDigits: decimal});

    if (IntlModule.locale?.startsWith(ELocale.fr)) {
        return res.replace(".", ",");
    }
    return res.replace(",", ".");
}

export const roundNumber = (number: number, precision = 2) => {
    const roundPrecision = Math.pow(10, precision);

    return Math.round((number + Number.EPSILON) * roundPrecision) / roundPrecision;
};

export const roundNumber2 = (number: number) => roundNumber(number, 2);

export function hasFractionalDigits(number: number): boolean {
  return number % 1 !== 0 ;
}
