import {EApplianceMode} from "@/services/Appliances/interfaces";
import {TContractTimePeriod, TContractTimePeriods} from "@/services/Contract/interfaces";
import {TDailyProg, TDailyProgRange, TDailyProgRanges, TDailyProgs} from "@/services/Program/interfaces";
import {EPlainTextHours} from "@/components/domains/Heating/MyVEditRange/interfaces";

export type TTimeSlotDate = {
    /**
     * Day number of the week. 0 mean "Sunday".
     */
    dayNumber: TDailyProg['dayNumber'];

    /**
     * Time period. (format: HH:mm)
     */
    time: TDailyProgRange['from'];
};

export type TTimeSlot = {
    /**
     * Start of the new slot.
     */
    from: TTimeSlotDate;

    /**
     * End of the new slot.
     */
    to: TTimeSlotDate;

    /**
     * Time slot is on ?
     */
    isOn: TDailyProgRange['isOn'];

    /**
     * Time slot's mode
     */
    mode: TDailyProgRange['mode'];

    /**
     * Time slot's temperature
     */
    temperature: TDailyProgRange['temperature'];
};

export type TTimeSlots = TTimeSlot[];

type TDailyProgWithoutId = Omit<TDailyProg, 'id'>;

export const convertDayTimeToFrLocaleOrder = (dayNumber: TDailyProg['dayNumber']) => (dayNumber - 1 + 7) % 7;

export const g_firstDayTime = EPlainTextHours.Midnight;
export const g_lastDayTime = EPlainTextHours.OneMinuteBeforeMidnight;
export const g_timeFormat = 'HH:mm';

export const g_defaultMode = EApplianceMode.Comfort;
export const g_defaultIsOn = true;
export const g_defaultTemperature = 19;

export type TDailyProgRangeSegment = TDailyProgRange & {
    /**
     * Is the range add by algorithm ?
     */
    isGenerated?: boolean;
};

export type TFillDailyProgSegmentsOptions = {
    /**
     * Segment of same type should be merged. (Default: false)
     */
    mergeSlot: boolean;

    /**
     * Segment of same type should be merged. (Default: false)
     * If mergeSlot is true, this parameter will be ignored.
     */
    mergeOnlyNewSlot: boolean;

    /**
     * Fill empty slot segment with default one (Default: false).
     */
    generateEmptySlot: boolean;
};

const defaultFillDailyProgSegmentsOptions: TFillDailyProgSegmentsOptions = {
    mergeSlot: false,
    mergeOnlyNewSlot: false,
    generateEmptySlot: false
};

const IsSameRange = (
    range1: TDailyProgRange,
    range2: Partial<TDailyProgRange>,
    ignoreTemperature: boolean
) => (
    (range1?.mode === (range2.mode ?? g_defaultMode)
        && range1.isOn === (range2.isOn ?? g_defaultIsOn)
        && (ignoreTemperature || range1.temperature === range2.temperature))
    || (range1.isOn === false && range2.isOn === false)
);

const clearRange = (range: TDailyProgRange) => {
    delete (range as TDailyProgRangeSegment).isGenerated;

    return range;
};

const addRangeToArray = (
    ranges: TDailyProgRangeSegment[],
    newRange: Partial<TDailyProgRange>,
    mergeSlot: TFillDailyProgSegmentsOptions['mergeSlot'],
    generateEmptySlot: TFillDailyProgSegmentsOptions['generateEmptySlot'],
    isGenerated = false,
    ignoreTemperature = false
) => {
    const _newRange = {
        ...newRange,
        isOn: newRange.isOn ?? g_defaultIsOn,
        mode: newRange.mode ?? g_defaultMode,
        temperature: newRange.temperature ?? g_defaultTemperature
    } as TDailyProgRange;
    let newStartTime = _newRange.from!;

    if (ranges.length > 0) {
        const lastRange = ranges[ranges.length - 1];

        if (lastRange.to.localeCompare(newStartTime) >= 0) {
            if (mergeSlot && IsSameRange(lastRange, _newRange, ignoreTemperature) &&
                (generateEmptySlot || (!(lastRange as TDailyProgRangeSegment).isGenerated) && !isGenerated)) {
                lastRange.to = _newRange.to!;
            }
            newStartTime = lastRange.to;
        }
    }

    if (newStartTime.localeCompare(_newRange.to) < 0) {
        ranges.push({
            ..._newRange,
            from: newStartTime,
            isGenerated
        });
    }
}

export const fillDailyProgSegments = (
    dailyProgs: TDailyProgs,
    newSlots: TTimeSlots = [],
    {
        mergeSlot = defaultFillDailyProgSegmentsOptions.mergeSlot,
        mergeOnlyNewSlot = defaultFillDailyProgSegmentsOptions.mergeOnlyNewSlot,
        generateEmptySlot = defaultFillDailyProgSegmentsOptions.generateEmptySlot
    } = {}): TDailyProgs => {
    const additionnalDailyProgs: TDailyProgWithoutId[] = [];

    // Convert New slots to dailyprogs segments.
    newSlots
        .sort((a, b) => {
            if (a.from.dayNumber === b.from.dayNumber) {
                return a.from.time.localeCompare(b.from.time);
            }
            return convertDayTimeToFrLocaleOrder(a.from.dayNumber) - convertDayTimeToFrLocaleOrder(b.from.dayNumber);
        })
        .forEach(({from, to, ...rangeDatas}) => {
            for (let day = from.dayNumber; day <= to.dayNumber; ++day) {
                let dailyProg = additionnalDailyProgs.find(({dayNumber}) => dayNumber === day);
                const newStartTime = from.dayNumber === day ? from.time : g_firstDayTime;
                const newEndTime = to.dayNumber === day ? to.time : g_lastDayTime;

                if (!dailyProg) {
                    dailyProg = {
                        dayNumber: day,
                        ranges: []
                    };
                    additionnalDailyProgs.push(dailyProg);
                }

                addRangeToArray(dailyProg.ranges, {
                    from: newStartTime,
                    to: newEndTime,
                    ...rangeDatas,
                }, mergeSlot || mergeOnlyNewSlot, generateEmptySlot);
            }
        });

    return dailyProgs
        .map(dailyProg => {
            const oldRanges: TDailyProgRanges = dailyProg.ranges?.map(r => ({...r}));
            const additionnalRanges = additionnalDailyProgs.find(({dayNumber}) => dayNumber === dailyProg.dayNumber)?.ranges || [];
            const allRanges: TDailyProgRanges = [];
            const ranges: TDailyProgRanges = [];
            let endLastRange = g_firstDayTime as string;

            if (oldRanges?.length) {
                for (const oldRange of oldRanges) {
                    for (let i = 0; i < additionnalRanges.length; i++) {
                        const newRange = additionnalRanges[i];

                        // Collision test.
                        if (newRange.from.localeCompare(oldRange.to) < 0 && newRange.to.localeCompare(oldRange.from) > 0) {
                            const fromCompare = newRange.from.localeCompare(oldRange.from);
                            const toCompare = newRange.to.localeCompare(oldRange.to);
                            const isBeforeFrom = fromCompare <= 0;
                            const isAfterTo = toCompare >= 0;

                            if (isBeforeFrom) {
                                allRanges.push(additionnalRanges[i]);
                                if (!isAfterTo) {
                                    oldRange.from = newRange.to;
                                }
                            } else {
                                if (isAfterTo) {
                                    allRanges.push({
                                        ...oldRange,
                                        to: newRange.from
                                    });
                                    oldRange.from = newRange.to;
                                } else if (additionnalRanges[i].from !== g_firstDayTime) {
                                    allRanges.push({
                                        ...oldRange,
                                        from: oldRange.from,
                                        to: additionnalRanges[i].from
                                    });
                                }
                                allRanges.push(additionnalRanges[i]);
                                if (oldRange.from === oldRange.to) {
                                    break;
                                }
                            }
                        } else if (newRange.from.localeCompare(oldRange.to) < 0) {
                            allRanges.push(additionnalRanges[i]);
                        } else {
                            break;
                        }
                    }

                    const lastRange = allRanges.length > 0 ? allRanges[allRanges.length - 1] : undefined;

                    if (lastRange && lastRange.to.localeCompare(oldRange.from) > 0) {
                        oldRange.from = lastRange.to;
                    }

                    if (oldRange.from.localeCompare(oldRange.to) < 0) {
                        allRanges.push(oldRange);
                    }
                }
            }

            allRanges.push(...additionnalRanges);

            for (const range of allRanges) {
                if (range.from.localeCompare(endLastRange) > 0) {
                    addRangeToArray(ranges, {
                        from: endLastRange,
                        to: range.from,
                        temperature: range.temperature
                    }, mergeSlot, generateEmptySlot, true, true);
                }

                addRangeToArray(ranges, range, mergeSlot, generateEmptySlot);
                endLastRange = range.to;
            }

            if (g_lastDayTime.localeCompare(endLastRange) > 0) {
                addRangeToArray(ranges, {
                    from: endLastRange,
                    to: g_lastDayTime
                }, mergeSlot, generateEmptySlot, true, true);
            }

            return ({
                ...dailyProg,
                ranges: generateEmptySlot ? ranges.map(clearRange) : ranges.filter(range => {
                    const remove = !(range as TDailyProgRangeSegment).isGenerated;

                    clearRange(range);
                    return !generateEmptySlot && remove;
                }),
            });
        });
};

/**
 * We format hours and min into number so we can sum multiple values in order to check the result.
 * For example, 14h30 will be 1430 in number format
 */
export const convertHoursAndMinutesToNumber = (time: TContractTimePeriods[number]['from']) => {
    const [hour, min] = time.split(':');

    return parseInt(hour) * 100 + parseInt(min);
}

export const splitTimePeriods = (ranges: TContractTimePeriods) => {
    const newRanges: TContractTimePeriods = [];

    for (const {from, to} of ranges) {
        const _newRanges: TContractTimePeriods = [];

        switch (from.localeCompare(to)) {
            case 1:
                _newRanges.push({
                    from,
                    to: g_lastDayTime,
                }, {
                    from: g_firstDayTime,
                    to
                });
                break;
            case -1:
                _newRanges.push({from, to});
                break;
        }

        // Check collision
        for (const range of _newRanges) {
            const collision = newRanges.findIndex(_range => (
                (range.from <= _range.from && _range.from <= range.to) ||
                (range.from <= _range.to && _range.to <= range.to)
            ));

            if (collision !== -1) {
                if (newRanges[collision].from > range.from) {
                    newRanges[collision].from = range.from;
                }
                if (newRanges[collision].to < range.to) {
                    newRanges[collision].to = range.to;
                }
            } else {
                newRanges.push(range);
            }
        }
    }

    return newRanges.sort((time1, time2) => time1.from.localeCompare(time2.from));
};

export const mergePeriods = (ranges: TContractTimePeriods) => {

    let newRanges: TContractTimePeriods = [];
    const indexStartMidnight = ranges.findIndex(range => range.from === g_firstDayTime);
    const indexEndMidnight = ranges.findIndex(range => range.to === g_lastDayTime);

    if (indexStartMidnight === -1 || indexEndMidnight === -1) {
        return ranges.sort((time1, time2) => time1.from.localeCompare(time2.from));
    }

    const mergedTwoRanges: TContractTimePeriod = {
        from: ranges[indexEndMidnight].from,
        to: ranges[indexStartMidnight].to
    }

    newRanges.push(mergedTwoRanges);

    const filteredRanges = ranges.filter(range => {
        return range.to !== mergedTwoRanges.to && range.from !== mergedTwoRanges.from;
    })

    newRanges = [
        ...newRanges,
        ...filteredRanges
    ];

    return newRanges.sort((time1, time2) => time1.from.localeCompare(time2.from));
}
