import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment } from "vue"

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {TBarGraphic} from "./interfaces";
import {ref} from "vue";
import {onBeforeMount, onUpdated, watch} from "vue";

interface Props {
  labels: TBarGraphic["labels"];
  datas: TBarGraphic["datas"];
  leftAxis: TBarGraphic["leftAxis"];
  color: TBarGraphic["color"];
  minBarLength?: TBarGraphic["minBarLength"];
  labelFilter?: TBarGraphic["labelFilter"];
  onAdjustChart?: TBarGraphic['onAdjustChart'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBarGraphic',
  props: {
    labels: { default: undefined },
    datas: { default: undefined },
    leftAxis: { default: undefined },
    color: { default: undefined },
    minBarLength: { default: undefined },
    labelFilter: { type: Function, default: undefined },
    onAdjustChart: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const chartDatas = ref<any>();
const update = ref(false);
const chartRef = ref(null);
const createChart = () => {
  const labelFilter = props.labelFilter !== undefined ? props.labelFilter : () => true;
  const labels = props.labels;
  const {red, green, blue} = props.color;
  const color = `${red}, ${green}, ${blue}`;

  const datasets = [{
    type: "bar",
    barPercentage: 1,
    categoryPercentage: 1,
    data: props.datas,
    minBarLength: props.minBarLength,
    borderColor: `rgb(${color})`,
    borderWidth: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    backgroundColor: (args: any) => (args.dataIndex === (labels.length - 1)) ? `rgb(${color})` : `rgba(${color}, 0.4)`,
    hoverBackgroundColor: (args: any) => (args.dataIndex === (labels.length - 1)) ? `rgb(${color})` : `rgba(${color}, 0.4)`,
    order: 1,
    datalabels: {
      display: false,
    },
  }];

  chartDatas.value = {
    type: "bar",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 24,
        },
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            labelColor: () => ({backgroundColor: `rgb(${color})`}),
          },
        }
      },
      scales: {
        x: {
          ticks: {
            callback: function (value: number) {
              const label: string = (this as any) ? (this as any).getLabelForValue(value) : undefined;

              return labelFilter(label, value, labels) ? label : '';
            },
          },
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          min: props.leftAxis.min,
          max: props.leftAxis.max,
          ticks: {
            stepSize: props.leftAxis.step || 1
          },
          grid: {
            display: true,
            borderWidth: false,
            borderDash: [8, 4]
          },
        },
      },
    },
    data: {
      datasets,
      labels: props.labels,
    },
  };

  props.onAdjustChart?.(chartDatas.value);
}

onUpdated(() => {
  if (update.value && chartRef.value) {
    createChart();
    (chartRef.value as any).chartJSState.props.data = chartDatas.value.data;
    (chartRef.value as any).chartJSState.props.options = chartDatas.value.options;
    (chartRef.value as any).update();
    update.value = false;
  }
})

onBeforeMount(() => {
  createChart()
})

watch([() => props.labels, () => props.datas, () => props.leftAxis, () => props.labelFilter, () => props.color], () => {
  update.value = true
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.leftAxis)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.axisTitles)
        }, _toDisplayString(_ctx.leftAxis.title), 3))
      : _createCommentVNode("", true),
    _createVNode(_unref(Vue3ChartJs), _mergeProps({
      ref_key: "chartRef",
      ref: chartRef
    }, chartDatas.value), null, 16)
  ], 64))
}
}

})