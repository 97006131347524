import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {AuthModule} from "@/store";
import ERoutes from "@/router/routes";
import MyVPageWithReturn from '@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue';
import {computed, onBeforeMount} from "vue";
import {useRoute, useRouter} from "vue-router";
import {TNewPasswordFormDatas} from "@/components/domains/Profile/MyVNewPasswordForm/interfaces";
import MyVNewPasswordForm from "@/components/domains/Profile/MyVNewPasswordForm/MyVNewPasswordForm.vue";


const FORM_NEW_PASSWORD = "form_new_password"

export default /*@__PURE__*/_defineComponent({
  __name: 'NewPassword',
  setup(__props) {

const route = useRoute()
const router = useRouter()

const token = computed(() => {
  return route.query?.token as string
})

const subscriberId = computed(() => {
  return route.query?.subscriberId as any
})

const submit = ({password}: TNewPasswordFormDatas) => {
  checkAndSendPassword(password);
}

const checkAndSendPassword = (password: string) => {
  AuthModule.newPasswordUpdate({
    token: token.value,
    subscriberId: subscriberId.value,
    newPassword: password
  })
      .then(({token, oldMyVoltalisUrl}) => {
        if (oldMyVoltalisUrl) {
          return window.location.href = oldMyVoltalisUrl
        } else {
          AuthModule.tryConnectUserFromToken(token)
        }
      })
      .then(user => router.push(user ? ERoutes.HOME : ERoutes.RESET_EMAIL_TOKEN))
      .catch((e) => {
        if (e.response.data.oldMyVoltalisUrl) {
          return window.location.href = e.response.data.oldMyVoltalisUrl
        }

        router.push(ERoutes.RESET_EMAIL_TOKEN);
      });
}

onBeforeMount(() => {
  if (token.value && subscriberId.value) {
    AuthModule.checkNewPasswordTokenValidity({
      token: token.value,
      subscriberId: subscriberId.value
    })
        .catch(() => {
          router.push(ERoutes.RESET_EMAIL_TOKEN);
        });
  } else {
    router.push(ERoutes.HOME);
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('newPassword.title'),
    type: "popin",
    returnAction:  _unref(ERoutes).LOGIN
  }, {
    default: _withCtx(() => [
      _createVNode(MyVNewPasswordForm, {
        formId: FORM_NEW_PASSWORD,
        onSubmit: submit
      })
    ]),
    _: 1
  }, 8, ["title", "returnAction"]))
}
}

})