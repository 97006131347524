export enum EValidationState {
    /**
     * Validation is empty, usefull if empty is nor a success neither an error.
     */
    EMPTY = 0,

    /**
     * Validation is on success' state
     */
    SUCCESS = 1,

    /**
     * Validation is on error's state
     */
    ERROR = 2,
}