import API from "@/helpers/api";

import {
    EAggregationAlias,
    TConsoAggregated,
    TConsoRoute,
    TConsoService,
    TConsumptionFullData,
    TBreakdown,
} from "./interfaces";

const baseRoute = (siteId: number) => `/api/site/${siteId}/consumption`;

const consoAggregatedRoute: TConsoRoute = (siteId, date, aggregationType, alias, skipLoading = false) => (
    API.get<TConsoAggregated>(`${baseRoute(siteId)}/${alias}/${date}`, {
        params: {
            aggregationType
        },
        skipLoading
    })
        .then(({data}) => data)
);

const ConsoService: TConsoService = {
    getConsoDailyAggregated: (siteId, date, aggregationType, skipLoading = false) => consoAggregatedRoute(siteId, date, aggregationType, EAggregationAlias.Day, skipLoading),

    getConsoWeeklyAggregated: (siteId, date, aggregationType, skipLoading = false) => consoAggregatedRoute(siteId, date, aggregationType, EAggregationAlias.Week, skipLoading),

    getConsoMonthlyAggregated: (siteId, date, aggregationType, skipLoading = false) => consoAggregatedRoute(siteId, date, aggregationType, EAggregationAlias.Month, skipLoading),

    getConsoAnnualAggregated: (siteId, date, aggregationType, skipLoading = false) => consoAggregatedRoute(siteId, date, aggregationType, EAggregationAlias.Year, skipLoading),

    getSummaryRepartition: (siteId, months = [], skipLoading = false) => (
        API.get<TBreakdown[]>(`${baseRoute(siteId)}/category/summary`, {
            params: {
                months
            },
            skipLoading
        }).then(({data}) => data)
    ),

    getWeeklyRepartition: (siteId, weeks = [], skipLoading = false) => (
        API.get<TBreakdown[]>(`${baseRoute(siteId)}/category/week`, {
            params: {
                weeks
            },
            skipLoading
        }).then(({data}) => data)
    ),

    getMonthlyRepartition: (siteId, months = [], skipLoading = false) => (
        API.get<TBreakdown[]>(`${baseRoute(siteId)}/category/month`, {
            params: {
                months
            },
            skipLoading
        }).then(({data}) => data)
    ),

    getMultiYearRepartition: (siteId, years, skipLoading = false) => (
        API.get<TConsumptionFullData>(`${baseRoute(siteId)}/multi-year/${years}/full-data`, {
            skipLoading
        }).then(({data}) => data)
    ),

    getAnnualRepartition: (siteId, years = [], skipLoading = false) => (
        API.get<TBreakdown[]>(`${baseRoute(siteId)}/category/year`, {
            params: {
                years
            },
            skipLoading
        }).then(({data}) => data)
    ),

    getConsumptionFullData: (siteId, period, date) => (
        API.get<TConsumptionFullData>(`${baseRoute(siteId)}/${period}/${date}/full-data`)
            .then(({data}) => data)
    ),

    getRealTimeConso: (siteId, mode, numPoints) => (
        API.get<TConsoAggregated>(`${baseRoute(siteId)}/realtime`, {
            params: {
                mode,
                numPoints
            },
            skipLoading: true
        }).then(({data}) => data)
    ),

    stopModulation: (siteId) => (
        API.post(`${baseRoute(siteId)}/stopmodulation`)
            .then(({data}) => data)
    ),
};

export default ConsoService;