import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "CheckHideSection__checkbox inner" }
const _hoisted_2 = { class: "inner" }

import {Checkbox} from 'ant-design-vue';
import {TCheckHideSection} from './interfaces';
import {onBeforeMount, ref, watch} from "vue";

export interface Props {
  label: TCheckHideSection["label"];
  checked?: TCheckHideSection["checked"];
  defaultChecked?: TCheckHideSection["defaultChecked"];
  onChange?: TCheckHideSection["onChange"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCheckHideSection',
  props: {
    label: { default: undefined },
    checked: { type: [Boolean, null], default: false },
    defaultChecked: { type: Boolean, default: false },
    onChange: { type: Function, default: undefined }
  },
  emits: ['update:checked'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const m_checked = ref(false);

onBeforeMount(() => {
  m_checked.value = props.checked || props.defaultChecked;
})

const onLocalChange = (event: any) => {
  props.onChange?.(event.target.checked);

}

watch(() => props.checked, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emit('update:checked', newValue);
  }
})

watch(() => props.checked, (newValue, oldValue) => {
  if (newValue !== null && newValue !== oldValue && newValue !== m_checked.value) {
    m_checked.value = newValue;
  }

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['CheckHideSection',{'checked':m_checked.value}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Checkbox), {
        checked: m_checked.value,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((m_checked).value = $event)),
        onChange: onLocalChange
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['CheckHideSection__content',{'hide':!m_checked.value}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (m_checked.value)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}
}

})